import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Box, Grid2, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { fb, insta, linkdin, office } from "../../../assets";
import { RootState } from "../../../redux/reducers";
import { colors } from "../../../themes/colors";
import { getHomePageData } from "../../redux/actions";
import styles from "./cmsComponent.module.css";

const ContactUsInfo = () => {
  const dispatch = useDispatch();
  const { homePageData = [] } = useSelector(
    (state: RootState) => state.cmsDataReducer
  );
  useEffect(() => {
    dispatch(getHomePageData("populate[contactUs][populate]=*"));
  }, []);

  return (
    <Grid2 container spacing={2} className={styles.contactUsContainer}>
      <Grid2 size={{ sm: 12, md: 6 }}>
        <Stack className={styles.contactUs} gap="25px">
          <Typography
            variant="h4"
            sx={{
              color: colors.secondary_black,
            }}
          >
            {"Office Address"}
          </Typography>
          <Stack gap="25px">
            <Stack gap="10px">
              <Typography variant="h6">{"Corporate Address"}</Typography>
              <Typography variant="body2" fontWeight="400">
                {homePageData[0]?.contactUs?.corporateAddress}
              </Typography>
            </Stack>
            <Stack gap="10px">
              <Typography variant="h6">{"Regional Office Address"}</Typography>
              <Stack gap="7px">
                {homePageData[0]?.contactUs?.regionalOfficeAddress?.map(
                  (address: any) => {
                    return (
                      <Box className={styles.regionalAddress}>
                        <Typography variant="subtitle2" fontWeight="600">
                          {address?.label}
                        </Typography>
                        <Typography variant="subtitle2" fontWeight="600">
                          :
                        </Typography>
                        <Typography variant="body2" fontWeight="400">
                          {address?.value}
                        </Typography>
                      </Box>
                    );
                  }
                )}
              </Stack>
            </Stack>
            <Box className={styles.contactUsSocials}>
              <HeadingValueContactUs
                heading={"Chat with us"}
                value={
                  <NavLink
                    to={homePageData[0]?.contactUs?.chatUrl}
                    target="_blank"
                  >
                    <WhatsAppIcon sx={{ color: "green" }} />
                  </NavLink>
                }
              />
              <HeadingValueContactUs
                heading={"Email Us"}
                value={
                  <NavLink to={`mailto:${homePageData[0]?.contactUs?.email}`}>
                    <Typography
                      variant="body1"
                      sx={{ textDecoration: "underline" }}
                    >
                      {homePageData[0]?.contactUs?.email}
                    </Typography>
                  </NavLink>
                }
              />
              <HeadingValueContactUs
                heading={"Call Us"}
                value={
                  <Typography
                    variant="body1"
                    sx={{ textDecoration: "underline" }}
                  >
                    {homePageData[0]?.contactUs?.mobile}
                  </Typography>
                }
              />
              <HeadingValueContactUs
                heading={"Follow Us"}
                value={
                  <Box className={styles.followUs}>
                    <NavLink
                      to={homePageData[0]?.contactUs?.facebookUrl}
                      target="_blank"
                    >
                      <img src={fb} alt="facebook" />
                    </NavLink>
                    <NavLink
                      to={homePageData[0]?.contactUs?.instagramUrl}
                      target="_blank"
                    >
                      <img src={insta} alt="instagram" />
                    </NavLink>
                    <NavLink
                      to={homePageData[0]?.contactUs?.linkedinUrl}
                      target="_blank"
                    >
                      <img src={linkdin} alt="linkedin" />
                    </NavLink>
                  </Box>
                }
              />
            </Box>
          </Stack>
        </Stack>
      </Grid2>
      <Grid2 size={{ sm: 12, md: 6 }}>
        <img src={office} alt={"office Address"} style={{ maxWidth: "100%" }} />
      </Grid2>
    </Grid2>
  );
};

const HeadingValueContactUs = ({ heading, value }: any) => {
  return (
    <Stack>
      <Typography variant="h6">{heading}</Typography>
      <>{value}</>
    </Stack>
  );
};
export default ContactUsInfo;
