import { Box, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import "react-range-slider-input/dist/style.css";
import InputField from "../../../../component/InputField";
import { VehicleTypeEnum, VehicleTypesArray } from "../../../../constant/enum";
import UncontrolledAccordian from "../../../../sharedComponents/molecule/Accordions/UncontrolledAccordions";
import RangeSliderMUI from "../../../../sharedComponents/molecule/RangeSlider/RangeSliderMUI";
import { commmaSeparator } from "../../../../utills/helper";

const Filters = ({
  handleChange,
  handleCheckboxChange,
  rentDetails,
  secondaryFilter,
  setSecondaryFilter,
  maxPrice,
  minPrice,
  handleResetstate,
  handlePriceRangeChange
}) => {
  const { filters } = rentDetails;
 
  // Vehicle Type Filter
  const VehicleTypeContent = () => {
    return (
      <>
        <div className="vihicle-type-box">
          <div className="form-check all-vehicle-type-label">
            <input
              value={VehicleTypeEnum.All}
              type={"radio"}
              className="form-check-input  mt-0"
              onChange={(e) => handleChange("vehicleType", VehicleTypeEnum.All)}
              checked={secondaryFilter.vehicleType == VehicleTypeEnum.All}
              id="allVehicleType"
            />
            <div>
              <label className="form-check-label" htmlFor="allVehicleType">
                All Vehicle Type
              </label>
            </div>
          </div>

          {VehicleTypesArray.length > 0
            ? VehicleTypesArray.map((type: any, ind: number) => {
                let vehicleTypeId = `${type}Type`;
                return (
                  <div className="form-check" key={type}>
                    <input
                      value={type}
                      type={"radio"}
                      className="form-check-input mt-0"
                      onChange={(e) =>
                        handleChange("vehicleType", e.target.value)
                      }
                      checked={secondaryFilter?.vehicleType == type}
                      id={vehicleTypeId}
                    />
                    <label className="form-check-label" htmlFor={vehicleTypeId}>
                      {type}
                    </label>
                  </div>
                );
              })
            : null}
        </div>

        <InputField
          value={"electric"}
          name={"isElectric"}
          type={"checkbox"}
          className="form-check-input"
          onChange={() => {
            setSecondaryFilter((pre) => {
              return {
                ...pre,
                isElectric: !pre.isElectric,
                vehicleType: !pre.isElectric ? "" : pre.vehicleType,
              };
            });
          }}
          isChecked={secondaryFilter.isElectric}
          label="Electric"
        />
      </>
    );
  };

  // Price rance filter
  const PriceRangeContent = () => {
    return (
      <>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Box>
            {commmaSeparator(
              secondaryFilter?.priceRange && secondaryFilter?.priceRange[0]
            )}
            {"/-"}
          </Box>
          <Box>
            {commmaSeparator(
              secondaryFilter?.priceRange && secondaryFilter?.priceRange[1]
            )}
            {"/-"}
          </Box>
        </Stack>
        <RangeSliderMUI
          // selectedValue={priceRangeSlider}
          selectedValue={secondaryFilter?.priceRange}
          handleSliderChange={handlePriceRangeChange}
          minValue={minPrice}
          maxValue={maxPrice}
        />
      </>
    );
  };

  // Vehicle model filter
  const VehicleModelContent = () => {
    return (
      <>
        <Box className="form-check">
          <InputField
            value={""}
            name={"vehicleModel"}
            type={"checkbox"}
            className="form-check-input"
            onChange={(name, value, checked) =>
              handleCheckboxChange(name, value, checked)
            }
            isChecked={
              secondaryFilter.vehicleModel?.filter((item) => item.checked)
                .length == 0
            }
            label="All"
          />
        </Box>
        {secondaryFilter.vehicleModel?.length > 0
          ? secondaryFilter.vehicleModel?.map((model: any, ind: number) => {
              let modelId = model.id;
              return (
                <Box className="form-check" key={ind}>
                  <InputField
                    value={modelId}
                    name={modelId}
                    type={"checkbox"}
                    className="form-check-input"
                    onChange={(name, value, checked) => {
                      handleCheckboxChange("vehicleModel", modelId, checked);
                    }}
                    isChecked={model.checked}
                    label={model.model_name}
                  />
                </Box>
              );
            })
          : null}
      </>
    );
  };

  // filter accordian
  const accordianDataArray = [
    {
      title: filters.VehicleType,
      Content: VehicleTypeContent,
      defaultExpanded: true,
    },
    {
      title: filters.PriceRange,
      Content: PriceRangeContent,
      defaultExpanded: false,
    },
    {
      title: filters.VehicleModel,
      Content: VehicleModelContent,
      defaultExpanded: false,
    },
  ];

  return (
    <Grid size={{ xs: 12, md: 4, lg: 3 }} className={"renting-filter-wrapper"}>
      <Box className="filter-list-wrap responsive-xl">
        <Box className="filter-head-wrap">
          <Typography variant={"h4"}>{filters.Filters}</Typography>
          <Box className="cp" onClick={handleResetstate}>
            <i className="fa-solid fa-rotate-right"></i>
          </Box>
        </Box>
        <UncontrolledAccordian accordianDataArray={accordianDataArray} />
      </Box>
    </Grid>
  );
};

export default Filters;
