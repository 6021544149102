import { Box, Container, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import ControlledAccordions from "../../../../sharedComponents/molecule/Accordions/ControlledAccordions";
import styles from "../index.module.css";

const FAQs = ({ cmsData }: any) => {
  const [expandedPanel, setExpandedPanel] = useState<string | false>(false);

  const onChangeHandler = (panel: string, isExpanded: string | boolean) => {
    setExpandedPanel(isExpanded ? panel : false);
  };
  return (
    <Box className={styles.faqInnerWrapper}>
      <Typography variant="h4" align="center">
        {cmsData?.title}
      </Typography>
      <Container sx={{ padding: 0 }}>
        <Stack className="faq-wrap" justifyContent={"space-between"} gap="45px">
          <ControlledAccordions
            accordianDataArray={cmsData?.faqs?.slice(0, 3)}
            onChangeHandler={onChangeHandler}
            expandedPanel={expandedPanel}
            titleKey="question"
            contentKey="answer"
          />
          {cmsData?.faqs?.length > 2 ? (
            <Box textAlign={"center"}>
              <Link to="/faq" className="freedo-outline-btn-small rounded-pill">
                Show More
              </Link>
            </Box>
          ) : null}
        </Stack>
      </Container>
    </Box>
  );
};

export default FAQs;
