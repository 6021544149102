import {
  Box,
  Button,
  Chip,
  Icon,
  CircularProgress,
  IconButton,
  Avatar,
  Badge,
  ChipProps,
} from "@mui/material";

export const CustomIconButton = (props: any) => {
  return (
    <IconButton
      {...props}
      sx={{
        borderRadius: "60px",
        background: "transparent",
        transition: "background 1s ease",
        ...props?.buttonSx,
        "&:hover": {
          background: "linear-gradient(90deg, #5AF7DB 0%, #18E3F1 100%)",
        },
      }}
    >
      {props.children}
    </IconButton>
  );
};

export const CustomLinkButton = (props: any) => {
  return;
};

// props :-
// startIcon = need to pass icon as component
// startImageSrc = need to pass image source

export const CustomButton = (props: any) => {
  const {
    label = "",
    disabled,
    loading,
    startIcon = null,
    startImageSrc = null,
    wrapperStyles = null,
    endImg = "",
    ...rest
  } = props;
  return (
    <Box sx={{ ...wrapperStyles }}>
      <Button
        startIcon={
          startIcon ? (
            startIcon
          ) : startImageSrc ? (
            <img src={startImageSrc} />
          ) : (
            ""
          )
        }
        endIcon={endImg ? <img src={endImg} alt={endImg} /> : ""}
        disabled={disabled || loading}
        {...rest}
      >
        {!props.loading ? (
          props.children || label
        ) : (
          <CircularProgress color={props.loaderColor || "inherit"} size={12} />
        )}
      </Button>
    </Box>
  );
};

interface CustomChipProps extends Omit<ChipProps, "size" | "color" | "label"> {
  label: string;
  size?: "small" | "medium";
  color?:
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning";
  customStyle?: any;
  variant?: "filled" | "outlined";
}
export const CustomChip: React.FC<CustomChipProps> = ({
  label = "",
  size = "small",
  color = "primary",
  customStyle = {},
  variant = "outlined",

  ...rest
}) => {
  return (
    <Chip
      size={size}
      color={color}
      label={label}
      variant={variant}
      sx={{ ...customStyle }}
      {...rest} // Spread the rest of the props that are valid for MUI's Chip component
    />
  );
};
