import { call, put, select, takeLatest } from "redux-saga/effects";
import { actionInterface } from "../../interfaces/commonInterface";
import { USER_KYC_ACTIONS } from "../actions/actionTypes";
import userKycService from "../services/userKyc";
import {
  apiStatusCode,
  generateSignedUrlDocType,
  kycSubTypes,
  kycTypes,
} from "../../constants/enum";
import { toastr } from "react-redux-toastr";
import * as selectors from "../../../redux/selectors";
import { handleMessage } from "../../../utills/helper";

// saga to get user trust score
function* getUserTrustScore(action: actionInterface): any {
  try {
    const response = yield call(userKycService.getTrustScore, action.payload);

    if (response.status === apiStatusCode.SUCCESS) {
      yield put({
        type: USER_KYC_ACTIONS.GET_TRUST_SCORE_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      yield put({
        type: USER_KYC_ACTIONS.GET_TRUST_SCORE_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: USER_KYC_ACTIONS.GET_TRUST_SCORE_FAILED,
    });
  }
}

// saga to get user doc attachment ids
function* getKycAttachmentIds(action: actionInterface): any {
  try {
    const response = yield call(
      userKycService.getAttachmentIds,
      action.payload?.apiPayload
    );

    if (
      response.status === apiStatusCode.SUCCESS ||
      response.status === apiStatusCode.CREATED
    ) {
      yield put({
        type: USER_KYC_ACTIONS.GET_KYC_ATTACHMENT_ID_SUCCESS,
        payload: response?.data?.data[0],
      });

      yield put({
        type: USER_KYC_ACTIONS.PUT_KYC_DOCUMENT_ON_SIGNED_URL_REQUESTED,
        payload: {
          signedUrlResponse: response?.data?.data[0],
          fileData: action?.payload?.fileData,
          submitPayload: action?.payload?.submitPayload,
        },
      });
    } else {
      yield put({
        type: USER_KYC_ACTIONS.GET_KYC_ATTACHMENT_ID_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: USER_KYC_ACTIONS.GET_KYC_ATTACHMENT_ID_FAILED,
    });
  }
}

//upload user document
function* uploadUserDocSignedUrl(action: actionInterface) {
  try {
    const { signedUrlResponse, fileData, submitPayload } = action.payload;

    let response = yield call(userKycService?.uploadKycDocumentService, {
      url: signedUrlResponse?.signedUrl,
      file: fileData,
    });

    if (response.status === apiStatusCode.SUCCESS) {
      //data for kyc reducer
      const kycData = yield select(selectors.kycReducerData);

      let submitKycDocPayload: any = {
        [kycTypes.DRIVING_LICENCE]: kycData?.uploadFileData[
          kycTypes.DRIVING_LICENCE
        ]
          ? kycData?.uploadFileData[kycTypes.DRIVING_LICENCE]
          : {},
      };

      // Upload Dl data
      if (submitPayload.type === kycTypes.DRIVING_LICENCE) {
        submitKycDocPayload.DRIVING_LICENCE[submitPayload.subType] =
          signedUrlResponse?.id;
      }

      /* upload user profile image */
      if (submitPayload.type === generateSignedUrlDocType.PROFILE_IMAGE) {
        submitKycDocPayload[generateSignedUrlDocType.PROFILE_IMAGE] =
          signedUrlResponse?.id;
      }

      if (submitPayload.type === kycTypes.OTHER_DOCUMENT) {
        yield put({
          type: USER_KYC_ACTIONS.PUT_KYC_DOCUMENT_ON_SIGNED_URL_SUCCESS,
          payload: {
            [kycTypes.OTHER_DOCUMENT]: kycData?.uploadFileData[
              kycTypes.OTHER_DOCUMENT
            ]?.length
              ? kycData.uploadFileData[kycTypes.OTHER_DOCUMENT]
                  .map((item: any) =>
                    item.documentType === submitPayload?.subType
                      ? {
                          ...item,
                          attachmentIds: [signedUrlResponse?.id],
                        }
                      : item
                  )
                  .concat(
                    kycData.uploadFileData[kycTypes.OTHER_DOCUMENT].some(
                      (item: any) =>
                        item.documentType === submitPayload?.subType
                    )
                      ? []
                      : [
                          {
                            attachmentIds: [signedUrlResponse?.id],
                            documentType: submitPayload?.subType,
                          },
                        ]
                  )
              : [
                  {
                    attachmentIds: [signedUrlResponse?.id],
                    documentType: submitPayload?.subType,
                  },
                ],
          },
        });
      } else {
        yield put({
          type: USER_KYC_ACTIONS.PUT_KYC_DOCUMENT_ON_SIGNED_URL_SUCCESS,
          payload: {
            attachmentsIds: signedUrlResponse?.id,
            ...submitKycDocPayload,
          },
        });
      }
    } else {
      yield put({
        type: USER_KYC_ACTIONS.PUT_KYC_DOCUMENT_ON_SIGNED_URL_FAILED,
        payload: signedUrlResponse,
      });
      toastr.error("", "Document Upload Failed");
      yield put({
        type: USER_KYC_ACTIONS.KYC_DOCUMENT_UPLOAD_FAILED,
      });
    }
  } catch (error) {}
}

// saga to submit user docs
function* submitKycDocument(action: actionInterface): any {
  try {
    const response = yield call(
      userKycService.submitKycDocuments,
      action.payload
    );

    if (
      response.status === apiStatusCode.SUCCESS ||
      response.status === apiStatusCode.CREATED
    ) {
      if (action?.payload?.apiPayload?.data?.type === kycTypes.AADHAAR)
        window.open(response?.data?.data?.details?.url, "_blank");

      yield put({
        type: USER_KYC_ACTIONS.SUBMIT_KYC_DOCUMENT_SUCCESS,
        payload: response?.data?.data,
      });
      action.payload.successCallBack && action.payload.successCallBack();
    } else {
      yield put({
        type: USER_KYC_ACTIONS.SUBMIT_KYC_DOCUMENT_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: USER_KYC_ACTIONS.SUBMIT_KYC_DOCUMENT_FAILED,
    });
  }
}

//kyc mutiple doc upload
function* kycDocUploadSaga(action: actionInterface): any {
  try {
    const response = yield call(userKycService.kycDocUpload, action.payload);

    if (
      response.status === apiStatusCode.SUCCESS ||
      response.status === apiStatusCode.CREATED
    ) {
      yield put({
        type: USER_KYC_ACTIONS.KYC_DOCUMENT_UPLOAD_SUCCESS,
        payload: response?.data?.data,
      });

      action.payload.successCallBack && action.payload.successCallBack();
    } else {
      yield put({
        type: USER_KYC_ACTIONS.KYC_DOCUMENT_UPLOAD_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: USER_KYC_ACTIONS.KYC_DOCUMENT_UPLOAD_FAILED,
    });
  }
}

// saga to get url for aadhaar digital verification through digilocker
function* getAadhaarKycUrl(action: actionInterface): any {
  try {
    const response = yield call(
      userKycService.getAadhaarKycUrl,
      action.payload
    );

    if (
      response.status === apiStatusCode.SUCCESS ||
      response.status === apiStatusCode.CREATED
    ) {
      yield put({
        type: USER_KYC_ACTIONS.GET_URL_AADHAAR_VERIFICATION_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      yield put({
        type: USER_KYC_ACTIONS.GET_URL_AADHAAR_VERIFICATION_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: USER_KYC_ACTIONS.GET_URL_AADHAAR_VERIFICATION_FAILED,
    });
  }
}

// master function
function* userKycSaga() {
  yield takeLatest(
    USER_KYC_ACTIONS.GET_TRUST_SCORE_REQUESTED,
    getUserTrustScore
  );

  yield takeLatest(
    USER_KYC_ACTIONS.GET_KYC_ATTACHMENT_ID_REQUESTED,
    getKycAttachmentIds
  );

  yield takeLatest(
    USER_KYC_ACTIONS.SUBMIT_KYC_DOCUMENT_REQUESTED,
    submitKycDocument
  );

  yield takeLatest(
    USER_KYC_ACTIONS.KYC_DOCUMENT_UPLOAD_REQUESTED,
    kycDocUploadSaga
  );

  yield takeLatest(
    USER_KYC_ACTIONS.PUT_KYC_DOCUMENT_ON_SIGNED_URL_REQUESTED,
    uploadUserDocSignedUrl
  );

  yield takeLatest(
    USER_KYC_ACTIONS.GET_URL_AADHAAR_VERIFICATION_REQUESTED,
    getAadhaarKycUrl
  );
}

export default userKycSaga;
