import { Box, Stack, Typography } from "@mui/material";
import { digiLocker } from "../../../../assets";
import { Grid2 as Grid } from "@mui/material";
import Styles from "../sections/userKyc.module.css";
import { colors } from "../../../../themes/colors";

// aadhaar verification steps
const verificationSteps: string[] = [
  "1. Initiate KYC",
  "2. Enter Aadhaar Number",
  "3. Verify Aadhaar Via OTP",
  "4. Verification with Freedo is Done",
];

const Aadhaar: React.FC<any> = (props) => {
  return (
    <Stack
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "20px",
      }}
    >
      <img className={Styles.digiLockerImg} src={digiLocker} />
      <Typography
        variant="body1"
        sx={{ textAlign: "center", color: colors.strong_blue, width: "50%" }}
      >
        Aadhaar Card Verification is done via Digilocker. Follow simple steps to
        complete verification with Freedo.
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "50px" }}>
        {verificationSteps.map((step: string) => (
          <Typography
            variant="body1"
            sx={{ textAlign: "center", color: colors.strong_blue }}
          >
            {step}
          </Typography>
        ))}
      </Box>
    </Stack>
  );
};

export default Aadhaar;
