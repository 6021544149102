import { toastr } from "react-redux-toastr";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import en from "../../locales/en.json";
import { CauraselData } from "../../screen/homeScreen/HomePageCarousal";
import {
  handleMessage,
  filterCurrentCity,
  isArrayNotEmpty,
} from "../../utills/helper";
import * as actionTypes from "../actions/actionTypes";
import { homeServices, commonServices } from "../services";

const {
  GET_CITY_LIST_MASTERS,
  SET_CITY_LIST_MASTERS,
  SET_PACKAGE_MASTERS,
  SET_VEHICLE_LIST,
  GET_PACKAGE_MASTERS,
  GET_VEHICLE_LIST,
  GET_CAROUSEL_DATA,
  SET_CAROUSEL_DATA,
  GET_COUPAN_DATA,
  SET_COUPAN_DATA,
  SET_FAQ_LIST,
  GET_FAQ_LIST,
  GET_NEAREST_CITY_REQUESTED,
  GET_NEAREST_CITY_FAILED,
  GET_NEAREST_CITY_SUCCESS,
  SET_SELECTED_CITY,
  SET_FILTER_TYPE,
  SET_DEFAULT_CITY,
  ZOHO_UPCOMING_CITY_LEAD_REQUESTED,
  ZOHO_UPCOMING_CITY_LEAD_FAILED,
  ZOHO_UPCOMING_CITY_LEAD_SUCCESS,
} = actionTypes.HOME_ACTIONS;

const {
  GET_MASTER_PLAN_DURATIONS_REQUESTED,
  GET_MASTER_PLAN_DURATIONS_SUCCESS,
  GET_MASTER_PLAN_DURATIONS_FAILED,
} = actionTypes.COMMON_ACTIONS;

/* const updateCityData = (cityArray: []) => {
  console.log("eee", cityArray);
  let cityLocal = getLocalStorageData(LocalStorage.userCity);
  if (cityLocal?.id) {
    let updatedCityData = cityArray.filter(
      (city: any) =>
        city.id === cityLocal?.id && city.city_name === cityLocal?.city_name
    );
    console.log("eee", updatedCityData);
    // setLocalStorageData(LocalStorage.userCity, updatedCityData);
  }
}; */

const { SET_LOCATION_VEHICLE_LIST } = actionTypes.GLOBAL_ACTION;
function* handlePackageMasters(action: any): any {
  const data = yield call(homeServices.packageMaster, action.payload);

  if (data?.status === 200) {
    // toastr.success("", handleMessage(data));

    if (data.data.Result.length === 0) {
      toastr.error("", en?.errorMessages?.NoPackageFound);
    } else {
      yield put({
        type: SET_PACKAGE_MASTERS,
        payload: data?.data?.Result || [],
      });
    }
  }
}
function* getCityList(action: any): any {
  const data = yield call(homeServices.packageMaster, action.payload);
  // updateCityData(data?.data?.Result);

  if (data?.status === 200) {
    yield put({
      type: SET_CITY_LIST_MASTERS,
      payload: data?.data?.Result || [],
    });

    yield put({
      type: SET_DEFAULT_CITY,
      payload: data?.data?.DefaultCity || {},
    });
  } else {
    toastr.error("", handleMessage(data));
  }
}

function* handleVehicleLists(action: any): any {
  try {
    const payload = action?.payload;
    const data = yield call(homeServices.vehicleList, payload?.payload);
    if (data?.status === 200) {
      if (payload?.isLocationList) {
        yield put({
          type: SET_LOCATION_VEHICLE_LIST,
          payload: data?.data,
        });
      } else {
        yield put({
          type: SET_VEHICLE_LIST,
          payload: data?.data,
        });
        yield put({
          type: SET_FILTER_TYPE,
          payload: payload?.primaryFilter,
        });
      }
    } else {
      yield put({
        type: SET_VEHICLE_LIST,
        payload: {},
      });
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    yield put({
      type: SET_VEHICLE_LIST,
      payload: {},
    });
    toastr.error("", handleMessage(error));
  }
}

function* getCarouselData(action: any): any {
  // const data = yield call(homeServices.getCarouselData, action.payload)
  // if(data?.status ===200 ){
  //   yield put({
  //     type: SET_CAROUSEL_DATA,
  //     payload: data?.data?.Result || [],
  //   });
  // }else{
  // currently disabled due to api not provided ,,   toastr.error("", handleMessage(data));
  yield put({
    type: SET_CAROUSEL_DATA,
    payload: CauraselData?.Result,
  });
  // }
}
function* getCoupanList(action: any): any {
  const data = yield call(homeServices.coupanList, action.payload);
  if (data?.status === 200) {
    yield put({
      type: SET_COUPAN_DATA,
      payload: { parta: data.data.Result, partb: data.data.Result_2 },
    });
  } else {
    toastr.error("", handleMessage(data));
  }
}

function* getFaqList(action: any): any {
  const data = yield call(homeServices.getFaqList, action.payload);
  console.log({ data });
  if (data?.status === 200) {
    yield put({
      type: SET_FAQ_LIST,
      payload: data.data.Result,
    });
  } else {
    toastr.error("", handleMessage(data));
  }
}

function* getNearestCity(action: any): any {
  try {
    const data = yield call(homeServices.findNearestCity, action.payload);
    if (data?.status === 200 && data?.data?.city?.id) {
      let currentCity = data?.data?.city;
      const selectedCity = filterCurrentCity(action.cityList, currentCity?.id);

      if (selectedCity?.length) {
        yield put({ type: SET_SELECTED_CITY, payload: selectedCity[0] });
        yield put({ type: GET_NEAREST_CITY_SUCCESS });
        action.handleSuccess(selectedCity[0]);
      } else {
        toastr.error("", en?.errorMessages?.NoCityFound);
        yield put({ type: GET_NEAREST_CITY_FAILED });
      }
    } else {
      toastr.error("", en?.errorMessages?.NoCityFound);
      yield put({ type: GET_NEAREST_CITY_FAILED });
    }
  } catch (error) {
    toastr.error("", en?.errorMessages?.NoCityFound);
    yield put({ type: GET_NEAREST_CITY_FAILED });
  }
}

function* setZohoLeads(action: any) {
  try {
    const data = yield call(homeServices.setZohoLead, action.payload);
    console.log("0909", data);
    if (data?.status === 200) {
      toastr.success("", handleMessage(data));
      yield put({ type: ZOHO_UPCOMING_CITY_LEAD_SUCCESS });
    } else {
      toastr.error("", handleMessage(data));
      yield put({ type: ZOHO_UPCOMING_CITY_LEAD_FAILED });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({ type: ZOHO_UPCOMING_CITY_LEAD_FAILED });
  }
}

function* homeSaga() {
  yield takeEvery(GET_PACKAGE_MASTERS, handlePackageMasters);
  yield takeEvery(GET_VEHICLE_LIST, handleVehicleLists);
  yield takeLatest(GET_CITY_LIST_MASTERS, getCityList);
  yield takeLatest(GET_CAROUSEL_DATA, getCarouselData);
  yield takeLatest(GET_COUPAN_DATA, getCoupanList);
  yield takeLatest(GET_FAQ_LIST, getFaqList);
  // yield takeLatest(GET_NEAREST_CITY, getNearestCity);
  yield takeLatest(GET_NEAREST_CITY_REQUESTED, getNearestCity);
  yield takeLatest(ZOHO_UPCOMING_CITY_LEAD_REQUESTED, setZohoLeads);
}

export default homeSaga;
