import { GenericObject } from "../../../utills/helper";
import * as actionTypes from "../actionTypes";
const {
  GET_MASTER_PLAN_DURATIONS_REQUESTED,
  GET_FILTERED_MODEL_LIST_REQUESTED,
  GET_RENTAL_MASTER_CITY_LIST_REQUESTED,
  SET_CAROUSAL_FILTER,
  SET_RENTAL_FLEET_FILTER,
  GET_NEAREST_LOCATED_CITY_REQUESTED,
  GET_RENTAL_MODEL_DETAILS_REQUESTED,
  GET_CUSTOMER_ADDRESSES_REQUESTED,
  ADD_CUSTOMER_ADDRESSES_REQUESTED,
  GET_MODEL_CHECKOUT_DETAILS_REQUESTED,
  GET_AVAILABLE_TIMESLOTS_REQUESTED,
  GET_DELIVERY_SLOT_PAUSE_REQUESTED,
  GET_CALCULATED_CHARGES_REQUESTED,
  SAVE_SELECTED_CITY,
} = actionTypes.COMMON_ACTIONS;

// Get master plan duration (Packages durations)
export const getMasterPlanDurations = (payload = "") => {
  return {
    type: GET_MASTER_PLAN_DURATIONS_REQUESTED,
    payload,
  };
};

// get filtered model list (Optional params : startDate - timestamp, endDate - timestamp, useCase=RENTALS, cities=["IN_UP_NOIDA"])
export const getFilteredVehicleModelList = (payload: any) => {
  return { type: GET_FILTERED_MODEL_LIST_REQUESTED, payload };
};

// get filtered model list (Optional params : startDate - timestamp, endDate - timestamp, useCase=RENTALS, cities=["IN_UP_NOIDA"])
export const getRentalMasterCityList = () => {
  return { type: GET_RENTAL_MASTER_CITY_LIST_REQUESTED };
};

// Home page loaction/carousel filters
export const setCarouselFilters = (payload: any) => {
  return { type: SET_CAROUSAL_FILTER };
};

// Home page renting fleet filters
export const setRentalFleetFilters = (payload: any) => {
  return { type: SET_RENTAL_FLEET_FILTER };
};

// Detect location (Nearest City)
export const getRentalNearestLocatedCity = (
  payload: any,
  cityList,
  handleSuccess
) => {
  return {
    type: GET_NEAREST_LOCATED_CITY_REQUESTED,
    payload,
    cityList,
    handleSuccess,
  };
};

// get model preview details
export const getRentalModelDetails = (payload: any, handleSuccess) => {
  return {
    type: GET_RENTAL_MODEL_DETAILS_REQUESTED,
    payload,
    handleSuccess,
  };
};

// Get logged in Customer's addresses
export const getCustomerAddresses = (payload: GenericObject) => {
  return {
    type: GET_CUSTOMER_ADDRESSES_REQUESTED,
    payload,
  };
};

// Add logged in Customer's address
export const addCustomerAddress = (payload: any) => {
  return {
    type: ADD_CUSTOMER_ADDRESSES_REQUESTED,
    payload,
  };
};

// get model checkout details
export const getModelCheckoutDetails = (
  payload: any,
  modelName: string | number,
  userData: GenericObject,
  navigate: any,
  modelCheckoutCallback
) => {
  return {
    type: GET_MODEL_CHECKOUT_DETAILS_REQUESTED,
    payload,
    modelName,
    userData,
    navigate,
    modelCheckoutCallback,
  };
};

// get available delivery time slots (Query params - deliveryType=BRANCH&bookingStartDate=1734805800000)
export const getAvailableTimeSlots = (
  payload: any,
  branchName: string,
  successCallback?: Function
) => {
  return {
    type: GET_AVAILABLE_TIMESLOTS_REQUESTED,
    payload,
    branchName,
    successCallback,
  };
};

// get delivery slot pause (Query Params - branch=IN_KA_BENGALURU_FREEDO_BENGALURU&startDate=1732291496021&endDate=1747843496021)
export const getDeliverySlotPause = (
  payload: any,
  successCallback?: Function
) => {
  return { type: GET_DELIVERY_SLOT_PAUSE_REQUESTED, payload, successCallback };
};

// claculate checkout charges for billing
export const getBillingCalculatedCharges = (
  payload: any,
  successCallback?: Function
) => {
  return { type: GET_CALCULATED_CHARGES_REQUESTED, payload, successCallback };
};

export const saveSelectedCity = (payload: any) => {
  return { type: SAVE_SELECTED_CITY, payload };
};
