import { BASE_URL, getApiUrl } from "../../config/apiUrls";
import { Axios } from "../../config/axiosConfig";

export const getMasterCityList = (payload: any) =>
  Axios.get(BASE_URL + getApiUrl()?.getMasterCityList).catch((err: any) => err);

export const operatorLeadGeneration = (payload: any) =>
  Axios.post(BASE_URL + getApiUrl()?.operatorLeadGeneration, payload).catch(
    (err: any) => err
  );

export const operatorLeadVerification = (payload: any) =>
  Axios.put(BASE_URL + getApiUrl()?.operatorLeadVerification, payload).catch(
    (err: any) => err
  );

export const retryOtpForOperator = (payload: any) =>
  Axios.post(BASE_URL + getApiUrl()?.retryOtpForOperator, payload).catch(
    (err: any) => err
  );

export const verifyOperatorEmail = (payload: any) =>
  Axios.get(BASE_URL + getApiUrl(payload)?.verifyOperatorEmail).catch(
    (err: any) => err
  );

export const fetchEsignDoc = (payload: any) =>
  Axios.get(BASE_URL + getApiUrl(payload)?.fetchEsignDoc).catch(
    (err: any) => err
  );

export const eSignGenerateOtp = (payload: any) =>
  Axios.post(BASE_URL + getApiUrl()?.eSignGenerateOtp, payload).catch(
    (err: any) => err
  );

export const eSignVerifyOtp = (payload: any) =>
  Axios.post(BASE_URL + getApiUrl()?.eSignVerifyOtp, payload).catch(
    (err: any) => err
  );

export const eSignResendOtp = (payload: any) =>
  Axios.post(BASE_URL + getApiUrl()?.eSignResendOtp, payload).catch(
    (err: any) => err
  );
