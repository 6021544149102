// import HomeScreen from "../screen/homeScreen";
// import CheckoutScreen from "../screen/checkoutScreen";
import CheckoutScreen from "../rental-src/screens/CheckoutScreen";
// import BlogScreen from "../screen/blog";
import DeleteAccount from "../component/DeleteAccount";
import ThankyouPage from "../component/ThankyouPage";
import { Routes } from "../constant/routeContants";
import BlogScreen from "../rental-src/screens/BlogPage/index";
import AboutUsScreen from "../rental-src/screens/cmsComponent/aboutUs/index";
import PaymentPending from "../screen/checkoutScreen/PaymentPending";
import PaymentSuccess from "../screen/checkoutScreen/PaymentSuccess";
import CityFreedo from "../screen/city-freedo/CityFreedo";
// import RentScreen from "../screen/rent";
import FreedoBusiness from "../screen/freedoBusiness";
import Mitc from "../screen/mitc";
import BookingDetails from "../screen/setting/myBookings/BookingDetails";

// Revamp screens
import { HomeScreen, RentScreen, UserKycScreen } from "../rental-src/screens";
import UserProfile from "../rental-src/screens/ProfileScreen";
import OperatorRegistration from "../screen/OperatorManagement/OperatorRegistration";
import OperatorVerification from "../screen/OperatorManagement/OperatorVerification";
import Feedback from "../screen/setting/myBookings/feedback";
import MyBookingsScreen from "../rental-src/screens/SettingScreens/MyBookings";
import ViewBooking from "../rental-src/screens/SettingScreens/MyBookings/sections/ViewBooking";
import YorRentingFleet from "../yor-src/screens/YorRentingFleet";
import YorHomescreen from "../yor-src/screens/YorHomeScreen";
import YORPlanDetails from "../yor-src/screens/PlanDetails";
import { PrivacyPolicy } from "../rental-src/screens/cmsComponent/PrivacyPolicy";
import { TermsAndConditions } from "../rental-src/screens/cmsComponent/TermsAndCondition";
import FaqScreen from "../rental-src/screens/cmsComponent/FaqScreen";
import GetInTouch from "../rental-src/screens/GetInTouch";
import BlogDetailPage from "../rental-src/screens/BlogPage/section/BlogDetailPage";

export const unauthRoutes = [
  {
    path: Routes.HOME,
    component: <HomeScreen />,
  },
  {
    path: Routes.ABOUT_US,
    component: <AboutUsScreen />, //new
  },
  {
    path: Routes.SUBSCRIPTION,
    component: <YorHomescreen />,
  },
  {
    path: Routes?.REVAMP_BOOKING_DETAILS,
    component: <MyBookingsScreen />,
  },
  {
    path: Routes.CONTACT,
    component: <GetInTouch />,
  },
  {
    path: Routes.FAQ,
    component: <FaqScreen />,
  },
  {
    path: Routes.RENT,
    component: <RentScreen />,
  },
  {
    path: Routes.BLOG,
    component: <BlogScreen />, // new
  },
  {
    path: Routes.BLOG_DETAILS,
    component: <BlogDetailPage />,
  },
  {
    path: Routes.TERMS_CONDITIONS,
    component: <TermsAndConditions />,
  },
  {
    path: Routes.TERMS_CONDITIONS_APP,
    component: <TermsAndConditions />,
  },
  {
    path: Routes.PRIVACY_POLICIE,
    component: <PrivacyPolicy />,
  },
  {
    path: Routes.BANGALORE,
    component: <CityFreedo />,
  },
  {
    path: Routes.GURGAON,
    component: <CityFreedo />,
  },
  {
    path: Routes.JAIPUR,
    component: <CityFreedo />,
  },
  {
    path: Routes.VADODARA,
    component: <CityFreedo />,
  },
  {
    path: Routes.DELHI,
    component: <CityFreedo />,
  },
  {
    path: Routes.NOIDA,
    component: <CityFreedo />,
  },
  {
    path: Routes.GHAZIABAD,
    component: <CityFreedo />,
  },
  {
    path: Routes.FARIDABAD,
    component: <CityFreedo />,
  },
  {
    path: Routes.VIJAYAWADA,
    component: <CityFreedo />,
  },
  {
    path: Routes.HYDERABAD,
    component: <CityFreedo />,
  },
  {
    path: Routes.KOLKATA,
    component: <CityFreedo />,
  },
  {
    path: Routes.FREEDO_BUSINESS,
    component: <FreedoBusiness />,
  },
  {
    path: Routes.MITC_FORM,
    component: <Mitc />,
  },
  {
    path: Routes?.THANKYOU_PAGE,
    component: <ThankyouPage />,
  },
  {
    path: Routes?.DELETE_ACCOUNT,
    component: <DeleteAccount />,
  },
  {
    path: Routes?.FEEDBACK,
    component: <Feedback />,
  },
  {
    path: Routes?.GOA,
    component: <CityFreedo />,
  },
  {
    path: Routes?.LUCKNOW,
    component: <CityFreedo />,
  },
  { path: Routes?.YOR_HOME_SCREEN, component: <YorHomescreen /> },
  { path: Routes?.YOR_RENTING_FLEET, component: <YorRentingFleet /> },
  {
    path: Routes?.YOR_PLAN_DETAILS,
    component: <YORPlanDetails />,
  },
  {
    path: Routes?.OPERATOR_REGISTRATION,
    component: <OperatorRegistration />,
  },
  {
    path: Routes?.OPERATOR_VERIFICATION,
    component: <OperatorVerification />,
  },
];

export const customRoutes = [
  {
    path: Routes.CHECKOUT,
    component: <CheckoutScreen />,
  },
  {
    path: Routes.PROFILE,
    component: <UserProfile />,
  },
  { path: Routes.REVAMP_BOOKING_DETAILS, component: <MyBookingsScreen /> },
  {
    path: Routes.REVAMP_VIEW_BOOKING,
    component: <ViewBooking />,
  },
  {
    path: Routes.BOOKING_DETAILS,
    component: <BookingDetails />,
  },
  {
    path: Routes.PAYMENT_SUCCESS,
    component: <PaymentSuccess />,
  },
  {
    path: Routes.PAYMENT_PENDING,
    component: <PaymentPending />,
  },
  {
    path: Routes.userKyc,
    component: <UserKycScreen />,
  },
];
