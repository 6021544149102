import { HelpDeskRequestInterface } from "../../../interfaces/kycInterfaces";
import { AUTH_ACTIONS } from "../actionTypes";

// get user profile data
export const getUserProfile = (payload: any) => ({
  type: AUTH_ACTIONS.GET_USER_PROFILE_DATA_REQUESTED,
  payload,
});

//toggle sign in drawer
export const toggleSignInDrawer = (payload: any) => ({
  type: AUTH_ACTIONS.TOGGLE_SIGN_IN_DRAWER,
  payload,
});

// login user
export const loginUser = (payload: any) => ({
  type: AUTH_ACTIONS.LOGIN_REQUESTED,
  payload,
});

// verify login otp
export const verifyLoginOTP = (payload: any) => ({
  type: AUTH_ACTIONS.LOGIN_VERIFY_OTP_REQUESTED,
  payload,
});

// resend login otp
export const resendLoginOtp = (payload: any) => ({
  type: AUTH_ACTIONS.LOGIN_RESEND_OTP_REQUESTED,
  payload,
});

// signup user
export const signUpUser = (payload: any) => ({
  type: AUTH_ACTIONS.SIGNUP_REQUESTED,
  payload,
});

// verify signup otp
export const verifySignUpOtp = (payload: any) => ({
  type: AUTH_ACTIONS.SIGNUP_VERIFY_OTP_REQUESTED,
  payload,
});

// resend sign in otp
export const resendSignUpOtp = (payload: any) => ({
  type: AUTH_ACTIONS.SIGNUP_RESEND_OTP_REQUESTED,
  payload,
});

// logout user
export const logoutUser = () => ({
  type: AUTH_ACTIONS.LOGOUT_REQUESTED,
});

//get in touch data
export const sendQueryData = (payload: HelpDeskRequestInterface) => ({
  type: AUTH_ACTIONS.SEND_QUERY_DATA_REQUESTED,
  payload,
});

//guest login action
export const guestLogin = () => ({
  type: AUTH_ACTIONS.GUEST_LOGIN_DATA_REQUESTED,
});
