import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import { useEffect, useRef, useState } from "react";
import {
  listingLocationIcon
} from "../../../../assets";
import { getFormattedINR } from "../../../utils/helper";

const containerStyle = {
  width: "100%",
  height: "48vh",
};
export const GoogleMapComponent = ({
  selectdMarkerLocation,
  branchDetails = [],
  branchChangeHandler,
  selectedBranch
}: any) => {
  // State for the selected warehouse
  const [selectedWarehouse, setSelectedWarehouse] = useState<any>(selectedBranch ? selectedBranch : null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const [zoom, setZoom] = useState<number>(10);
  const mapCenter = () => {
    if (branchDetails.length) {
      if (selectedIndex) {
        return {
          lat: branchDetails[selectedIndex]?.branchDetails.latitude,
          lng: branchDetails[selectedIndex]?.branchDetails.longitude,
        };
      }
      // Default center when no marker is selected
      return {
        lat: branchDetails[0]?.branchDetails.latitude,
        lng: branchDetails[0]?.branchDetails.longitude,
      };
    } else {
      return { lat: 0.0, lng: 0.0 };
    }
  };

  const handleMaplick = (item: any, index: number) => {
    setSelectedWarehouse(item);
    setSelectedIndex(index);
    setZoom(12);
    branchChangeHandler(item);

  };
  const handleListingClick = (item: any, index: number) => {
    setSelectedWarehouse(item);
    setSelectedIndex(index);
    setZoom(12);
    branchChangeHandler(item);
  };

  const getFullAddress = (addr: any) => {
    return `${addr?.addressLine1}, ${
      addr?.addressLine2 ? addr?.addressLine2 : ""
    }, ${addr?.landmark ? addr?.landmark : ""}, ${addr?.zipCode}`;
  };

  // Initially select first branch
  useEffect(() => {
    if (branchDetails?.length === 1) {
      // setSelectedWarehouse(branchDetails[0]?.branchDetails);
      selectdMarkerLocation(branchDetails[0]?.branchDetails);
    }
  }, [branchDetails]);
  // Reset zoom to show all markers if no selection is made
  useEffect(() => {
    if (selectedIndex === null) {
      setZoom(10); // Reset to zoom out when no marker is selected
    }
  }, [selectedIndex]);
  return (
    // <div className="row mt-2">
    <Grid
      container
      className=""
      alignItems={"center"}
      textAlign={"center"}
      sx={{ mt: 2 }}
    >
      <Grid size={{ md: 8 }}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={mapCenter()}
          zoom={zoom}
          clickableIcons={false}
        >
          {branchDetails.map((item: any, index: number) => (
            <Marker
              key={index}
              position={{
                lat: item?.branchDetails?.latitude,
                lng: item?.branchDetails?.longitude,
              }}
            >
              <InfoWindow
                position={{
                  lat: item?.branchDetails?.latitude,
                  lng: item?.branchDetails?.longitude,
                }}
              >
                <Box
                  className="mapWarehouseInfo"
                  onClick={() => handleMaplick(item?.branchDetails, index)}
                >
                  <Box
                    className={`mapWarehouseInfoPricing ${
                      selectedWarehouse?.branchName ===
                      item?.branchDetails?.name
                        ? "selected"
                        : ""
                    }`}
                  >
                    {getFormattedINR(item?.pricing?.amount)}
                  </Box>
                  <Box className="mapWarehouseName">
                    <p>
                      {item?.hubMapName
                        ? item.hubMapName
                        : item?.branchDetails?.displayName}
                    </p>
                  </Box>
                </Box>
              </InfoWindow>
            </Marker>
          ))}
        </GoogleMap>
      </Grid>
      <Grid size={{ md: 4 }} textAlign="left">
        <CardListing
          branchDetails={branchDetails}
          handleListingClick={handleListingClick}
          selectedWarehouse={selectedWarehouse}
          getFullAddress={getFullAddress}
          selectedIndex={selectedIndex}
        />
      </Grid>
    </Grid>
    // </div>
  );
};
const CardListing = ({
  branchDetails,
  handleListingClick,
  selectedWarehouse,
  getFullAddress,
  selectedIndex = null,
}: any) => {
  const addressRefs = useRef<any>([]);
  // Scroll to the selected address
  useEffect(() => {
    if (selectedIndex !== null && addressRefs.current[selectedIndex]) {
      addressRefs.current[selectedIndex]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [selectedIndex]);
  return (
    <Box className="card-listing">
      <span className="listing-heading">Click to select hub</span>
      <Box className="card-scroll-listing">
        {branchDetails.map((item: any, index: number) => (
          <Box
            className={`card-warehouse-listing ${
              selectedWarehouse?.name === item?.branchDetails?.name
                ? "selected"
                : ""
            }`}
            key={index}
            onClick={() => handleListingClick(item?.branchDetails, index)}
            ref={(el) => (addressRefs.current[index] = el)}
          >
            <img src={listingLocationIcon} style={{ marginTop: "4px" }} />
            <Box className="card-warehouse-info">
              <Box className="card-warehouse-infoBox">
                <span
                  className="card-warehouse-heading"
                  data-toggle="tooltip"
                  title={item?.branchDetails?.displayName}
                >
                  {item?.branchDetails?.displayName}
                </span>
                <p>{getFormattedINR(item?.pricing?.amount)}</p>
              </Box>
              <Box className="card-warehouse-location">
                <p>{getFullAddress(item?.branchDetails?.address)}</p>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
