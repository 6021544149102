import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { Box, Container, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "../../../../redux/reducers";
import { getBlogData } from "../../../redux/actions/cmsActions";
import styles from "../index.module.css";
import { BlogCard } from "./BlogCard";
import { Tag } from "./Tag";

const BlogDetailPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { detailPageData } = location.state;

  const { blogPageData = [] } = useSelector(
    (state: RootState) => state.cmsDataReducer
  );

  const [relatedPosts, setRelatedPosts] = useState<any>();

  const blogTitle: string = location?.pathname?.split("/")[2];

  //filter out realted post
  const related = blogPageData?.filter((item: any) => {
    return (
      item?.buttonText === detailPageData?.buttonText &&
      item?.title !== detailPageData?.title
    );
  });

  //in case of refresh need blog data for realted posts
  useEffect(() => {
    dispatch(getBlogData());
  }, []);

  //to get related posts
  useEffect(() => {
    setRelatedPosts(related);
  }, [blogTitle]);

  return (
    <>
      <Helmet>
        <title>{blogPageData?.meta?.title}</title>
        <meta name="description" content={blogPageData?.meta?.description} />
        <meta name="author" content={blogPageData?.meta?.author} />
        <meta property="og:locale" content={blogPageData?.meta?.ogLocale} />
        <meta property="og:type" content={blogPageData?.meta?.ogType} />
        <meta property="og:title" content={blogPageData?.meta?.ogTitle} />
        <meta
          property="og:description"
          content={blogPageData?.meta?.ogDescription}
        />
        <meta property="og:url" content={blogPageData?.meta?.imgSrc} />
        <meta property="og:image" content={blogPageData?.meta?.ogImage} />
        <meta
          property="og:site_name"
          content={blogPageData?.meta?.ogSiteName}
        />
        <link rel="canonical" href={blogPageData?.meta?.canonical} />
        <meta name="robots" content={blogPageData?.meta?.robot} />
        <meta name="viewport" content={blogPageData?.meta?.viewport} />
      </Helmet>
      <Container sx={{ marginTop: "40px", marginBottom: "40px" }}>
        <Box>
          {/* bread crumbs */}
          <Box>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/blog">Blogs</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Blogs Details
                </li>
              </ol>
            </nav>
          </Box>
          {/* Blog Detail component */}
          <Stack alignItems="center">
            <Box
              className={styles.blogDetailImg}
              style={{
                backgroundImage: `url(${detailPageData?.image})`,
                width: "100%",
              }}
            ></Box>
            <Box className={styles.blogDetailData}>
              <Stack>
                <Box className={styles.blogDetailDataInner}>
                  <Tag text={detailPageData?.buttonText} />
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "3px" }}
                  >
                    <CalendarTodayIcon sx={{ fontSize: "12px" }} />
                    <Typography variant="caption">
                      {detailPageData?.date} / {detailPageData?.location}
                    </Typography>
                  </Box>
                </Box>
                <div
                  dangerouslySetInnerHTML={{
                    __html: detailPageData?.longDescription,
                  }}
                />
              </Stack>
            </Box>
          </Stack>

          {related?.length ? (
            <Container>
              <Typography variant="h4">Related Posts</Typography>
              <Box>
                {relatedPosts?.map((posts: any) => (
                  <BlogCard data={posts} />
                ))}
              </Box>
            </Container>
          ) : (
            <></>
          )}
        </Box>
      </Container>
    </>
  );
};

export default BlogDetailPage;
