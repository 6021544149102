//export { default as MyBookingSaga } from "./MyBookingSaga";
import MyBookingSaga from "./MyBookingSaga";
import newAuthSaga from "./authSaga";
import newProfileSaga from "./profileSaga";
import userKycSaga from "./userKycSaga";
import MasterSaga from "./masterSaga";
import UserAddressesSaga from "./userAddressesSaga";

// export all revamp saga here
export const rentalSaga = [
  userKycSaga(),
  newAuthSaga(),
  newProfileSaga(),
  MyBookingSaga(),
  MasterSaga(),
  UserAddressesSaga(),
];
