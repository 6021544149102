import { CMS_ACTIONS } from "../actionTypes";

const {
  GET_PRIVACY_POLICY_CMS_DATA_REQUESTED,
  GET_TERMS_CONDITION_CMS_DATA_REQUESTED,
  GET_ABOUT_US_CMS_DATA_REQUESTED,
  GET_HOME_PAGE_DATA_REQUESTED,
  GET_BLOG_PAGE_DATA_REQUESTED,
} = CMS_ACTIONS;

// get data for privacy policy content
export const getPrivacyPolicyCmsData = () => {
  return {
    type: GET_PRIVACY_POLICY_CMS_DATA_REQUESTED,
  };
};

//get terms and conditions
export const getTermsAndConditions = () => {
  return {
    type: GET_TERMS_CONDITION_CMS_DATA_REQUESTED,
  };
};

//get about us data
export const getAboutUsData = () => {
  return {
    type: GET_ABOUT_US_CMS_DATA_REQUESTED,
  };
};

//get home page data
export const getHomePageData = (payload?: any) => {
  return {
    type: GET_HOME_PAGE_DATA_REQUESTED,
    payload,
  };
};

//get blog page data
export const getBlogData = () => {
  return {
    type: GET_BLOG_PAGE_DATA_REQUESTED,
  };
};
