import { Box, Divider, InputLabel, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { phone } from "../../../../assets";
import en from "../../../../locales/en.json";
import { RootState } from "../../../../redux/store";
import { CustomButton } from "../../../../sharedComponents/atom/Buttons/CustomButtons";
import { colors } from "../../../../themes/colors";
import { checkNumeric, checkPhone } from "../../../../utills/regex";
import Input from "../../../../yor-src/components/atom/InputFields/Input";
import {
  loginUser,
  resendLoginOtp,
  toggleSignInDrawer,
  verifyLoginOTP,
} from "../../../redux/actions/authActions";
import SignInEnterOtp from "./EnterOtp";
import authStyles from "../styleObject";

const { AuthScreens } = en;

const SignIn = (props: any) => {
  const { fields, handleChange } = props;
  const dispatch = useDispatch();
  const { userAuthData, loginLoader } = useSelector(
    (state: RootState) => state.newAuthReducer
  );
  const { token = "" } = userAuthData;

  const { phoneNo, isEnterLoginOtp = false } = fields;

  // handle login user
  const handleSignIn = () => {
    if (!checkPhone(phoneNo)) {
      handleChange("errorFields", {
        ...fields.errorFields,
        phoneNo: en.errorMessages.mobileError,
      });
      return;
    } else {
      dispatch(
        loginUser({
          phoneNo,
          onSuccess: () => handleChange("isEnterLoginOtp", true),
        })
      );
    }
  };

  // handle submit otp
  const handleSubmitOtp = () => {
    const payloadData = { data: { otp: fields.loginOtp, token } };

    dispatch(
      verifyLoginOTP({
        ...payloadData,
        onSuccess: () => {
          dispatch(toggleSignInDrawer(false));
        },
      })
    );
  };

  // on change of otp
  const onChangeOtp = (key, value) => {
    handleChange("loginOtp", value);
  };

  // hit resend otp api
  const handleResendOtp = () => {
    dispatch(resendLoginOtp({ token }));
  };

  if (isEnterLoginOtp)
    return (
      <SignInEnterOtp
        phoneNo={fields.phoneNo}
        key={en.AuthScreens.SignIn}
        otpValue={fields.loginOtp}
        onChangeOtp={onChangeOtp}
        handleSubmitOtp={handleSubmitOtp}
        handleResendOtp={handleResendOtp}
        isLoading={loginLoader}
      />
    );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        padding: "122px 40px",
      }}
    >
      <Typography variant="h5" sx={{ textAlign: "center" }}>
        {en.AuthScreens.SignIn}
      </Typography>
      <Divider
        style={{
          width: "35px",
          borderBottomWidth: "3px",
          borderColor: colors.THEME_BLUE,
          opacity: 1,
          marginTop: "10px",
          marginBottom: "10px",
        }}
        // color={colors.THEME_BLUE}
      />
      <Typography variant="caption">
        {en.AuthScreens.dontHaveAccount}
        <span
          style={{
            textDecoration: "underline",
            fontWeight: 600,
            cursor: "pointer",
            fontSize: "12px",
          }}
          onClick={() => handleChange("isSignIn", false)}
        >
          {en.AuthScreens.SignUp}
        </span>
      </Typography>
      <Box sx={{ width: "100%", marginTop: "35px" }}>
        <InputLabel
          shrink
          htmlFor="phone-input"
          sx={{ fontSize: "15px", marginTop: "10px", color: "unset" }}
        >
          {AuthScreens.PhoneNumber}
          <span style={authStyles.mandatoryStyle}> *</span>
        </InputLabel>
        <Input
          onChange={(e: any) => {
            if (checkNumeric(e.target.value) && e.target.value.length <= 10) {
              handleChange("phoneNo", e.target.value);
              handleChange("errorFields", {}); // empty error field
            }
          }}
          value={phoneNo}
          id="phone-input"
          name="mobile"
          type="number"
          autoFocus
          autoComplete="on"
          placeholder="Enter mobile number"
          startAdornment={() => {
            return (
              <>
                <img src={phone} />
                <Divider
                  orientation={"vertical"}
                  sx={{ width: "5px", height: "18px" }}
                />
              </>
            );
          }}
          containerStyle={{ width: "100%" }}
          inputMode="numeric"
          errormessage={fields.errorFields?.phoneNo}
          onSubmit={handleSignIn}
        />
      </Box>
      <CustomButton
        label={AuthScreens.SignIn}
        variant={"contained"}
        wrapperStyles={{ width: "100%", marginTop: "20px" }}
        onClick={handleSignIn}
        loading={loginLoader}
        disabled={phoneNo.length < 10}
      />
      <Typography
        variant="caption"
        sx={{ fontWeight: 500, textAlign: "center", marginTop: "10px" }}
      >
        {AuthScreens["tNcNoteSignIn"]}
        <a
          style={{ textDecoration: "underline", fontWeight: 600 }}
          href="/terms-conditions"
          target="_blank"
        >
          {AuthScreens["termsAndConditions"]}
        </a>
        {AuthScreens["and"]}
        {"\n"}
        <a
          href="/privacy-policy"
          style={{ textDecoration: "underline", fontWeight: 600 }}
          target="_blank"
        >
          {AuthScreens["privacyPolicy"]}
        </a>
      </Typography>
    </Box>
  );
};

export default SignIn;
