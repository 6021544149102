import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import {
  excesshourly,
  excesskms,
  fullFace,
  halfFace,
  helmetgif,
  tag,
  totalkms,
} from "../../../../assets";
import {
  HelmetTypesDisplayEnum,
  HelmetTypesEnum,
  LocalStorage,
} from "../../../../constant/enum";
import en from "../../../../locales/en.json";
import {
  commmaSeparator,
  getLocalStorageData,
} from "../../../../utills/helper";
import BillingPrice from "./BillingPrice";
import { Link } from "react-router-dom";
import { Routes } from "../../../../constant/routeContants";
import Grid from "@mui/material/Grid2";
import { Box, Typography, Stack } from "@mui/material";
import PlanCards from "./PlanCards";
import styles from "./section.module.css";
import { getFormattedINR } from "../../../utils/helper";
import Helmetbadge from "../../../../sharedComponents/molecule/HelmetBadge";

const BillingDetails = (props: any) => {
  const {
    selectedBranchData,
    selectedBranchDetails,
    checkoutData,
    additionHelmetChangeHandler,
    availablePlans,
    selectedPlan,
    handlePlanChange,
    calculatedCharges,
    toggleAdditionalHelmet,
    extraHelmetsChangeHandler,
    errorFields,
    // check below props
    modelName,
    selectedPackage,
    // homeDeliverCharges,
    // isCOD,
    handleCheckoutDataUpdate,
    handlePayClick,
    payNowLoader,
    handleNavigation,
    addressNotDeliverable,
    isHomeDelivery,
    branchData,
    productData,
    setSelectedCoupon,
    // selectedCouponData,
    // setAdditionalHelmet,
    // additionalHelmet,
    // setAdditionalHelmetData,
    // additionalHelmetData,
  } = props;
  const { checkout } = en;

  const cityData = getLocalStorageData(LocalStorage.userCity);

  const branchHelmetConfig = selectedBranchDetails?.config?.helmets ?? [];

  const helmetIcons = {
    PREMIUM: fullFace,
    FULL_FACE: fullFace,
    HALF_FACE: halfFace,
    KIDS: halfFace,
  };
  const { additionalHelmet } = checkoutData;
  return (
    <Grid size={5} className={"coupon-right"}>
      {/* Select Plan Type */}
      <Stack gap="2px" className={styles.checkoutCardWrapper}>
        <Typography variant={"h6"}>{"Available Plans"}</Typography>
        <Box sx={{ display: "flex", gap: "8px" }}>
          {availablePlans?.map((plan: any, index: number) => {
            return (
              <PlanCards
                key={index}
                plan={plan}
                selectedPlan={selectedPlan}
                onPlanSelect={handlePlanChange}
              />
            );
          })}
        </Box>
      </Stack>

      {/* Additional details */}
      {branchData && (
        <Box className="add-details-wrapper">
          <Typography component={"p"} className="add-det">
            {checkout.additionaDetails}
          </Typography>

          <Box className="details-wrapper">
            <Box className="total-km">
              <span>
                <img
                  src={totalkms}
                  alt="Total Kilometers"
                  style={{ height: "0.9rem" }}
                />
              </span>
              <Box className="additional-helmets-info">
                <span>{checkout.totalKms}</span>

                <span>{`${commmaSeparator(
                  selectedBranchDetails?.pricing?.kmLimit
                )} km`}</span>
              </Box>
            </Box>

            <Box className="excess-charge">
              <span>
                <img
                  src={excesskms}
                  alt="Excess Kilometeres"
                  // style={{ height: "0.9rem" }}
                />
              </span>
              <Box className="additional-helmets-info">
                <span>{checkout.excessKmChange}</span>

                <span>{`${getFormattedINR(
                  selectedBranchDetails?.pricing?.excessKmCharges || 0
                )}/km`}</span>
              </Box>
            </Box>

            <Box className="excess-hour-charge">
              <span>
                <img
                  src={excesshourly}
                  alt="Excess Hourly Charges"
                  // style={{ height: "0.9rem" }}
                />
              </span>
              <Box className="additional-helmets-info">
                <span> {checkout.excessHrCharge} </span>

                <span>{`${getFormattedINR(
                  selectedBranchDetails?.pricing?.excessHourCharges || 0
                )}/hr`}</span>
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      {/* Coupons & Additional Helmet */}
      <Box className="first-row">
        <Box className="coupon-details-wrapper">
          {/* Additional Helmet */}
          <Box className="helmet-wrap">
            <img
              src={helmetgif}
              alt="Complimentary Image"
              className="img-responsive float-end"
              width={40}
            />
            <Typography variant="h6">Freedo Helmet </Typography>
            <Typography component="p">One helmet is complimentary.</Typography>
          </Box>

          {branchHelmetConfig && branchHelmetConfig.length > 0 && (
            <Box className="helmate-wrap">
              <form>
                <Box className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="rentAdditionalHelmet"
                    checked={checkoutData?.isAdditionalHelmetChecked}
                    onChange={(e) => toggleAdditionalHelmet()}
                    style={{ cursor: "pointer" }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={"rentAdditionalHelmet"}
                    style={{ cursor: "pointer" }}
                  >
                    Rent Additional Helmet
                  </label>
                </Box>
               {/* OLD DESIGN
                {checkoutData?.isAdditionalHelmetChecked && (
                  <Box className="d-flex rented-helmet">
                    {branchHelmetConfig?.map((helmet: any, ind: number) => {
                      return (
                        <Box
                          key={ind}
                          className="p-2 cp"
                          onClick={() => {
                            additionHelmetChangeHandler(
                              HelmetTypesEnum[
                                helmet?.type as keyof typeof HelmetTypesEnum
                              ]
                            );
                          }}
                        >
                          <Box
                            className={`card text-center ${
                              Object.keys(
                                checkoutData?.additionalHelmet
                              ).includes(helmet?.type) &&
                              checkoutData?.additionalHelmet[
                                HelmetTypesEnum[
                                  helmet?.type as keyof typeof HelmetTypesEnum
                                ]
                              ]
                                ? "selectedHelmet"
                                : ""
                            }`}
                          >
                            <img
                              src={
                                helmet.type === HelmetTypesEnum.FULL_FACE
                                  ? fullFace
                                  : halfFace
                              }
                              className="card-img-top"
                              alt="Half Face Helmet"
                            />
                            <Box className="card-body">
                              <h6 className="card-title">
                                {
                                  HelmetTypesDisplayEnum[
                                    helmet?.type as keyof typeof HelmetTypesDisplayEnum
                                  ]
                                }
                              </h6>
                              <h6>{`${getFormattedINR(helmet?.price)}/-`}</h6>
                            </Box>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                )} */}
                {checkoutData?.isAdditionalHelmetChecked && (
                  <>
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, mt: 2}}>
                      {branchHelmetConfig?.map((item) => {
                        const { type, price } = item;
                        
                        return (
                          <Helmetbadge
                            title={
                              HelmetTypesDisplayEnum[
                                type as keyof typeof HelmetTypesDisplayEnum
                              ]
                            }
                            type={type}
                            clickHandler={extraHelmetsChangeHandler}
                            helmetTypeCount={
                              additionalHelmet[
                                HelmetTypesEnum[
                                  type as keyof typeof HelmetTypesEnum
                                ]
                              ]
                            }
                            helmetIconType={
                              HelmetTypesEnum[
                                type as keyof typeof HelmetTypesEnum
                              ]
                            }
                            price={getFormattedINR(price ?? 0)}
                          />
                        );
                      })}
                    </Box>
                    <Box>
                      {errorFields?.maxHelmets && (
                        <Typography
                          variant={"errorMessage1"}
                          style={{ fontSize: "0.7rem" }}
                        >
                          {errorFields?.maxHelmets}
                        </Typography>
                      )}
                    </Box>
                  </>
                )}
              </form>
            </Box>
          )}
        </Box>
      </Box>

      <Box className="coupon-details-wrapper pt-0">
        <button
          className="pay-now freedo-primary-button rounded-pill"
          // onClick={() => handlePayClick()}
          onClick={() => {}}
        /*   disabled={
            payNowLoader || (isHomeDelivery && addressNotDeliverable?.Error)
          } */
          disabled={true}
        >
          {payNowLoader ? (
            <Box
              className="spinner-border spinner-border-sm text-light"
              role="status"
            >
              <Typography component={"span"} className="visually-hidden">
                Loading...
              </Typography>
            </Box>
          ) : (
            <Stack direction="row" gap="2px" justifyContent={"center"}>
              {checkout.payNow}
              <Typography component={"span"}>
                {getFormattedINR(calculatedCharges?.totalAmount ?? 0)}
              </Typography>
            </Stack>
          )}
        </button>
        <p className="pay-now-term-and-condition">
          By clicking on pay now you are accepting the{" "}
          <Link to={Routes.TERMS_CONDITIONS} title="term & condition">
            Terms & Conditions{" "}
          </Link>
          of Freedo
        </p>
        {/* -------------------------- Discussion Required ---------------------------- */}
        {/* <BillingPrice
          classNamename="table-striped"
          priceDetail={getPriceDetailData()}
          selectedBranchDetails={selectedBranchDetails}
          calculatedCharges={calculatedCharges}
        /> */}
      </Box>
      <button
        type="button"
        id="EmrgNoCheck"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
        style={{ display: "none" }}
      />
      <Box
        className="modal fade"
        id="staticBackdrop"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <Box className="modal-dialog modal-dialog-centered">
          <Box className="modal-content">
            <Box className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </Box>
            <Box className="modal-body">
              <h5>Please add emergency contact before booking</h5>
            </Box>
            <Box className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleNavigation}
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Go to Profile
              </button>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* </Box> */}
    </Grid>
  );
};

export default BillingDetails;
