import { GenericObject } from "../../interfaces/commonInterface";
import { OPERATOR_ACTIONS } from "../actions/actionTypes";

// Destructuring actions
const {
  GET_MASTER_CITY_REQUESTED,
  GET_MASTER_CITY_SUCCEEDED,
  GET_MASTER_CITY_FAILED,
  OPERATOR_LEAD_GENERATION_REQUESTED,
  OPERATOR_LEAD_GENERATION_SUCCEEDED,
  OPERATOR_LEAD_GENERATION_FAILED,
  OPERATOR_LEAD_VERIFICATION_REQUESTED,
  OPERATOR_LEAD_VERIFICATION_SUCCEEDED,
  OPERATOR_LEAD_VERIFICATION_FAILED,
  RETRY_OTP_FOR_OPERATOR_REQUESTED,
  RETRY_OTP_FOR_OPERATOR_SUCCEEDED,
  RETRY_OTP_FOR_OPERATOR_FAILED,
  VERIFY_OPERATOR_EMAIL_REQUESTED,
  VERIFY_OPERATOR_EMAIL_SUCCEEDED,
  VERIFY_OPERATOR_EMAIL_FAILED,
  FETCH_ESIGN_DOC_REQUESTED,
  FETCH_ESIGN_DOC_SUCCEEDED,
  FETCH_ESIGN_DOC_FAILED,
  ESIGN_GENERATE_OTP_REQUESTED,
  ESIGN_GENERATE_OTP_SUCCEEDED,
  ESIGN_GENERATE_OTP_FAILED,
  ESIGN_VERIFY_OTP_REQUESTED,
  ESIGN_VERIFY_OTP_SUCCEEDED,
  ESIGN_VERIFY_OTP_FAILED,
  ESIGN_RESEND_OTP_REQUESTED,
  ESIGN_RESEND_OTP_SUCCEEDED,
  ESIGN_RESEND_OTP_FAILED,
} = OPERATOR_ACTIONS;

// Define the interface for the state of the operator reducer
interface InitialState {
  masterCityListLoader: boolean;
  masterCityList: string[];
  leadGenerationLoader: boolean;
  leadGenerationSuccessData: GenericObject;
  showOtpScreen: boolean;
  leadVerificationLoader: boolean;
  leadVerificationSuccessData: GenericObject;
  retryOtpForOperatorLoader: boolean;
  retryOtpForOperatorSuccessData: GenericObject;
  verifyOperatorEmailData: GenericObject;
  verifyOperatorEmailLoader: boolean;
  fetchEsignDocLoader: boolean;
  fetchEsignDocSuccessData: GenericObject;
  eSignOtpGenerationLoader: boolean;
  eSignOtpGenerationData: GenericObject;
  eSignVerifyOtpLoader: boolean;
  eSignVerifyOtpData: GenericObject;
  eSignResendOtpLoader: boolean;
  eSignResendOtpData: GenericObject;
}

// Define the initial state of the operator reducer with default values
const initialState: InitialState = {
  masterCityListLoader: false,
  masterCityList: [],
  leadGenerationLoader: false,
  leadGenerationSuccessData: {},
  showOtpScreen: false,
  leadVerificationLoader: false,
  leadVerificationSuccessData: {},
  retryOtpForOperatorLoader: false,
  retryOtpForOperatorSuccessData: {},
  verifyOperatorEmailData: {},
  verifyOperatorEmailLoader: false,
  fetchEsignDocLoader: false,
  fetchEsignDocSuccessData: {},
  eSignOtpGenerationLoader: false,
  eSignOtpGenerationData: {},
  eSignVerifyOtpLoader: false,
  eSignVerifyOtpData: {},
  eSignResendOtpLoader: false,
  eSignResendOtpData: {},
};

const operatorReducer = (state: any = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    // Get master cities
    case GET_MASTER_CITY_REQUESTED:
      return {
        ...state,
        masterCityListLoader: true,
        masterCityList: [],
      };
    case GET_MASTER_CITY_SUCCEEDED:
      return {
        ...state,
        masterCityListLoader: false,
        masterCityList: payload?.districtList ?? [],
      };
    case GET_MASTER_CITY_FAILED:
      return {
        ...state,
        masterCityListLoader: false,
        masterCityList: [],
      };

    // Lead Generation
    case OPERATOR_LEAD_GENERATION_REQUESTED:
      return {
        ...state,
        leadGenerationLoader: true,
        leadGenerationSuccessData: {},
        showOtpScreen: false,
      };
    case OPERATOR_LEAD_GENERATION_SUCCEEDED:
      return {
        ...state,
        leadGenerationLoader: false,
        leadGenerationSuccessData: payload,
        showOtpScreen: true,
      };
    case OPERATOR_LEAD_GENERATION_FAILED:
      return {
        ...state,
        leadGenerationLoader: false,
        leadGenerationSuccessData: {},
        showOtpScreen: false,
      };

    // Lead Verification
    case OPERATOR_LEAD_VERIFICATION_REQUESTED:
      return {
        ...state,
        leadVerificationLoader: true,
        leadVerificationSuccessData: {},
      };
    case OPERATOR_LEAD_VERIFICATION_SUCCEEDED:
      return {
        ...state,
        leadVerificationLoader: false,
        leadVerificationSuccessData: payload,
      };
    case OPERATOR_LEAD_VERIFICATION_FAILED:
      return {
        ...state,
        leadVerificationLoader: false,
        leadVerificationSuccessData: {},
      };

    // Retry OTP For Operator
    case RETRY_OTP_FOR_OPERATOR_REQUESTED:
      return {
        ...state,
        retryOtpForOperatorLoader: true,
        retryOtpForOperatorSuccessData: {},
      };
    case RETRY_OTP_FOR_OPERATOR_SUCCEEDED:
      return {
        ...state,
        retryOtpForOperatorLoader: false,
        retryOtpForOperatorSuccessData: payload,
      };
    case RETRY_OTP_FOR_OPERATOR_FAILED:
      return {
        ...state,
        retryOtpForOperatorLoader: false,
        retryOtpForOperatorSuccessData: {},
      };

    // Verify Operator Email
    case VERIFY_OPERATOR_EMAIL_REQUESTED:
      return {
        ...state,
        verifyOperatorEmailData: {},
        verifyOperatorEmailLoader: true,
      };
    case VERIFY_OPERATOR_EMAIL_SUCCEEDED:
      return {
        ...state,
        verifyOperatorEmailData: payload,
        verifyOperatorEmailLoader: false,
      };
    case VERIFY_OPERATOR_EMAIL_FAILED:
      return {
        ...state,
        verifyOperatorEmailData: {},
        verifyOperatorEmailLoader: false,
      };

    // Fetch ESign Doc
    case FETCH_ESIGN_DOC_REQUESTED:
      return {
        ...state,
        fetchEsignDocLoader: true,
        fetchEsignDocSuccessData: {},
      };
    case FETCH_ESIGN_DOC_SUCCEEDED:
      return {
        ...state,
        fetchEsignDocLoader: false,
        fetchEsignDocSuccessData: payload,
      };
    case FETCH_ESIGN_DOC_FAILED:
      return {
        ...state,
        fetchEsignDocLoader: false,
        fetchEsignDocSuccessData: {},
      };

    // Esign Generate OTP
    case ESIGN_GENERATE_OTP_REQUESTED:
      return {
        ...state,
        eSignOtpGenerationLoader: true,
        eSignOtpGenerationData: {},
      };
    case ESIGN_GENERATE_OTP_SUCCEEDED:
      return {
        ...state,
        eSignOtpGenerationLoader: false,
        eSignOtpGenerationData: payload,
      };
    case ESIGN_GENERATE_OTP_FAILED:
      return {
        ...state,
        eSignOtpGenerationLoader: false,
        eSignOtpGenerationData: {},
      };

    // Esign Verify OTP
    case ESIGN_VERIFY_OTP_REQUESTED:
      return {
        ...state,
        eSignVerifyOtpLoader: true,
        eSignVerifyOtpData: {},
      };
    case ESIGN_VERIFY_OTP_SUCCEEDED:
      return {
        ...state,
        eSignVerifyOtpLoader: false,
        eSignVerifyOtpData: payload,
      };
    case ESIGN_VERIFY_OTP_FAILED:
      return {
        ...state,
        eSignVerifyOtpLoader: false,
        eSignVerifyOtpData: {},
      };

    // Esign Resend OTP
    case ESIGN_RESEND_OTP_REQUESTED:
      return {
        ...state,
        eSignResendOtpLoader: true,
        eSignResendOtpData: {},
      };
    case ESIGN_RESEND_OTP_SUCCEEDED:
      return {
        ...state,
        eSignResendOtpLoader: false,
        eSignResendOtpData: payload,
      };
    case ESIGN_RESEND_OTP_FAILED:
      return {
        ...state,
        eSignResendOtpLoader: false,
        eSignResendOtpData: {},
      };

    default:
      return state;
  }
};

export default operatorReducer;
