import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { operatorESignStatus, operatorLinkType } from "../../../constant/enum";
import { GenericObject } from "../../../interfaces/commonInterface";
import {
  InitialOtpState,
  IResendOtpPayload,
  IVerifyOtpPayload,
} from "../../../interfaces/operatorInterface";
import en from "../../../locales/en.json";
import {
  eSignGenerateOtpAction,
  eSignResendOtpAction,
  eSignVerifyOtpAction,
  fetchESignDocAction,
  verifyOperatorEmailAction,
} from "../../../redux/actions";
import { RootState } from "../../../redux/store";
import { CustomButton } from "../../../yor-src/components/atom/Buttons/CustomButtons";
import OtpModal from "../../../yor-src/components/template/OtpModal";
import { StyleObject } from "./StyleObject";

const { operator } = en;

const initialOtpState: InitialOtpState = {
  phone: "",
  otp: "",
  referenceId: "",
  agreementId: "",
};

const OperatorVerification = (props: any) => {
  const { linkType, uniqueKey } = useParams();
  const dispatch = useDispatch();
  const clearOtpRef = useRef<any>({});

  const {
    verifyOperatorEmailData, // {}
    verifyOperatorEmailLoader, // boolean
    fetchEsignDocLoader, // boolean
    fetchEsignDocSuccessData, // {}
    eSignOtpGenerationLoader, // boolean
    eSignOtpGenerationData, // {}
  } = useSelector((state: RootState) => state.operatorReducer);

  const [fields, setFields] = useState<InitialOtpState>({ ...initialOtpState });
  const [show, setShow] = useState<boolean>(false); // OTP modal

  useEffect(() => {
    const handleLinkType = async (): Promise<void> => {
      try {
        if (linkType === operatorLinkType?.VERIFY_EMAIL) {
          dispatch(verifyOperatorEmailAction(uniqueKey));
        } else if (linkType === operatorLinkType?.E_SIGN) {
          dispatch(fetchESignDocAction(uniqueKey));
        }
      } catch (err) {
        console.error(err);
      }
    };

    if (linkType && uniqueKey) {
      handleLinkType();
    }
  }, [linkType, uniqueKey]);

  const handleChange = (key: string, value: string): void => {
    setFields((prevState) => ({ ...prevState, [key]: value }));
  };

  // Function to resend otp
  const handleResendOtp = (): void => {
    setFields({ ...fields, otp: "" });
    const payload: IResendOtpPayload = {
      data: { referenceId: fields?.referenceId },
    };
    dispatch(eSignResendOtpAction(payload));
    //
    clearOtpRef?.current?.clearOtp();
  };

  const eSignGenerateOtpApiSuccessFunction = (data?: GenericObject): void => {
    setFields((prev: InitialOtpState) => ({
      ...prev,
      referenceId: data?.referenceId,
    }));
    setShow(true);
  };

  // Function to generate otp
  const generateOtpHandler = (): void => {
    const payload: { data: { agreementId?: string } } = {
      data: {
        agreementId: fetchEsignDocSuccessData?.agreementId,
      },
    };
    dispatch(
      eSignGenerateOtpAction(payload, eSignGenerateOtpApiSuccessFunction)
    );
  };

  const eSignVerifyOtpApiSuccessFunction = (): void => {
    setShow(false);
    dispatch(fetchESignDocAction(uniqueKey));
  };

  // Function to verify otp
  const validateOtpHandler = (): void => {
    const payload: IVerifyOtpPayload = {
      data: {
        otp: fields?.otp,
        referenceId: fields?.referenceId,
      },
    };
    dispatch(eSignVerifyOtpAction(payload, eSignVerifyOtpApiSuccessFunction));
  };

  const isOtpSubmitBtnEnabled = (): boolean => {
    if (fields?.otp && fields?.otp?.length === 6) {
      return false;
    } else {
      return true;
    }
  };

  // Function to handle modal close
  const handleModalClose = (event: any, reason: string): void => {
    if (reason && reason === "backdropClick") return;
    setShow(false);
  };

  if (verifyOperatorEmailLoader || fetchEsignDocLoader) {
    return (
      <Box sx={StyleObject?.loaderBox}>
        <CircularProgress />
      </Box>
    );
  }

  if (uniqueKey && linkType === operatorLinkType?.VERIFY_EMAIL) {
    return (
      <Box sx={StyleObject?.emailBox}>
        <Card sx={StyleObject?.emailCard}>
          {/* Top Half: Image */}
          <Box sx={StyleObject?.topHalfBox} />

          {/* Bottom Half */}
          <CardContent sx={StyleObject?.bottomHalfBox}>
            {linkType === operatorLinkType?.VERIFY_EMAIL && (
              <Typography variant="h5" align="center" gutterBottom>
                {verifyOperatorEmailData?.statusCode === 422
                  ? verifyOperatorEmailData?.errorMessage
                  : verifyOperatorEmailData?.emailVerificationStatus}
              </Typography>
            )}
          </CardContent>
        </Card>
      </Box>
    );
  }

  return (
    <Box sx={StyleObject?.eSignBox}>
      <div
        dangerouslySetInnerHTML={{
          __html: fetchEsignDocSuccessData?.generatedAgreement,
        }}
      />

      <CustomButton
        onClick={generateOtpHandler}
        variant="contained"
        loading={eSignOtpGenerationLoader}
        sx={{ borderRadius: "5px", minWidth: 207 }}
        disabled={
          fetchEsignDocSuccessData?.status === operatorESignStatus?.VERIFIED
            ? true
            : false
        }
      >
        {fetchEsignDocSuccessData?.status === operatorESignStatus?.VERIFIED
          ? operator?.agreementVerified
          : operator?.verifyAgreement}
      </CustomButton>

      {/* Otp Modal */}
      {show && (
        <OtpModal
          handleChange={handleChange}
          otpValue={fields?.otp}
          phoneNumber={eSignOtpGenerationData?.phone}
          openModal={show}
          handleModalClose={handleModalClose}
          handleOtpModalSubmit={validateOtpHandler}
          handleResendOtp={handleResendOtp}
          isOtpSubmitBtnEnabled={isOtpSubmitBtnEnabled}
        />
      )}
    </Box>
  );
};

export default OperatorVerification;
