import { Box, Container, Typography } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { searchFaq } from "../../../assets";
import { langErrorMsg } from "../../../screen/city-freedo/constants/constants";
import ControlledAccordions from "../../../sharedComponents/molecule/Accordions/ControlledAccordions";
import { colors } from "../../../themes/colors";
import { debounce, hindiTextValidation } from "../../../utills/helper";
import { getHomePageData } from "../../redux/actions";
import styles from "./cmsComponent.module.css";
import { RootState } from "../../../redux/reducers";

const FaqScreen: FC = () => {
  const dispatch = useDispatch();
  const [showFaqData, setShowFaqData] = useState<any>("");
  const [errMsg, setErrMsg] = useState<boolean>(false);
  const [expandedPanel, setExpandedPanel] = useState<string | false>(false);
  const [searchFaqData, setSearchFaqData] = useState<string>("");

  const { homePageData = [] } = useSelector(
    (state: RootState) => state.cmsDataReducer
  );

  useEffect(() => {
    dispatch(getHomePageData("populate[faq][populate]=*"));
  }, []);

  useEffect(() => {
    if (homePageData[0]?.faq?.faqs?.length)
      setShowFaqData([...homePageData[0]?.faq?.faqs]);
  }, [homePageData]);

  //On Change Accordian
  const onChangeHandler = (panel: string, isExpanded: string | boolean) => {
    setExpandedPanel(isExpanded ? panel : false);
  };

  //handle search FAQ
  const handleSearchFaq = (searchKey: string = "") => {
    if (searchKey) {
      const hindiValidation = hindiTextValidation(searchKey);
      hindiValidation
        ? setShowFaqData(
            homePageData[0]?.faq?.faqs.filter((faq: any) =>
              faq.question.toLowerCase().includes(searchKey.toLowerCase())
            )
          )
        : setErrMsg(true);
    } else {
      setShowFaqData([...homePageData[0]?.faq?.faqs]);
      setErrMsg(false);
    }
  };

  //debounced for searching
  const debouncedChangeHandler = useCallback(
    debounce(handleSearchFaq, 1000),
    []
  );

  return (
    <>
      <Helmet>
        <title>Freedo: Bike Rentals | Two Wheelers Rentals App</title>
        <meta
          name="description"
          content="Freedo rentals offers scooter & bike rentals in Gurgaon online at cheap prices. Wide Range of two wheelers at affordable hourly rates."
        />
        <meta name="author" content="Freedo Rentals" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Bike Rentals in Gurgaon | Bike For Rent in Gurgaon"
        />
        <meta
          property="og:description"
          content="Freedo rentals offers scooter & bike rentals in Gurgaon online at cheap prices. Wide Range of two wheelers at affordable hourly rates."
        />
        <meta property="og:url" content="https://freedo.rentals/faq/" />
        <meta
          property="og:image"
          content="https://freedo.rentals/page image url"
        />
        <meta property="og:site_name" content="Freedo Rentals" />
        <link rel="canonical" href="https://freedo.rentals/faq/" />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <Box className="main-faq-wrapper ">
        <Container>
          <Box className={styles.faqBannerWrap}>
            <Typography
              variant="h4"
              sx={{
                color: colors.white,
                textAlign: "center",
              }}
            >
              {homePageData[0]?.faq?.title}
            </Typography>
          </Box>
          <form
            className="col-md-6 col-sm-7 col-10"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="mb-3">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  <img src={searchFaq} alt="" />
                </span>
                <input
                  type="text"
                  value={searchFaqData}
                  onChange={(e) => {
                    setSearchFaqData(e.target.value);
                    debouncedChangeHandler(e.target.value);
                  }}
                  className="form-control"
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
              {errMsg ? (
                <span className="error-field">{langErrorMsg}</span>
              ) : (
                ""
              )}
            </div>
          </form>
        </Container>
        <Box className={styles.faqsInner}>
          <Container>
            <Box className={styles.faqInnerWrap}>
              {showFaqData?.length ? (
                <ControlledAccordions
                  accordianDataArray={showFaqData}
                  onChangeHandler={onChangeHandler}
                  expandedPanel={expandedPanel}
                  titleKey="question"
                  contentKey="answer"
                />
              ) : (
                <>{!showFaqData?.length && "No Search Data Found"}</>
              )}
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default FaqScreen;
