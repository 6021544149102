// DESIGN PENDING

import { Box, Card, Stack, Tooltip, Typography } from "@mui/material";
import { getFormattedINR } from "../../../utils/helper";
import styles from "./section.module.css";
import { colors } from "../../../../themes/colors";
import { truncateText } from "../../../../utills/helper";
import { PlanTypeCard } from "../../../../assets";

const PlanCards = ({ onPlanSelect, selectedPlan, plan, width }: any) => {
  const isSelected = selectedPlan === plan?.key;

  // full text- to show in tooltip
  // truncated text- is used to show only first few characters and "..." at the end of the text.
  const fullTextKmLimit = `${plan?.kmLimit} km`;
  const truncatedTextKmLimit = truncateText(fullTextKmLimit, 6);
  const fullTextPrice = `${getFormattedINR(plan?.amount)}`;
  const truncatedTextPrice = truncateText(fullTextPrice, 6);
  return (
    <Stack direction={"row"} gap="10px">
      <Card
        key={plan?.key}
        className={`${styles.card} ${isSelected ? styles.selected : ""}`}
        onClick={() => {
          onPlanSelect(plan.key)}}
        style={{
          backgroundImage: `url(${PlanTypeCard})`,
        }}
      >
        {plan?.isAvailable ? (
          <Box
            className={styles.planTypeCardBox}
            width={width ? width : "170px"}
          >
            <Stack alignItems="center" gap="15px">
              <Typography variant={"subheading"}>{plan.heading}</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: isSelected
                    ? colors.primary_dark_blue
                    : colors.secondary_light_gray,
                  justifyContent: "space-between",
                  padding: "0px 22px",
                  gap: "10px",
                  borderRadius: "16px",
                }}
              >
                <Tooltip title={fullTextKmLimit}>
                  <Typography
                    variant={"body1"}
                    color={isSelected ? "white" : colors.primary_light_gray}
                    sx={{
                      maxWidth: "100%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      padding: "4px 0px",
                    }}
                  >
                    {truncatedTextKmLimit}
                  </Typography>
                </Tooltip>
                <Box
                  sx={{
                    width: "2px",
                    backgroundColor: !isSelected
                      ? "white"
                      : colors.primary_light_gray,
                    margin: "0 10px", // Adjust the margin as needed
                  }}
                ></Box>
                <Tooltip title={fullTextPrice}>
                  <Typography
                    variant={"body1"}
                    color={isSelected ? "white" : colors.primary_light_gray}
                    sx={{
                      maxWidth: "100%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      padding: "4px 0px",
                    }}
                  >
                    {truncatedTextPrice}
                  </Typography>
                </Tooltip>
              </Box>
            </Stack>
          </Box>
        ) : (
          <></>
        )}
      </Card>
    </Stack>
  );
};

export default PlanCards;
