import moment from "moment";
import { infinite, prodImage, zero } from "../../../../assets";
import en from "../../../../locales/en.json";
import {
  GenericObject,
  getDifferenceInDays,
  getLocalStorageData,
} from "../../../../utills/helper";
import { LocalStorage, VehicleImageTypeEnum } from "../../../../constant/enum";
import Grid from "@mui/material/Grid2";
import { Box, Stack, Typography } from "@mui/material";
import { WEB_URL, modelImageBucketUrl } from "../../../../config/apiUrls";
import { useSelector } from "react-redux";

const CheckoutProductDetail = (props: any) => {
  const { checkout } = en;
  const bikeDetail = props.productData;
  const rentDetail = props.rentData;
  const userCity = getLocalStorageData(LocalStorage.userCity);
  const modelAvailability = bikeDetail?.availability;
  const modelName = bikeDetail?.modelName;
  const modelPricing = modelAvailability[0]?.pricing;
  const isSecurityDeposit = modelPricing?.security?.available;
  const isUnlimitedKM = modelPricing?.unlimited?.available;

  const { rentalModelDetails, rentalCommonLoader } = useSelector(
    (state: any) => state.rentalsCommonReducer
  );
  const {
    // name: modelName,
    manufacturer,
    displayName,
    type: ModelType,
    imagePath: vehicleImagePath,
    details: {
      fuelType = "",
      specificSpecifications: {
        averageEconomy = "",
        cubicCapacity = "",
        hp: modelHp = "",
      } = {},
    } = {},
    ...rest
  } = rentalModelDetails;
  // let imageObj: GenericObject = {};
  let imageObj: GenericObject = {};
  imageObj = rentalModelDetails?.images.find(
    (item) => item.type === VehicleImageTypeEnum.RIGHT
  );
  if (!Object.keys(imageObj).length) {
    imageObj = rentalModelDetails?.images[0];
  }
  return (
    <Grid
      container
      // rowSpacing={1}
      // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      alignItems={"center"}
      className="product-details"
    >
      <Grid size={{ lg: 10, md: 10, sm: 10 }}>
        <img src={prodImage} className="prod-image" />
        <Typography
          component="span"
          variant="caption"
          className="product-detail"
        >
          {checkout.productDetails}
        </Typography>
      </Grid>

      <Grid container>
        <Grid size={{ lg: 7, md: 8 }}>
          <Typography
            component={"span"}
            variant="caption"
            className="hero smallest-text"
          >
            {manufacturer}
          </Typography>
          <Typography component={"span"} variant="caption" className="head">
            {displayName}
          </Typography>
          <Typography component="p" className="rent-det">
            {checkout.rentDetails}
          </Typography>

          <Box className="fee-wrapper">
            {!isSecurityDeposit && (
              <Stack direction={"row"} gap="5px" alignItems={"center"}>
                <img src={zero} alt="Zero Deposit" style={{ height: "0.9rem"}} />
                <Typography variant="smallText">{checkout.zeroDeposit}</Typography>
              </Stack>
            )}
            {isUnlimitedKM && (
              <Box className="unlimited mt-1">
                <img src={infinite} alt="Unlimited Kilometeres" />
                {checkout.unlimitedKm}
              </Box>
            )}
          </Box>
          
          {rentDetail && (
            <Box className="date-progress">
              <Stack
                className="location smallest-text"
                alignItems={"center"}
                direction="row"
              >
                <i className="fas fa-location-dot me-1"></i>
                {userCity.displayName ?? ""}
              </Stack>

              <Box className="progressbar-wrap">
                <Box className="smallest-text freedo-txt">
                  {moment(rentDetail.startDate).format("DD-MM-YYYY")}
                </Box>
                <Box className="progressbar">
                  <span className="active"></span>
                  <span className="next"></span>
                </Box>
                <Box className="ml-0 smallest-text">
                  {moment(rentDetail.endDate).format("DD-MM-YYYY")}
                </Box>
              </Box>

              <Box className="day-one smallest-text">
                {getDifferenceInDays(rentDetail.startDate, rentDetail.endDate)}{" "}
                Days
              </Box>
            </Box>
          )}
        </Grid>
        <Grid size={{ lg: 4, md: 5 }}>
          <img
            src={`${modelImageBucketUrl}${modelName}/${imageObj?.type}-${imageObj?.fileName}`}
            className="bike-right"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CheckoutProductDetail;
