import React, { useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import authStyles from "./styleObject";
import { signInbackground } from "../../../assets";
import { colors } from "../../../themes/colors";
import en from "../../../locales/en.json";
import SignIn from "./sections/SignIn";
import SignUp from "./sections/SignUp";

const initialState = {
  isSignIn: true,
  phoneNo: "",
  signUpPhoneNo: "",
  firstName: "",
  lastName: "",
  email: "",
  gender: "",
  profileImage: "",
  loginOtp: "",
  signUpOtp: "",
  isEnterSignUpOtp: false,
  errorFields: {
    firstName: "",
    lastName: "",
    email: "",
    signUpPhoneNo: "",
    phoneNo: "",
    gender: "",
  },
};

const UserAuth = () => {
  const [fields, setFields] = useState(initialState);
  const { isSignIn } = fields;

  const handleChange = (key: string, value: any) => {
    if (!key) {
      setFields((prevState) => ({
        ...prevState,
        ...value,
      }));
      return;
    }
    setFields((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  return (
    <Box
      sx={{
        justifyContent: "center",
        ...authStyles.containerStyle,
      }}
    >
      {isSignIn ? (
        <SignIn fields={fields} handleChange={handleChange} />
      ) : (
        <SignUp fields={fields} handleChange={handleChange} />
      )}
    </Box>
  );
};

export default UserAuth;
