import {
  Box,
  Chip,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import { colors } from "../../../../../themes/colors";
import UsePagination from "./bookingListPagination";
import { dateFormat, getEncryptedText } from "../../../../../utills/helper";
import {
  getDeliveryAddress,
  getFormattedDate,
  getFormattedINR,
} from "../../../../utils/helper";
import { useDispatch } from "react-redux";
import { getBookingHistory } from "../../../../redux/actions";
import { CustomButton } from "../../../../../sharedComponents/atom/Buttons/CustomButtons";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../../../constant/routeContants";
import { TypographyConstants } from "../../../../../constant/enum";
import {
  BookingStatusColorCodes,
  BookingStatusDisplay,
} from "../../../../constants/enum";
import en from "../../../../../locales/en.json";
import style from "./bookingDetails.module.css";
import { noCurrentBooking } from "../../../../../assets";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import CloseIcon from "@mui/icons-material/Close";
import EventIcon from "@mui/icons-material/Event";

const statusIcons = {
  UPCOMING: <EventIcon />,
  ONGOING: <TwoWheelerIcon />,
  CANCELLED: <CloseIcon />,
  ENDED: <CloseIcon />,
};

const HeadingValue = ({ heading, value }) => {
  return (
    <Box sx={{ display: "flex" }}>
      <Typography
        variant={TypographyConstants.SUBHEADING}
        sx={{ width: "150px" }}
      >
        {heading}
      </Typography>
      <Typography variant={TypographyConstants.BODY1}>{value}</Typography>
    </Box>
  );
};

function BookingList({
  bookings,
  pageData,
  custId,
  setCurrentPage,
  currentPage,
  activeTab,
}: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handlePagination = (page: any) => {
    setCurrentPage(page);
  };

  //step Icon
  function StepIcon(props) {
    return (
      <Box>
        <Box
          className={style.stepIcon}
          sx={{
            borderRadius: props.square ? "0px" : "50%",
          }}
        />
      </Box>
    );
  }

  //navigate on view-booking
  const handleViewDetails = (e, bookingId) => {
    e.preventDefault();
    let object = {
      bookingId: bookingId,
    };
    const encryptedObject = getEncryptedText(JSON.stringify(object));
    navigate(`/view-booking/${encryptedObject}`);
  };
  return (
    <>
      <Grid container spacing={2}>
        {bookings?.length ? (
          bookings?.map((booking: any) => {
            return activeTab === "Rentals" ? (
              <Grid size={12}>
                <Box className={style.bookingCard}>
                  <Box className={style.flexBox}>
                    <Typography variant="h6">
                      {booking?.modelDisplayName}
                    </Typography>
                    <Box sx={{ display: "flex", gap: "5px" }}>
                      <span>
                        {getFormattedDate(booking?.startDate, "date")}
                      </span>
                      <Box>-------</Box>
                      <span>{getFormattedDate(booking?.endDate, "date")}</span>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "start",
                      gap: "35px",
                    }}
                  >
                    <Stepper
                      activeStep={1}
                      orientation="vertical"
                      sx={{
                        "& .MuiStepConnector-root": { marginLeft: "4px" },
                        "& .MuiStepConnector-line": {
                          display: "block",
                          borderLeft: "3px solid #2bc7c9",
                          minHeight: "30px",
                          borderWidth: "2px",
                        },
                        width: "100%",
                      }}
                    >
                      <Step>
                        <StepLabel StepIconComponent={() => <StepIcon />}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="caption" sx={{ width: "40%" }}>
                              {booking?.deliveryDetails?.type === "BRANCH"
                                ? `${en.bookingDetails.pickUpAddress}: `
                                : `${en.bookingDetails.deliveryAddress}: `}
                            </Typography>
                            <Typography
                              variant="caption"
                              sx={{ width: "100%", height: "20px" }}
                            >
                              {getDeliveryAddress(
                                booking?.deliveryDetails?.address
                              ) ?? "N/A"}
                            </Typography>
                          </Box>
                        </StepLabel>
                      </Step>
                      <Step>
                        <StepLabel
                          StepIconComponent={() => <StepIcon square={true} />}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Typography variant="caption" sx={{ width: "40%" }}>
                              Drop Location:{" "}
                            </Typography>
                            <Typography
                              variant="caption"
                              sx={{ width: "100%", height: "20px" }}
                            >
                              {getDeliveryAddress(
                                booking?.returnDetails?.address
                              ) ?? "N/A"}
                            </Typography>
                          </Box>
                        </StepLabel>
                      </Step>
                    </Stepper>
                  </Box>
                  <Box className={style.flexBox}>
                    <Stack direction="row" sx={{ height: "fit-content" }}>
                      <Typography>
                        {booking?.mainBookingPackageDuration}
                      </Typography>
                      <Box className={style.verticalSeparator} />
                      <Typography>{booking?.cityDisplayName}</Typography>
                      <Box className={style.verticalSeparator} />
                      <Typography>
                        {getFormattedINR(booking?.totalPaidAmount)}
                      </Typography>
                    </Stack>
                    <Box>
                      <CustomButton
                        variant="contained"
                        label={en.bookingDetails.viewDetails}
                        onClick={(e) => {
                          handleViewDetails(e, booking?.id);
                        }}
                        sx={{
                          minHeight: "20px",
                          height: "30px",
                          padding: "0px 10px",
                        }}
                      />
                    </Box>
                  </Box>
                  <Box className={style.statusBadge}>
                    <Chip
                      icon={statusIcons[booking?.status]}
                      label={`${BookingStatusDisplay[booking?.status]} Ride`}
                      sx={{
                        color: colors.white,
                        backgroundColor:
                          BookingStatusColorCodes[booking?.status],
                        padding: "0px 5px",
                        "&& .MuiChip-icon": {
                          color: "white",
                          fontSize: "large",
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            ) : (
              <Grid size={6}>
                <Box className={style.bookingCard}>
                  <Stack>
                    <Box className={style.flexBox}>
                      <Typography variant="h6">
                        {booking?.modelDisplayName}
                      </Typography>
                      <Box sx={{ display: "flex", gap: "5px" }}>
                        <span>
                          {getFormattedDate(booking?.startDate, "date")}
                        </span>
                        <Box>-------</Box>
                        <span>
                          {getFormattedDate(booking?.endDate, "date")}
                        </span>
                      </Box>
                    </Box>
                    <HeadingValue
                      heading={`${en.bookingDetails.hubName}: `}
                      value={booking?.branchDisplayName}
                    />
                    <HeadingValue
                      heading={`${en.bookingDetails.cityName}: `}
                      value={booking?.cityDisplayName}
                    />
                    <HeadingValue
                      heading={`${en.bookingDetails.amountPaid}: `}
                      value={getFormattedINR(booking?.paidAmount)}
                    />
                    <HeadingValue
                      heading={`${en.bookingDetails.monthlyEMI}: `}
                      value={getFormattedINR(booking?.monthlyCharges)}
                    />
                    <HeadingValue
                      heading={`${en.bookingDetails.remainingAmount}: `}
                      value={getFormattedINR(booking?.remainingAmount)}
                    />
                  </Stack>
                  <Box className={style.statusBadge}>
                    <Chip
                      label={`${BookingStatusDisplay[booking?.status]} Ride`}
                      sx={{
                        color: colors.white,
                        backgroundColor:
                          BookingStatusColorCodes[booking?.status],
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            );
          })
        ) : activeTab === "Subscription" ? (
          <Stack gap="10px">
            <img
              src={noCurrentBooking}
              className="no-booking-img"
              alt="No Booking Found"
            />
            <Typography sx={{ display: "flex", justifyContent: "Center" }}>
              You have not booked any rides
            </Typography>
            <Typography sx={{ display: "flex", justifyContent: "Center" }}>
              Would you like to plan your next ride with us ?
            </Typography>
            <Typography
              variant="h4"
              sx={{ display: "flex", justifyContent: "Center" }}
            >
              Explore Renting Fleet
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "Center" }}>
              <CustomButton
                variant="contained"
                label={en.bookingDetails.exploreFleet}
                onClick={() => {
                  navigate(Routes.RENT);
                }}
                sx={{ borderRadius: "30px" }}
              />
            </Box>
          </Stack>
        ) : (
          <></>
        )}
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        {(activeTab === "Rentals" && pageData?.total > 2) ||
        (activeTab === "Subscription" && pageData?.total > 4) ? (
          <UsePagination
            count={pageData?.total}
            handlePagination={handlePagination}
            currentPage={currentPage}
            itemsPerPage={activeTab === "Rentals" ? 2 : 4}
          />
        ) : (
          <></>
        )}
      </Box>
    </>
  );
}

export default BookingList;
