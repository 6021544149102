import CloseIcon from "@mui/icons-material/Close";
import { Box, Card, Grid2, Modal, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { CityDummy, New, upcomingcity } from "../../../../assets";
import { WEB_URL } from "../../../../config/apiUrls";
import { LocalStorage, TypographyConstants } from "../../../../constant/enum";
import en from "../../../../locales/en.json";
import { colors } from "../../../../themes/colors";
import {
  getLocalStorageData,
  isValidImageUrl,
} from "../../../../utills/helper";
import Styles from "../index.module.css";

const {
  home: { homePageCarousal },
  yourOwnRide,
} = en;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  backgroundColor: "#fff",
  borderRadius: "10px",
  boxShadow: 24,
  maxHeight: "90%",
  paddingBottom: "10px",
};

const modalBodyStyle = {
  width: "100%",
  overflowY: "auto",
  maxHeight: "calc(100% - 50px)",
  scrollbarWidth: "thin",
  scrollbarColor: "#888 #e0e0e0",
  display: "flex",
  padding: "20px",
  "&::-webkit-scrollbar": {
    width: "2px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#f5f5f5",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#b3b3b3",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#8c8c8c",
  },
};

const cityCardStyle = {
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  backgroundRepeat: "no-repeat",
  height: "140px",
  width: "130px",
  backgroundSize: "cover",
  cursor: "pointer",
  position: "relative",
};

const newImageTag = {
  width: "76px",
  position: "absolute",
  left: "-8px",
  top: "0px",
  height: "20px",
};

const SelectCityModalYor = (props: any) => {
  const { cityList, handleCityClick, openModal, handleClose } = props;
  const cityData = getLocalStorageData(LocalStorage.yorUserCity);
  const [isUpcomingModal, setIsUpcomingModal] = useState<boolean>(false);

  // state to store valid image paths
  const [imagePaths, setImagePaths] = useState<string[]>([]);

  // side-effect to filter out valid image paths or url
  useEffect(() => {
    const validateImageUrls = async () => {
      const validImagePaths = await Promise.all(
        cityList.map((item: any) => {
          const imagePath: string = `${WEB_URL}/${item?.images[0]?.filePath}`;
          return isValidImageUrl(imagePath).then((isValid) =>
            isValid ? imagePath : CityDummy
          );
        })
      );
      setImagePaths(validImagePaths);
    };
    if (cityList) {
      validateImageUrls();
    }
  }, [cityList]);

  return (
    <Modal
      open={openModal}
      onClose={cityData?.name ? handleClose : () => {}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEscapeKeyDown
    >
      <Stack sx={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "14px",
          }}
        >
          <Stack marginLeft={"24px"}>
            <Typography
              variant={TypographyConstants.SUBTITLE2}
              sx={{ fontSize: "16px", marginLeft: "4px" }}
            >
              {yourOwnRide.selectCity}
            </Typography>
            <Box className={Styles.selectCityUnderline} />
          </Stack>
          {cityData && (
            <CloseIcon
              onClick={() => handleClose()}
              sx={{ cursor: "pointer" }}
            />
          )}
        </Box>
        {/* Modal Body with scrollable content */}
        <Box sx={modalBodyStyle}>
          <Grid2 container spacing={2}>
            {cityList?.map((item: any, index: number) => {
              return (
                <Grid2
                  size={{ xs: 12, sm: 3, md: 2 }}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Card
                    className={Styles.cityCard}
                    sx={{
                      ...cityCardStyle,
                      backgroundImage: `url(${imagePaths[index] || CityDummy})`,
                    }}
                    onClick={() => handleCityClick(index)}
                    key={index}
                  >
                    <Box>
                      {item?.isNew ? (
                        <img className={Styles.cityCardNew} src={New} />
                      ) : null}

                      {item?.isUpcoming ? (
                        <img
                          src={upcomingcity}
                          className={Styles.cityCardUpcoming}
                        />
                      ) : null}
                    </Box>
                    <Box
                      className={Styles.cityNameCard}
                      sx={{
                        backgroundColor:
                          item?.name === cityData?.name
                            ? colors.strong_cyan
                            : colors.black_opacity,
                      }}
                    >
                      <Typography sx={{ color: colors.white }}>
                        {item?.displayName}
                      </Typography>
                    </Box>
                  </Card>
                </Grid2>
              );
            })}
          </Grid2>
        </Box>
      </Stack>
    </Modal>
  );
};
export default SelectCityModalYor;
