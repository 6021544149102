import * as React from "react";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Container, Stack, styled } from "@mui/material";
import { GenericObject } from "../../../../utills/helper";
import { colors } from "../../../../themes/colors";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { CAInterface } from "../../../../interfaces/actionInterfaces";

/** 
 *  props info & usage
@param {GenericObject[]} accordianDataArray - accordian title content array. Expected default array below
[{ "id": 1, "question": "", "answer": ""}, { "id": 2, "question": "", "answer":""}, ...]
@param {string} titleKey - dynamic key to access accordian title (AccordionSummary Title)
@param {string} contentKey - dynamic key to access accordian content (AccordionDetails Content)
@param {Function} onChangeHandler - callback function to control accordian on change event 
eg- const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
@param {string | number | boolean} expandedPanel - value to control accordian state (collapse/expand) 
const [expanded, setExpanded] = useState<string | false>(false);
*/

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    square
    {...props}
    slotProps={{ transition: { unmountOnExit: true } }}
  />
))(({ theme }) => ({
  boxShadow: "0 5px 20px -2px #7090b038",
  margin: "20px 20px 30px",
  border: "none",
  borderRadius: "10px",
  backgroundColor: colors.neon_white,
  "&:not(:last-child)": {
    borderRadius: "10px",
  },
  "&::before": {
    display: "none",
  },
}));

const ControlledAccordions = ({
  accordianDataArray,
  onChangeHandler,
  expandedPanel,
  contentKey,
  titleKey,
  expandIcon,
  containerStyle = {},
  accordionStyle = {},
  accordionSummaryStyle = {},
}: CAInterface) => {
  return (
    <Container sx={{ padding: 0, ...containerStyle }}>
      {accordianDataArray?.map((item, index) => {
        let isExpanded = expandedPanel === `panel${index}`;
        return (
          <>
            <Accordion
              expanded={isExpanded}
              onChange={(e: any, expanded) => {
                onChangeHandler(`panel${index}`, expanded);
              }}
              sx={{ ...accordionStyle }}
            >
              <AccordionSummary
                expandIcon={
                  expandIcon ? (
                    expandIcon
                  ) : isExpanded ? (
                    <RemoveCircleIcon sx={{ color: colors.primary }} />
                  ) : (
                    <AddCircleIcon sx={{ color: colors.primary }} />
                  )
                }
                aria-controls={`panel${index}bh-content`}
                id={`panel${index}bh-header`}
                sx={{ ...accordionSummaryStyle }}
              >
                {titleKey ? item[titleKey] : item?.title}
              </AccordionSummary>
              <AccordionDetails>
                {contentKey ? item[contentKey] : item?.content}
              </AccordionDetails>
            </Accordion>
          </>
        );
      })}
    </Container>
  );
};

export default ControlledAccordions;
