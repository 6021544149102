import { Components, Theme } from "@mui/material";

export const radioCustomization: Components<Theme> = {
  MuiRadio: {
    styleOverrides: {
      root: {
        "&.Mui-checked .MuiSvgIcon-root": {
          backgroundColor: "##2BC7C9", // Inner circle (checked) color
          borderColor: "##2BC7C9", // Optional: change the outer border color when checked
        },

        // Checked state style (outer circle border color when checked)
        "&.Mui-checked": {
          color: "##2BC7C9", // Outer circle color when checked
        },
      },
    },
  },
};
