import {
  Box,
  Chip,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React from "react";
import style from "./bookingDetails.module.css";
import { bike, noCurrentBooking, upcomingBooking } from "../../../../../assets";
import StepIcon from "@mui/material/StepIcon";
import { colors } from "../../../../../themes/colors";
import { CustomButton } from "../../../../../sharedComponents/atom/Buttons/CustomButtons";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../../../constant/routeContants";
import {
  getDeliveryAddress,
  getFormattedDate,
  getFormattedINR,
} from "../../../../utils/helper";
import {
  BookingStatusColorCodes,
  BookingStatusDisplay,
} from "../../../../constants/enum";

import en from "../../../../../locales/en.json";

function CurrentBooking({ currentBooking }: any) {
  //step icon
  function StepIcon(props) {
    return (
      <Box>
        <Box
          className={style.stepIcon}
          sx={{
            borderRadius: props.square ? "0px" : "50%",
          }}
        />
      </Box>
    );
  }
  const navigate = useNavigate();

  return (
    <Stack gap="20px">
      {currentBooking ? (
        <>
          <Box className={style.currentBookingBox}>
            <img
              src={upcomingBooking}
              alt={en.bookingDetails.currentBooking}
              style={{ maxWidth: "35%" }}
            />
            <Box className={style.statusBadge}>
              <Chip
                label={`${BookingStatusDisplay[currentBooking?.status]} Ride`}
                sx={{
                  color: colors.THEME_BLUE,
                  backgroundColor: "#f5feff",
                }}
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">
              {currentBooking.modelDisplayName}
            </Typography>
            <Box sx={{ display: "flex", gap: "5px" }}>
              <span>{getFormattedDate(currentBooking?.startDate, "date")}</span>
              <Box>-------</Box>
              <span style={{ color: colors.THEME_BLUE }}>
                {getFormattedDate(currentBooking?.endDate, "date")}
              </span>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "start",
              gap: "35px",
            }}
          >
            <Stepper
              activeStep={1}
              orientation="vertical"
              sx={{
                "& .MuiStepConnector-root": { marginLeft: "4px" },
                "& .MuiStepConnector-line": {
                  display: "block",
                  borderLeft: "3px dashed #2bc7c9",
                  minHeight: "30px",
                  borderWidth: "2px",
                },
                width: "100%",
              }}
            >
              <Step>
                <StepLabel StepIconComponent={() => <StepIcon />}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="caption" sx={{ width: "40%" }}>
                      {currentBooking?.deliveryDetails?.type === "BRANCH"
                        ? `${en.bookingDetails.pickUpAddress}: `
                        : `${en.bookingDetails.deliveryAddress}: `}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{ width: "100%", height: "20px" }}
                    >
                      {getDeliveryAddress(
                        currentBooking?.deliveryDetails?.address
                      ) ?? "N/A"}
                    </Typography>
                  </Box>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel StepIconComponent={() => <StepIcon square={true} />}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="caption" sx={{ width: "40%" }}>
                      Drop Location:{" "}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        color: colors.LIGHT_BLUE,
                        width: "100%",
                        height: "20px",
                      }}
                    >
                      {getDeliveryAddress(
                        currentBooking?.returnDetails?.address
                      ) ?? "N/A"}
                    </Typography>
                  </Box>
                </StepLabel>
              </Step>
            </Stepper>
          </Box>
          <Stack direction="row">
            <Typography>
              {currentBooking?.mainBookingPackageDuration}
            </Typography>
            <Box className={style.verticalSeparator} />
            <Typography>{currentBooking?.cityDisplayName}</Typography>
            <Box className={style.verticalSeparator} />
            <Typography>
              {getFormattedINR(currentBooking?.totalPaidAmount)}
            </Typography>
          </Stack>
        </>
      ) : (
        <Stack gap="10px" sx={{ padding: "20px 0px" }}>
          <img
            src={noCurrentBooking}
            className="no-booking-img"
            alt="No Booking Found"
          />
          <Typography sx={{ display: "flex", justifyContent: "Center" }}>
            {en.bookingDetails.noBookedRide}
          </Typography>
          <Typography sx={{ display: "flex", justifyContent: "Center" }}>
            {en.bookingDetails.rideWithUs}
          </Typography>
          <Typography
            variant="h4"
            sx={{ display: "flex", justifyContent: "Center" }}
          >
            {en.bookingDetails.exploreRentingFleet}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "Center" }}>
            <CustomButton
              variant="contained"
              label={en.bookingDetails.exploreFleet}
              onClick={() => {
                navigate(Routes.RENT);
              }}
              sx={{ borderRadius: "30px" }}
            />
          </Box>
        </Stack>
      )}
    </Stack>
  );
}

export default CurrentBooking;
