import { BASE_URL, getApiUrl } from "../../../config/apiUrls";
import { customAxios } from "../../../config/axiosConfig";

//add new address
const addNewAddress = (payload) => {
  try {
    const url = BASE_URL + getApiUrl().addNewAddress;
    return customAxios.post(url, payload.data).catch((err) => {
      return err;
    });
  } catch (err) {
    return err;
  }
};

//update address
const updateAddress = (payload) => {
  try {
    const addId = `${payload.ids.customerId}/address/${payload.ids.id}`;
    const url = BASE_URL + getApiUrl(addId).deleteUpdateAddress;
    return customAxios.put(url, payload.data).catch((err) => {
      return err;
    });
  } catch (err) {
    return err;
  }
};

//delete address
const deleteAddress = (payload) => {
  try {
    const addId = `${payload.customerId}/address/${payload.id}`;
    const url = BASE_URL + getApiUrl(addId).deleteUpdateAddress;
    return customAxios.delete(url).catch((err) => {
      return err;
    });
  } catch (err) {
    return err;
  }
};

export default { addNewAddress, deleteAddress, updateAddress };
