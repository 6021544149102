import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { Box, Fade, Grid2, Stack, Typography } from "@mui/material";
import { memo, useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { uploadSelfie } from "../../../../assets";
import en from "../../../../locales/en.json";
import { RootState } from "../../../../redux/reducers";
import GenericModal from "../../../../sharedComponents/molecule/Modals/GenericModal";
import { colors } from "../../../../themes/colors";
import { SuccessContent } from "./SuccessScreen";
import TakeSelfieModalContent from "./TakeSelfieModalContent";
import styles from "./userKyc.module.css";
import DeleteIcon from "@mui/icons-material/Delete";

interface selfie {
  setImagePreview: any;
  imagePreview: any;
  callGetAttachment: Function;
  handleUploadImgClick: any;
  onClickDeleteImage: any;
  handleImageInput: Function | any;
}

const {
  supportedFormats = "N/A",
  or = "N/A",
  takeSelfie = "N/A",
  uploadASelfie = "N/A",
} = en.profile.kyc;

const Selfie = (props: selfie) => {
  const {
    setImagePreview,
    imagePreview,
    callGetAttachment,
    handleUploadImgClick,
    onClickDeleteImage,
    handleImageInput,
  } = props;

  //reducer data
  const { userTrustScoreData = {} } = useSelector(
    (state: RootState) => state.userKycReducer
  );

  const { isVerified = false, isPresent = false } =
    userTrustScoreData?.trustScoreFactor?.selfieTrustScore || {};

  // selfie modal state
  const [showTakeSelfieModal, setShowTakeSelfieModal] =
    useState<boolean>(false);
  const webcamRef = useRef<any>(null);

  //toogle selfie modal
  const toggleSelfieModal = () => {
    setShowTakeSelfieModal((prev) => !prev);
  };

  //handle capture selfie
  const handleCaptureSelfie = useCallback(() => {
    const imageSrc = webcamRef?.current?.getScreenshot();

    if (!imageSrc) {
      return;
    }
    setImagePreview((prev: any) => {
      return {
        ...prev,
        src: imageSrc,
        fileName: "MySelfie.jpeg",
      };
    });
    callGetAttachment(imageSrc, "MySelfie.jpeg");
    toggleSelfieModal();
  }, [webcamRef, setImagePreview]);

  return (
    <>
      {isPresent && isVerified ? (
        <SuccessContent msg="Selfie Verified Sucessfully" />
      ) : isPresent ? (
        <SuccessContent msg="Selfie Uploaded Sucessfully " />
      ) : (
        <>
          {imagePreview?.src ? (
            <Stack gap="20px" width="100%" alignItems="center">
              <Box
                className={styles.deleteIconBox}
                onClick={onClickDeleteImage}
              >
                <DeleteIcon fontSize="small" />
              </Box>
              <img
                className={styles.previewImage}
                src={imagePreview?.src}
                alt=""
                style={{
                  width: "280px",
                  objectFit: "cover",
                }}
              />
              <Typography variant="body2" fontWeight="500">
                {imagePreview?.fileName}
              </Typography>
            </Stack>
          ) : (
            <Fade>
              <>
                <Grid2 container className={styles.gridStyle} spacing={3}>
                  <Grid2 size={{ md: 12, lg: 6 }} sx={{ width: "100%" }}>
                    <Box
                      className={styles.selfieCard}
                      onClick={handleImageInput}
                    >
                      <input
                        accept="image/*"
                        type="file"
                        onChange={handleUploadImgClick}
                        style={{ display: "none" }}
                        id="image_upload"
                      />
                      <img src={uploadSelfie} alt="" />
                      <Typography variant="subtitle1">
                        {uploadASelfie}
                      </Typography>
                    </Box>
                  </Grid2>
                  <Grid2 size={{ md: 12, lg: 1 }} sx={{ width: "100%" }}>
                    {or}
                  </Grid2>
                  <Grid2 size={{ md: 12, lg: 5 }} sx={{ width: "100%" }}>
                    <Box
                      className={styles.selfieCard}
                      onClick={toggleSelfieModal}
                    >
                      <PhotoCameraIcon className={styles.cameraIcon} />
                      <Typography variant="subtitle1">{takeSelfie}</Typography>
                    </Box>
                  </Grid2>
                </Grid2>
                <Typography variant="caption" color={colors.dark_gray}>
                  {supportedFormats}
                </Typography>
              </>
            </Fade>
          )}
        </>
      )}

      {showTakeSelfieModal && (
        <GenericModal
          isOpen={showTakeSelfieModal}
          handleClose={toggleSelfieModal}
          title={"Take Selfie Modal"}
        >
          <TakeSelfieModalContent
            webcamRef={webcamRef}
            handleCaptureSelfie={handleCaptureSelfie}
          />
        </GenericModal>
      )}
    </>
  );
};

export default memo(Selfie);
