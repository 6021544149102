import { Stack } from "@mui/material";
import styles from "../index.module.css";
import { BlogCardData } from "./BlogCardData";
import { GenericObject } from "../../../../interfaces/commonInterface";

interface BlogCardInterface {
  data: GenericObject;
}

export const BlogCard = ({ data }: BlogCardInterface) => {
  return (
    <Stack>
      <img
        src={data?.image}
        alt={data?.title}
        style={{ maxWidth: "100%", objectFit: "cover", borderRadius: "20px " }}
      />
      <BlogCardData data={data} classNameStyle={styles.blogCardData} />
    </Stack>
  );
};
