import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { paymentSuccess } from "../../assets";
import { Routes } from "../../constant/routeContants";
import { actions } from "../../redux";
import { RootState } from "../../redux/reducers";
import { EventApi } from "../../utills/eventApi";
import {
  getEncryptedText,
  getFormattedINR,
  getLocalStorageData,
  removeLocalStorageData,
} from "../../utills/helper";
import { LocalStorage } from "../../constant/enum";

interface paymentInterface {
  bookingDetails?: boolean;
}
const PaymentSuccess = () => {
  const { state: bookingState } = getLocalStorageData(
    LocalStorage.bookingDetails
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userProfileDetail } = useSelector(
    (state: RootState) => state.profileReducer
  );

  ///facebook and firebase analytics service call
  const analyticsEventCall = () => {
    const eventData = {
      data: [
        {
          event_name: "Purchase",
          event_time: new Date(),
          event_source_url: window.location.href,
          user_data: {
            client_ip_address: "1.2.3.4",
            client_user_agent: "*",
          },
        },
      ],
    };
    EventApi(eventData);
  };

  const navigateToProfile = () => {
    navigate(Routes.PROFILE, {
      state: { values: "my-profile" },
    });
  };

  useEffect(() => {
    if (userProfileDetail?.emergency_number_verified === false) {
      const ele = document.getElementById("EmrgNoCheck");
      ele && ele.click();
    }
  }, [userProfileDetail.emergency_number_verified]);

  useEffect(() => {
    analyticsEventCall();
    dispatch(actions.validateUserEmergency());
    // @ts-ignore
    // gtag_report_conversion();

    // clearing booking data from local storage on unmounting
    return () => {
      removeLocalStorageData(LocalStorage.bookingDetails);
    };
  }, []);

  const handleRedirection = async (e) => {
    e.preventDefault();
    let object = {
      bookingId: bookingState?.bookingId,
    };
    const encryptedObject = getEncryptedText(JSON.stringify(object));
    if (bookingState?.isYor) {
      navigate(`/profile`, {
        state: { isYor: true },
      });
    } else {
      navigate(`/booking-details/${encryptedObject}`);
    }
  };
  return (
    <>
      <div className="container payment-wrap">
        <div className="d-flex justify-content-center mt-2">
          <div className="card w-100 mb-2 border-0">
            <div className="card-body">
              <video width="100%" autoPlay muted loop>
                <source src={paymentSuccess} type="video/mp4" />
                <source src="movie.ogg" type="video/ogg" />
              </video>
              <div className="text-center payment-text">
                <h5>Your ride is successfully booked !</h5>
                <p className="mt-1 mb-1">
                  <small>
                    Thank you for choosing to ride with Freedo, please find
                    below the booking details.
                  </small>
                </p>
              </div>
            </div>
            <div className="mt-0">
              <div className="d-flex justify-content-center ">
                <ul className="list-group list-group-flush payment-list success-book-text">
                  <li className="list-group-item text-center">
                    <p className="fw-light">
                      Amount:
                      <span className="p-2 success-book-text fw-normal">
                        {bookingState?.isYor
                          ? getFormattedINR(bookingState?.amount)
                          : `${
                              "₹" + " " + Math.round(bookingState?.amount) || 0
                            }`}
                      </span>
                    </p>
                  </li>
                  <li className="list-group-item text-center">
                    <p className="fw-light">
                      Order Id:
                      <span className="p-2 success-book-text fw-normal">
                        {bookingState?.orderId || "-NA-"}
                      </span>
                    </p>
                  </li>
                  <li className="list-group-item text-center">
                    <p className="fw-light">
                      Transaction Id:
                      <span
                        className="p-2 success-book-text fw-normal"
                        fw-normal
                      >
                        {bookingState?.isYor
                          ? bookingState?.paymentModeDetails?.bankTransactionId
                          : bookingState?.transactionId || "-NA-"}
                      </span>
                    </p>
                  </li>
                  <li className="list-group-item text-center">
                    <p className="fw-light">
                      Transaction Date & Time:
                      <span className="p-2  success-book-text fw-normal">
                        {/* added this check bcz differnt response coming from api in case cod */}
                        {bookingState?.transaction_date ||
                          moment(bookingState?.transactionDate).format("lll")}
                      </span>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="d-flex justify-content-center m-3">
              <button
                onClick={handleRedirection}
                className="freedo-primary-button btn-sm rounded-pill"
              >
                {bookingState?.isYor
                  ? "View Booking List"
                  : "View Booking Details"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <button
        type="button"
        id="EmrgNoCheck"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
        style={{ display: "none" }}
      />
      <div
        className="modal fade"
        id="staticBackdrop"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body text-center">
              {userProfileDetail?.relation_name === null ||
              userProfileDetail?.relation_name === "" ? (
                <h5>Please add emergency contact before proceeding</h5>
              ) : userProfileDetail?.relation_name &&
                !userProfileDetail?.emergency_number_verified ? (
                <h5>Please verify emergency contact before proceeding</h5>
              ) : (
                ""
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={navigateToProfile}
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Go to Profile
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentSuccess;
