import { GenericObject } from "../../../../utills/helper";
import { actionInterface } from "../../../interfaces/commonInterface";
import { PROFILE_ACTIONS } from "../../actions/actionTypes";

interface initialState {
  updateProfileLoader: boolean;
  getEmergencyDetailsLoader: boolean;
  emergencyContactDetails: GenericObject;
  emergencyOtpScreen: boolean;
  addEmergencyLoader: boolean;
  generateOtpLoader: boolean;
  generateOtpData: GenericObject;
  verifyOtpLoader: boolean;
  updateEmergencyDetailsLoader: boolean;
  updateProfileImageLoader: boolean;
}

const initialState: initialState = {
  updateProfileLoader: false,
  getEmergencyDetailsLoader: false,
  emergencyContactDetails: [],
  emergencyOtpScreen: false,
  addEmergencyLoader: false,
  generateOtpLoader: false,
  generateOtpData: {},
  verifyOtpLoader: false,
  updateEmergencyDetailsLoader: false,
  updateProfileImageLoader: false,
};

const newProfileReducer = (state = initialState, action: actionInterface) => {
  const { type, payload } = action;

  switch (type) {
    case PROFILE_ACTIONS.UPDATE_USER_PROFILE_REQUESTED:
      return { ...state, updateProfileLoader: true };

    case PROFILE_ACTIONS.UPDATE_USER_PROFILE_SUCCESS:
      return { ...state, updateProfileLoader: false };

    case PROFILE_ACTIONS.UPDATE_USER_PROFILE_FAILED:
      return { ...state, updateProfileLoader: false };

    case PROFILE_ACTIONS.UPDATE_USER_PROFILE_IMAGE_REQUESTED:
      return { ...state, updateProfileImageLoader: true };

    case PROFILE_ACTIONS.UPDATE_USER_PROFILE_IMAGE_SUCCESS:
      return { ...state, updateProfileImageLoader: false };

    case PROFILE_ACTIONS.UPDATE_USER_PROFILE_IMAGE_FAILED:
      return { ...state, updateProfileImageLoader: false };

    case PROFILE_ACTIONS.GET_EMERGENCY_CONTACT_DETAILS_REQUESTED:
      return {
        ...state,
        getEmergencyDetailsLoader: true,
        emergencyContactDetails: [],
      };

    case PROFILE_ACTIONS.GET_EMERGENCY_CONTACT_DETAILS_SUCCESS:
      return {
        ...state,
        getEmergencyDetailsLoader: false,
        emergencyContactDetails: payload?.emergencyContacts ?? [],
      };

    case PROFILE_ACTIONS.GET_EMERGENCY_CONTACT_DETAILS_FAILED:
      return {
        ...state,
        getEmergencyDetailsLoader: false,
        emergencyContactDetails: [],
      };

    case PROFILE_ACTIONS.ADD_EMERGENCY_CONTACT_DETAILS_REQUESTED:
      return { ...state, addEmergencyLoader: true };

    case PROFILE_ACTIONS.ADD_EMERGENCY_CONTACT_DETAILS_SUCCESS:
      return { ...state, addEmergencyLoader: false };

    case PROFILE_ACTIONS.ADD_EMERGENCY_CONTACT_DETAILS_FAILED:
      return { ...state, addEmergencyLoader: false };

    case PROFILE_ACTIONS.UPDATE_EMERGENCY_CONTACT_DETAILS_REQUESTED:
      return { ...state, updateEmergencyDetailsLoader: true };

    case PROFILE_ACTIONS.UPDATE_EMERGENCY_CONTACT_DETAILS_SUCCESS:
      return { ...state, updateEmergencyDetailsLoader: false };

    case PROFILE_ACTIONS.UPDATE_EMERGENCY_CONTACT_DETAILS_FAILED:
      return { ...state, updateEmergencyDetailsLoader: false };

    case PROFILE_ACTIONS.GENERATE_OTP_EMERGENCY_CONTACT_REQUESTED:
      return {
        ...state,
        emergencyOtpScreen: false,
        generateOtpLoader: true,
        generateOtpData: {},
      };

    case PROFILE_ACTIONS.GENERATE_OTP_EMERGENCY_CONTACT_SUCCESS:
      return {
        ...state,
        emergencyOtpScreen: true,
        generateOtpLoader: false,
        generateOtpData: payload,
      };

    case PROFILE_ACTIONS.GENERATE_OTP_EMERGENCY_CONTACT_FAILED:
      return {
        ...state,
        emergencyOtpScreen: false,
        generateOtpLoader: false,
        generateOtpData: {},
      };

    case PROFILE_ACTIONS.RESEND_OTP_EMERGENCY_CONTACT_REQUESTED:
      return { ...state };

    case PROFILE_ACTIONS.RESEND_OTP_EMERGENCY_CONTACT_SUCCESS:
      return { ...state };

    case PROFILE_ACTIONS.RESEND_OTP_EMERGENCY_CONTACT_FAILED:
      return { ...state };

    case PROFILE_ACTIONS.OTP_VERIFICATION_EMERGENCY_CONTACT_REQUESTED:
      return { ...state, verifyOtpLoader: true };

    case PROFILE_ACTIONS.OTP_VERIFICATION_EMERGENCY_CONTACT_SUCCESS:
      return { ...state, emergencyOtpScreen: false, verifyOtpLoader: false };

    case PROFILE_ACTIONS.OTP_VERIFICATION_EMERGENCY_CONTACT_FAILED:
      return { ...state, verifyOtpLoader: false };

    case PROFILE_ACTIONS.SET_PROFILE_REDUCER_KEYS:
      return { ...state, ...payload };

    default:
      return { ...state };
  }
};

export default newProfileReducer;
