import { colors, Components, Theme } from "@mui/material";

export const chipCustomizations: Components<Theme> = {
  MuiChip: {
    styleOverrides: {
      root: {
        variants: [
          {
            props: {
              variant: "filled",
              color: "primary",
            },
            style: {
              background:
                "linear-gradient(89.92deg, #1BD8DA 1.67%, rgba(27, 196, 166, 0.95) 123.92%)",
              borderRadius: "8px",
              cursor: "pointer",
              color: "#FFFF",

              "&:hover": {
                background:
                  "linear-gradient(89.92deg, #1BD8DA 1.67%, rgba(27, 196, 166, 0.95) 123.92%)",
                color: "white",
                border: "none",
              },
              "&:active": {
                background:
                  "linear-gradient(89.92deg, #1BD8DA 1.67%, rgba(27, 196, 166, 0.95) 123.92%)",
                boxShadow: "0px 0px 20px rgba(238, 238, 238, 0.25)",
                color: "white",
              },
              "& .MuiChip-label": {
                fontWeight: 500,
                fontSize: "12px",
                color: "white",
              },
            },
          },
          {
            props: {
              variant: "outlined",
              color: "primary",
            },
            style: {
              background: "linear-gradient(0deg, #FFFFFF, #FFFFFF)",
              cursor: "pointer",
              color: "#757575",
              border: "1px solid #D7D7D7",
              borderRadius: "8px",
              "&:hover": {
                background:
                  "linear-gradient(89.92deg, #1BD8DA 1.67%, rgba(27, 196, 166, 0.95) 123.92%)",
                color: "white",
                "& .MuiChip-label": { color: "white" },
                border: "1px solid transparent",
              },
              "&:active": {
                background:
                  "linear-gradient(89.92deg, #1BD8DA 1.67%, rgba(27, 196, 166, 0.95) 123.92%)",
                boxShadow: "0px 0px 20px rgba(238, 238, 238, 0.25)",
                color: "white",
                border: "1px solid transparent",
              },
              "& .MuiChip-label": {
                fontWeight: 500,
                fontSize: "12px",
              },
            },
          },
        ],
      },
    },
  },
};
