import React from "react";
import "./components.css";
import { Box, Stack } from "@mui/material";
const CustomSearchLayout = ({
  titleContent,
  formFields,
  actionButton,
  isBackgroundStyle = true,
}: any) => {
  return (
    <Stack
      className={isBackgroundStyle ? "responseForm_Wrapper" : ""}
      justifyContent={"center"}
    >
      <Stack gap="10px">
        {/* Title Content Wrapper */}
        {titleContent && <Box>{titleContent}</Box>}

        <Stack
          direction="row"
          style={{ width: "100%" }}
          className="responseform_innerWrapper"
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          {/* Form Fields Wrapper*/}
          {formFields && (
            <Stack direction={"row"} alignItems={"center"} gap={"10px"}>
              {formFields}
            </Stack>
          )}

          {/* Action Button Wrapper */}
          {actionButton && <Stack alignItems={"center"}>{actionButton}</Stack>}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CustomSearchLayout;
