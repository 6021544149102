import config from "./config.json";
import { NODE_ENV } from "./envConfig";
const configEnv: any = config;
const env: any = NODE_ENV;
const BASE_URL = configEnv[env]["BASE_URL"];
const WEB_URL = configEnv[env]["WEB_URL"];
const BASE_URL_CUSTOMER = configEnv[env]["BASE_URL_CUSTOMER"];
const CMS_API_URL = configEnv[env]["CMS_WEB_URL"];
const oldVersion = "v1/";
const versioning = "v2/";
const LatestVersion = "v5/";
const kyc = "kyc/";
const RAZORPAY_KEY = configEnv[env]["RAZOR_PAY_KEY"];
const FeedbackAPIExtenstion = "/dragon-buzz/";
const aliasFreedoLtr = "/freedo-ltr/";
const aliasFreedoVms = "/vms/";
const aliasFreedoGrahak = "/freedo-grahak/";
export const modelImageBucketUrl = WEB_URL + "/images/model/";
const aliasYor: string = "/freedo-r2o/";
const aliasFreedoBiz: string = "/freedo-biz/";
const aliasMunim: string = "/freedo-munim/";
const aliasCms: string = "/cms/";
const aliasTitanSheild: string = "/titan-shield/authentication/";

const API_URLS = {
  sendOTP: "sendOTP",
  verifyOTP: "verifyOTP",
  getMaster: "getMaster",
  packageMaster: "package_master",
  getVehicleModelListForCustomer: "vehicle/model-list",
  getCityList: "city/0",
  getCityById: "getCityById",
  getBookingList: "booking/",
  getBookingDetailsById: "booking/",
  vehicleType: "vehicle_type",
  sendOtpForSignUp: "sendOtpForSignUp",
  signupWithOtp: "signupWithOtp",
  userProfile: "user/get",
  updateUserProfile: "user/update",
  relationList: "relations",
  userLogout: "logout",
  getVehicleModelListDetails: "vehicle/model-detail",
  getFaqList: "public/faq",
  postContact: "public/createContactDetail",
  addAddress: "addAddress",
  deleteAddress: "deleteAddress",
  updateAddress: "updateAddress",
  extendDetails: "booking/extentionPaymentDetails/",
  getCurrentBooking: "booking/current-booking/",
  endRide: "endRide/",
  advertisement: "advertisement",
  getExtendPackage: "plans/extentions/",
  getBookingPriceById: "booking/booking-price/",
  sendEmergencyNumberOTP: "user/send-otp",
  verifyEmergencyNumberOTP: "user/verify-otp",
  getDocumentDetails: "document/",
  getBranch: "branch/",
  extendRide: "extendride",
  findNearestCity: "public/location/findNearestCity",
  notifyMe: "public/notifyMe",
  uploadOdometerReadings: "uploadOdometerReadings",
  payExtraCharges: "payExtraCharges",
  getVehicleDetails: "public/vehicle/getVehicleDetails",
  getAllMitcIds: "mitc/getAllMitcIds",
  getMitcHtmlContent: "public/mitc/getMitcHtmlContent",
  sendOtpForMitc: "public/mitc/sendOtpForMitc",
  verifyMitcForm: "public/mitc/verifyMitcForm",
  getDelAccOptions: "del_account_options",
  sendOtpForDelAcc: "public/v2/sendOtpForDeleteAcoount",
  changeAccStatus: "public/v2/changeAccountStatus",
  cancellationCharge: "cancellation-charge/",
  cancelBooking: "cancel-booking/",
  checkRefundStatus: "checkRefundStatusV2/",
  getBookingStatus: "getBookingStatus/",
  modificationCharges: "modificationCharges/",
  modifyBooking: "modifyBooking",
  getModelAvailableDates: "getModelAvailableDates",
  bookingChangeCharges: "bookingChangeCharges",
  getIssueCategoryList: "getMaster/issue_category/0",
  saveHelpDesk: "helpDesk",
  getUserProfileSignedUrl: "public/getSignedUrl",
  SubmitFeedbackForm: "feedback/v1",
  getFeedbackFormByBookingId: "feedback/v1/form/",

  // Admin rental endpoints
  getMasterPlanDurations: aliasFreedoLtr + "plan/v1/master",
  getRentalMasterCityListURL: aliasFreedoBiz + "city/v2",
  getFilteredModelListUrl: aliasFreedoLtr + "search/v1",
  getRentalNearestCityUrl: aliasFreedoBiz + "city/v1/location",
  getRentalModelDetailsUrl: aliasFreedoVms + "model/v1",
};
const CHECKOUT_API_URLS = {
  getCouponList: "getcouponsV2",
  getCustomer: "getCustomer",
  getBranchSlotsForBooking: "getBranchSlotsForBooking",
  getHomeDeliveryCharges: "getBranchDeliveryCharges",
  getHomeAddress: "getAddress/all",
  validateUserEmergency: "validateUserEmergency",
  storeBookingAppV4: "storeBookingAppV4",
  storeBookingV1: "v1/storeBooking",
  processOrder: "payment/order",
  verifyBookingPayment: "payment/verificationRes",
};
const KYC_API_URLS = {
  getDlSignedurl: "getSignedUrl",
  generateDigiLockerUrlWeb: "aadhaar/generateDigiLockerUrlWeb",
  userCheckKYC: "userKycChecks",
  saveDlImageData: "saveImageData",
  checkUserDocumentStatus: "manualKyc/customer/checkUserDocumentStatus",
  createAdditionalDocs: "manualKyc/customer/createAdditionalDocs",
};

// used to get the api url in revamped code (id is the dynamic id in the API url)
export const getApiUrl = (id: string = "", name: string | undefined = "") => {
  return {
    // kyc and profile apis
    getUserTrustScore: aliasFreedoGrahak + `trust-score/v1/${id}`,
    getAttachmentIds: aliasFreedoGrahak + `attachment/v1`,
    submitUserDocument: aliasFreedoGrahak + `kyc/v1/${id}`,
    getAadhaarKycUrl: aliasFreedoGrahak + `kyc/v1/${id}`,
    kycDocUpload: aliasFreedoGrahak + `kyc/v2/${id}`,

    getCustomerAddressesUrl: aliasFreedoGrahak + `profile/v1/${id}/address`,
    addCustomerAddressUrl: aliasFreedoGrahak + `profile/v1/address`,
    getModelCheckoutDetailsUrl: aliasFreedoLtr + `search/v1/${id}`,
    getCalculateChargesUrl: aliasFreedoLtr + `booking/v1/calculate-charges`,
    getDeliverySlotPauseUrl: aliasFreedoLtr + `booking/v1/slot-pause`,
    getBranchTimeSlotsUrl: aliasFreedoLtr + `booking/v1/${id}/slots`,
    getUserProfile: aliasFreedoGrahak + `profile/v1/${id}`,
    updateProfile: aliasFreedoGrahak + `profile/v1/${id}`,
    updateProfileImage: aliasFreedoGrahak + `profile/v1/${id}/image/`, // append attachment id also
    getAllAddresses: aliasFreedoGrahak + `profile/v1/${id}/address`,
    addNewAddress: aliasFreedoGrahak + `profile/v1/address`,
    deleteUpdateAddress: aliasFreedoGrahak + `profile/v1/${id}`,

    getCityV2: aliasFreedoBiz + "city/v2",
    getVehicleAvailabilityYor: aliasYor + `search/v1/availability`,
    calculateBookingCharges: aliasYor + "booking/v1/calculate/charges",
    getAvailablePlans: aliasYor + "plan/v1/availables",
    storeBooking: aliasYor + "booking/v1",
    createOrder: aliasMunim + "payment/v1",
    getPaymentStatus: aliasMunim + `payment/v1/${id}`,
    getBookingDetails: aliasYor + "booking/v1/list",
    getAllCoupons: aliasMunim + "coupon/v1/list",
    cancelledBooking: aliasYor + `booking/v1/${id}/cancel`,

    // OPERATOR_API_URLS
    getMasterCityList: aliasFreedoBiz + "city/v1/district",
    operatorLeadGeneration: aliasFreedoBiz + "operator/v1/lead",
    operatorLeadVerification: aliasFreedoBiz + "operator/v1/lead/verify",
    retryOtpForOperator: aliasFreedoBiz + "operator/v1/resend-otp",
    verifyOperatorEmail: aliasFreedoBiz + `operator/v1/verify-email/${id}`,
    fetchEsignDoc: aliasFreedoBiz + `operator/v1/e-sign/${id}`,
    eSignGenerateOtp: aliasFreedoBiz + "operator/v1/e-sign/generate-otp",
    eSignVerifyOtp: aliasFreedoBiz + "operator/v1/e-sign/verify",
    eSignResendOtp: aliasFreedoBiz + "operator/v1/e-sign/resend-otp",
    helpDesk: aliasFreedoBiz + "crm/v1/helpDesk",
    issueCategoryList: aliasFreedoBiz + "crm/v1/issue-category",

    /* booking api urls */
    getBookingHistory: aliasFreedoLtr + "booking/v1",
    getLtrBookingDetails: aliasFreedoLtr + `booking/v1/${id}/details`,
    getYorBookingHistory: aliasYor + "booking/v1/list",
    getBookingCencellationCharges:
      aliasFreedoLtr + `booking/v1/${id}/cancel/charges`,
    cancleBooking: aliasFreedoLtr + `booking/v1/${id}/cancel`,

    getVehicleDocuments: aliasFreedoVms + "vehicle/document/v1",
    downloadVehicleDocument: aliasFreedoVms + `vehicle/document/v1/${id}`,
    //cms data
    getPrivacyPolicyCmsData: aliasCms + "api/privacy-policies",
    getTermsAndConditions: aliasCms + "api/terms-and-conditions",
    getAboutUsData:
      aliasCms +
      "about-us-pages?populate%5Bsection1%5D%5Bpopulate%5D=*&populate%5Bsection2%5D%5Bpopulate%5D=*&populate%5Bsection3%5D%5Bpopulate%5D=*&populate%5Bsection4%5D%5Bpopulate%5D=*",
    getHomePageData: aliasCms + "home-pages",
    getBlogData: aliasCms + "blogs?populate=*",

    /* emergency contact api */
    getEmergencyContactData: aliasFreedoGrahak + `emergency-contact/v1/${id}`, // id - customer ID
    addEmergencyContactData: aliasFreedoGrahak + `emergency-contact/v1/${id}`, // id - customer ID
    updateEmergencyContactData:
      aliasFreedoGrahak + `emergency-contact/v1/${id}`, // id - customer ID / emergency contact id to update contact details
    resendOtpEmergencyContact:
      aliasFreedoGrahak + `emergency-contact/v1/otp/${id}`, // id - emergency contact id
    otpVerificationEmergencyContact:
      aliasFreedoGrahak + `emergency-contact/v1/otp`,
    generateEmergencyOtp: aliasFreedoGrahak + `emergency-contact/v1/${id}/otp`, // id - customer ID / emergency contact id to update contact details

    // auth apis
    signUpUser: aliasFreedoGrahak + "auth/register/v1",
    verifySignupOtp: aliasFreedoGrahak + "auth/register/v1/verify",
    resendSignUpOtp: aliasFreedoGrahak + `register/v1/${id}`,
    loginUser: aliasFreedoGrahak + `auth/login/v1/${id}`,
    verifyLoginOtp: aliasFreedoGrahak + "auth/login/v1/",
    resendLoginOtp: aliasFreedoGrahak + `auth/login/v1/${id}`,
    logoutUser: aliasTitanSheild + "v1/logout",
    guestLogin: aliasTitanSheild + "v1/guest-login",

  };
};

export {
  BASE_URL,
  BASE_URL_CUSTOMER,
  versioning,
  LatestVersion,
  oldVersion,
  API_URLS,
  CHECKOUT_API_URLS,
  KYC_API_URLS,
  kyc,
  RAZORPAY_KEY,
  WEB_URL,
  FeedbackAPIExtenstion,
  CMS_API_URL,
};
