import { Box, Chip, Typography } from "@mui/material";
import React from "react";
//import styles from "../components.module.css";
import { minusIcon, plusIcon } from "../../../assets";
import { colors } from "../../../themes/colors";

const styles = {
  helmetBadgeChip: {
    minHeight: "35px",
    height: "40px",
    padding: "0px 18px",
    borderRadius: "22px ",
    border: "1px solid #ECECEC",
    gap: "0px",
  },

  badge: {
    border: "1px solid #ECECEC",
    borderRadius: "50%",
    position: "absolute",
    top: "-15px",
    right: "42%",
    /* z-index: 1; */
    backgroundColor: "white",
    padding: "2px",
    cursor: "pointer",
  },

  chipBadge: {
    height: "20px",
    position: "absolute",
    top: "-10px",
    right: "19%",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
  },
};

function Helmetbadge(props: any) {
  return (
    <Box sx={{ position: "relative", display: "inline-block" }}>
      <Chip
        icon={props.helmetIcon}
        label={props.title}
        variant="outlined"
        sx={{
          fontSize: props.helmetTypeCount === 0 ? "14px" : "12px",
          width: props?.width ? props.width : "auto",
          ...styles.helmetBadgeChip,
        }}
      />
      {props.helmetTypeCount === 0 ? (
        <Box
          sx={{ ...styles.badge }}
          onClick={(e: any) => props.clickHandler(e, props.type, "INCREMENT")}
        >
          <img src={plusIcon} alt="" />
        </Box>
      ) : (
        <Box sx={{ ...styles.chipBadge }}>
          <Chip
            label={
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "62px",
                }}
              >
                <img
                  src={minusIcon}
                  onClick={(e) =>
                    props.clickHandler(e, props.type, "DECREMENT")
                  }
                  style={{ cursor: "pointer" }}
                />
                <Typography
                  sx={{ fontWeight: 600, color: colors.secondary_green }}
                >
                  {props.helmetTypeCount}
                </Typography>
                <img
                  src={plusIcon}
                  onClick={(e) =>
                    props.clickHandler(e, props.type, "INCREMENT")
                  }
                  style={{ cursor: "pointer" }}
                />
              </Box>
            }
            variant="outlined"
            sx={{
              height: "22px",
            }}
          />
        </Box>
      )}
    </Box>
  );
}

export default Helmetbadge;
