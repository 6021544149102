import { Components, Theme } from "@mui/material";

export const accordionCustomization: Components<Theme> = {
  MuiAccordion: {
    styleOverrides: {
      root: {
        "&::before": { backgroundColor: "transparent" },
        "&:first-of-type": {
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
        },
        "&:last-of-type": {
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
        },
      },
    },
  },
};
