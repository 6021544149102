import * as React from "react";
import { Box, Stack, TextField, Typography } from "@mui/material";
import { colors } from "../../themes/colors";

export default function TextAreaInput(props: any) {
  const { errormessage, topHeading } = props;
  return (
    <Stack gap="5px">
      <Typography variant="subtitle2" sx={{ color: colors.secondary_black }}>
        {topHeading}
      </Typography>
      <TextField multiline minRows={3} placeholder="" {...props} />
      <Box>
        {errormessage && (
          <Typography variant="errorMessage1" marginLeft="8px">
            {errormessage}
          </Typography>
        )}
      </Box>
    </Stack>
  );
}
