import React from "react";

function KidsHelmetIcon() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 22 22"
        fill="none"
      >
        <g fill="#2BC7C9" stroke="#2BC7C9">
          <path d="M9.60166 2.95508H12.3982C16.0312 2.95508 18.9764 5.90025 18.9764 9.53329V15.2751C18.9764 15.7371 18.6019 16.1115 18.14 16.1115C17.6781 16.1115 17.3036 15.7371 17.3036 15.2751V12.7205C17.3036 10.8872 16.1549 9.18571 14.3085 8.74973C13.2266 8.49429 11.9403 8.26775 10.765 8.26775C9.66026 8.26775 8.53136 8.46773 7.57874 8.70436C5.76665 9.15448 4.69617 10.85 4.69617 12.6329V15.2751C4.69617 15.7371 4.32172 16.1115 3.8598 16.1115C3.39789 16.1115 3.02344 15.7371 3.02344 15.2751V9.53329C3.02344 5.90025 5.96861 2.95508 9.60166 2.95508Z" />
          <path d="M6.91014 9.24082C6.33617 8.57814 4.60129 7.2563 3.41724 7.43311" />
          <path d="M15.1711 9.24013C15.7451 8.57745 17.3665 7.28445 18.5505 7.46127" />
          <path d="M11.0412 8.23726C11.0412 7.51441 11.0407 3.81984 11.0412 2.95654" />
          <path d="M17.2885 13.729C17.2885 17.078 14.9016 18.766 11.0411 19.5457C8.22523 18.9629 4.71338 17.1134 4.71338 13.7644" />
          <path d="M5.11914 10.438C7.59989 10.7441 13.0964 11.1324 16.3921 10.2046" />
        </g>
      </svg>
    </>
  );
}

export default KidsHelmetIcon;
