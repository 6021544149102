import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import { toastr } from "react-redux-toastr";
import { handleMessage } from "../../../utills/helper";
import userAddressService from "../services/userAddressService";

//add new address
function* addNewAddress(action: any) {
  try {
    const data = yield call(userAddressService.addNewAddress, action.payload);
    if (data?.status == 200) {
      yield put({
        type: actionTypes.USER_ADDRESSES_ACTIONS.ADD_NEW_ADDRESS_SUCCESS,
        payload: data?.data?.data,
      });
      yield put({
        type: actionTypes.MASTER_ACTIONS.GET_ALL_ADDRESSES_REQUESTED,
        payload: { id: action.payload.data.data.customerId },
      });
    } else {
      yield put({
        type: actionTypes.USER_ADDRESSES_ACTIONS.ADD_NEW_ADDRESS_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (err) {
    toastr.error("", handleMessage(err));
    yield put({
      type: actionTypes.USER_ADDRESSES_ACTIONS.ADD_NEW_ADDRESS_FAILED,
    });
  }
}

//update user address
function* updateAddress(action: any) {
  try {
    const data = yield call(userAddressService.updateAddress, action.payload);
    if (data?.status == 200) {
      yield put({
        type: actionTypes.USER_ADDRESSES_ACTIONS.UPDATE_ADDRESS_SUCCESS,
        payload: data?.data?.data,
      });
      yield put({
        type: actionTypes.MASTER_ACTIONS.GET_ALL_ADDRESSES_REQUESTED,
        payload: { id: action.payload.data.data.customerId },
      });
    } else {
      yield put({
        type: actionTypes.USER_ADDRESSES_ACTIONS.UPDATE_ADDRESS_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (err) {
    yield put({
      type: actionTypes.USER_ADDRESSES_ACTIONS.UPDATE_ADDRESS_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}

//delete user address
function* deleteAddress(action: any) {
  try {
    const data = yield call(userAddressService.deleteAddress, action.payload);
    if (data?.status == 200) {
      yield put({
        type: actionTypes.USER_ADDRESSES_ACTIONS.DELETE_ADDRESS_SUCCESS,
        payload: data?.data?.data,
      });
      yield put({
        type: actionTypes.MASTER_ACTIONS.GET_ALL_ADDRESSES_REQUESTED,
        payload: { id: action.payload.customerId },
      });
    } else {
      yield put({
        type: actionTypes.USER_ADDRESSES_ACTIONS.DELETE_ADDRESS_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (err) {
    yield put({
      type: actionTypes.USER_ADDRESSES_ACTIONS.DELETE_ADDRESS_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}

function* UserAddressesSaga() {
  yield takeLatest(
    actionTypes.USER_ADDRESSES_ACTIONS.ADD_NEW_ADDRESS_REQUESTED,
    addNewAddress
  );
  yield takeLatest(
    actionTypes.USER_ADDRESSES_ACTIONS.UPDATE_ADDRESS_REQUESTED,
    updateAddress
  );
  yield takeLatest(
    actionTypes.USER_ADDRESSES_ACTIONS.DELETE_ADDRESS_REQUESTED,
    deleteAddress
  );
}

export default UserAddressesSaga;
