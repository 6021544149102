import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import CustomScrollbarStyle from "../../../../yor-src/components/atom/CustomScrollbar/customScrollbar.module.css";

interface IMultiSelectWithSearch {
  defaultKey?: string | any;
  hidePlaceholder?: boolean;
  displayNameKey?: string;
  value?: any;
  choice?: any;
  handleChange?: any;
  formHelperText?: string;
  errorMessage?: string | boolean | null;
  topHeading?: string;
  isShowSelectAll?: boolean;
  selectAllText?: string;
  minWidth?: string | number;
  width?: string | number;
  maxWidth?: string | number;
  boxStyle?: any;
  heading?: string;
  disabled?: boolean;
  icon?: any;
  muiIcons?: any;
  placeholder?: string;
  ITEMS_PER_BATCH?: number;
}

const MultiSelectWithSearch = (props: IMultiSelectWithSearch) => {
  const {
    defaultKey,
    hidePlaceholder = false,
    displayNameKey = "displayName",
    value,
    choice,
    handleChange,
    formHelperText,
    errorMessage,
    topHeading,
    isShowSelectAll = true,
    selectAllText = "Select All",
    placeholder,
    boxStyle,
    heading,
    width = "100%",
    maxWidth = "520px",
    minWidth,
    ITEMS_PER_BATCH = 10,
  } = props;

  const [displayedChoices, setDisplayedChoices] = useState<any[]>([]);
  const [batch, setBatch] = useState<number>(1);

  useEffect(() => {
    // Load the initial batch
    setDisplayedChoices(choice.slice(0, ITEMS_PER_BATCH * batch));
  }, [choice, batch]);

  const handleScroll = (event: any) => {
    const bottom =
      event.target.scrollHeight - event.target.scrollTop ===
      event.target.clientHeight;

    if (bottom && displayedChoices.length < choice.length) {
      setBatch((prev: number) => prev + 1);
    }
  };

  const isAllSelected = Object?.keys(value)?.length === choice?.length;

  const handleSelect = (event: any) => {
    const value = event.target.value;
    if (isShowSelectAll && value[value?.length - 1] === "all") {
      const updatedValue = choice.reduce((accum: any, item: any) => {
        accum.push(item?.[defaultKey]);
        return accum;
      }, []);
      handleChange(isAllSelected ? [] : updatedValue);
      return;
    }
    // Update the selected values
    handleChange(value);
  };

  return (
    <Box
      minWidth={minWidth}
      width={width}
      maxWidth={maxWidth}
      sx={{ ...boxStyle, display: "flex", flexDirection: "column" }}
    >
      {topHeading && <Typography variant="heading">{topHeading}</Typography>}
      <FormControl sx={{ width: "100%", borderRadius: 40 }}>
        {heading && (
          <InputLabel
            id="multi-select-label"
            sx={{ fontSize: "12px", marginTop: "3px" }}
          >
            {heading}
          </InputLabel>
        )}
        <Select
          label={heading}
          value={value || []}
          multiple
          displayEmpty
          sx={{
            "& .MuiButtonBase-root-MuiMenuItem-root li": {
              minHeight: "30px",
            },
            "& .MuiMenu-list li": {
              height: "42px !important",
            },
            borderRadius: "60px !important",
            minHeight: "50px",
            height: "5vh",
            margin: "0px",
            fontSize: "12px",
            boxShadow:
              "1px 1px 2px 0px #00000021 inset, 11px 10px 32px 0px #0000000a, -19px -7px 32px 0px #3333330a",
          }}
          onChange={handleSelect}
          MenuProps={{
            PaperProps: {
              onScroll: handleScroll,
              className: CustomScrollbarStyle.customScrollbar, // Apply custom class
              style: { maxHeight: 200 }, // Set max height for dropdown
            },
          }}
          renderValue={(selected: any) => {
            if (selected?.length === 0) {
              return <>{placeholder}</>;
            } else if (isAllSelected) {
              return selectAllText;
            }
            return (selected as any)
              ?.map((val: any) => {
                const item = choice?.find(
                  (choice: any) => choice[defaultKey] === val
                );
                return item ? item[displayNameKey] : val;
              })
              .join(", ");
          }}
        >
          {!hidePlaceholder && (
            <MenuItem disabled value={[]} sx={{ height: "40px" }}>
              <>{placeholder}</>
            </MenuItem>
          )}
          {isShowSelectAll && (
            <MenuItem value="all" sx={{ height: "40px" }}>
              <ListItemIcon>
                <Checkbox
                  checked={isAllSelected}
                  indeterminate={
                    value?.length > 0 && value?.length < choice?.length
                  }
                />
              </ListItemIcon>
              <ListItemText primary={selectAllText} />
            </MenuItem>
          )}
          {displayedChoices?.map((data: any, index: number) => {
            const isSelected = (value || []).includes(data[defaultKey]);
            return (
              <MenuItem
                key={index}
                value={data[defaultKey]}
                sx={{ height: "40px" }}
              >
                <Checkbox checked={isSelected} />
                <ListItemText
                  primary={data[displayNameKey] || data[defaultKey]}
                />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormHelperText>
        {errorMessage && (
          <Typography variant="errorMessage1" sx={{ pl: 1, pb: 1 }}>
            {errorMessage}
          </Typography>
        )}
        {formHelperText && (
          <Typography color="drawerMenu.text.secondary" sx={{ pl: 1, pb: 1 }}>
            {formHelperText}
          </Typography>
        )}
      </FormHelperText>
    </Box>
  );
};

export default MultiSelectWithSearch;

// Usage Notes:
// 1. `choice` should be an array of objects with {name, displayName} or similar.
// 2. `value` should be an array of selected values (e.g., names).
// 3. `handleChange` should handle the array of selected values (e.g., names).
// 4. `displayNameKey` is the key for the display name in the choice objects (default is "displayName").
