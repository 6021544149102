import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  SxProps,
} from "@mui/material";
import { TypographyConstants } from "../../../../constant/enum";
import { AccordionArrowIcon } from "../../../../assets";

interface CustomAccordionProps {
  headerText: string; // header text
  children: React.ReactNode; // content to be displayed inside the Accordion
  customStyle: any;
  rest?: any;
}
const CustomAccordion = (props: CustomAccordionProps) => {
  const { headerText, children, customStyle, ...rest } = props;
  return (
    <Accordion
      sx={{
        ...customStyle,
        borderRadius: "0px",
      }}
      {...rest}
    >
      <AccordionSummary
        sx={{
          borderRadius: 0,
          boxShadow: "none",
          "& .MuiAccordion-root::before": { display: "none" },
        }}
        expandIcon={<img src={AccordionArrowIcon} alt="Accordion Arrow" />}
      >
        <Typography
          variant={TypographyConstants.BODY1}
          sx={{ fontWeight: 500 }}
        >
          {headerText}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
export default CustomAccordion;
