import { Box, Drawer, Stack, Typography, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useRef } from "react";
import { colors } from "../../../../themes/colors";
interface sideDrawerInterface {
  open: boolean;
  toggleDrawer?: Function;
  disablePadding?: boolean;
  heading?: string;
  headerPadding?: string;
  renderUI: any;
  setOpen?: any;
  headingVariant?: any;
  hideBackdrop?: boolean;
  disableEscapeKeyDown?: boolean;
  disableBackdropClick?: boolean;
  width?: string | number;
  ref?: any;
}
const SideDrawer = ({
  open,
  disablePadding,
  headerPadding,
  heading,
  renderUI,
  disableEscapeKeyDown = false,
  disableBackdropClick = false,
  headingVariant,
  width,
  toggleDrawer = () => {},
  ...rest
}: sideDrawerInterface) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={(event: object, reason: string) => {
        if (reason && reason === "backdropClick" && disableEscapeKeyDown)
          return false;
        if (reason && reason === "escapeKeyDown" && disableBackdropClick)
          return false;
        toggleDrawer();
      }}
      sx={{
        "& .MuiDrawer-paper": {
          width: width ?? window?.innerWidth < 450 ? "unset" : "400px",
          background: colors.white,
          padding: !disablePadding ? "12px 26px 34px 26px" : "unset",
          display: "flex",
          overflowY: "auto",
          height: "100%",
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            background: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            background: colors.gray,
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: colors.light_gray,
          },
        },
      }}
      {...rest}
    >
      <Stack
        direction="row"
        sx={{
          background: colors.white,
          color: colors.text_gray,
          justifyContent: "space-between",
          alignItems: "center",
          padding: !!!headerPadding ? "1vw 0.5vw" : headerPadding,
          borderBottom: heading ? "1px solid #ECECEC" : "none",
        }}
      >
        {heading ? (
          <Typography
            variant="heading"
            color={colors.text_gray}
            sx={{ padding: 0, fontSize: "14px", fontWeight: 500 }}
          >
            {heading}
          </Typography>
        ) : null}

        <Box
          onClick={() => toggleDrawer()}
          sx={{
            cursor: "pointer",
            position: "absolute",
            right: "10px",
            top: "10px",
          }}
        >
          <CloseIcon />
        </Box>
      </Stack>

      {renderUI}
    </Drawer>
  );
};
export default SideDrawer;
