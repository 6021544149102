import { Stack } from "@mui/material";
import Webcam from "react-webcam";
import styles from "./userKyc.module.css";
import { CustomButton } from "../../../../sharedComponents/atom/Buttons/CustomButtons";
const TakeSelfieModalContent = ({
  webcamRef,
  handleCaptureSelfie,
}: any) => {
  return (
    <Stack alignItems="center">
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        className={styles.selfieCamera}
      />
      <CustomButton
        label="Capture"
        variant="contained"
        onClick={handleCaptureSelfie}
      />
    </Stack>
  );
};

export default TakeSelfieModalContent;
