import axios from "axios";
import { BASE_URL, CMS_API_URL, getApiUrl } from "../../../config/apiUrls";
import { constructUrl } from "../../../utills/helper";

const getPrivacyPolicy = () => {
  try {
    const url: string = CMS_API_URL + getApiUrl().getPrivacyPolicyCmsData;

    return axios.get(url).catch((err) => {
      return err;
    });
  } catch (error) {
    return error;
  }
};

const getTermsAndConditions = () => {
  try {
    const url: string = CMS_API_URL + getApiUrl().getTermsAndConditions;

    return axios.get(url).catch((err) => {
      return err;
    });
  } catch (error) {
    return error;
  }
};

const getAboutUsData = () => {
  try {
    const url: string = BASE_URL + getApiUrl().getAboutUsData;
    return axios.get(url).catch((err) => {
      return err;
    });
  } catch (error) {
    return error;
  }
};

const getHomePageData = (payload: string) => {
  try {
    const url: string = BASE_URL + getApiUrl().getHomePageData;
    const apiUrlWithParams: string = url?.concat(`?${payload}`);
    return axios.get(apiUrlWithParams).catch((err) => {
      return err;
    });
  } catch (error) {
    return error;
  }
};

const getBlogPageData = () => {
  try {
    const url: string = BASE_URL + getApiUrl().getBlogData;
    return axios.get(url).catch((err) => {
      return err;
    });
  } catch (error) {
    return error;
  }
};

export default {
  getPrivacyPolicy,
  getTermsAndConditions,
  getAboutUsData,
  getHomePageData,
  getBlogPageData,
};
