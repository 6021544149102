import {
  Box,
  Drawer,
  Stack,
  Typography,
  Button,
  Chip,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import CloseIcon from "@mui/icons-material/Close";
import { colors } from "../../../themes/colors";
import { useEffect, useRef } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { GOOGLE_MAP } from "../../../constant/enum";
import Input from "../../atom/InputFields/Input";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import HomeIcon from "@mui/icons-material/Home";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import { CustomButton } from "../../atom/Buttons/CustomButtons";
import { addressTypes } from "../../../rental-src/constants/enum";
import en from "../../../locales/en.json";
import { alfaNumericValidation } from "../../../rental-src/utils/helper";

const addressTypeIcons = {
  Home: <HomeIcon sx={{ fontSize: "large", color: colors?.white }} />,
  Office: (
    <BusinessCenterIcon sx={{ fontSize: "large", color: colors?.white }} />
  ),
  Other: <LocationOnIcon sx={{ fontSize: "large", color: colors?.white }} />,
};

interface sideDrawerInterface {
  open: boolean;
  toggleDrawer?: Function;
  disablePadding?: boolean;
  headerPadding?: string;
  setOpen?: any;
  hideBackdrop?: boolean;
  disableEscapeKeyDown?: boolean;
  disableBackdropClick?: boolean;
  width?: string | number;
  ref?: any;
  fields: any;
  setFields: any;
  errorFields: any;
  handleAddNewAddress: any;
  setErrorFields: any;
  loading: boolean;
  updateAddress: boolean;
}
const AddressSideDrawer = ({
  open,
  disableEscapeKeyDown = false,
  disableBackdropClick = false,
  width,
  toggleDrawer = () => {},
  fields,
  setFields,
  handleAddNewAddress,
  errorFields,
  setErrorFields,
  loading,
  updateAddress,
  ...rest
}: sideDrawerInterface) => {
  const onMapClick = (event) => {
    getAddress(event.latLng.lat(), event.latLng.lng());
  };

  const geoFindMe = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        getAddress(position.coords.latitude, position.coords.longitude);
      },
      (error) => {},
      { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
    );
  };

  const getAddress = (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results: any, status) => {
      if (status === "OK") {
        if (
          results[0]?.address_components.find((addressComponent) =>
            addressComponent.types.includes("postal_code")
          )?.short_name
        ) {
          setFields({
            ...fields,
            lat: lat,
            lng: lng,
            pinCode: results[0]?.address_components.find((addressComponent) =>
              addressComponent.types.includes("postal_code")
            )?.short_name,
            city: results[0]?.address_components.find((addressComponent) =>
              addressComponent.types.includes("administrative_area_level_3")
            )?.short_name,
            formattedAddress: results[0]?.formatted_address,
          });
        }
      } else {
        console.log(`Geocoder failed due to: ${status}`);
      }
    });
  };

  const onChange = (field: any, value: any) => {
    setFields((prev) => ({
      ...prev,
      [field]: value,
    }));
    setErrorFields((prev) => ({
      ...prev,
      [field]: "",
    }));
  };

  useEffect(() => {
    if (fields?.lat === "" && fields?.lng === "") {
      geoFindMe();
    }
  }, [fields]);
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={(event: object, reason: string) => {
        if (reason && reason === "backdropClick" && disableEscapeKeyDown)
          return false;
        if (reason && reason === "escapeKeyDown" && disableBackdropClick)
          return false;
        toggleDrawer();
      }}
      sx={{
        "& .MuiDrawer-paper": {
          width: width ?? "380px",
          background: colors.white,
          //padding: !disablePadding ? "12px 26px 34px 26px" : "unset",
          display: "flex",
          overflowY: "auto",
          height: "100%",
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            background: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            background: colors.gray,
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: colors.grayish_cyan,
          },
        },
      }}
      {...rest}
    >
      <Stack
        direction="row"
        sx={{
          background: colors.THEME_BLUE,
          color: colors.text_gray,
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px",
          borderRadius: "0px 0px 20px 20px",
        }}
      >
        <Typography
          variant="h6"
          //color={colors.side_drawer_heading_text_color}
          sx={{ padding: 0 }}
        >
          {`${updateAddress ? "Update" : "Add"} Delivery Address`}
        </Typography>
        <Box onClick={() => toggleDrawer()} sx={{ cursor: "pointer" }}>
          <CloseIcon sx={{ color: colors.white }} />
        </Box>
      </Stack>
      <Box sx={{ marginBottom: "70px" }}>
        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: "48vh",
            marginTop: "10px",
          }}
          center={{
            lat: fields?.lat,
            lng: fields?.lng,
          }}
          zoom={11}
          onClick={onMapClick}
        >
          <Marker
            position={{
              lat: fields?.lat,
              lng: fields?.lng,
            }}
            title={"any"}
          />
        </GoogleMap>
        <Stack gap={2} sx={{ padding: "10px" }}>
          <Box>
            <Typography
              variant="subtitle2"
              sx={{ color: colors.secondary_black, marginBottom: "5px" }}
            >
              {en.bookingDetails.deliveryLocation}
            </Typography>
            <ReactGoogleAutocomplete
              apiKey={GOOGLE_MAP.key}
              className="form-control"
              options={{
                types: ["(regions)"],
              }}
              placeholder={en.bookingDetails.selectLocation}
              value={fields?.formattedAddress}
              onChange={(e: any) => {
                setFields({
                  ...fields,
                  formattedAddress: e.target.value,
                });
              }}
              onPlaceSelected={(place: any) => {
                if (place?.geometry?.location) {
                }
                getAddress(
                  place?.geometry?.location?.lat(),
                  place?.geometry?.location?.lng()
                );
              }}
            />
          </Box>
          <Input
            topHeading={en.bookingDetails.fullName}
            value={fields.name}
            errormessage={errorFields.name}
            onChange={(e) => {
              if (alfaNumericValidation(e.target.value)) {
                onChange("name", e.target.value);
              }
            }}
          />
          <Input
            topHeading={en.bookingDetails.address}
            value={fields.addressLine1}
            errormessage={errorFields.addressLine1}
            onChange={(e) => {
              onChange("addressLine1", e.target.value);
            }}
          />
          <Input
            topHeading={en.bookingDetails.houseFlatBlockNo}
            value={fields.addressLine2}
            errormessage={errorFields.addressLine2}
            onChange={(e) => {
              onChange("addressLine2", e.target.value);
            }}
          />
          <Input
            topHeading={en.bookingDetails.landmarkOptional}
            value={fields.landmark}
            onChange={(e) => {
              onChange("landmark", e.target.value);
            }}
          />
          <Grid container spacing={0}>
            {Object.keys(addressTypeIcons).map((item) => {
              return (
                <Grid size={3}>
                  <Chip
                    label={item}
                    icon={addressTypeIcons[item]}
                    sx={{
                      backgroundColor:
                        fields?.type === addressTypes[item]
                          ? colors.THEME_BLUE
                          : "#f5feff",
                      cursor: "pointer",
                      color:
                        fields?.type === addressTypes[item]
                          ? colors.white
                          : colors.black,
                      "&& .MuiChip-icon": {
                        color:
                          fields?.type === addressTypes[item]
                            ? colors.white
                            : colors.black,
                      },
                    }}
                    onClick={() => {
                      onChange("type", addressTypes[item]);
                    }}
                  />
                </Grid>
              );
            })}
            {errorFields.type && (
              <Typography variant="errorMessage1" marginLeft="8px">
                {en.errorMessages.addressType}
              </Typography>
            )}
          </Grid>
          <FormControlLabel
            checked={fields?.default}
            control={<Checkbox />}
            label={en.bookingDetails.useAsDefault}
            onChange={(e: any) => {
              if (e.target.checked) {
                onChange("default", true);
              } else {
                onChange("default", false);
              }
            }}
          />
        </Stack>
      </Box>
      <Box sx={{ position: "fixed", bottom: "10px", padding: "10px" }}>
        <CustomButton
          variant="contained"
          label={
            updateAddress
              ? en.bookingDetails.updateAddress
              : en.bookingDetails.saveAddress
          }
          sx={{ width: "355px" }}
          onClick={() => handleAddNewAddress(updateAddress)}
          loading={loading}
        />
      </Box>
    </Drawer>
  );
};
export default AddressSideDrawer;
