import { call, put, takeLatest } from "redux-saga/effects";
import { CMS_ACTIONS } from "../actions/actionTypes";
import { actionInterface } from "../../interfaces/commonInterface";
import { cmsDataService } from "../services";
import { apiStatusCode } from "../../constants/enum";

//get privacy policy cms Data
function* getPrivacyPolicyCmsData(action: actionInterface): any {
  try {
    const response = yield call(cmsDataService.getPrivacyPolicy);

    if (response.status === apiStatusCode.SUCCESS) {
      yield put({
        type: CMS_ACTIONS.GET_PRIVACY_POLICY_CMS_DATA_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      yield put({
        type: CMS_ACTIONS.GET_PRIVACY_POLICY_CMS_DATA_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: CMS_ACTIONS.GET_PRIVACY_POLICY_CMS_DATA_FAILED,
    });
  }
}

//get T&C data
function* getTermsConditionCmsData(action: actionInterface): any {
  try {
    const response = yield call(cmsDataService.getTermsAndConditions);

    if (response.status === apiStatusCode.SUCCESS) {
      yield put({
        type: CMS_ACTIONS.GET_TERMS_CONDITION_CMS_DATA_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      yield put({
        type: CMS_ACTIONS.GET_TERMS_CONDITION_CMS_DATA_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: CMS_ACTIONS.GET_TERMS_CONDITION_CMS_DATA_FAILED,
    });
  }
}

//get about us page data
function* getAboutUsData(action: actionInterface): any {
  try {
    const response = yield call(cmsDataService.getAboutUsData);

    if (response.status === apiStatusCode.SUCCESS) {
      yield put({
        type: CMS_ACTIONS.GET_ABOUT_US_CMS_DATA_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      yield put({
        type: CMS_ACTIONS.GET_ABOUT_US_CMS_DATA_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: CMS_ACTIONS.GET_ABOUT_US_CMS_DATA_FAILED,
    });
  }
}

//get home page data
function* getHomePageData(action: actionInterface): any {
  try {
    const response = yield call(cmsDataService.getHomePageData, action.payload);

    if (response.status === apiStatusCode.SUCCESS) {
      yield put({
        type: CMS_ACTIONS.GET_HOME_PAGE_DATA_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      yield put({
        type: CMS_ACTIONS.GET_HOME_PAGE_DATA_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: CMS_ACTIONS.GET_HOME_PAGE_DATA_FAILED,
    });
  }
}

function* getBlogPageSaga(): any {
  try {
    const response = yield call(cmsDataService.getBlogPageData);

    if (response.status === apiStatusCode.SUCCESS) {
      yield put({
        type: CMS_ACTIONS.GET_BLOG_PAGE_DATA_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      yield put({
        type: CMS_ACTIONS.GET_BLOG_PAGE_DATA_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: CMS_ACTIONS.GET_BLOG_PAGE_DATA_FAILED,
    });
  }
}

function* cmsDataSaga() {
  yield takeLatest(
    CMS_ACTIONS.GET_PRIVACY_POLICY_CMS_DATA_REQUESTED,
    getPrivacyPolicyCmsData
  );
  yield takeLatest(
    CMS_ACTIONS.GET_TERMS_CONDITION_CMS_DATA_REQUESTED,
    getTermsConditionCmsData
  );
  yield takeLatest(CMS_ACTIONS.GET_ABOUT_US_CMS_DATA_REQUESTED, getAboutUsData);
  yield takeLatest(CMS_ACTIONS.GET_HOME_PAGE_DATA_REQUESTED, getHomePageData);
  yield takeLatest(CMS_ACTIONS.GET_BLOG_PAGE_DATA_REQUESTED, getBlogPageSaga);
}

export default cmsDataSaga;
