import {
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { toPascalCase } from "../../../utills/helper";
import { TypographyConstants } from "../../../rental-src/constants/enum";
import { colors } from "../../../themes/colors";

const InputSelect = (props: any) => {
  const {
    customLabel,
    defaultKey,
    hidePlaceholder = false,
    skipData,
    isDisabled,
    customStyle = {},
    handleChange,
    topHeadingStyle,
  } = props;

  const handleSelect = (data: any) => {
    handleChange(data);
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column" }}
      minWidth={props.minWidth}
      width={props?.width ? props?.width : 1}
      {...props.boxStyle}
    >
      {props?.topHeading ? (
        <Typography
          variant={TypographyConstants.SUBTITLE2}
          sx={{
            color: colors.secondary_black,
            marginBottom: "5px",
            ...topHeadingStyle,
          }}
        >
          {props?.topHeading}
        </Typography>
      ) : null}
      <FormControl
        sx={{
          width: "100%",
        }}
      >
        {props?.heading && (
          <InputLabel
            id="demo-simple-select-label"
            sx={{ fontSize: "16px", left: "6px" }}
          >
            {props.heading}
          </InputLabel>
        )}

        <Select
          label={props.heading}
          value={props.value}
          displayEmpty
          startAdornment={
            <InputAdornment position="start">
              {props.muiIcons ? props.muiIcons : <img src={props.icon} />}
            </InputAdornment>
          }
          sx={{
            borderRadius: "8px !important",
            minHeight: "42px",
            height: "5vh",
            margin: "0px",
            ...customStyle,
          }}
          {...props}
        >
          {!hidePlaceholder && (
            <MenuItem disabled value={""}>
              {props.placeholder}
            </MenuItem>
          )}

          {/* dropdown values */}
          {props.choice?.map((data: any, index: number) => {
            if (skipData && skipData(data, index)) return null;
            return (
              <MenuItem
                key={index}
                onClick={() => handleSelect(data)}
                value={defaultKey ? data[defaultKey] : data}
                disabled={isDisabled ? isDisabled(data) : false}
              >
                {customLabel
                  ? customLabel(data)
                  : defaultKey
                  ? data[defaultKey]
                  : toPascalCase(data)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormHelperText>
        {props.errormessage && (
          <Typography variant="errorMessage1" sx={{ pl: 1, pb: 1 }}>
            {props?.errormessage}
          </Typography>
        )}
        {props.formHelperText && (
          <Typography
            color="drawerMenu.text.secondary"
            variant="body1"
            sx={{ pl: 1, pb: 1 }}
          >
            {props?.formHelperText}
          </Typography>
        )}
      </FormHelperText>
    </Box>
  );
};

export default InputSelect;

// Pass the choice array of object in form of {name ,displayName} or  ""
// Pass the defaultKey on which basis you want to show the Data in Dropdown
// Make sure the inital state of Value (passing) is empty before passing
// Heading above dropdown pass Top Heading
