import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import HeaderBar from "../component/HeaderBar";
import TopHeaderBar from "../component/TopHeaderBar";
import UnderMaintenance from "../component/UnderMaintenance";
import OutsideAlerter from "../component/outSideAlert";
import { setAuthToken } from "../config/axiosConfig";
import { getData } from "../config/filterStorage";
import { LocalStorage } from "../constant/enum";
import "../css/fonts.css";
import "../css/global.css";
import "../css/profile.css";
import { actions } from "../redux";
import { showUnderMaintenance } from "../redux/actions";
import {
  getFilteredVehicleModelList,
  getMasterPlanDurations,
  getRentalMasterCityList,
} from "../redux/actions/commonActions";
import { RootState } from "../redux/store";
import {
  getUserProfile,
  guestLogin,
} from "../rental-src/redux/actions/authActions";
import WrongUrl from "../screen/redirectionPages/inavlidUrl";
import ExploreContent from "../sharedComponents/template/exploreContent";
import FooterNew from "../sharedComponents/template/footer";
import { getLocalStorageData } from "../utills/helper";
import { customRoutes, unauthRoutes } from "../utills/routes";
import { getUserAllAddresses } from "../rental-src/redux/actions/masterActions";

const RoutWeb = () => {
  const dispatch = useDispatch();
  const TERMS_CONDITIONS_APP =
    window.location.pathname === "/terms-conditions-app";

  const [showRoutes, setShowRoutes] = useState(false);
  const { underMaintenance } = useSelector(
    (state: RootState) => state.authReducer
  );
  const { isAuthenticated, userProfileData = {} } = useSelector(
    (state: RootState) => state.newAuthReducer
  );
  const { id } = userProfileData;

  const { rentingFleetFilter } = useSelector(
    (state: any) => state.rentalsCommonReducer
  );
  const cityData = getLocalStorageData(LocalStorage.userCity);

  useEffect(() => {
    getData(LocalStorage.token).then((data: any) => {
      if (data) {
        let parsedData = JSON.parse(data);
        console.log("RoutWeb>", parsedData);
        setAuthToken(parsedData?.token);
        dispatch(
          actions.setUserToken({
            ...parsedData,
            // ExpireTime: "2022-12-14 12:33:02.000",
          })
        );
        if (parsedData?.customerId) {
          setTimeout(() => {
            dispatch(
              getUserProfile({
                customerId: parsedData?.customerId,
                apiParams: { ipi: true },
              })
            );
          }, 100);
        }
      }
    });

    setTimeout(() => {
      setShowRoutes(true);
    }, 200);
  }, []);

  // Master API Calls
  useEffect(() => {
    dispatch(getRentalMasterCityList());

    // New Admin Master APIs
    if (isAuthenticated) {
      dispatch(getMasterPlanDurations());
      dispatch(
        getFilteredVehicleModelList({
          FilterPayload: { ...rentingFleetFilter, cities: [cityData?.name] },
        })
      );
      // dispatch(getRentalMasterCityList());
    } else {
      dispatch(guestLogin());
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && id) {
      dispatch(getUserAllAddresses({ id: id }));
    }
  }, [id]);

  if (!showRoutes) {
    return <></>;
  }

  return (
    <>
      {!TERMS_CONDITIONS_APP && (
        <div className="sticky-top">
          <TopHeaderBar />
          <HeaderBar />
        </div>
      )}
      <div style={{ overflowY: "hidden", zIndex: -99 }}>
        {underMaintenance ? (
          <OutsideAlerter
            handleOutsideClick={(e) => dispatch(showUnderMaintenance(false))}
          >
            <UnderMaintenance />
          </OutsideAlerter>
        ) : (
          <Routes>
            {[...unauthRoutes, ...customRoutes].map((route: any, ind) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.component}
              />
            ))}
            <Route path="*" element={<WrongUrl />} />
          </Routes>
        )}

        <FooterNew />
        <ExploreContent />
        {/* <ExploreContent /> */}
      </div>
    </>
  );
};

export default RoutWeb;
