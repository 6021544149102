import { Box, Container, Grid2, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { getInTouch } from "../../../assets";
import { CustomButton } from "../../../sharedComponents/atom/Buttons/CustomButtons";
import Input from "../../../sharedComponents/atom/InputFields/Input";
import TextAreaInput from "../../../sharedComponents/atom/TextAreaInput";
import { colors } from "../../../themes/colors";
import { mobileValidation, validateEmail } from "../../../utills/helper";
import { checkAlfa, checkPhone, checkWhiteSpace } from "../../../utills/regex";
import styles from "./index.module.css";
import en from "../../../locales/en.json";
import { sendQueryData } from "../../redux/actions/authActions";
import { useDispatch } from "react-redux";
import ContactUsInfo from "../cmsComponent/ContactUsInfo";
import { maxWidth } from "@mui/system";

const {
  ContactUs,
  GetInTouch: getInTouchText,
  emailPlaceholder,
  EmailID,
  namePlaceholder,
  Name,
  writeQueryPlaceholder,
  SendMessage,
  WriteMessage,
  mobilePlaceholder,
  MobileNumber,
} = en.contact;

interface InitialState {
  emailId: string;
  name: string;
  mobileNumber: string;
  queryText: string;
}

const initialState: InitialState = {
  emailId: "",
  name: "",
  mobileNumber: "",
  queryText: "",
};

const GetInTouch = () => {
  const dispatch = useDispatch();
  const [fields, setFields] = useState<any>({ ...initialState });
  const [errFields, setErrFields] = useState<any>({ ...initialState });

  const handleChangeFields = (field: string, value: string) => {
    const emailValidate = validateEmail(value);
    const mobileValidate = mobileValidation(value);

    if (field === "emailId" && (!emailValidate?.status || value)) {
      setErrFields({
        [field]: emailValidate.error,
      });
    } else if (field === "mobileNumber" && !mobileValidate) {
      setErrFields({
        [field]: en.errorMessages.enterValidMobileNumber,
      });
    } else {
      setErrFields({
        [field]: "",
      });
    }
    setFields((prevState: InitialState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleSendMessageClick = () => {
    const payload = {
      data: {
        name: fields.name,
        email: fields.emailId,
        mobileNmber: fields.mobileNumber,
        remark: fields.queryText,
      },
    };
    dispatch(sendQueryData(payload));
  };

  return (
    <Container>
      <Stack gap="40px" marginTop="40px">
        <Box className={styles.faqBannerWrap}>
          <Typography
            variant="h4"
            sx={{
              color: colors.white,
              textAlign: "center",
            }}
          >
            {ContactUs}
          </Typography>
        </Box>

        {/* get in touch form  */}
        <Grid2 container spacing={2} className={styles.getInTouchForm}>
          <Grid2 size={{ sm: 12, md: 6 }}>
            <img src={getInTouch} alt={getInTouchText} />
          </Grid2>
          <Grid2 size={{ sm: 12, md: 6 }} sx={{ width: "100%" }}>
            <Stack gap="15px">
              <Typography
                variant="h4"
                sx={{
                  color: colors.secondary_black,
                }}
              >
                {getInTouchText}
              </Typography>
              <Input
                topHeading={Name}
                value={fields.name}
                placeholder={namePlaceholder}
                onChange={(e: any) => {
                  if (
                    e.target.value === "" ||
                    (e.target.value?.length < 50 &&
                      checkAlfa(e.target.value) &&
                      checkWhiteSpace(e.target.value))
                  )
                    handleChangeFields("name", e.target.value);
                }}
                errormessage={errFields.name}
                customStyle={{ minHeight: "35px", height: "38px" }}
              />
              <Input
                topHeading={EmailID}
                value={fields.emailId}
                placeholder={emailPlaceholder}
                onChange={(e: any) => {
                  if (
                    e.target.value === "" ||
                    (e.target.value?.length < 50 &&
                      checkWhiteSpace(e.target.value))
                  )
                    handleChangeFields("emailId", e.target.value);
                }}
                errormessage={errFields.emailId}
                customStyle={{ minHeight: "35px", height: "38px" }}
              />
              <Input
                topHeading={MobileNumber}
                value={fields.mobileNumber}
                placeholder={mobilePlaceholder}
                onChange={(e: any) => {
                  if (
                    e.target.value === "" ||
                    (checkPhone(e.target.value) && e.target.value?.length < 11)
                  )
                    handleChangeFields("mobileNumber", e.target.value);
                }}
                errormessage={errFields.mobileNumber}
                customStyle={{ minHeight: "35px", height: "38px" }}
              />
              <Stack gap="7px">
                <Typography
                  variant="subtitle2"
                  sx={{ color: colors.secondary_black }}
                >
                  {WriteMessage}
                </Typography>
                <TextAreaInput
                  placeholder={writeQueryPlaceholder}
                  minRows={2}
                  maxRows={2}
                  value={fields.queryText}
                  onChange={(e: any) => {
                    handleChangeFields("queryText", e.target.value);
                  }}
                />
              </Stack>
              <CustomButton
                label={SendMessage}
                variant="contained"
                onClick={() => handleSendMessageClick()}
                wrapperStyles={{ maxWidth: "200px" }}
                disabled={
                  !(
                    fields.mobileNumber &&
                    fields.name &&
                    fields.emailId &&
                    fields.queryText &&
                    !errFields.mobileNumber &&
                    !errFields.name &&
                    !errFields.emailId &&
                    !errFields.queryText
                  )
                }
              />
            </Stack>
          </Grid2>
        </Grid2>
        <ContactUsInfo />
      </Stack>
    </Container>
  );
};

export default GetInTouch;
