import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Modal, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import en from "../../../locales/en.json";
import { colors } from "../../../themes/colors";
import { CustomButton } from "../atom/Buttons/CustomButtons";
import OtpField from "./OtpField";

const OtpModal = (props: any) => {
  //
  const {
    phoneNumber,
    otpValue,
    openModal,
    handleChange,
    handleModalClose,
    handleResendOtp,
    label = en?.global?.otpExpiresIn,
    timeInSeconds = 120,
    showResendAtSeconds = 30,
    handleOtpModalSubmit,
    isOtpSubmitBtnEnabled,
    loading = false,
  } = props;
  //

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    right: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "400px",
    bgcolor: "background.paper",
    borderRadius: "2rem",
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    alignItems: "center",
  };

  const [time, setTime] = useState<number>(timeInSeconds);
  const [isOtpResent, setOtpResent] = useState<boolean>(true);

  const resendOtpClickHandler = (): void => {
    setOtpResent(true);
    setTime(timeInSeconds);
  };

  useEffect(() => {
    let timer: string | number | NodeJS.Timer | undefined;

    if (isOtpResent) {
      timer = setInterval(() => {
        setTime((time) => {
          if (time <= 0) {
            setOtpResent(false);
            clearInterval(timer);
            return 0;
          } else return time - 1;
        });
      }, 1000);
    }

    // cleanup
    return () => {
      clearInterval(timer);
    };
  }, [isOtpResent]);

  return (
    <Modal
      open={openModal}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableAutoFocus={true}
    >
      <Box sx={style}>
        <Box
          sx={{
            position: "absolute",
            cursor: "pointer",
            border: `0.5px solid ${colors?.white}`,
            borderRadius: "20px",
            backgroundColor: "transparent",
            top: "-50px",
            left: "46%",
            height: 40,
            width: 40,
          }}
        >
          <IconButton onClick={handleModalClose} sx={{ color: colors?.white }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Stack alignItems={"center"}>
          <Typography sx={{ fontSize: 20, fontWeight: 500, padding: "12px 0" }}>
            {en?.global?.otpVerification}
          </Typography>
          <Stack alignItems={"center"}>
            <Typography color={colors.dark_gray} sx={{ fontSize: 12 }}>
              {en?.global?.enterTheCode}
            </Typography>
            <Typography padding={"0px"} sx={{ fontSize: 15, fontWeight: 500 }}>
              {en?.global?.countryCodeIN} {phoneNumber ? phoneNumber : "N/A"}
            </Typography>
          </Stack>
          <Typography
            variant="heading"
            color={colors.THEME_BLUE}
            sx={{ fontSize: 16 }}
          >
            <span>
              {label} {`${Math.floor(time / 60)}`.padStart(2, "0")}:
              {`${time % 60}`.padStart(2, "0")}
            </span>
          </Typography>
          <Stack alignItems={"center"} gap="10px">
            <Box></Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                pb: 2,
                alignItems: "center",
              }}
            >
              <OtpField
                separator={<span> </span>}
                value={otpValue}
                onChange={handleChange}
                length={6}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography color={colors.dark_gray} sx={{ fontSize: 12 }}>
                  {en?.global?.otpNotReceived}{" "}
                </Typography>

                {/* Resend Otp */}
                <CustomButton
                  label={en?.global?.resend}
                  onClick={() => {
                    resendOtpClickHandler();
                    handleResendOtp(); // api call
                  }}
                  disabled={time > showResendAtSeconds}
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                      background: "none",
                    },
                    fontSize: 14,
                  }}
                />
              </Box>
            </Box>
          </Stack>
        </Stack>
        <CustomButton
          variant="outlined"
          sx={{ width: 320 }}
          label={en?.global?.submit}
          loading={loading}
          onClick={() => {
            handleOtpModalSubmit(otpValue);
          }}
          disabled={isOtpSubmitBtnEnabled()}
        />
      </Box>
    </Modal>
  );
};

export default OtpModal;
