import moment from "moment";
import { targetGrey } from "../../../../assets";
import "./TopFilter.css";
import { useState, useRef, lazy, Suspense } from "react";
import { format } from "date-fns";
import DatePickerInput from "../../../../component/DatePicker/DatePickerInput";
import { toggleCityModal } from "../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import GenericModal from "../../../../sharedComponents/molecule/Modals/GenericModal";
import InputSelect from "../../../../sharedComponents/atom/InputFields/InputSelect";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Box } from "@mui/material";
const SelectCityModalContent = lazy(
  () => import("../../HomeScreen/sections/SelectCityModalContent")
);

const FilterForm = ({
  handlePackageChange,
  findEndDate,
  cityData,
  setPrimaryFilter,
  primaryFilter,
  getFilteredModelList,
}) => {
  const dispatch = useDispatch();
  const {
    masterPlanDurationsData,
    filteredModelList,
    carousalFilter,
    rentingFleetFilter,
    modelFilterType,
  } = useSelector((state: any) => state.rentalsCommonReducer);

  const { selectedPackage, startDate, endDate } = primaryFilter;
  const [showCityModal, setShowCityModal] = useState(false);

  const datePickerRef = useRef<any>();
  const DateFormatForPicker = (date = new Date(), isTime = false) => {
    let dateFormat = format(new Date(date), "yyyy-MM-dd");
    if (isTime) {
      dateFormat = format(new Date(date), "yyyy-MM-dd hh:mm a");
    }
    return dateFormat;
  };
  const handleDatePickerOnchange = (e) => {
    let selectedDate = e;
    if (selectedDate) {
      DateFormatForPicker(e);
    }
    let endDate = findEndDate(selectedDate, primaryFilter.selectedPackage);
    setPrimaryFilter({
      ...primaryFilter,
      startDate: selectedDate,
      endDate,
    });
    // fetch filtered models
    getFilteredModelList();
  };

  // toggle city model handler
  const cityModalToggleHandler = () => {
    setShowCityModal((prev) => !prev);
  };

  // city change Handler
  const handleCityChange = (newCity: any) => {
    cityModalToggleHandler();
  };

  const formattedLaunchDate = cityData?.launch_date
    ?.split("-")
    .reverse()
    .join("-");
  let launchDateForPicker = formattedLaunchDate
    ? new Date(formattedLaunchDate)
    : new Date();
  launchDateForPicker =
    launchDateForPicker <= new Date() ? new Date() : launchDateForPicker;

  const packageArray =
    masterPlanDurationsData &&
    masterPlanDurationsData?.map((item: any, index: number) => {
      // Removes "Custom" in Package, remove when functionality to select End date is implemented
      // if (typeof item === "number") {
      return {
        displayName: typeof item === "string" ? item : `${item} Day`,
        name: item,
      };
      // }
    });

  return (
    <>
      <Box className="filter-row form">
        {/* --------------------- Select City -------------------- */}
        <Box className="detect-filter">
          <Box className="input-group" onClick={() => cityModalToggleHandler()}>
            <input
              type="button"
              className="form-control form-control-sm text-start"
              value={cityData?.displayName}
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
            <Box className="loaction-detect">
              <i className="fa-solid fa-location-dot"></i>
            </Box>
            <a href="#" className="input-group-text" id="basic-addon1">
              <img src={targetGrey} alt="" />
            </a>
          </Box>
        </Box>

        {/* --------------------- Ride Start Date  -------------------- */}
        <Box className="date-filter">
          <Box className="input-group">
            <DatePickerInput
              startDate={startDate}
              handleDatePickerOnchange={handleDatePickerOnchange}
              datePickerRef={datePickerRef}
              isClearableEnabled={false}
              minDate={launchDateForPicker}
            />
            <Box className="start-end-date-text">Start Date</Box>
          </Box>

          <Box>
            <Box className="progressbar">
              <span className="active"></span>
              <span className="next"></span>
            </Box>
          </Box>

          {/* ------------- Ride End date ------------- */}
          <Box className="input-group end-date-mobile">
            <input
              className="form-control form-control-sm rounded-pill"
              value={endDate ? moment(endDate).format("DD-MM-YYYY") : ""}
              style={{ color: "#777" }}
            />
            <Box className="start-end-date-text">End Date</Box>
          </Box>

          <Box className="ml-0 end-date">
            {endDate ? moment(endDate).format("DD-MM-YYYY") : ""}
          </Box>
        </Box>

        {/* ----------------- Package Days ------------- */}
        {packageArray && packageArray?.length > 0 && (
          <Box className="day-select-filter">
            <InputSelect
              choice={packageArray}
              value={
                typeof selectedPackage === "string"
                  ? selectedPackage
                  : `${selectedPackage} Day`
              }
              defaultKey={"displayName"}
              handleChange={(selectPackage: any) => {
                handlePackageChange(selectPackage);
              }}
            />

            <Box className="clock-Day">
              <i className="fa-regular fa-clock"></i>
            </Box>
          </Box>
        )}
      </Box>

      {/* Select city Modal */}
      {showCityModal && (
        <Suspense>
          <GenericModal
            isOpen={showCityModal}
            handleClose={cityModalToggleHandler}
            title={"Select City"}
          >
            <SelectCityModalContent
              handleChange={(cityData: any) => {
                handleCityChange(cityData);
              }}
            />
          </GenericModal>
        </Suspense>
      )}
    </>
  );
};

export default FilterForm;
