import MyBookingReducer from "./MyBookingReducer";
import newAuthReducer from "./authReducer";
import newProfileReducer from "./profileReducer";
import userKycReducer from "./userKycReducer";
// import rentalCheckoutReducer from "./checkoutReducer";
import cmsDataReducer from "./cmsReducer";
import MasterReducer from "./masterReducer";
import UserAddressesReducer from "./userAddressReducer";

// export all revamp reducers here...
export const rentalReducer = {
  userKycReducer,
  // rentalCheckoutReducer,
  newAuthReducer,
  newProfileReducer,
  cmsDataReducer,
  MyBookingReducer,
  MasterReducer,
  UserAddressesReducer,
};
