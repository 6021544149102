import { MyBookingsActions } from "../actionTypes";

//action for get booking history
export const getBookingHistory = (payload: any) => {
  return { type: MyBookingsActions.GET_BOOKING_HISTORY_REQUESTED, payload };
};

//action for get booking details
export const getBookingDetails = (payload: any) => {
  return { type: MyBookingsActions.GET_BOOKING_DETAILS_REQUESTED, payload };
};

//action for get yor booking history
export const getYorBookingHistory = (payload: any) => {
  return {
    type: MyBookingsActions.GET_YOR_BOOKING_HISTORY_REQUESTED,
    payload,
  };
};

//get vehicle documents
export const getVehicleDocuments = (payload: any) => {
  return {
    type: MyBookingsActions.GET_VEHICLE_DOCUMENTS_REQUESTED,
    payload,
  };
};

//clear booking details
export const clearBookingDetails = () => {
  return {
    type: MyBookingsActions.CLEAR_BOOKING_DETAILS,
  };
};

//download vehicle documents
export const downloadVehicleDocument = (payload: any) => {
  return {
    type: MyBookingsActions.DOWNLOAD_VEHICLE_DOCUMENT_REQUESTED,
    payload,
  };
};

// get cancellation charges
export const getbookingCancellationCharges = (payload: any) => ({
  type: MyBookingsActions.GET_BOOKING_CANCELLATION_CHARGES_REQUESTED,
  payload,
});

// cancle booking action
export const cancelUserBooking = (payload: any) => ({
  type: MyBookingsActions.CANCEL_USER_BOOKING_REQUESTED,
  payload,
});
