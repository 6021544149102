import { bannerImageOperatorModule } from "../../../assets";
import { GenericObject } from "../../../interfaces/commonInterface";

export const StyleObject: GenericObject = {
  loaderBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "80vh",
  },
  emailBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "80vh",
  },
  emailCard: {
    width: { xs: "90%", sm: "70%", md: "70%" }, // Responsive width
    margin: "auto", // Center align
    borderRadius: 1,
    overflow: "hidden",
    boxShadow: 1,
    height: { xs: "400px", sm: "400px", md: "400px" }, // Responsive height
  },
  topHalfBox: {
    height: { xs: 42 / 100, sm: 42 / 100, md: 47 / 100 }, // Responsive height
    backgroundImage: `url(${bannerImageOperatorModule})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  bottomHalfBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: { xs: "230px", sm: "230px", md: "230px" }, // Responsive height
  },
  eSignBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "80vh",
  },
};
