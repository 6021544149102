import { setAuthToken } from "../../../../config/axiosConfig";
import { storeData } from "../../../../config/filterStorage";
import { LocalStorage } from "../../../../constant/enum";
import { GenericObject } from "../../../../utills/helper";
import { actionInterface } from "../../../interfaces/commonInterface";
import { AUTH_ACTIONS } from "../../actions/actionTypes";

interface initialState {
  loginLoader: boolean;
  userProfileData: GenericObject;
  userAuthData: GenericObject;
  isAuthenticated: boolean;
  isOpenLoginDrawer: boolean;
  signUpLoader: boolean;
  guestLoginData: GenericObject;
  queryLoader: boolean;
}

const initialState: initialState = {
  loginLoader: false,
  userProfileData: {},
  userAuthData: {},
  isAuthenticated: false,
  isOpenLoginDrawer: false,
  signUpLoader: false,
  guestLoginData: {},
  queryLoader: false,
};

const newAuthReducer = (state = initialState, action: actionInterface) => {
  const { type, payload } = action;

  switch (type) {
    case AUTH_ACTIONS.SET_USER_TOKEN:
      return {
        ...state,
        isAuthenticated: payload?.token ? true : false,
        token: payload?.token,
        refreshToken: payload?.refreshToken,
        customerId: payload?.customerId,
      };

    case AUTH_ACTIONS.TOGGLE_SIGN_IN_DRAWER:
      return { ...state, isOpenLoginDrawer: payload };

    case AUTH_ACTIONS.GET_USER_PROFILE_DATA_REQUESTED:
      return { ...state, userProfileData: {} };

    case AUTH_ACTIONS.GET_USER_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        userProfileData: { ...state.userProfileData, ...payload },
      };

    case AUTH_ACTIONS.GET_USER_PROFILE_DATA_FAILED:
      return { ...state, userProfileData: {} };

    case AUTH_ACTIONS.LOGIN_REQUESTED:
      return { ...state, loginLoader: true };

    case AUTH_ACTIONS.LOGIN_SUCCESS:
      return { ...state, userAuthData: payload, loginLoader: false };

    case AUTH_ACTIONS.LOGIN_FAILED:
    case AUTH_ACTIONS.LOGIN_VERIFY_OTP_FAILED:
      return { ...state, loginLoader: false };

    case AUTH_ACTIONS.LOGIN_VERIFY_OTP_REQUESTED:
      return { ...state, loginLoader: true };

    case AUTH_ACTIONS.LOGIN_VERIFY_OTP_SUCCESS:
    case AUTH_ACTIONS.SIGNUP_VERIFY_OTP_SUCCESS:
      const customerId = state?.userAuthData?.customerId;
      storeData(LocalStorage.token, {
        token: payload?.accessToken,
        refreshToken: payload?.refreshToken,
        ExpireTime: payload?.expirationTime,
        customerId,
      });
      setAuthToken(payload?.accessToken);

      return {
        ...state,
        token: payload?.accessToken,
        refreshToken: payload?.refreshToken,
        ExpireTime: payload?.expiry,
        isAuthenticated: true,
        loginLoader: false,
      };

    case AUTH_ACTIONS.SIGNUP_REQUESTED:
      return { ...state, signUpLoader: true };

    case AUTH_ACTIONS.SIGNUP_FAILED:
      return { ...state, signUpLoader: false };

    case AUTH_ACTIONS.SIGNUP_SUCCESS:
      return {
        ...state,
        signUpLoader: false,
        userAuthData: payload,
      };

    case AUTH_ACTIONS.SIGNUP_VERIFY_OTP_REQUESTED:
      return { ...state, signUpLoader: true };

    case AUTH_ACTIONS.SIGNUP_VERIFY_OTP_FAILED:
      return { ...state, signUpLoader: false };

    case AUTH_ACTIONS.LOGOUT_SUCCESS:
      storeData(LocalStorage.token, null);
      setAuthToken("");
      return {
        ...state,
        userProfileData: {},
        userAuthData: {},
        isAuthenticated: false,
      };

    // case AUTH_ACTIONS.SIGNUP_RESEND_OTP_REQUESTED:
    case AUTH_ACTIONS.SEND_QUERY_DATA_REQUESTED:
      return { ...state, queryLoader: true };

    case AUTH_ACTIONS.SEND_QUERY_DATA_SUCCESS:
      return { ...state, queryLoader: false };

    case AUTH_ACTIONS.SEND_QUERY_DATA_FAILED:
      return { ...state, queryLoader: false };

    case AUTH_ACTIONS.GUEST_LOGIN_DATA_REQUESTED:
      return { ...state };

    case AUTH_ACTIONS.GUEST_LOGIN_DATA_SUCCESS:
      return { ...state, guestLoginData: payload };

    case AUTH_ACTIONS.GUEST_LOGIN_DATA_FAILED:
      return { ...state };
    default:
      return { ...state };
  }
};

export default newAuthReducer;
