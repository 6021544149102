import {
  Box,
  Container,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { helpCrm } from "../../../assets";
import { WEB_URL } from "../../../config/apiUrls";
import { Routes } from "../../../constant/routeContants";
import { RootState } from "../../../redux/reducers";
import { CustomButton } from "../../../sharedComponents/atom/Buttons/CustomButtons";
import { colors } from "../../../themes/colors";
import {
  GenericObject,
  OtherDocumentTypes,
  TypographyConstants,
  generateSignedUrlDocType,
  kycSubTypes,
  kycTypes,
} from "../../constants/enum";
import {
  HelpDeskErrorFieldsInterface,
  HelpDeskFieldsInterface,
  dlPreviewFields,
  getKycAttachmentIdsPayload,
  radioButtonObject,
} from "../../interfaces/kycInterfaces";
import {
  clearUserKycReducerData,
  getKycAttachmentIds,
  getTrustScore,
  issueCategoryListAction,
  kycDocUpload,
  submitKycDocuments,
} from "../../redux/actions";
import Aadhaar from "./sections/Aadhaar";
import DrivingLicence from "./sections/DrivingLicence";
import { OtherDocUpload } from "./sections/OtherDocUpload";
import Selfie from "./sections/Selfie";
import styles from "./sections/userKyc.module.css";
import en from "../../../locales/en.json";
import GenericModal from "../../../sharedComponents/molecule/Modals/GenericModal";
import InputSelect from "../../../sharedComponents/atom/InputFields/InputSelect";
import TextAreaInput from "../../../sharedComponents/atom/TextAreaInput";
import { sendQueryData } from "../../redux/actions/authActions";
import { validate } from "../../utils/helper";
// array for radio buttons
const radioButtonArray: radioButtonObject[] = [
  {
    id: kycTypes.DRIVING_LICENCE,
    value: kycTypes.DRIVING_LICENCE,
    label: "Driving Licence(DL)",
  },
  {
    id: kycTypes.AADHAAR,
    value: kycTypes.AADHAAR,
    label: "Aadhaar",
  },
  {
    id: kycTypes.SELFIE,
    value: kycTypes.SELFIE,
    label: "Selfie",
  },
  {
    id: kycTypes.OTHER_DOCUMENT,
    value: kycTypes.OTHER_DOCUMENT,
    label: "Others",
  },
];

const initialState: HelpDeskFieldsInterface = {
  queryText: "",
  issueCategory: {
    name: "",
    id: "",
  },
};

const errorInitialState: HelpDeskErrorFieldsInterface = {
  queryText: "",
  issueCategory: "",
};

const UserKycScreen = () => {
  const isDesktop = useMediaQuery("(min-width:1200px)");
  const isIpad = useMediaQuery("(min-width:600px)");

  const dispatch = useDispatch();

  // state to handle kyc documents screen
  const [kycDocumentScreen, setKycDocumentScreen] = useState<string>(
    kycTypes.DRIVING_LICENCE
  );

  const [fields, setFields] = useState<GenericObject>({ ...initialState });

  const [errorFields, setErrorFields] = useState<GenericObject>({
    ...errorInitialState,
  });

  // state to preview image of dl
  const [dlPreview, setDlPreview] = useState<dlPreviewFields>({
    frontSide: "",
    backSide: "",
  });

  // data from profile reducer
  const { userProfileData = {}, queryLoader = false } = useSelector(
    (state: RootState) => state.newAuthReducer
  );

  // data from profile reducer
  const { issueCategoryList = [] } = useSelector(
    (state: RootState) => state.MasterReducer
  );

  // data from kyc reducer
  const {
    userTrustScoreData = {},
    uploadFileData = {},
    submitDocLoader = false,
    getAttachmentLoader = false,
    userTrustScoreDataLoader = false,
  } = useSelector((state: RootState) => state.userKycReducer);

  const {
    selfieTrustScore,
    otherDocsTrustScore,
    dlTrustScore,
    aadhaarTrustScore,
  } = userTrustScoreData?.trustScoreFactor || {};

  //state to check others doc uploaded or not
  const [otherDocsUpload, setOtherDocsUpload] = useState<any>({
    [OtherDocumentTypes.BANK_DETAIL]: false,
    [OtherDocumentTypes.ELECTRICITY_BILL]: false,
    [OtherDocumentTypes.OTHER]: false,
    [OtherDocumentTypes.PASSPORT]: false,
    [OtherDocumentTypes.RENT_BILL]: false,
    [OtherDocumentTypes.VOTER_ID]: false,
  });

  //handle image preview
  const [imagePreview, setImagePreview] = useState<
    Array<{ src: string; fileName: string }>
  >([
    {
      src: uploadFileData.signedUrl,
      fileName: uploadFileData.fileName,
    },
  ]);

  const [showNeedHelpModal, setShowNeedHelpModal] = useState<boolean>(false);

  useEffect(() => {
    if (userProfileData?.id) {
      dispatch(getTrustScore({ customerId: userProfileData.id }));
    }
  }, []);

  // handle change in radio group or button
  const handleRadiochange = (event: ChangeEvent<HTMLInputElement>) => {
    setKycDocumentScreen(event.target.value);
  };

  //call get attchment id
  const callGetAttachment = (
    file: any,
    fileName: string | undefined,
    type?: string,
    subType?: string
  ) => {
    const payload = {
      apiPayload: {
        data: {
          attachments: [fileName],
          type: generateSignedUrlDocType.KYC_DOCUMENT,
        },
      },
      fileData: file,
      submitPayload: {
        type: type ?? "",
        subType: subType ?? "",
      },
    };

    dispatch(getKycAttachmentIds(payload));
  };

  //handle submit sucess callback
  const handleSubmitSuccessCallback = () => {
    dispatch(getTrustScore({ customerId: userProfileData.id }));
    if (kycDocumentScreen === kycTypes.OTHER_DOCUMENT) {
      setOtherDocsUpload((prevState: any) => ({
        ...prevState,
        [OtherDocumentTypes.BANK_DETAIL]: false,
        [OtherDocumentTypes.ELECTRICITY_BILL]: false,
        [OtherDocumentTypes.OTHER]: false,
        [OtherDocumentTypes.PASSPORT]: false,
        [OtherDocumentTypes.RENT_BILL]: false,
        [OtherDocumentTypes.VOTER_ID]: false,
      }));
    }
    dispatch(
      clearUserKycReducerData({
        uploadFileData: {},
        documentAttachmentData: {},
      })
    );
  };

  // get doc details for api payload
  const getPayloadDetails = (type: string) => {
    if (type === kycTypes.SELFIE)
      return {
        verifyDigitally: true,
        selfieAttachmentId: uploadFileData.attachmentsIds,
      };
    else if (type === kycTypes.DRIVING_LICENCE)
      return {
        ocr: true,
        frontImageAttachmentId:
          uploadFileData[kycTypes.DRIVING_LICENCE]?.frontImageAttachmentId,
        backImageAttachmentId:
          uploadFileData[kycTypes.DRIVING_LICENCE]?.backImageAttachmentId,
      };
    else if (type === kycTypes.OTHER_DOCUMENT) return {};
    else if (type === kycTypes.AADHAAR)
      return {
        verifyDigitally: true,
        callbackUrl: `${WEB_URL}/${Routes.userKyc}`,
      };
  };

  //other document upload
  const handleSubmitOtherDocUpload = () => {
    const payload = {
      customerId: userProfileData?.id,
      apiPayload: {
        data: {
          details: uploadFileData[kycTypes.OTHER_DOCUMENT],
          type: kycTypes.OTHER_DOCUMENT,
        },
      },
      successCallBack: handleSubmitSuccessCallback,
    };

    dispatch(kycDocUpload(payload));
  };

  //handle submit click
  const handleSubmitClick = (type: string) => {
    const payload = {
      customerId: userProfileData?.id,
      apiPayload: {
        data: {
          details: getPayloadDetails(type),
          type,
        },
      },
      successCallBack: handleSubmitSuccessCallback,
    };

    dispatch(submitKycDocuments(payload));
  };

  //handle uplaod image click
  const handleUploadImgClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    validTypes: string[],
    type?: string,
    subType?: string
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      // Check for valid file type
      if (!validTypes.includes(file.type)) {
        toastr.error("", "Please upload a valid file");
        return;
      }
      // Check for file size (5MB = 5 * 1024 * 1024 bytes)
      if (file.size > 5 * 1024 * 1024) {
        toastr.error("", "File size must be less than 5MB");
        return;
      }
      const imageUrl = URL.createObjectURL(file);

      if (type === kycTypes.OTHER_DOCUMENT && subType) {
        setOtherDocsUpload((prev: any) => {
          return {
            ...prev,
            [subType]: true,
          };
        });
      } else {
        setImagePreview((prev: any) => {
          return {
            ...prev,
            src: imageUrl,
            fileName: file?.name,
          };
        });
      }
      // Call the attachment handler
      callGetAttachment(file, file?.name, type, subType);
    }
  };

  //show submit button or not
  const showSubmitButton = () => {
    if (kycDocumentScreen === kycTypes.SELFIE) {
      if (selfieTrustScore?.isPresent || selfieTrustScore?.isVerified) {
        return false;
      }
    } else if (kycDocumentScreen === kycTypes.DRIVING_LICENCE) {
      if (dlTrustScore?.isPresent || dlTrustScore?.isVerified) {
        return false;
      }
    } else if (kycDocumentScreen === kycTypes.AADHAAR) {
      if (aadhaarTrustScore?.isPresent || aadhaarTrustScore?.isVerified) {
        return false;
      }
    } else if (kycDocumentScreen === kycTypes.OTHER_DOCUMENT) {
      if (otherDocsTrustScore?.isPresent || otherDocsTrustScore?.isVerified) {
        return true;
      }
    }
    return true;
  };

  //on click image delete
  const onClickDeleteImage = () => {
    setImagePreview([]);
    dispatch(
      clearUserKycReducerData({
        uploadFileData: {},
        documentAttachmentData: {},
      })
    );
  };

  //handle image Input
  const handleImageInput = (type?: string) => {
    const inputElement = document.getElementById(
      type ? type : "image_upload"
    ) as HTMLInputElement;
    inputElement && inputElement.click();
  };

  // function to handle disable submit button
  const submitButtonDisabled = () => {
    if (kycDocumentScreen === kycTypes.SELFIE) {
      return !imagePreview;
    } else if (kycDocumentScreen === kycTypes.DRIVING_LICENCE) {
      return uploadFileData[kycTypes.DRIVING_LICENCE]?.frontImageAttachmentId &&
        uploadFileData[kycTypes.DRIVING_LICENCE]?.backImageAttachmentId
        ? false
        : true;
    } else if (kycDocumentScreen === kycTypes.AADHAAR) {
      return false;
    } else if (kycDocumentScreen === kycTypes.OTHER_DOCUMENT) {
      for (let key in otherDocsUpload) {
        if (otherDocsUpload[key]) {
          return false;
        }
      }
    }
    return true;
  };

  // handle file change for dl
  const handleFilechange = (
    event: ChangeEvent<HTMLInputElement>,
    subType: string
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      // Validate file type
      const validTypes = ["image/jpeg", "image/png", "image/jpg"];

      if (!validTypes.includes(file.type)) {
        toastr.error("", "Please upload a valid image file (JPG, PNG, JPEG)");
        return;
      }
      const imageUrl: string = URL.createObjectURL(file);

      // set dl for preview
      setDlPreview((prev: dlPreviewFields) => ({
        ...prev,
        [subType]: imageUrl,
      }));

      // payload to get attachment id
      const payload: getKycAttachmentIdsPayload = {
        apiPayload: {
          data: {
            attachments: [file?.name],
            type: generateSignedUrlDocType.KYC_DOCUMENT,
          },
        },
        fileData: event.target.files?.[0],
        submitPayload: {
          type: kycTypes.DRIVING_LICENCE,
          subType:
            subType === "frontSide"
              ? kycSubTypes.FRONT_IMAGE_DRIVING_LICENCE
              : kycSubTypes.BACK_IMAGE_DRIVING_LICENCE,
        },
      };
      dispatch(getKycAttachmentIds(payload));
    }
  };

  const handleOnclickNeedHelpBtn = () => {
    dispatch(issueCategoryListAction());
    setShowNeedHelpModal(true);
  };

  const handleChangeFields = (field: any, value: any) => {
    setFields((prev: dlPreviewFields) => ({
      ...prev,
      [field]: value,
    }));

    setErrorFields((prev: dlPreviewFields) => ({
      ...prev,
      [field]: "",
    }));
  };

  const toogleNeedHelpModal = () => {
    setShowNeedHelpModal((prev) => !prev);

    setFields((prev: dlPreviewFields) => ({
      ...prev,
      ...initialState,
    }));

    setErrorFields((prev: dlPreviewFields) => ({
      ...prev,
      ...errorInitialState,
    }));
  };

  const handleSubmitNeedHelp = () => {
    const validation = validate(fields, errorFields);

    if (validation.error) {
      return setErrorFields({ ...errorFields, ...validation.errorFields });
    }

    const payload = {
      data: {
        customerId: userProfileData?.id,
        name: `${userProfileData?.firstName} ${userProfileData?.lastName}`,
        mobileNmber: userProfileData?.mobileNumber,
        email: userProfileData?.email,
        issueCategory: fields?.issueCategory?.id,
        remark: fields.queryText,
      },
      successCallback: () => {
        toogleNeedHelpModal();
      },
    };
    dispatch(sendQueryData(payload));
  };

  return (
    <Stack>
      {/* header box */}
      <Box className={styles.updateKycContainer}>
        <Typography
          variant={TypographyConstants.SUBTITLE1}
          sx={{
            color: colors.secondary_black,
            fontWeight: 500,
          }}
        >
          {en.profile.kyc.updateKYC}
        </Typography>
        <Box sx={{ cursor: "pointer" }} onClick={handleOnclickNeedHelpBtn}>
          <Typography
            variant={TypographyConstants.CAPTION}
            sx={{
              color: colors.THEME_BLUE,
              textDecoration: "underline",
            }}
          >
            {en.contact.needHelpBtn}
          </Typography>
          <img src={helpCrm} alt="" style={{ width: "40px" }} />
        </Box>
      </Box>

      {/* radio buttons container */}
      <Box>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={kycDocumentScreen}
          name="radio-buttons-group"
          value={kycDocumentScreen}
          onChange={handleRadiochange}
          sx={{ display: "flex", flexDirection: "row" }}
        >
          {radioButtonArray.map((item: radioButtonObject) => (
            <FormControlLabel
              id={item.id}
              value={item?.value}
              control={<Radio size="small" />}
              label={item?.label}
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "14px",
                  color: colors.secondary_black,
                  fontWeight: 400,
                },
              }}
            />
          ))}
        </RadioGroup>
      </Box>

      {/* Doc upload section */}
      <Stack
        sx={{
          padding: { xs: "16px 6px", sm: "16px 16px", md: "16px 26px" },
        }}
        alignItems="center"
        gap="20px"
      >
        <Container className={styles.container}>
          {kycDocumentScreen !== kycTypes.OTHER_DOCUMENT && (
            <Box className={styles.docsWrap}>
              {/* Driving licence upload section */}
              {kycDocumentScreen === kycTypes.DRIVING_LICENCE ? (
                <DrivingLicence
                  dlTrustScore={
                    userTrustScoreData?.trustScoreFactor?.dlTrustScore
                  }
                  dlPreview={dlPreview}
                  handleFilechange={handleFilechange}
                />
              ) : null}

              {kycDocumentScreen === kycTypes.SELFIE ? (
                <Selfie
                  imagePreview={imagePreview}
                  setImagePreview={setImagePreview}
                  callGetAttachment={callGetAttachment}
                  handleUploadImgClick={(event: any) => {
                    handleUploadImgClick(event, [
                      "image/jpeg",
                      "image/png",
                      "image/jpg",
                    ]);
                  }}
                  onClickDeleteImage={onClickDeleteImage}
                  handleImageInput={handleImageInput}
                />
              ) : null}

              {/* Aadhaar verification section */}
              {kycDocumentScreen === kycTypes.AADHAAR ? <Aadhaar /> : null}
            </Box>
          )}

          {kycDocumentScreen === kycTypes.OTHER_DOCUMENT ? (
            <OtherDocUpload
              handleUploadImgClick={handleUploadImgClick}
              handleImageInput={handleImageInput}
              callGetAttachment={callGetAttachment}
              setOtherDocsUpload={setOtherDocsUpload}
              otherDocsUpload={otherDocsUpload}
            />
          ) : null}
        </Container>

        {showSubmitButton() && (
          <CustomButton
            label={
              kycDocumentScreen === kycTypes.AADHAAR ? "Initiate KYC" : "Submit"
            }
            variant="contained"
            onClick={
              kycDocumentScreen === kycTypes.OTHER_DOCUMENT
                ? () => handleSubmitOtherDocUpload()
                : () => handleSubmitClick(kycDocumentScreen)
            }
            wrapperStyles={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
            sx={{ width: "100%", maxWidth: "420px" }}
            disabled={submitButtonDisabled()}
            loading={
              submitDocLoader || getAttachmentLoader || userTrustScoreDataLoader
            }
          />
        )}

        {showNeedHelpModal && (
          <GenericModal
            isOpen={showNeedHelpModal}
            handleClose={toogleNeedHelpModal}
            title={en.contact.needHelpBtn}
            modalWidth={isDesktop ? "35vw" : isIpad ? "70vw" : "90vw"}
          >
            <Stack gap="10px">
              <InputSelect
                choice={issueCategoryList?.categories}
                placeholder={en.contact.issueCategoryPlaceholder}
                topHeading={en.contact.issueCategory}
                value={fields?.issueCategory?.name}
                defaultKey={"name"}
                handleChange={(value: any) => {
                  handleChangeFields("issueCategory", value);
                }}
                customStyle={{
                  minHeight: "36px",
                  height: "36px",
                }}
                required={true}
                errormessage={errorFields?.issueCategory}
              />

              <TextAreaInput
                topHeading={en.contact.WriteMessage}
                placeholder={en.contact.writeMessagePlaceHolder}
                minRows={3}
                maxRows={3}
                value={fields.queryText}
                onChange={(e: any) => {
                  handleChangeFields("queryText", e.target.value);
                }}
                errormessage={errorFields?.queryText}
              />

              <CustomButton
                label={en.global.submit}
                variant={"contained"}
                wrapperStyles={{ width: "100%", marginTop: "20px" }}
                onClick={() => handleSubmitNeedHelp()}
                loading={queryLoader}
              />
            </Stack>
          </GenericModal>
        )}
      </Stack>
    </Stack>
  );
};

export default UserKycScreen;
