import { USER_ADDRESSES_ACTIONS } from "../../actions/actionTypes";

interface initialStateInterface {
  newAddress: any;
  updatedAddress: any;
  loading: boolean;
}
const initialState: initialStateInterface = {
  newAddress: "",
  updatedAddress: "",
  loading: false,
};
const UserAddressesReducer = (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case USER_ADDRESSES_ACTIONS.ADD_NEW_ADDRESS_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case USER_ADDRESSES_ACTIONS.ADD_NEW_ADDRESS_SUCCESS:
      return {
        ...state,
        newAddress: payload,
        loading: false,
      };
    case USER_ADDRESSES_ACTIONS.ADD_NEW_ADDRESS_FAILED:
      return {
        ...state,
        loading: false,
      };

    case USER_ADDRESSES_ACTIONS.UPDATE_ADDRESS_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case USER_ADDRESSES_ACTIONS.UPDATE_ADDRESS_SUCCESS:
      return {
        ...state,
        updatedAddress: payload,
        loading: false,
      };
    case USER_ADDRESSES_ACTIONS.UPDATE_ADDRESS_FAILED:
      return {
        ...state,
        loading: false,
      };

    case USER_ADDRESSES_ACTIONS.DELETE_ADDRESS_REQUESTED:
      return {
        ...state,
        loading: false,
      };
    case USER_ADDRESSES_ACTIONS.DELETE_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case USER_ADDRESSES_ACTIONS.DELETE_ADDRESS_FAILED:
      return {
        ...state,
        loading: false,
      };

    case USER_ADDRESSES_ACTIONS.CLEAR_NEW_ADDRESS:
      return {
        ...state,
        newAddress: "",
        updatedAddress: "",
      };

    default:
      return {
        ...state,
      };
  }
};

export default UserAddressesReducer;
