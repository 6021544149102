import { useEffect } from "react";

import { Container } from "@mui/material";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { getAboutUsData } from "../../../redux/actions/cmsActions";
import styles from "../cmsComponent.module.css";
import Banner from "./section/Banner";
import TopBanner from "./section/TopBanner";
import { RootState } from "../../../../redux/reducers";

const index = () => {
  const dispatch = useDispatch();

  // data from kyc reducer
  const { aboutUsData = [] } = useSelector(
    (state: RootState) => state.cmsDataReducer
  );

  useEffect(() => {
    dispatch(getAboutUsData());
  }, []);

  return (
    <>
      <Helmet>
        <title>Freedo: Bike Rentals | Two Wheelers Rentals App</title>
        <meta
          name="description"
          content="Freedo rentals offers scooter & bike rentals in Gurgaon online at cheap prices. Wide Range of two wheelers at affordable hourly rates."
        />
        <meta name="author" content="Freedo Rentals" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Bike Rentals in Gurgaon | Bike For Rent in Gurgaon"
        />
        <meta
          property="og:description"
          content="Freedo rentals offers scooter & bike rentals in Gurgaon online at cheap prices. Wide Range of two wheelers at affordable hourly rates."
        />
        <meta property="og:url" content="https://freedo.rentals/gurgaon" />
        <meta
          property="og:image"
          content="https://freedo.rentals/page image url"
        />
        <meta property="og:site_name" content="Freedo Rentals" />
        <link rel="canonical" href="https://freedo.rentals/aboutus/" />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <TopBanner
        data={Object?.keys(aboutUsData).length && aboutUsData[0]["section1"]}
      />
      <Container className={styles.containerBanner}>
        {aboutUsData?.length &&
          Object?.keys(aboutUsData[0]).length &&
          Object?.keys(aboutUsData[0])?.map((key: string) => {
            if (key.includes("section1")) {
              return;
            } else {
              if (key.includes("section")) {
                return <Banner item={aboutUsData[0][key]} />;
              } else {
                return;
              }
            }
          })}
      </Container>
    </>
  );
};

export default index;
