import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Fragment } from "react";
import { ITermsAndConditionsModal } from "../../../../interfaces/operatorInterface";
import en from "../../../../locales/en.json";
import { colors } from "../../../../themes/colors";
import { CustomButton } from "../../../../yor-src/components/atom/Buttons/CustomButtons";
import CustomScrollbarStyle from "../../../../yor-src/components/atom/CustomScrollbar/customScrollbar.module.css";
import { StyleObject } from "./StyleObject";

const { operator, global } = en;

const TermsAndConditionsModal = (props: ITermsAndConditionsModal) => {
  const {
    isMobile,
    showModal,
    handleModal,
    isChecked,
    handleAgree,
    handleDisagree,
  } = props;

  return (
    <Fragment>
      <Box display="flex" alignItems="center" onClick={() => handleModal()}>
        <Checkbox checked={isChecked} />
        <Typography
          variant="body2"
          sx={{
            ...StyleObject?.cursorPointer,
            textDecoration: isChecked ? "none" : "underline",
          }}
        >
          {operator?.tnCApply}
        </Typography>
      </Box>

      <Dialog
        open={showModal}
        sx={{ width: isMobile ? 9 / 10 : 1 / 2, mx: "auto" }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
          variant="headingNoPadding"
          color={colors?.THEME_BLUE}
        >
          {global?.tnc}
        </DialogTitle>

        <DialogContent
          sx={{ height: 300 }}
          className={CustomScrollbarStyle.customScrollbar}
        >
          <DialogContentText
            variant="body1"
            sx={StyleObject?.yourAgreementText}
          >
            {operator?.yourAgreement}
          </DialogContentText>
          <DialogContentText>
            Let Google help apps determine location. This means sending
            anonymous location data to Google, even when no apps are running.Let
            Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.Let Google
            help apps determine location. This means sending anonymous location
            data to Google, even when no apps are running.Let Google help apps
            determine location. This means sending anonymous location data to
            Google, even when no apps are running.Let Google help apps determine
            location. This means sending anonymous location data to Google, even
            when no apps are running.Let Google help apps determine location.
            This means sending anonymous location data to Google, even when no
            apps are running.Let Google help apps determine location. This means
            sending anonymous location data to Google, even when no apps are
            running.Let Google help apps determine location. This means sending
            anonymous location data to Google, even when no apps are running.Let
            Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.Let Google
            help apps determine location. This means sending anonymous location
            data to Google, even when no apps are running.Let Google help apps
            determine location. This means sending anonymous location data to
            Google, even when no apps are running.Let Google help apps determine
            location. This means sending anonymous location data to Google, even
            when no apps are running.Let Google help apps determine location.
            This means sending anonymous location data to Google, even when no
            apps are running.Let Google help apps determine location. This means
            sending anonymous location data to Google, even when no apps are
            running.Let Google help apps determine location. This means sending
            anonymous location data to Google, even when no apps are running.Let
            Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CustomButton
            variant="text"
            onClick={handleDisagree}
            sx={{ color: colors?.error, borderRadius: "5px" }}
          >
            {global?.disagree}
          </CustomButton>
          <CustomButton
            onClick={handleAgree}
            variant="contained"
            sx={{ borderRadius: "5px" }}
          >
            {global?.agree}
          </CustomButton>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default TermsAndConditionsModal;
