import { USER_ADDRESSES_ACTIONS } from "../actionTypes";

//action for add new address
export const addNewAddress = (payload: any) => {
  return {
    type: USER_ADDRESSES_ACTIONS.ADD_NEW_ADDRESS_REQUESTED,
    payload,
  };
};

//action for update User Address
export const updateUserAddress = (payload: any) => {
  return {
    type: USER_ADDRESSES_ACTIONS.UPDATE_ADDRESS_REQUESTED,
    payload,
  };
};

//action for delete User Address
export const deleteUserAddress = (payload: any) => {
  return {
    type: USER_ADDRESSES_ACTIONS.DELETE_ADDRESS_REQUESTED,
    payload,
  };
};

//action for clear User Address
export const clearNewAddress = () => {
  return {
    type: USER_ADDRESSES_ACTIONS.CLEAR_NEW_ADDRESS,
  };
};
