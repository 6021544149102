import { OPERATOR_ACTIONS } from "../actionTypes";

const {
  GET_MASTER_CITY_REQUESTED,
  OPERATOR_LEAD_GENERATION_REQUESTED,
  OPERATOR_LEAD_VERIFICATION_REQUESTED,
  RETRY_OTP_FOR_OPERATOR_REQUESTED,
  VERIFY_OPERATOR_EMAIL_REQUESTED,
  FETCH_ESIGN_DOC_REQUESTED,
  ESIGN_GENERATE_OTP_REQUESTED,
  ESIGN_VERIFY_OTP_REQUESTED,
  ESIGN_RESEND_OTP_REQUESTED,
} = OPERATOR_ACTIONS;

// Master City List
export const getMasterCityListAction = () => {
  return { type: GET_MASTER_CITY_REQUESTED };
};

// Lead Generation
export const operatorLeadGenerationAction = (
  payload: any,
  apiSuccessFunction?: Function | undefined
) => {
  return {
    type: OPERATOR_LEAD_GENERATION_REQUESTED,
    payload,
    apiSuccessFunction,
  };
};

// Lead Verification
export const operatorLeadVerificationAction = (
  payload: any,
  apiSuccessFunction?: Function | undefined
) => {
  return {
    type: OPERATOR_LEAD_VERIFICATION_REQUESTED,
    payload,
    apiSuccessFunction,
  };
};

// Retry OTP For Operator
export const retryOtpForOperatorAction = (
  payload: any,
  apiSuccessFunction?: Function | undefined
) => {
  return {
    type: RETRY_OTP_FOR_OPERATOR_REQUESTED,
    payload,
    apiSuccessFunction,
  };
};

// Verify Operator Email
export const verifyOperatorEmailAction = (
  payload: any,
  apiSuccessFunction?: Function | undefined
) => {
  return {
    type: VERIFY_OPERATOR_EMAIL_REQUESTED,
    payload,
    apiSuccessFunction,
  };
};

// Fetch Esign Doc
export const fetchESignDocAction = (
  payload: any,
  apiSuccessFunction?: Function | undefined
) => {
  return {
    type: FETCH_ESIGN_DOC_REQUESTED,
    payload,
    apiSuccessFunction,
  };
};

// Esign Generate OTP
export const eSignGenerateOtpAction = (
  payload: any,
  apiSuccessFunction?: Function | undefined
) => {
  return {
    type: ESIGN_GENERATE_OTP_REQUESTED,
    payload,
    apiSuccessFunction,
  };
};

// Esign Verify OTP
export const eSignVerifyOtpAction = (
  payload: any,
  apiSuccessFunction?: Function | undefined
) => {
  return {
    type: ESIGN_VERIFY_OTP_REQUESTED,
    payload,
    apiSuccessFunction,
  };
};

// Esign Resend OTP
export const eSignResendOtpAction = (
  payload: any,
  apiSuccessFunction?: Function | undefined
) => {
  return {
    type: ESIGN_RESEND_OTP_REQUESTED,
    payload,
    apiSuccessFunction,
  };
};
