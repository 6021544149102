import { call, put, takeLatest } from "redux-saga/effects";
import { actionInterface } from "../../interfaces/commonInterface";
import { AUTH_ACTIONS, PROFILE_ACTIONS } from "../actions/actionTypes";
import { GenericObject, handleMessage } from "../../../utills/helper";
import { profileService } from "../services";
import { apiStatusCode, EmergencyContactStatus } from "../../constants/enum";
import { toastr } from "react-redux-toastr";

/* update user profile data */
function* updateProfile(action: actionInterface): any {
  try {
    const response: GenericObject = yield call(
      profileService.updateProfile,
      action?.payload
    );

    if (
      response.status === apiStatusCode.SUCCESS ||
      response.status === apiStatusCode.CREATED
    ) {
      yield put({
        type: PROFILE_ACTIONS.UPDATE_USER_PROFILE_SUCCESS,
        payload: response?.data?.data,
      });

      yield put({
        type: AUTH_ACTIONS.GET_USER_PROFILE_DATA_REQUESTED,
        payload: {
          customerId: action?.payload?.customerId,
          apiParams: { ipi: true },
        },
      });
    } else {
      yield put({
        type: PROFILE_ACTIONS.UPDATE_USER_PROFILE_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: PROFILE_ACTIONS.UPDATE_USER_PROFILE_FAILED,
    });
  }
}

/* update user profile image data */
function* updateProfileImage(action: actionInterface): any {
  try {
    const response: GenericObject = yield call(
      profileService.updateProfileImage,
      action?.payload
    );

    if (
      response.status === apiStatusCode.SUCCESS ||
      response.status === apiStatusCode.CREATED
    ) {
      yield put({
        type: PROFILE_ACTIONS.UPDATE_USER_PROFILE_IMAGE_SUCCESS,
        payload: response?.data?.data,
      });

      action.payload?.successCallback && action.payload?.successCallback();

      yield put({
        type: AUTH_ACTIONS.GET_USER_PROFILE_DATA_REQUESTED,
        payload: {
          customerId: action?.payload?.customerId,
          apiParams: { ipi: true },
        },
      });
    } else {
      yield put({
        type: PROFILE_ACTIONS.UPDATE_USER_PROFILE_IMAGE_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: PROFILE_ACTIONS.UPDATE_USER_PROFILE_IMAGE_FAILED,
    });
  }
}

/* get emergency contact details */
function* getEmergencyContactDetails(action: actionInterface): any {
  try {
    const response: GenericObject = yield call(
      profileService.getEmergencyContactDetails,
      action?.payload
    );

    if (
      response.status === apiStatusCode.SUCCESS ||
      response.status === apiStatusCode.CREATED
    ) {
      yield put({
        type: PROFILE_ACTIONS.GET_EMERGENCY_CONTACT_DETAILS_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      yield put({
        type: PROFILE_ACTIONS.GET_EMERGENCY_CONTACT_DETAILS_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: PROFILE_ACTIONS.GET_EMERGENCY_CONTACT_DETAILS_FAILED,
    });
  }
}

/* add emergency contact details */
function* addEmergencyContactDetails(action: actionInterface): any {
  try {
    const response: GenericObject = yield call(
      profileService.addEmergencyContactDetails,
      action?.payload
    );

    if (
      response.status === apiStatusCode.SUCCESS ||
      response.status === apiStatusCode.CREATED
    ) {
      yield put({
        type: PROFILE_ACTIONS.ADD_EMERGENCY_CONTACT_DETAILS_SUCCESS,
        payload: response?.data?.data,
      });

      /* call to an generate otp api if user update details only if status is unverified */
      if (response?.data?.data?.status === EmergencyContactStatus.UNVERIFIED) {
        yield put({
          type: PROFILE_ACTIONS.GENERATE_OTP_EMERGENCY_CONTACT_REQUESTED,
          payload: {
            customerId: action.payload.customerId,
            emergencyContactId: response?.data?.data?.id,
          },
        });
      }

      /* after addition of emergency data success get api call */
      yield put({
        type: PROFILE_ACTIONS.GET_EMERGENCY_CONTACT_DETAILS_REQUESTED,
        payload: {
          customerId: action.payload.customerId,
        },
      });
    } else {
      yield put({
        type: PROFILE_ACTIONS.ADD_EMERGENCY_CONTACT_DETAILS_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: PROFILE_ACTIONS.ADD_EMERGENCY_CONTACT_DETAILS_FAILED,
    });
  }
}

/*update emergency contact details */
function* updateEmergencyContactDetails(action: actionInterface): any {
  try {
    const response: GenericObject = yield call(
      profileService.updateEmergencyContactDetails,
      action?.payload
    );

    if (
      response.status === apiStatusCode.SUCCESS ||
      response.status === apiStatusCode.CREATED
    ) {
      yield put({
        type: PROFILE_ACTIONS.UPDATE_EMERGENCY_CONTACT_DETAILS_SUCCESS,
        payload: response?.data?.data,
      });

      /* call to an generate otp api if user update details only if status is unverified */
      if (response?.data?.data?.status === EmergencyContactStatus.UNVERIFIED) {
        yield put({
          type: PROFILE_ACTIONS.GENERATE_OTP_EMERGENCY_CONTACT_REQUESTED,
          payload: {
            customerId: action.payload.customerId,
            emergencyContactId: response?.data?.data?.id,
          },
        });
      }

      /* after updation of emergency data success get api call */
      yield put({
        type: PROFILE_ACTIONS.GET_EMERGENCY_CONTACT_DETAILS_REQUESTED,
        payload: {
          customerId: action.payload?.customerId,
        },
      });
    } else {
      yield put({
        type: PROFILE_ACTIONS.UPDATE_EMERGENCY_CONTACT_DETAILS_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: PROFILE_ACTIONS.UPDATE_EMERGENCY_CONTACT_DETAILS_FAILED,
    });
  }
}

/*  generate otp emergency contact details */
function* generateOtpEmergencyContact(action: actionInterface): any {
  try {
    const response: GenericObject = yield call(
      profileService.generateOtpEmergencyContact,
      action?.payload
    );

    if (
      response.status === apiStatusCode.SUCCESS ||
      response.status === apiStatusCode.CREATED
    ) {
      yield put({
        type: PROFILE_ACTIONS.GENERATE_OTP_EMERGENCY_CONTACT_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      yield put({
        type: PROFILE_ACTIONS.GENERATE_OTP_EMERGENCY_CONTACT_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: PROFILE_ACTIONS.GENERATE_OTP_EMERGENCY_CONTACT_FAILED,
    });
  }
}

/*  resend otp emergency contact details */
function* resendOtpEmergencyContact(action: actionInterface): any {
  try {
    const response: GenericObject = yield call(
      profileService.resendOtpEmergencyContact,
      action?.payload
    );

    if (
      response.status === apiStatusCode.SUCCESS ||
      response.status === apiStatusCode.CREATED
    ) {
      yield put({
        type: PROFILE_ACTIONS.RESEND_OTP_EMERGENCY_CONTACT_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      yield put({
        type: PROFILE_ACTIONS.RESEND_OTP_EMERGENCY_CONTACT_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: PROFILE_ACTIONS.RESEND_OTP_EMERGENCY_CONTACT_FAILED,
    });
  }
}

/*otp verification emergency contact details */
function* otpVerificationEmergencyContact(action: actionInterface): any {
  try {
    const response: GenericObject = yield call(
      profileService.otpVerificationEmergencyContact,
      action?.payload
    );

    if (
      response.status === apiStatusCode.SUCCESS ||
      response.status === apiStatusCode.CREATED
    ) {
      yield put({
        type: PROFILE_ACTIONS.OTP_VERIFICATION_EMERGENCY_CONTACT_SUCCESS,
        payload: response?.data?.data,
      });
      toastr.success("", handleMessage(response));

      /* after verification  of otp success get api call */
      yield put({
        type: PROFILE_ACTIONS.GET_EMERGENCY_CONTACT_DETAILS_REQUESTED,
        payload: {
          customerId: action.payload?.customerId,
        },
      });
    } else {
      yield put({
        type: PROFILE_ACTIONS.OTP_VERIFICATION_EMERGENCY_CONTACT_FAILED,
      });
      toastr.error("", handleMessage(response));
    }
  } catch (error) {
    yield put({
      type: PROFILE_ACTIONS.OTP_VERIFICATION_EMERGENCY_CONTACT_FAILED,
    });
    toastr.error("", handleMessage(error));
  }
}

function* newProfileSaga() {
  yield takeLatest(
    PROFILE_ACTIONS.UPDATE_USER_PROFILE_REQUESTED,
    updateProfile
  );

  yield takeLatest(
    PROFILE_ACTIONS.UPDATE_USER_PROFILE_IMAGE_REQUESTED,
    updateProfileImage
  );

  yield takeLatest(
    PROFILE_ACTIONS.GET_EMERGENCY_CONTACT_DETAILS_REQUESTED,
    getEmergencyContactDetails
  );

  yield takeLatest(
    PROFILE_ACTIONS.ADD_EMERGENCY_CONTACT_DETAILS_REQUESTED,
    addEmergencyContactDetails
  );

  yield takeLatest(
    PROFILE_ACTIONS.UPDATE_EMERGENCY_CONTACT_DETAILS_REQUESTED,
    updateEmergencyContactDetails
  );

  yield takeLatest(
    PROFILE_ACTIONS.GENERATE_OTP_EMERGENCY_CONTACT_REQUESTED,
    generateOtpEmergencyContact
  );

  yield takeLatest(
    PROFILE_ACTIONS.RESEND_OTP_EMERGENCY_CONTACT_REQUESTED,
    resendOtpEmergencyContact
  );

  yield takeLatest(
    PROFILE_ACTIONS.OTP_VERIFICATION_EMERGENCY_CONTACT_REQUESTED,
    otpVerificationEmergencyContact
  );
}

export default newProfileSaga;
