import CloseIcon from "@mui/icons-material/Close";
import { Stack, Tooltip, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import * as React from "react";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "0.5rem",
  boxShadow: 24,
  pb: 4,
  padding: "20px 15px",
  maxHeight: "80vh",
  marginTop: "10vh",
  overflow: "auto",
};

const scrollableStyle = {
  overflowY: "auto",
  paddingRight: "0px",
  "&::-webkit-scrollbar": {
    width: "2px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#dcdcdc",
    borderRadius: "20px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#a8a8a8",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#f9f9f9",
    borderRadius: "10px",
  },
  "&:hover::-webkit-scrollbar": {
    display: "block",
  },
};

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  title: string;
  children?: React.ReactNode;
  containerStyle?: {};
  modalWidth?: string;
  showCloseIcon?: boolean;
}

export default function GenericModal(props: Props) {
  const isDesktop = useMediaQuery("(min-width:1200px)");
  const isIpad = useMediaQuery("(min-width:1000px)");

  const {
    isOpen,
    handleClose,
    title = "",
    containerStyle = {},
    modalWidth,
    showCloseIcon = true,
  } = props;

  return (
    <Box
      onClick={(e: any) => {
        e.stopPropagation();
      }}
    >
      <Modal
        style={{
          // overflow: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={isOpen}
        onClose={handleClose}
        disableAutoFocus={true}
        closeAfterTransition
      >
        <Fade in={isOpen}>
          <Stack
            sx={{
              ...style,
              ...containerStyle,
              width: modalWidth
                ? modalWidth
                : isDesktop
                ? "60vw"
                : isIpad
                ? "70vw"
                : "90vw",
            }}
            gap="20px"
            justifyContent={"space-between"}
            className="generic-model-content"
          >
            <Stack direction="row" justifyContent={"space-between"}>
              <Typography className="generic-modal-title" variant={"h5"}>
                {title}
              </Typography>
              <Tooltip title={"close"}>
                {showCloseIcon ? (
                  <Box
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={handleClose}
                  >
                    <CloseIcon />
                  </Box>
                ) : (
                  <></>
                )}
              </Tooltip>
            </Stack>
            <Box sx={scrollableStyle}>{props.children}</Box>
          </Stack>
        </Fade>
      </Modal>
    </Box>
  );
}
