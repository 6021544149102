import { call, put, takeLatest } from "redux-saga/effects";
import { MASTER_ACTIONS } from "../actions/actionTypes";
import commonService from "../services/commonService";
import { toastr } from "react-redux-toastr";
import { handleMessage } from "../../../utills/helper";

//get all addresses
function* getAllAddresses(action: any) {
  try {
    const data = yield call(commonService.getUsersAllAddresses, action.payload);
    if (data.status === 200) {
      yield put({
        type: MASTER_ACTIONS.GET_ALL_ADDRESSES_SUCCESS,
        payload: data?.data?.data?.addresses,
      });
    } else {
      yield put({
        type: MASTER_ACTIONS.GET_ALL_ADDRESSES_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (err) {
    toastr.error("", handleMessage(err));
    yield put({
      type: MASTER_ACTIONS.GET_ALL_ADDRESSES_FAILED,
    });
  }
}

//get issue category list saga
function* issueCategoryListSaga(action: any) {
  try {
    const response = yield call(commonService.issueCategoryListService);
    if (response.status === 200) {
      yield put({
        type: MASTER_ACTIONS.GET_ISSUE_CATEGORY_LIST_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      yield put({
        type: MASTER_ACTIONS.GET_ISSUE_CATEGORY_LIST_FAILED,
      });
      toastr.error("", handleMessage(response));
    }
  } catch (err) {
    toastr.error("", handleMessage(err));
    yield put({
      type: MASTER_ACTIONS.GET_ISSUE_CATEGORY_LIST_FAILED,
    });
  }
}
function* MasterSaga() {
  yield takeLatest(MASTER_ACTIONS.GET_ALL_ADDRESSES_REQUESTED, getAllAddresses);
  yield takeLatest(
    MASTER_ACTIONS.GET_ISSUE_CATEGORY_LIST_REQUESTED,
    issueCategoryListSaga
  );
}

export default MasterSaga;
