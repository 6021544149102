import { signInbackground } from "../../../assets";

const authStyles = {
  containerStyle: {
    background: `linear-gradient(
      180deg,
      rgba(242, 242, 242, 0.454) 41%,
      rgba(43, 198, 201, 0.331) 68%
    ),
   url(${signInbackground}) no-repeat center`,
    display: "flex",
    height: "100vh",
    width: "100%",
    backgroundSize: "cover",
    maxWidth: "100%",
    overflowY: "scroll",
  },
  mandatoryStyle: { color: "red", fontSize: "18px" },
};

export default authStyles;
