import * as React from "react";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { Container, Stack, styled } from "@mui/material";
import { colors } from "../../../../themes/colors";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { AccordianInterface } from "../../../../interfaces/actionInterfaces";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    square
    {...props}
    slotProps={{ transition: { unmountOnExit: true } }}
  />
))(({ theme }) => ({
  // boxShadow: "0 5px 20px -2px #7090b038",
  margin: "20px 20px 30px",
  border: "none",
  borderRadius: "10px",
  backgroundColor: colors.neon_white,
  "&:not(:last-child)": {
    borderRadius: "10px",
  },
  "&::before": {
    display: "none",
  },
}));

export default function UncontrolledAccordian({
  accordianDataArray = [],
  titleKey = "question",
  contentKey = "answer",
}: AccordianInterface) {
  return (
    <>
      {accordianDataArray &&
        accordianDataArray?.map((item, index) => {
          const { title, Content, defaultExpanded } = item;
          return (
            <>
              <Accordion sx={{ m: 0 }} defaultExpanded={defaultExpanded} key={index}>
                <AccordionSummary
                  expandIcon={
                    // isExpanded ? (
                    //   <RemoveCircleIcon sx={{ color: colors.primary }} />
                    // ) : (
                    <AddCircleIcon sx={{ color: colors.primary }} />
                    // )
                  }
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                  sx={{ padding: 0 }}
                >
                  <Typography component="span" className="underlined-header">{title}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>{Content()}</AccordionDetails>
              </Accordion>
            </>
          );
        })}
    </>
  );
}
