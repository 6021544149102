// This is the css style objects that will be provided to "sx" prop of the mui components.

import { bannerImageOperatorModule } from "../../../../assets";
import { GenericObject } from "../../../../interfaces/commonInterface";
import { colors } from "../../../../themes/colors";

export const StyleObject: GenericObject = {
  leftSectionTopBox: {
    mx: "auto",
  },
  leftSectionSecondBox: {
    mx: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: 2,
  },
  textAlignCenter: { textAlign: "center" },
  cursorPointer: { cursor: "pointer" },
  customStyle: { boxShadow: "unset", minHeight: "50px" },
  muiTextFieldRootStyle: {
    width: 1,
    minWidth: "unset",
    "& .MuiOutlinedInput-root": { borderRadius: "30px" },
  },
  yourAgreementText: {
    fontWeight: 600,
    pb: 1,
  },
  joinUsButton: {
    borderRadius: 20,
    width: 1,
    height: 50,
    zIndex: 99,
  },
  imageStyle: {
    height: "600px",
    width: "100%",
    padding: "unset",
  },
  scrollableBox: {
    display: "flex",
    overflowX: "scroll", // Use scroll for functionality but hide scrollbar
    padding: "16px",
    scrollbarWidth: "none", // Hide scrollbar in Firefox
    "&::-webkit-scrollbar": {
      display: "none", // Hide scrollbar in WebKit browsers (Chrome, Safari)
    },
  },
  cardStyle: {
    minWidth: 300,
    maxWidth: 300,
    minHeight: 200,
    maxHeight: 200,
    marginRight: 2,
    borderRadius: "3px",
    boxShadow: 0,
    backgroundColor: "#fafafa",
  },
  imageIconStyle: {
    width: "40px",
    height: "40px",
  },
  gridContainer: {
    position: "absolute",
    width: 1,
  },
  truncatedTitleText: {
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    pb: 1,
  },
  truncatedDescriptionText: {
    display: "-webkit-box",
    WebkitLineClamp: 3, // Show up to 3 lines
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  topBox: {
    py: 5,
    backgroundColor: "#f5feff",
  },
  alignItemsCenter: { alignItems: "center" },
  howToJoinText: {
    textAlign: "center",
    py: 5,
  },
  boxTop: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardTop: {
    minWidth: 300,
    maxWidth: 300,
    minHeight: 200,
    maxHeight: 200,
    marginRight: 2,
  },
  boxxTop: {
    minHeight: "400px", // Full viewport height
    backgroundImage: `url(${bannerImageOperatorModule})`, // Background image
    backgroundSize: "cover", // Cover the entire box
    backgroundPosition: "center", // Center the image
    display: "flex",
    flexDirection: "column",
    justifyContent: "center", // Vertically center
    alignItems: "center", // Horizontally center
    textAlign: "center",
    color: "#fff", // White text for visibility
    padding: "50px",
  },
  customBtn: {
    color: colors?.black,
    borderRadius: 20,
    width: 1,
    height: 50,
  },
  boxStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "linear-gradient(to right, #ecfdf9, #fbfcfc)",
    padding: "50px 0",
  },
  faqText: {
    fontSize: "30px",
    borderBottom: "2px solid #2bc7c9",
    paddingBottom: "3px",
    marginBottom: "50px",
  },
  accordianStyle: {
    marginBottom: "15px",
    borderRadius: "10px",
    backgroundColor: "#f5feff",
  },
  accordionSummaryStyle: {
    backgroundColor: "#f5feff",
    padding: "10px 15px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "space-between",
  },
};
