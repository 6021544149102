import React from "react";

function FullFaceHelmetIcon() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 22 22"
        fill="none"
      >
        <path
          d="M17.75 7.79033C16.4 4.64033 13.6932 2.84033 10.4641 2.84033C5.78952 2.84033 2 6.65769 2 11.3667C2 14.4424 3.61671 17.1377 6.04079 18.6374C6.56235 18.9601 6.99198 19.0403 7.60391 19.0403H13.4867C13.4943 19.0403 13.502 19.0403 13.5096 19.0402M17.75 7.79033H18.8454C19.5 7.79033 19.8272 7.79033 19.9568 8.02908C20.0864 8.26781 19.9147 8.51722 19.5711 9.01602C19.0795 9.72983 18.3951 10.4903 17.7108 10.4903M17.75 7.79033H13.7624M17.7108 10.4903C14.8138 10.4903 12.5649 10.973 12.17 13.6431C12.1123 14.0333 12.0834 14.2283 12.2181 14.3844C12.468 14.674 13.1318 14.5403 13.4867 14.5403C14.7554 14.5403 15.7839 15.5477 15.7839 16.7903C15.7839 18.0255 14.7678 19.0282 13.5096 19.0402M17.7108 10.4903C19.6879 11.6903 20.4923 16.7903 19.5711 17.6903C18.65 18.5903 15.95 19.4903 13.5096 19.0402"
          stroke="#2BC7C9"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.7071 16.7905H13.699"
          stroke="#2BC7C9"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
}

export default FullFaceHelmetIcon;
