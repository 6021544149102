import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Container, Stack, Typography } from "@mui/material";
import {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Webcam from "react-webcam";
import {
  cameraFeatherProfile,
  cameraProfile,
  eyeFeatherProfile,
  settingTopBannerBike,
  uploadFeatherProfile,
  userPic,
  userVerified,
} from "../../../assets";
import en from "../../../locales/en.json";
import { RootState } from "../../../redux/reducers";
import { CustomButton } from "../../../sharedComponents/atom/Buttons/CustomButtons";
import ControlledAccordions from "../../../sharedComponents/molecule/Accordions/ControlledAccordions";
import GenericModal from "../../../sharedComponents/molecule/Modals/GenericModal";
import CustomPopover from "../../../sharedComponents/molecule/PopOver/CustomPopover";
import CustomVerticalTabs from "../../../sharedComponents/template/tabs/CustomVerticalTabs";
import { colors } from "../../../themes/colors";
import { GenericObject, isEqualObject, validate } from "../../../utills/helper";
import {
  EmergencyContactRelationShip,
  generateSignedUrlDocType,
  profileTabs,
  TypographyConstants,
} from "../../constants/enum";
import {
  emergencyFields,
  errEmergencyFields,
  imagePreview,
  profileFields,
  uploadProfileImage,
} from "../../interfaces/profileInterface";
import {
  addEmergencyContactDetails,
  generateOtpEmergencyContact,
  otpVerificationEmergencyContact,
  resendOtpEmergencyContact,
  setProfileReducerKeys,
  updateEmergencyContactDetails,
  updateProfile,
  updateProfileImage,
} from "../../redux/actions/profileActions";
import MyAddresses from "../SettingScreens/MyAddresses";
import MyBookingsScreen from "../SettingScreens/MyBookings";
import UserKycScreen from "../UserKyc";
import Styles from "./index.module.css";
import ProfileDetails from "./sections/ProfileDetails";
// import ProfileDetails from "./sections/ProfileDetails";
import { toastr } from "react-redux-toastr";
import {
  clearUserKycReducerData,
  getKycAttachmentIds,
} from "../../redux/actions";

const {
  profileScreen,
  profile: { profileDetails },
} = en;

// array to handle query params
const keysArray: string[] = [
  profileTabs.DETAILS,
  profileTabs.KYC,
  profileTabs.BOOKINGS,
  profileTabs.ADDRESSES,
];

// object for mobile view tabs (accordion)
const mobileTabKeys = {
  panel0: profileTabs.DETAILS,
  panel1: profileTabs.KYC,
  panel2: profileTabs.BOOKINGS,
  panel3: profileTabs.ADDRESSES,
};

enum profileOptionKey {
  VIEW_PHOTO = "VIEW_PHOTO",
  UPLOAD_NEW = "UPLOAD_NEW",
  TAKE_SELFIE = "TAKE_SELFIE",
}

/* profile images option array */
const profileOption: { id: string; icon: any; label: string }[] = [
  {
    id: profileOptionKey.VIEW_PHOTO,
    icon: eyeFeatherProfile,
    label: profileScreen.viewPhoto,
  },
  {
    id: profileOptionKey.UPLOAD_NEW,
    icon: uploadFeatherProfile,
    label: profileScreen.uploadNew,
  },
  {
    id: profileOptionKey.TAKE_SELFIE,
    icon: cameraFeatherProfile,
    label: profileScreen.takeSelfie,
  },
];

/* accordion data for mobile view */

/* Initial state object for fields */
const initialState: profileFields = {
  gender: "",
  emailId: "",
  profileImage: "",
};

/* Initial state object for error fields */
const errInitialState: profileFields = {
  gender: "",
  emailId: "",
  profileImage: "",
};

/* Initial state for emergency details fields */
const emergencyInitialState: emergencyFields = {
  name: "",
  relationship: { name: "", displayName: "" },
  mobileNumber: "",
  otp: "",
};

/* error initial state for emergency details fields */
const errEmergencyInitialState: errEmergencyFields = {
  name: "",
  relationship: "",
  mobileNumber: "",
};

enum previewModalKeys {
  IMAGE_PREVIEW = "IMAGE_PREVIEW",
  TAKE_SELFIE = "TAKE_SELFIE",
}

// rider realation array
const relationArray: { name: string; displayName: string }[] = [
  {
    name: EmergencyContactRelationShip.FAMILY,
    displayName: profileDetails.family,
  },
  {
    name: EmergencyContactRelationShip.FRIEND,
    displayName: profileDetails.friend,
  },
  {
    name: EmergencyContactRelationShip.COLLEAGUE,
    displayName: profileDetails.colleague,
  },
];

/* store emergency details copy */
let emergencyDetailsCopy: emergencyFields = {
  ...emergencyInitialState,
};

const UserProfile = () => {
  /* get and set web query params */
  const [params, setParams] = useSearchParams();

  const dispatch = useDispatch();

  /* data from auth reducer  */
  const { userProfileData = {} } = useSelector(
    (state: RootState) => state.newAuthReducer
  );

  /* data from kyc reducer  */
  const { uploadFileData = {} } = useSelector(
    (state: RootState) => state.userKycReducer
  );

  /* data from profile reducer  */
  const { emergencyContactDetails, generateOtpData, updateProfileImageLoader } =
    useSelector((state: RootState) => state.newProfileReducer);

  /* ref for profile image  */
  const profileImageRef = useRef<any>(null);

  /* web camera ref */
  const webcamRef = useRef<any>(null);

  /* state to handle tabs */
  const [value, setValue] = useState<number>(0);

  /* state to toggle profile image modal */
  const [openSelectionModal, setOpenSelectionModal] = useState<boolean>(false);

  /* state to handle toggle image preview and take selfie */
  const [imagePreview, setImagePreview] = useState<imagePreview>({
    open: false,
    previewModal: "",
  });

  /* state for uploaded profile image data */
  const [uploadProfileImage, setUploadProfileImage] =
    useState<uploadProfileImage>({
      profileImageUrl: "",
      openPreviewModal: false,
    });

  /* state to store profile data  */
  const [fields, setFields] = useState<profileFields>({
    ...initialState,
  });

  /* state to handle error fields */
  const [errFields, setErrFields] = useState<profileFields>({
    ...errInitialState,
  });

  /* duplicate state for comparision of data change */
  const [duplicateFields, setDuplicateFields] = useState<profileFields>({
    ...initialState,
  });

  /* state to store emergency details  */
  const [emergencyDetails, setEmergencyDetails] = useState<emergencyFields>({
    ...emergencyInitialState,
  });

  /* error state to store emergency details  */
  const [errEmergencyDetails, setErrEmergencyDetails] =
    useState<errEmergencyFields>({
      ...errEmergencyInitialState,
    });

  /* state to handle popover open close */
  const [profilePopover, setProfilePopover] = useState<boolean>(false);

  /* state for accordion expansion */
  const [expandedPanel, setExpandedPanel] = useState<string | false>(false);

  useEffect(() => {
    return () => {
      dispatch(
        setProfileReducerKeys({
          emergencyOtpScreen: false,
        })
      );
    };
  }, []);

  useEffect(() => {
    if (uploadFileData?.PROFILE_IMAGE && !uploadProfileImage.openPreviewModal) {
      setUploadProfileImage((prev: uploadProfileImage) => ({
        ...prev,
        openPreviewModal: true,
      }));
    }
  }, [uploadFileData?.PROFILE_IMAGE]);

  useEffect(() => {
    /* set user data to state if there is change in api data*/
    setUserData();
  }, [userProfileData]);

  // side effect to set tabs if change in routes
  useEffect(() => {
    const keyParams: string = params.get("key") ?? "";
    const keyindex: number = keysArray.findIndex(
      (key: string) => key === keyParams
    );

    if (keysArray.includes(keyParams) && (keyindex || keyindex === 0)) {
      setValue(keyindex);
      setExpandedPanel(`panel${keyindex}`);
    } else {
      /* if user enter wrong url tab set to profile details*/
      setValue(0);
      setParams((prev: any) => {
        prev.set("key", keysArray[0]);
        return prev;
      });
    }
  }, [params.get("key")]);

  /* side effect for emergency details */
  useEffect(() => {
    if (emergencyContactDetails?.length) {
      const contactDetail: emergencyFields = {
        name: emergencyContactDetails[0]?.name,
        mobileNumber: emergencyContactDetails[0]?.mobileNumber,
        relationship: relationArray?.filter(
          (item: any) => emergencyContactDetails[0]?.relationship === item?.name
        )[0] ?? { name: "", displayName: "" },
      };

      setEmergencyDetails({ ...contactDetail });
      emergencyDetailsCopy = contactDetail;
    }
  }, [emergencyContactDetails]);

  // set user profile data
  const setUserData = () => {
    if (Object.keys(userProfileData).length) {
      const data: profileFields = {
        gender: userProfileData.gender,
        emailId: userProfileData.email,
        profileImage: userProfileData?.profileImage,
      };

      setFields(data);
      setDuplicateFields(data);
    }
  };

  // handle change of tabs
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setParams((prev: any) => {
      prev.set("key", keysArray[newValue]);
      return prev;
    });
  };

  // handle change in radio group or button for gender
  const handleRadiochange = (event: ChangeEvent<HTMLInputElement>) => {
    setFields((prev: profileFields) => ({
      ...prev,
      gender: event.target.value,
    }));
  };

  /* handle change of fields */
  const handleChangeFields = (key: string, value: string | GenericObject) => {
    setFields((prev: profileFields) => ({ ...prev, [key]: value }));
    setErrFields((prev: profileFields) => ({ ...prev, [key]: "" }));
  };

  /* handle enable and disable of update profile button */
  const disableUpdateProfile = (): boolean =>
    isEqualObject(fields, duplicateFields);

  /* onClick update profile button */
  const handleUpdateProfile = () => {
    /* validate profile details fields */
    const validateFields = validate(fields, errFields, ["profileImage"]);

    if (validateFields.error) {
      return setErrFields({ ...errFields, ...validateFields.errorFields });
    }

    const payload: GenericObject = {
      customerId: userProfileData?.id,
      apiBody: {
        data: {
          gender:
            userProfileData.gender === fields.gender
              ? undefined
              : fields.gender,
          email:
            userProfileData.email === fields.emailId
              ? undefined
              : fields.emailId,
        },
      },
    };

    /* call to update profile api after validation success*/
    dispatch(updateProfile(payload));
  };

  /* toggle selection modal open close  */
  const toggleSelectionModal = () => setOpenSelectionModal(!openSelectionModal);

  /* toggle profile options popover */
  const toggleProfileOptionsPopover = () => setProfilePopover(!profilePopover);

  /* handle click of profile option */
  const handleProfileOptionClick = (id: string) => {
    if (id === profileOptionKey.VIEW_PHOTO) {
      setImagePreview((prev: imagePreview) => ({
        ...prev,
        open: true,
        previewModal: id,
      }));
    } else if (id === profileOptionKey.UPLOAD_NEW) {
      profileImageRef?.current?.click();
    } else {
      setImagePreview((prev: imagePreview) => ({
        ...prev,
        open: true,
        previewModal: id,
      }));
    }
    toggleProfileOptionsPopover();
  };

  /* handle change of profile image */
  const handleProfileImageChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {};

  //handle capture selfie
  const handleCaptureSelfie = useCallback(() => {
    const imageSrc = webcamRef?.current?.getScreenshot();

    if (!imageSrc) {
      return;
    }
    setImagePreview((prev: any) => {
      return {
        ...prev,
        src: imageSrc,
        fileName: "MyProfile.jpeg",
      };
    });
    // callGetAttachment(imageSrc, "MySelfie.jpeg");
    // toggleSelfieModal();
  }, [webcamRef, setImagePreview]);

  /* on click capture picture */
  const handleChangePictureClick = () => {
    setImagePreview((prev: imagePreview) => ({
      ...prev,
      open: false,
      previewModal: "",
    }));

    profileImageRef?.current?.click();
  };

  /* on change handler of aacordion */
  const onChangeHandler = (panel: string, isExpanded: string | boolean) => {
    setExpandedPanel(isExpanded ? panel : false);
    setParams((prev: any) => {
      prev.set("key", isExpanded ? mobileTabKeys[panel] : mobileTabKeys.panel0);
      return prev;
    });
  };

  /* handle change in emergency filelds */
  const handleChangeEmergency = (key: string, value: GenericObject) => {
    setEmergencyDetails((prev: emergencyFields) => ({ ...prev, [key]: value }));
    setErrEmergencyDetails((prev: errEmergencyFields) => ({
      ...prev,
      [key]: "",
    }));
  };

  /* on save emergency contact details */
  /* props */
  // key is to check add or update the emergency contact details api to be called
  const submitEmergencyDetails = (key: string) => {
    /* validate emergency contact details fields */
    const validateFields = validate(emergencyDetails, errEmergencyDetails);

    if (validateFields.error) {
      return setErrEmergencyDetails({
        ...errEmergencyDetails,
        ...validateFields.errorFields,
      });
    }

    if (key === "add") {
      /* call api to add emergency details */
      dispatch(
        addEmergencyContactDetails({
          customerId: userProfileData?.id,
          apiBody: {
            data: {
              name: emergencyDetails.name,
              relationship: emergencyDetails.relationship?.name,
              mobileNumber: emergencyDetails.mobileNumber,
            },
          },
        })
      );
    } else if (key === "update") {
      /* call api to update emergency details */
      dispatch(
        updateEmergencyContactDetails({
          customerId: emergencyContactDetails[0]?.customerId,
          emergencyContactId: emergencyContactDetails[0]?.id,
          apiBody: {
            /* only updated keys to api body */
            data: {
              name:
                emergencyDetails.name !== emergencyDetailsCopy.name
                  ? emergencyDetails.name
                  : undefined,
              relationship:
                emergencyDetails.relationship?.name !==
                emergencyDetailsCopy.relationship?.name
                  ? emergencyDetails.relationship
                  : undefined,
              mobileNumber:
                emergencyDetails.mobileNumber !==
                emergencyDetailsCopy.mobileNumber
                  ? emergencyDetails.mobileNumber
                  : undefined,
            },
          },
        })
      );
    }
  };

  /* on click verify now  */
  const onClickVerifyNow = () => {
    dispatch(
      generateOtpEmergencyContact({
        customerId: emergencyContactDetails[0]?.customerId,
        emergencyContactId: emergencyContactDetails[0]?.id,
      })
    );
  };

  /* on click resend otp in emergency details */
  const onClickResendOtp = () => {
    dispatch(
      resendOtpEmergencyContact({
        token: generateOtpData?.token,
      })
    );
  };

  /* on click verify otp emergency details */
  const onClickVerifyOtpEmergencyDetails = () => {
    if (generateOtpData?.token && emergencyDetails?.otp?.length === 6)
      dispatch(
        otpVerificationEmergencyContact({
          customerId: userProfileData?.id,
          apiBody: {
            data: {
              token: generateOtpData?.token,
              otp: emergencyDetails?.otp,
            },
          },
        })
      );
  };

  //call get attchment id
  const getAttachmentIds = (file: any, fileName: string | undefined) => {
    const payload = {
      apiPayload: {
        data: {
          attachments: [fileName],
          type: generateSignedUrlDocType.PROFILE_IMAGE,
        },
      },
      fileData: file,
      submitPayload: {
        type: generateSignedUrlDocType.PROFILE_IMAGE,
      },
    };

    dispatch(getKycAttachmentIds(payload));
  };

  //handle upload profile image click
  const handleUploadProfileImgChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    validTypes: string[]
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      // Check for valid file type
      if (!validTypes.includes(file.type)) {
        toastr.error("", "Please upload a valid file");
        return;
      }
      // Check for file size (5MB = 5 * 1024 * 1024 bytes)
      if (file.size > 5 * 1024 * 1024) {
        toastr.error("", "File size must be less than 5MB");
        return;
      }
      const imageUrl = URL.createObjectURL(file);

      setUploadProfileImage((prev: uploadProfileImage) => ({
        ...prev,
        profileImageUrl: imageUrl,
      }));

      getAttachmentIds(file, file?.name);
    }
  };

  // on click submit profile image
  const handleSubmitProfileImage = () => {
    if (uploadFileData?.PROFILE_IMAGE) {
      const payload = {
        customerId: userProfileData?.id,
        attachmentId: uploadFileData?.PROFILE_IMAGE,
        successCallback: onClickCancel,
      };
      dispatch(updateProfileImage(payload));
    }
  };

  /* on click cancel upload profile image */
  const onClickCancel = () => {
    setUploadProfileImage((prev: uploadProfileImage) => ({
      ...prev,
      openPreviewModal: false,
    }));

    const { PROFILE_IMAGE, ...rest } = uploadFileData;
    dispatch(
      clearUserKycReducerData({
        uploadFileData: { ...rest },
      })
    );
  };

  /* tabs data for desktop view */
  const tabData: { label: string; content: ReactElement }[] = [
    {
      label: profileScreen?.myProfile,
      content: (
        <ProfileDetails
          onClickUpdateHere={handleChange}
          handleRadiochange={handleRadiochange}
          fields={fields}
          duplicateFields={duplicateFields}
          handleChangeFields={handleChangeFields}
          disableUpdateProfile={disableUpdateProfile}
          handleUpdateProfile={handleUpdateProfile}
          errFields={errFields}
          emergencyDetails={emergencyDetails}
          handleChangeEmergency={handleChangeEmergency}
          submitEmergencyDetails={submitEmergencyDetails}
          errEmergencyDetails={errEmergencyDetails}
          onClickVerifyNow={onClickVerifyNow}
          emergencyDetailsCopy={emergencyDetailsCopy}
          onClickVerifyOtpEmergencyDetails={onClickVerifyOtpEmergencyDetails}
          onClickResendOtp={onClickResendOtp}
        />
      ),
    },
    { label: profileScreen?.kyc, content: <UserKycScreen /> },
    { label: profileScreen?.myBookings, content: <MyBookingsScreen /> },
    {
      label: profileScreen?.myAddresses,
      content: <MyAddresses />,
    },
  ];

  /* tabs data for mobile view */
  const accordionData: { title: ReactElement; content: ReactElement }[] = [
    {
      title: (
        <Typography variant={TypographyConstants.SUBTITLE1}>
          {profileScreen?.myProfile}
        </Typography>
      ),
      content: (
        <ProfileDetails
          onClickUpdateHere={handleChange}
          handleRadiochange={handleRadiochange}
          fields={fields}
          duplicateFields={duplicateFields}
          handleChangeFields={handleChangeFields}
          disableUpdateProfile={disableUpdateProfile}
          handleUpdateProfile={handleUpdateProfile}
          errFields={errFields}
          emergencyDetails={emergencyDetails}
          handleChangeEmergency={handleChangeEmergency}
          submitEmergencyDetails={submitEmergencyDetails}
          errEmergencyDetails={errEmergencyDetails}
          onClickVerifyNow={onClickVerifyNow}
          emergencyDetailsCopy={emergencyDetailsCopy}
          onClickVerifyOtpEmergencyDetails={onClickVerifyOtpEmergencyDetails}
          onClickResendOtp={onClickResendOtp}
        />
      ),
    },
    {
      title: (
        <Typography variant={TypographyConstants.SUBTITLE1}>
          {profileScreen?.kyc}
        </Typography>
      ),
      content: <UserKycScreen />,
    },
    {
      title: (
        <Typography variant={TypographyConstants.SUBTITLE1}>
          {profileScreen?.myBookings}
        </Typography>
      ),
      content: <MyBookingsScreen />,
    },
    {
      title: (
        <Typography variant={TypographyConstants.SUBTITLE1}>
          {profileScreen?.myAddresses}
        </Typography>
      ),
      content: <MyAddresses />,
    },
  ];

  return (
    <Container sx={{ paddingTop: "20px" }}>
      <Stack sx={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
        {/* header section (profile image and name)*/}
        <Box className={Styles.profileTopBanner}>
          <CustomPopover
            open={profilePopover}
            onClose={toggleProfileOptionsPopover}
            popoverContent={
              <Stack gap={"8px"}>
                {profileOption?.map((option: GenericObject) => (
                  <Box
                    sx={{
                      display: "flex",
                      gap: "12px",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => handleProfileOptionClick(option.id)}
                  >
                    <img
                      src={option.icon}
                      style={{ width: "18px", height: "14px" }}
                    />
                    <Typography
                      variant="subtitle1"
                      sx={{ color: "#212529", fontWeight: 400 }}
                    >
                      {option.label}
                    </Typography>
                  </Box>
                ))}
              </Stack>
            }
          >
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box className={Styles.profileBox}>
                <img
                  src={
                    duplicateFields?.profileImage
                      ? duplicateFields.profileImage
                      : userPic
                  }
                  className={Styles.userProfileImage}
                  onClick={toggleSelectionModal}
                />
                <img
                  src={userVerified}
                  style={{ position: "absolute", left: "77px", top: "7px" }}
                />
                <Box className={Styles.overlay}>
                  <img
                    src={cameraProfile}
                    alt="camera icon"
                    style={{ width: "24px", height: "20px" }}
                  />
                  <Typography
                    variant={TypographyConstants.CAPTION}
                    sx={{ color: colors.white, textAlign: "center" }}
                  >
                    Change Profile Photo
                  </Typography>
                </Box>
              </Box>

              <Stack>
                <Typography
                  variant={TypographyConstants.H6}
                  sx={{ color: colors.secondary_black }}
                >
                  {`${userProfileData?.firstName} ${userProfileData?.lastName}`}
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ color: colors.secondary_black, fontWeight: 400 }}
                >
                  {profileScreen.rider}
                </Typography>
              </Stack>
            </Box>
          </CustomPopover>

          <Box>
            <img src={settingTopBannerBike} />
          </Box>
        </Box>

        {/* input for profile image selection */}
        <input
          ref={profileImageRef}
          type="file"
          style={{ display: "none" }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleUploadProfileImgChange(e, [
              "image/jpeg",
              "image/png",
              "image/jpg",
            ])
          }
          accept="image/*"
        />

        {/* desktop view tabs */}
        <Box display={{ xs: "none", md: "flex" }}>
          <CustomVerticalTabs
            tabs={tabData}
            value={value}
            handleChange={handleChange}
          />
        </Box>

        {/* Mobile view tab */}
        <Stack display={{ xs: "flex", md: "none" }}>
          <ControlledAccordions
            accordianDataArray={accordionData}
            expandedPanel={expandedPanel}
            onChangeHandler={onChangeHandler}
            expandIcon={<ExpandMoreIcon />}
            containerStyle={{
              margin: "0px",
              border: "1px solid #dee2e6",
              borderRadius: "6px",
            }}
            accordionStyle={{
              margin: "0px",
              "&.MuiAccordion-root": {
                boxShadow: "none",
                backgroundColor: "#ffffff !important",
                borderTopLeftRadius: "6px",
                borderTopRightRadius: "6px",
                borderBottomLeftRadius: "0px",
                borderBottomRightRadius: "0px",
                borderBottom: "1px solid #dee2e6",
              },
              "&:last-of-type": {
                borderBottom: "none",
                borderBottomLeftRadius: "6px",
                borderBottomRightRadius: "6px",
              },
            }}
            accordionSummaryStyle={{
              // borderBottom: "1px solid #dee2e6",
              "&.Mui-expanded": {
                backgroundColor: "#cfe2ff",
                borderBottom: "1px solid #dee2e6",
              },
              // "&:first-of-type.Mui-expanded": {
              //   borderTopLeftRadius: "6px",
              //   borderTopRightRadius: "6px",
              // },
            }}
          />
        </Stack>
      </Stack>

      {/* modal for selfie capture and preview already uploaded profile image */}
      <GenericModal
        isOpen={imagePreview.open}
        handleClose={() =>
          setImagePreview((prev: imagePreview) => ({
            ...prev,
            open: false,
            previewModal: "",
          }))
        }
        title={
          imagePreview?.previewModal === profileOptionKey.VIEW_PHOTO
            ? profileScreen.profilePicture
            : profileScreen?.pleaseTakeSelfie
        }
      >
        {imagePreview?.previewModal === profileOptionKey.VIEW_PHOTO ? (
          <Stack gap={"28px"} padding={"10px"} alignItems={"center"}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              width={{ xs: "70vw", sm: "30vw", md: "18vw" }}
            >
              <img
                src={
                  duplicateFields?.profileImage
                    ? duplicateFields.profileImage
                    : userPic
                }
                style={{ objectFit: "cover", width: "100%" }}
              />
            </Box>

            <CustomButton
              label={profileScreen.changePicture}
              variant="contained"
              onClick={handleChangePictureClick}
              wrapperStyles={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
              sx={{
                width: "100%",
                minHeight: "30px",
                maxWidth: "140px",
                borderRadius: "4px",
                padding: "0px",
              }}
            />
          </Stack>
        ) : (
          <Stack alignItems="center">
            <Webcam
              audio={false}
              height={700}
              width={1200}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              className={Styles.selfieCamera}
            />
            <CustomButton
              label="Capture"
              variant="contained"
              onClick={handleCaptureSelfie}
            />
          </Stack>
        )}
      </GenericModal>

      {/* modal to preview new uploaded profile image and also to save it */}
      <GenericModal
        isOpen={uploadProfileImage?.openPreviewModal}
        handleClose={() => {}}
        title={profileDetails.preview}
        showCloseIcon={false}
      >
        <Stack gap={"28px"} padding={"10px"} alignItems={"center"}>
          <Box className={Styles.uploadedImagePreview}>
            <img src={uploadProfileImage?.profileImageUrl} />
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
              justifyContent: "center",
            }}
          >
            <CustomButton
              label={profileScreen.cancel}
              variant="outlined"
              onClick={onClickCancel}
              wrapperStyles={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
              sx={{
                width: "100%",
                minHeight: "30px",
                maxWidth: "140px",
                borderRadius: "4px",
                color: "red",
                border: "1px solid red",
                "&:hover": {
                  background: "red",
                  color: "white",
                  border: "1px solid transparent",
                },
                "&:active": {
                  background: "red",
                  color: "white",
                  border: "1px solid transparent",
                },
              }}
            />
            <CustomButton
              label={profileScreen.submit}
              variant="contained"
              onClick={handleSubmitProfileImage}
              wrapperStyles={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
              sx={{
                width: "100%",
                minHeight: "30px",
                maxWidth: "140px",
                borderRadius: "4px",
              }}
              loading={updateProfileImageLoader}
            />
          </Box>
        </Stack>
      </GenericModal>
    </Container>
  );
};

export default UserProfile;
