import { Box, Container, Grid2, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { colors } from "../../../../../themes/colors";
import styles from "../../cmsComponent.module.css";

const TopBannerNew = ({ data }: any) => {
  return (
    <Box className={styles.topBanner}>
      <Container>
        <Grid2 container sx={{ display: "flex", alignItems: "center" }}>
          <Grid2 size={{ sm: 12, md: 6 }}>
            <Stack className={styles.leftText}>
              <Typography variant="h1" color={colors.secondary_black}>
                {data.heading}
              </Typography>
              <Typography variant="subtitle1" lineHeight="normal">
                {data.description}
              </Typography>
              <Typography variant="subtitle2">{data?.tagLine}</Typography>
            </Stack>
          </Grid2>
          <Grid2 size={6} display={{ xs: "none", sm: "none", md: "block" }}>
            <Grid2 container spacing={1}>
              <Grid2 size={{ sm: 12, md: 2 }}></Grid2>
              <Grid2 size={5} className={styles.gridStyleTopBanner}>
                <Box className={styles.imageHead}>
                  <img
                    src={data?.storyImage}
                    alt="Freedo's Story"
                    className={styles.imgStyleBanner}
                  />
                  <Link to="#our-story">
                    <Typography variant="subtitle1" className={styles.text}>
                      OUR STORY
                    </Typography>
                  </Link>
                </Box>
                <Box className={styles.imageHead}>
                  <video
                    style={{
                      objectFit: "cover",
                      height: "265px",
                      width: "100%",
                    }}
                    autoPlay
                    muted
                    loop
                  >
                    <source src={data?.video} type="video/mp4" />
                    <source src="movie.ogg" type="video/ogg" />
                  </video>
                </Box>
              </Grid2>
              <Grid2 size={5}>
                <Stack gap={1}>
                  <Box className={styles.imageHead}>
                    <img
                      src={data?.missionImage}
                      alt="Freedo's Story"
                      style={{
                        height: "265px",
                        objectFit: "cover",
                        maxWidth: "100%",
                      }}
                    />

                    <Link to="#our-mission">
                      <Typography variant="subtitle1" className={styles.text}>
                        OUR MISSION{" "}
                      </Typography>
                    </Link>
                  </Box>
                  <Box className={styles.imageHead}>
                    <img
                      src={data?.visionImage}
                      alt="Freedo's Vision"
                      className={styles.imgStyleBanner}
                    />

                    <Link to="#our-vision">
                      <Typography variant="subtitle1" className={styles.text}>
                        OUR VISION
                      </Typography>
                    </Link>
                  </Box>
                </Stack>
              </Grid2>
            </Grid2>
          </Grid2>
        </Grid2>
      </Container>
    </Box>
  );
};

export default TopBannerNew;
