import {
  Box,
  Container,
  Divider,
  Grid2,
  Stack,
  Typography,
} from "@mui/material";
import styles from "./index.module.css";
import { androidBtn, footerLogo, iosBtn } from "../../../assets";
import { useLocation, useNavigate } from "react-router";
import { Routes } from "../../../constant/routeContants";
import en from "../../../locales/en.json";
import { Link } from "react-router-dom";
import { colors } from "../../../themes/colors";
import { AppLink, LocalStorage } from "../../../constant/enum";
import { RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { setLocalStorageData } from "../../../utills/helper";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { statusCode } from "../../../rental-src/constants/enum";
import { saveSelectedCity } from "../../../redux/actions/commonActions";
import { getHomePageData } from "../../../rental-src/redux/actions";
import { useEffect } from "react";

interface FooterListInterface {
  title: string;
  list: Array<any>;
  isLink: Boolean;
  ind: number;
}

const FooterNew = () => {
  const { footer } = en;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname = "" } = useLocation();

  const {
    rentalMasterCityList: { cities = [] },
  } = useSelector((state: RootState) => state.rentalsCommonReducer);



  //handle navigation on logo click
  const handleFreedoLogo = () => {
    if (pathname === Routes.HOME) window.scrollTo(0, 0);
    navigate(Routes.HOME);
  };

  //handle select city
  const handleSelect = (city: Object | any) => {
    setLocalStorageData(LocalStorage.userCity, city);
    //WILL WORK ON THIS LATER
    // let firebaseObject: any = {
    //   city: city?.city_name,
    // };
    // if (userData?.mobile_number) firebaseObject.mobile = userData.mobile_number;
    // FirebaseEvents("SelectCity", firebaseObject);

    dispatch(saveSelectedCity(city));
    window.scrollTo(0, 0);
  };


  return (
    <Box className={styles.footer}>
      <Container>
        <img
          src={footerLogo}
          alt="footerLogo"
          onClick={handleFreedoLogo}
          style={{ cursor: "pointer", marginBottom: "20px" }}
        />

        <Grid2
          container
          spacing={3}
          sx={{ display: "flex", flexDirection: "row" }}
        >
          {footer?.listItems?.map(({ title, list, isLink, id }, ind) => (
            <FooterList ind={ind} title={title} list={list} isLink={isLink} />
          ))}
        </Grid2>

        {/* download oup app section */}
        <Grid2
          container
          spacing={3}
          sx={{ display: "flex", flexDirection: "row" }}
        >
          <Grid2
            size={{ xs: 12, sm: 6, md: 6, lg: 9 }}
            className={styles.footerListItemDownload}
          >
            <Typography
              variant="h6"
              color={colors.white}
              fontWeight={500}
              textTransform="uppercase"
            >
              {footer.DownloadOurApp}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: "10px",
              }}
            >
              <Link target="_blank" to={AppLink.appStoreLink}>
                <img
                  src={iosBtn}
                  alt="Download from app store"
                  style={{ width: "160px" }}
                />
              </Link>

              <Link target="_blank" to={AppLink.playstoreLink}>
                <img
                  src={androidBtn}
                  alt="Download from play store"
                  style={{ width: "160px" }}
                />
              </Link>
            </Box>
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 6, md: 6, lg: 3 }}>
            <Link to={Routes.CONTACT}>{footer.GetInTouch}</Link>
          </Grid2>
        </Grid2>
        <Divider className={styles.divider} />

        {/* City list section */}
        <Stack gap="20px">
          <Typography
            variant="h5"
            sx={{
              color: colors.white,
              fontWeight: 500,
              textTransform: "capitalize",
              textDecoration: "underline",
            }}
          >
            {footer.FreedoCities}
          </Typography>
          <Grid2 container spacing={2}>
            {cities.map((city: any) =>
              city.status === statusCode.ACTIVE ? (
                <Grid2
                  size={{ xs: 12, sm: 4, md: 3, lg: 2 }}
                  className={styles.footerCityList}
                  onClick={() => handleSelect(city)}
                >
                  <Link to="">
                    <LocationOnIcon sx={{ width: "20px", height: "16px" }} />
                    <Typography
                      variant="caption"
                      color={colors.white}
                      sx={{
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      {footer.BikeRentIn} <strong>{city.displayName} </strong>
                    </Typography>
                  </Link>
                </Grid2>
              ) : null
            )}
          </Grid2>
        </Stack>
      </Container>
    </Box>
  );
};

const FooterList = ({ title, list, isLink, ind }: FooterListInterface) => (
  <Grid2
    key={ind}
    size={{ xs: 12, sm: 6, md: 6, lg: 3 }}
    className={styles.footerListItems}
  >
    <Typography
      variant="h6"
      color={colors.white}
      fontWeight={500}
      textTransform="uppercase"
    >
      {title}
    </Typography>
    <Stack className={styles.footerListItem}>
      {list?.map((item, ind) => (
        <Box key={ind} className={!isLink ? styles.textFooter : ""}>
          {isLink ? (
            <Link
              target={title === "Freedo Support" ? "_blank" : "_parent"}
              to={item.link}
            >
              <Typography
                variant="caption"
                color={colors.white}
                sx={{
                  "&:hover": {
                    color: colors.THEME_BLUE,
                    textDecoration: "underline",
                  },
                }}
              >
                {item.label}
              </Typography>
            </Link>
          ) : (
            <Typography variant="caption" color={colors.white}>
              {item.label}
            </Typography>
          )}
        </Box>
      ))}
    </Stack>
  </Grid2>
);

export default FooterNew;
