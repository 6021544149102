import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

function valuetext(value: number) {
  return `${value}`;
}

interface RangeSliderInterface {
  selectedValue?: number[];
  // handleSliderChange: (newValue: number | number[]) => void;
  handleSliderChange: any;
  label?: string;
  minValue: number;
  maxValue: number;
}
const RangeSliderMUI = ({
  selectedValue,
  handleSliderChange,
  label,
  minValue,
  maxValue,
}: RangeSliderInterface) => {
  return (
    <Box>
      <Slider
        getAriaLabel={() => ""}
        value={selectedValue}
        onChange={handleSliderChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        min={minValue}
        max={maxValue}
      />
    </Box>
  );
};

export default React.memo(RangeSliderMUI);
