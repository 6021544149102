import { useEffect, useState } from "react";

import { Box, Grid2, Typography } from "@mui/material";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { blogsData } from "../../../constant/BlogData";
import { RootState } from "../../../redux/reducers";
import { CustomButton } from "../../../sharedComponents/atom/Buttons/CustomButtons";
import Input from "../../../sharedComponents/atom/InputFields/Input";
import { getBlogData } from "../../redux/actions/cmsActions";
import styles from "./index.module.css";
import { BlogCard } from "./section/BlogCard";
import { BlogFirstCard } from "./section/BlogFirstCard";
import { Tag } from "./section/Tag";

const Blog = () => {
  const dispatch = useDispatch();

  const { blogPageData = [] } = useSelector(
    (state: RootState) => state.cmsDataReducer
  );

  const [searchPopup, setSearchPopup] = useState<boolean>(false);
  const [visibleItems, setVisibleItems] = useState(10); // Initial number of items to display
  const [topic, setTopic] = useState<string>("All");
  const [showCard, setShowCard] = useState<any>("");

  const { searchOptions } = blogsData;

  //inital useeffect
  useEffect(() => {
    dispatch(getBlogData());
  }, []);

  useEffect(() => {
    if (topic === "All") {
      setShowCard(blogPageData);
    } else {
      let cardTopic = blogPageData.filter(function (item: any) {
        return item?.buttonText === topic;
      });
      setShowCard(cardTopic);
    }
  }, [topic, blogPageData]);

  //load more cards
  const loadMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 10);
  };

  //handle cards
  const handleCards = (topic: string): any => {
    setTopic(topic);
    setSearchPopup(false);
  };

  return (
    <>
      <Helmet>
        <title>Freedo: Bike Rentals | Two Wheelers Rentals App</title>
        <meta
          name="title"
          content="Freedo: Bike Rentals | Two Wheelers Rentals App"
        />
        <meta
          name="description"
          content="Freedo rentals offers bikes and scooters on rent in Noida and NCR online at cheap prices. Wide Range of Two wheelers at affordable hourly rates"
        />
        <meta name="url" content="https://freedo.rentals/" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://freedo.rentals/" />
        <meta
          property="og:title"
          content="Bike Rentals in Noida, Delhi NCR | Freedo Rentals"
        />
        <meta
          property="og:description"
          content="Freedo rentals offers bikes and scooters on rent in Noida and NCR online at cheap prices. Wide Range of Two wheelers at affordable hourly rates"
        />
        {/* <meta
          property="og:image"
          content="https://herowebuatfreedo.azurewebsites.net/logo.jpg"
        /> */}
        <meta property="twitter:url" content="https://freedo.rentals/" />
        <meta
          property="twitter:title"
          content="Bike Rentals in Noida, Delhi NCR | Freedo Rentals"
        />
        <meta
          property="twitter:description"
          content="Freedo rentals offers bikes and scooters on rent in Noida and NCR online at cheap prices. Wide Range of Two wheelers at affordable hourly rates"
        />

        <meta property="og:site_name" content="Freedo Rentals" />
        <meta property="og:locale" content="en-IN" />
        <meta property="og:country-name" content="in" />
        {/* <meta property="og:image:width" content="300" /> */}
        {/* <meta property="og:image:height" content="300" /> */}
      </Helmet>
      <Container
        style={{
          maxWidth: "650px",
          marginTop: "20px",
          marginBottom: "70px",
        }}
      >
        <Input
          name="cityName"
          value={topic}
          placeholder="Search topic here"
          onBlur={() => setSearchPopup(false)}
          onClick={() => setSearchPopup(true)}
          containerWidth="100%"
        />

        <Box className={styles.searchOptionsMain}>
          {searchPopup ? (
            <Box className={styles.searchOptions}>
              {searchOptions?.map((option, ind) => (
                <Link
                  to={""}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    handleCards(option);
                  }}
                >
                  <Tag text={option} />
                </Link>
              ))}
            </Box>
          ) : null}
        </Box>
      </Container>
      {showCard?.length > 0 && <BlogFirstCard cardDetails={blogPageData[0]} />}
      <Container>
        {showCard?.length > 0 ? (
          showCard
            .slice(1)
            .reverse()
            ?.slice(1, visibleItems)
            ?.map((ind: number) => {
              return (
                <Grid2 container spacing={4} key={ind}>
                  {showCard.slice(1, visibleItems).map((item: any) => (
                    <Grid2 key={item.id} size={{ sm: 12, md: 6, lg: 4 }}>
                      <BlogCard data={item} />
                    </Grid2>
                  ))}
                </Grid2>
              );
            })
        ) : (
          <Typography sx={{ textAlign: "center" }}>No Blog Found</Typography>
        )}
        {showCard?.length > visibleItems ? (
          <CustomButton
            label={"Load More Stories"}
            variant="contained"
            onClick={loadMore}
            wrapperStyles={{ maxWidth: "200px", textAlign: "center" }}
          />
        ) : null}
      </Container>
    </>
  );
};

export default Blog;
