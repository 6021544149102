import { BASE_URL, getApiUrl } from "../../../config/apiUrls";
import { customAxios } from "../../../config/axiosConfig";

const getUsersAllAddresses = (payload) => {
  try {
    const url = BASE_URL + getApiUrl(payload?.id).getAllAddresses;
    return customAxios.get(url).catch((err) => {
      return err;
    });
  } catch (e) {}
};

//issue category list
const issueCategoryListService = () => {
  try {
    const url: string = BASE_URL + getApiUrl().issueCategoryList;
    return customAxios.get(url).catch((err) => err);
  } catch (error) {
    return error;
  }
};
export default { getUsersAllAddresses, issueCategoryListService };
