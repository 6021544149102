import { Box, Typography } from "@mui/material";
import { colors } from "../../../../themes/colors";

export const SuccessContent = (props: any) => {
  return (
    <Box>
      <Typography variant="body2" color={colors.THEME_BLUE} fontStyle="italic">
        {props?.msg}
      </Typography>
    </Box>
  );
};
