import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { heroImageOperatorModule } from "../../../../assets";
import { ITopSection } from "../../../../interfaces/operatorInterface";
import en from "../../../../locales/en.json";
import { RootState } from "../../../../redux/store";
import { CustomButton } from "../../../../yor-src/components/atom/Buttons/CustomButtons";
import Input from "../../../../yor-src/components/atom/InputFields/Input";
import MultiSelectWithSearch from "../../../../yor-src/components/atom/InputFields/MultiSelectWithSearch";
import OtpModal from "../../../../yor-src/components/template/OtpModal";
import { StyleObject } from "./StyleObject";
import TermsAndConditionsModal from "./TermsAndConstionsModal";

const { operator, global } = en;

const TopSection = (props: ITopSection) => {
  //
  const {
    generateOtpHandler,
    state,
    handleChange,
    filteredOptions,
    isDisabled,
    handleModal,
    isChecked,
    showModal,
    handleDisagree,
    handleAgree,
    validateOtpHandler,
    fields,
    handleResendOtp,
    show,
    isOtpSubmitBtnEnabled,
    handleModalClose,
    errorState,
  } = props;
  //
  const theme = useTheme();
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down(750));

  const { leadGenerationLoader, leadVerificationLoader } = useSelector(
    (state: RootState) => state.operatorReducer
  );

  return (
    <Stack direction={isMobile ? "column" : "row"}>
      {/* Left Section */}
      <Box width={isMobile ? 1 : 42 / 100} sx={StyleObject?.leftSectionTopBox}>
        <Box
          width={isMobile ? 9 / 10 : 6 / 10}
          height={isMobile ? "600px" : "600px"}
          sx={StyleObject?.leftSectionSecondBox}
        >
          <Typography variant="h3" sx={StyleObject?.textAlignCenter}>
            {operator?.partnerWithUs}
          </Typography>

          {/* Name */}
          <Input
            placeholder={operator?.nameOfTheOwner}
            value={state?.name}
            inputProps={{ maxLength: 30 }}
            customStyle={StyleObject?.customStyle}
            containerWidth={1}
            inputContainerStyle={StyleObject?.muiTextFieldRootStyle}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleChange("name", e.target.value);
            }}
            errormessage={!errorState?.name ? null : errorState?.name}
          />

          {/* Phone */}
          <Input
            placeholder={operator?.enterYourPhoneNumber}
            value={state?.phone}
            startAdornment={() => global?.countryCodeIN}
            inputProps={{ maxLength: 10 }}
            customStyle={StyleObject?.customStyle}
            containerWidth={1}
            inputContainerStyle={StyleObject?.muiTextFieldRootStyle}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleChange("phone", e.target.value);
            }}
            errormessage={!errorState?.phone ? null : errorState?.phone}
          />

          {/* Email */}
          <Input
            placeholder={operator?.emailPlaceholder}
            value={state?.email}
            inputProps={{ maxLength: 30 }}
            customStyle={StyleObject?.customStyle}
            containerWidth={1}
            inputContainerStyle={StyleObject?.muiTextFieldRootStyle}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleChange("email", e.target.value);
            }}
            errormessage={!errorState?.email ? null : errorState?.email}
          />

          {/* City */}
          <MultiSelectWithSearch
            placeholder={operator?.selectYourCity}
            selectAllText={operator?.allCities}
            minWidth={"unset"}
            width={1}
            maxWidth={"unset"}
            isShowSelectAll={false}
            value={state?.preferredCity}
            handleChange={(values: string[]) => {
              handleChange("preferredCity", values);
            }}
            choice={filteredOptions}
            defaultKey={global?.nameKey}
            displayNameKey={global?.displayNameKey}
            errorMessage={
              !errorState?.preferredCity ? null : errorState?.preferredCity
            }
          />

          {/* T&C Modal */}
          <TermsAndConditionsModal
            isMobile={isMobile}
            showModal={showModal}
            handleModal={handleModal}
            isChecked={isChecked}
            handleAgree={handleAgree}
            handleDisagree={handleDisagree}
          />

          {/* Button */}
          <CustomButton
            onClick={generateOtpHandler}
            variant="contained"
            loading={leadGenerationLoader}
            sx={StyleObject?.joinUsButton}
            disabled={isDisabled()}
          >
            {operator?.partnerWithUs}
          </CustomButton>
        </Box>

        {/* OTP Verification Modal */}
        {show && (
          <OtpModal
            handleChange={handleChange}
            handleModalClose={handleModalClose}
            otpValue={fields?.otp}
            phoneNumber={fields?.phone}
            openModal={show}
            handleOtpModalSubmit={validateOtpHandler}
            handleResendOtp={handleResendOtp}
            isOtpSubmitBtnEnabled={isOtpSubmitBtnEnabled}
            loading={leadVerificationLoader}
          />
        )}
      </Box>

      {/* Right Section */}
      <Box sx={{ width: isMobile ? 1 : 58 / 100 }}>
        <img
          src={heroImageOperatorModule}
          alt={operator?.altHero}
          style={StyleObject?.imageStyle}
        />
      </Box>
    </Stack>
  );
};

export default TopSection;
