import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import { Box, Stack, Typography } from "@mui/material";
import { TypographyConstants } from "../../../../constant/enum";
import { CustomButton } from "../../../../sharedComponents/atom/Buttons/CustomButtons";
import { Padding } from "@mui/icons-material";
import AddressCard from "../../../../sharedComponents/template/AddressCard";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import AddressSideDrawer from "../../../../sharedComponents/template/sideDrawer/AddressSideDrawer";
import { validate } from "../../../utils/helper";
import {
  addNewAddress,
  clearNewAddress,
  updateUserAddress,
} from "../../../redux/actions/profileActions/UserAddressesActions";
import { addressTypes } from "../../../constants/enum";

interface stateInterface {
  addressLine1: string;
  addressLine2: string;
  default: boolean;
  landmark?: string;
  lng: any;
  lat: any;
  name: string;
  pinCode: any;
  type: string;
  formattedAddress: any;
  addressId: any;
}
function MyAddresses() {
  const [open, setOpen] = useState(false);

  const [updateAddress, setUpdateAddress] = useState(false);

  const initialState = {
    addressLine1: "",
    addressLine2: "",
    default: false,
    landmark: "",
    lng: "",
    lat: "",
    name: "",
    pinCode: "",
    type: "",
    formattedAddress: "",
    addressId: "",
  };
  const [fields, setFields] = useState<stateInterface>({ ...initialState });
  const [errorFields, setErrorFields] = useState({
    addressLine1: "",
    addressLine2: "",
    lng: "",
    lat: "",
    name: "",
    pinCode: "",
    type: "",
  });
  const { allAddresses } = useSelector(
    (state: RootState) => state.MasterReducer
  );
  const { selectedCity } = useSelector(
    (state: RootState) => state.localeReducer
  );

  const { userProfileData = {} } = useSelector(
    (state: RootState) => state.newAuthReducer
  );
  const { newAddress, loading, updatedAddress } = useSelector(
    (state: RootState) => state.UserAddressesReducer
  );

  const { id } = userProfileData;

  const dispatch = useDispatch();

  //toggle drawer
  const toggleDrawer = () => {
    setOpen(!open);
  };

  //toggle drawer on add and update address
  useEffect(() => {
    if (Object.keys(newAddress).length || Object.keys(updatedAddress).length) {
      setOpen(false);
      setFields(initialState);
      if (Object.keys(updatedAddress).length) {
        setUpdateAddress(false);
      }
    }
    return () => {
      dispatch(clearNewAddress());
    };
  }, [newAddress, updatedAddress]);

  //add/update new address
  const handleAddNewAddress = (updateAddress) => {
    let obj = {
      addressLine1: fields.addressLine1,
      addressLine2: fields.addressLine2,
      lng: fields.lng,
      lat: fields.lat,
      name: fields.name,
      pinCode: fields.pinCode,
      type: fields.type,
    };
    let objErr = {
      addressLine1: "",
      addressLine2: "",
      lng: "",
      lat: "",
      name: "",
      pinCode: "",
      type: "",
    };
    let error = false;

    const validatedFields = validate(obj, objErr);

    if (validatedFields.error) {
      error = true;
      setErrorFields({ ...errorFields, ...validatedFields.errorFields });

      return error;
    }

    let payload: any = {
      data: {
        data: {
          addressLine1: fields.addressLine1,
          addressLine2: fields.addressLine2,
          customerId: id,
          default: fields.default,
          landmark: fields.landmark,
          locationData: {
            coordinates: {
              lng: fields.lng,
              lat: fields.lat,
            },
          },
          name: fields.name,
          pinCode: fields.pinCode,
          type: fields.type,
        },
      },
    };

    if (updateAddress) {
      payload["ids"] = {
        id: fields?.addressId,
        customerId: id,
      };
    }
    if (updateAddress) {
      dispatch(updateUserAddress({ ...payload }));
    } else {
      dispatch(addNewAddress({ ...payload }));
    }
  };

  //set field on click of edit icon
  const handleUpdateClick = (address) => {
    setUpdateAddress(true);
    setFields((prev) => ({
      ...prev,
      addressLine1: address?.addressLine1,
      addressLine2: address?.addressLine2,
      lng: address?.locationData?.coordinates?.lng,
      lat: address?.locationData?.coordinates?.lat,
      name: address?.name,
      pinCode: address?.pinCode,
      type: address?.type,
      formattedAddress: address?.locationData?.formattedAddress,
      addressId: address?.id,
      custId: address?.customerId,
    }));

    toggleDrawer();
  };

  return (
    <Stack gap={2}>
      <Typography variant="h6" sx={{ fontSize: "14px" }}>
        My Addresses
      </Typography>
      <CustomButton
        label={"Add Address"}
        variant="outlined"
        wrapperStyles={{ display: "flex", justifyContent: "end" }}
        sx={{ minHeight: "35px", maxWidth: "180px", Padding: "0px" }}
        onClick={toggleDrawer}
      />
      <Grid container spacing={2}>
        {allAddresses?.map((address) => {
          return (
            address?.status === "ACTIVE" && (
              <Grid size={4}>
                <AddressCard
                  address={address}
                  handleUpdateClick={handleUpdateClick}
                />
              </Grid>
            )
          );
        })}
      </Grid>
      <AddressSideDrawer
        open={open}
        setOpen={setOpen}
        toggleDrawer={toggleDrawer}
        fields={fields}
        setFields={setFields}
        errorFields={errorFields}
        setErrorFields={setErrorFields}
        handleAddNewAddress={handleAddNewAddress}
        loading={loading}
        updateAddress={updateAddress}
      />
    </Stack>
  );
}

export default MyAddresses;
