import { Box, Container, Grid2, Typography } from "@mui/material";
import styles from "../index.module.css";

interface featureInterface {
  src?: string;
  content?: any;
}

const FreedoContainer = ({ src, content }: featureInterface) => {
  return (
    <Box className={styles.featuresContentCard}>
      <img src={src} alt="" style={{ height: "40px", objectFit: "contain" }} />

      <Typography variant="subtitle2">{content}</Typography>
    </Box>
  );
};

const FreedoFeature = ({ cmsData }: any) => {
  return (
    <Container sx={{ marginTop: "20px" }}>
      <Box className={styles.featuresContent}>
        {cmsData?.map((data: any) => {
          return <FreedoContainer content={data.heading} src={data.image} />;
        })}
      </Box>
    </Container>
  );
};

export default FreedoFeature;
