import * as actionTypes from "../actions/actionTypes";
import { localeInterface } from "../../interfaces/actionInterfaces";
import {
  GenericObject,
  addDays,
  getTimestampAtEndOfDay,
  getTimestampAtStartOfDay,
} from "../../utills/helper";
import { homePageFilters } from "../../rental-src/constants/enum";
import { useCaseEnum } from "../../constant/enum";

const {
  GET_MASTER_PLAN_DURATIONS_REQUESTED,
  GET_MASTER_PLAN_DURATIONS_SUCCESS,
  GET_MASTER_PLAN_DURATIONS_FAILED,

  GET_FILTERED_MODEL_LIST_REQUESTED,
  GET_FILTERED_MODEL_LIST_SUCCESS,
  GET_FILTERED_MODEL_LIST_FAILED,

  SET_CAROUSAL_FILTER,
  SET_RENTAL_FLEET_FILTER,

  GET_RENTAL_MASTER_CITY_LIST_REQUESTED,
  GET_RENTAL_MASTER_CITY_LIST_SUCCESS,
  GET_RENTAL_MASTER_CITY_LIST_FAILED,

  GET_RENTAL_MODEL_DETAILS_REQUESTED,
  GET_RENTAL_MODEL_DETAILS_SUCCESS,
  GET_RENTAL_MODEL_DETAILS_FAILED,

  GET_CUSTOMER_ADDRESSES_REQUESTED,
  GET_CUSTOMER_ADDRESSES_SUCCESS,
  GET_CUSTOMER_ADDRESSES_FAILED,

  GET_MODEL_CHECKOUT_DETAILS_REQUESTED,
  GET_MODEL_CHECKOUT_DETAILS_SUCCESS,
  GET_MODEL_CHECKOUT_DETAILS_FAILED,

  GET_CALCULATED_CHARGES_REQUESTED,
  GET_CALCULATED_CHARGES_SUCCESS,
  GET_CALCULATED_CHARGES_FAILED,

  SET_CALCULATE_CHARGES_PAYLOAD,
  RESET_CALCULATE_CHARGES_PAYLOAD,

  GET_NEAREST_LOCATED_CITY_REQUESTED,
  GET_NEAREST_LOCATED_CITY_SUCCESS,
  GET_NEAREST_LOCATED_CITY_FAILED,

  GET_AVAILABLE_TIMESLOTS_REQUESTED,
  GET_AVAILABLE_TIMESLOTS_SUCCESS,
  GET_AVAILABLE_TIMESLOTS_FAILED,

  GET_DELIVERY_SLOT_PAUSE_REQUESTED,
  GET_DELIVERY_SLOT_PAUSE_SUCCESS,
  GET_DELIVERY_SLOT_PAUSE_FAILED,
  SET_MASTER_MODEL_FOR_CITY,
  SAVE_SELECTED_CITY,
} = actionTypes.COMMON_ACTIONS;

// interface for vehicle filter
interface vehicleFilter {
  startDate?: any;
  endDate?: any;
  branches?: string[];
  useCase?: string;
  cities?: string[];
  vehicleType?: string;
}

interface commonReducerInterface {
  rentalCommonLoader: boolean;
  masterPlanDurationsData: Number[] | string[];
  filteredModelList: GenericObject;
  carousalFilter: vehicleFilter;
  rentingFleetFilter: vehicleFilter;
  rentalMasterCityList: any;
  modelFilterType: string;
  selectedCity: GenericObject;
  rentalModelDetails: GenericObject;
  customerAddresses: any;
  modelCheckoutDetails: any;
  calculatedCharges: any;
  calculateChargesPayload: any;
  availableTimeSlots: GenericObject[];
  branchSlotPause: GenericObject[];
  masterModelForCity: GenericObject[];
}
const rentalFleetEndDate = addDays(new Date(), 1);

// initial state of the reducer
const initialState: commonReducerInterface = {
  rentalCommonLoader: false,
  masterPlanDurationsData: [],
  filteredModelList: {},
  carousalFilter: {
    useCase: useCaseEnum.RENTALS,
  },
  rentingFleetFilter: {
    useCase: useCaseEnum.RENTALS,
    startDate: getTimestampAtStartOfDay(new Date()),
    endDate: getTimestampAtEndOfDay(rentalFleetEndDate),
  },
  rentalMasterCityList: {
    cities: [],
    pagination: {},
  },
  modelFilterType: "",
  selectedCity: {},
  rentalModelDetails: {},
  customerAddresses: [],
  modelCheckoutDetails: {},
  calculatedCharges: {},
  calculateChargesPayload: {
    modelName: "",
    branchName: "",
    useCase: "",
    customerId: "",
    startDate: null,
    endDate: null,
    complementaryHelmet: false,
    unlimitedSelected: false,
    premiumSelected: false,
    deliveryDetails: {},
    helmetSelection: {},
  },
  availableTimeSlots: [],
  branchSlotPause: [],
  masterModelForCity: [],
};


const rentalsCommonReducer = (
  state = initialState,
  action: localeInterface
) => {
  const prevState = { ...state };
  const { type, payload } = action;
  switch (type) {
    case GET_MASTER_PLAN_DURATIONS_REQUESTED:
      return {
        ...state,
        rentalCommonLoader: true,
      };
    case GET_MASTER_PLAN_DURATIONS_SUCCESS:
      return {
        ...state,
        rentalCommonLoader: false,
        masterPlanDurationsData: payload,
      };
    case GET_MASTER_PLAN_DURATIONS_FAILED:
      return {
        ...state,
        rentalCommonLoader: false,
        masterPlanDurationsData: [],
      };
    case GET_FILTERED_MODEL_LIST_REQUESTED:
      return {
        ...state,
        rentalCommonLoader: true,
      };
    case GET_FILTERED_MODEL_LIST_SUCCESS:
      return {
        ...state,
        rentalCommonLoader: false,
        filteredModelList: payload,
      };
    case GET_FILTERED_MODEL_LIST_FAILED:
      return {
        ...state,
        rentalCommonLoader: false,
        filteredModelList: {},
      };
    case SET_MASTER_MODEL_FOR_CITY:
      return {
        ...state,
        masterModelForCity: payload,
      };

    case SET_CAROUSAL_FILTER:
      return {
        ...state,
        // carousalFilter: { ...prevState.carousalFilter, payload },
        carousalFilter: payload,
        modelFilterType: homePageFilters.carousalFilter,
      };
    case SET_RENTAL_FLEET_FILTER:
      return {
        ...state,
        // rentingFleetFilter: { ...prevState.rentingFleetFilter, payload },
        rentingFleetFilter: payload,
        modelFilterType: homePageFilters.rentingFleetFilter,
      };

    // Master city list
    case GET_RENTAL_MASTER_CITY_LIST_REQUESTED:
      return {
        ...state,
        rentalCommonLoader: true,
      };
    case GET_RENTAL_MASTER_CITY_LIST_SUCCESS:
      return {
        ...state,
        rentalCommonLoader: false,
        rentalMasterCityList: payload ?? initialState.rentalMasterCityList,
      };
    case GET_RENTAL_MASTER_CITY_LIST_FAILED:
      return {
        ...state,
        rentalCommonLoader: false,
        rentalMasterCityList: initialState.rentalMasterCityList,
      };

    // Detect Location
    case GET_NEAREST_LOCATED_CITY_REQUESTED:
      return {
        ...state,
        rentalCommonLoader: true,
        selectedCity: initialState.selectedCity,
      };
    case GET_NEAREST_LOCATED_CITY_SUCCESS:
      return {
        ...state,
        rentalCommonLoader: false,
        selectedCity: payload,
      };
    case GET_NEAREST_LOCATED_CITY_FAILED:
      return {
        ...state,
        rentalCommonLoader: false,
        selectedCity: initialState.selectedCity,
      };

    // model details for preview
    case GET_RENTAL_MODEL_DETAILS_REQUESTED:
      return {
        ...state,
        rentalCommonLoader: true,
        rentalModelDetails: initialState.rentalModelDetails,
      };
    case GET_RENTAL_MODEL_DETAILS_SUCCESS:
      return {
        ...state,
        rentalCommonLoader: false,
        rentalModelDetails: payload,
      };
    case GET_RENTAL_MODEL_DETAILS_FAILED:
      return {
        ...state,
        rentalCommonLoader: false,
        rentalModelDetails: initialState.rentalModelDetails,
      };

    // get customer addresses
    case GET_CUSTOMER_ADDRESSES_REQUESTED:
      return {
        ...state,
        rentalCommonLoader: true,
        customerAddresses: initialState.customerAddresses,
      };
    case GET_CUSTOMER_ADDRESSES_SUCCESS:
      return {
        ...state,
        rentalCommonLoader: false,
        customerAddresses: payload,
      };
    case GET_CUSTOMER_ADDRESSES_FAILED:
      return {
        ...state,
        rentalCommonLoader: false,
        customerAddresses: initialState.customerAddresses,
      };

    // model checkout details
    case GET_MODEL_CHECKOUT_DETAILS_REQUESTED:
      return {
        ...state,
        rentalCommonLoader: true,
        modelCheckoutDetails: initialState.modelCheckoutDetails,
      };
    case GET_MODEL_CHECKOUT_DETAILS_SUCCESS:
      return {
        ...state,
        rentalCommonLoader: false,
        modelCheckoutDetails: payload,
      };
    case GET_MODEL_CHECKOUT_DETAILS_FAILED:
      return {
        ...state,
        rentalCommonLoader: false,
        modelCheckoutDetails: initialState.modelCheckoutDetails,
      };

    // calculate billing charges
    case GET_CALCULATED_CHARGES_REQUESTED:
      return {
        ...state,
        rentalCommonLoader: true,
        calculatedCharges: initialState.calculatedCharges,
      };
    case GET_CALCULATED_CHARGES_SUCCESS:
      return {
        ...state,
        rentalCommonLoader: false,
        calculatedCharges: payload,
      };
    case GET_CALCULATED_CHARGES_FAILED:
      return {
        ...state,
        rentalCommonLoader: false,
        calculatedCharges: initialState.calculatedCharges,
      };

    case SET_CALCULATE_CHARGES_PAYLOAD:
      return {
        ...state,
        calculatedCharges: payload,
      };
    case RESET_CALCULATE_CHARGES_PAYLOAD:
      return {
        ...state,
        calculatedCharges: initialState.calculatedCharges,
      };

    // get available time slots for selected branch
    case GET_AVAILABLE_TIMESLOTS_REQUESTED:
      return {
        ...state,
        rentalCommonLoader: true,
        availableTimeSlots: initialState.availableTimeSlots,
      };
    case GET_AVAILABLE_TIMESLOTS_SUCCESS:
      return {
        ...state,
        rentalCommonLoader: false,
        availableTimeSlots: payload,
      };
    case GET_AVAILABLE_TIMESLOTS_FAILED:
      return {
        ...state,
        rentalCommonLoader: false,
        availableTimeSlots: initialState.availableTimeSlots,
      };

    //  get slot pauses for selected branch
    case GET_DELIVERY_SLOT_PAUSE_REQUESTED:
      return {
        ...state,
        rentalCommonLoader: true,
        branchSlotPause: initialState.branchSlotPause,
      };
    case GET_DELIVERY_SLOT_PAUSE_SUCCESS:
      return {
        ...state,
        rentalCommonLoader: false,
        branchSlotPause: payload,
      };
    case GET_DELIVERY_SLOT_PAUSE_FAILED:
      return {
        ...state,
        rentalCommonLoader: false,
        branchSlotPause: initialState.branchSlotPause,
      };
    case SAVE_SELECTED_CITY:
      return {
        ...prevState,
        selectedCity: payload,
      };
    default:
      return state;
  }
};

export default rentalsCommonReducer;
