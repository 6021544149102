import { Box, Container, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import { colors } from "../../../../themes/colors";

const CardCounter = ({ counter }: any) => {
  return (
    <>
      <Grid size={6}>
        <CountUp
          className="counter-number"
          suffix={counter?.label === "Total Two-Wheelers" ? "" : "+"}
          start={0}
          end={counter?.value}
          duration={3}
          style={{
            fontSize: "2.5em",
            color: colors.primary,
            textAlign: "center",
          }}
        ></CountUp>
        <Typography variant="subtitle1">{counter?.label}</Typography>
      </Grid>
    </>
  );
};

const FreedoCounter = ({ cmsData }): any => {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <Box ref={ref} className="counter-wrapper">
      <Container>
        <Box className="counter-wrap">
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            className=""
            alignItems={"center"}
            textAlign={"center"}
          >
            <Grid size={6}>
              {inView && (
                <Box className="row">
                  {cmsData?.stats?.map((counter, ind) => {
                    return (
                      <CardCounter key={ind} ind={ind} counter={counter} />
                    );
                  })}
                </Box>
              )}
            </Grid>
            <Grid size={6}>
              <img
                src={cmsData?.image}
                alt="total-count-image"
                style={{ maxWidth: "100%" }}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default FreedoCounter;
