import { Box, Typography } from "@mui/material";
import React from "react";
import { getFormattedINR } from "../../../../../utils/helper";

function PackageDetailsAccordion({ duration, mainBookingPlanCharges }) {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Typography>Package</Typography>
      <Typography>
        {`${duration} ${duration == 1 ? "Day" : "Days"} (${getFormattedINR(
          mainBookingPlanCharges?.amount
        )})`}
      </Typography>
    </Box>
  );
}

export default PackageDetailsAccordion;
