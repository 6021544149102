import { Box, Card, CardActions, CardContent, Typography } from "@mui/material";
import React from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import GroupsIcon from "@mui/icons-material/Groups";
import HomeIcon from "@mui/icons-material/Home";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { colors } from "../../../themes/colors";
import { useDispatch } from "react-redux";
import { deleteUserAddress } from "../../../rental-src/redux/actions/profileActions/UserAddressesActions";

const addressTypeIcons = {
  HOME: <HomeIcon sx={{ fontSize: "large", color: colors?.THEME_BLUE }} />,
  FRIENDS: <GroupsIcon sx={{ fontSize: "large", color: colors?.THEME_BLUE }} />,
  OFFICE: (
    <BusinessCenterIcon sx={{ fontSize: "large", color: colors?.THEME_BLUE }} />
  ),
  OTHER: (
    <LocationOnIcon sx={{ fontSize: "large", color: colors?.THEME_BLUE }} />
  ),
};

function AddressCard({ address, handleUpdateClick }) {
  const dispatch = useDispatch();
  return (
    <Card
      sx={{
        minWidth: 275,
        border: "1px solid #2ABDBD",
        paddingBottom: "-15px",
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            position: "absolute",
            width: "28px",
            height: "28px",
            borderRadius: "100%",
            boxShadow: "1px 1px 8px 0 #2bc7c94f",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "-30px",
            backgroundColor: colors.white,
          }}
        >
          {addressTypeIcons[address?.type]}
        </Box>
        <Box sx={{ display: "flex", gap: "5px", justifyContent: "end" }}>
          <EditIcon
            sx={{
              fontSize: "large",
              color: colors.THEME_BLUE,
              cursor: "pointer",
            }}
            onClick={() => handleUpdateClick(address)}
          />
          <DeleteOutlineIcon
            sx={{
              fontSize: "large",
              color: colors.error_red,
              cursor: "pointer",
            }}
            onClick={() => {
              dispatch(
                deleteUserAddress({
                  id: address.id,
                  customerId: address.customerId,
                })
              );
            }}
          />
        </Box>
        <Typography variant="h6" sx={{ fontSize: "14px" }}>
          {address?.name}
        </Typography>
        <Typography>{`${address?.addressLine1}, ${address?.addressLine2}`}</Typography>
        <Typography>{`${address?.landmark ? address?.landmark : ""}, ${
          address?.pinCode ? address?.pinCode : ""
        }`}</Typography>
      </CardContent>
      <CardActions></CardActions>
    </Card>
  );
}

export default AddressCard;
