import { Box, Stack, SvgIcon, Typography } from "@mui/material";
import DatePicker from "react-datepicker";
import { CustomIconButton } from "../../atom/Buttons/CustomButtons";
import { colors } from "../../../themes/colors";
import OutsideAlerter from "../../../component/outSideAlert";

interface datePickerInterface {
  selectedDate: any;
  dateChangeHandler: Function;
  minDate?: Date;
  maxDate?: Date;
  maxTime?: Date;
  dateFormat?: string;
  showTimeBlock?: boolean;
  showPackages?: boolean;
  packages?: any[];
  selectedPackage?: any;
  packagebuttonStyles?: any;
  packageSelectHandler?: Function;
  toggleCalendarHandler?: Function;
  excludedDates?: string[] | number[];
}

const CustomReactDateTimePicker = ({
  selectedDate = new Date(),
  dateChangeHandler = () => {},
  minDate = new Date(),
  maxDate = new Date(),
  maxTime = new Date(),
  dateFormat = "MMMM d, yyyy h:mm aa",
  showPackages = false,
  showTimeBlock = false,
  packages = [],
  selectedPackage = {},
  packagebuttonStyles,
  packageSelectHandler = () => {},
  toggleCalendarHandler = () => {},
  excludedDates = [],
  ...rest
}: datePickerInterface) => {
  return (
    <OutsideAlerter
      handleOutsideClick={(e) => {
        toggleCalendarHandler();
      }}
    >
      <Stack className="DatePickerWrapper" sx={{ maxWidth: "min-content" }}>
        <DatePicker
          selected={selectedDate ?? ""}
          onChange={(date: any) => dateChangeHandler(date)}
          showTimeSelect={showTimeBlock}
          minDate={minDate}
          minTime={() => new Date()}
          maxTime={() => new Date()}
          // maxTime={() => setHours(setMinutes(new Date(), 30), 20)}
          dateFormat="MMMM d, yyyy h:mm aa"
          inline
          style={{ width: "max-content" }}
          // onInputClick={() => setIsOpen(true)}
          excludeDates={excludedDates ?? []}
          isClearable={true}
        />

        {/* Custom UI */}
        {showPackages && (
          <Stack
            gap="5px"
            sx={{
              background: "#fff",
              border: "1px solid darkgray",
              width: "auto",
              padding: "5px",
              borderRadius: "5px",
            }}
          >
            <Typography variant="subtitle2">Select Package</Typography>

            <Stack direction="row" flexWrap="wrap" gap="4px">
              {packages &&
                packages?.length > 0 &&
                packages.map((item: any, index: number) => {
                  let packageName = item !== "Custom" ? item + " Day" : item;
                  return (
                    <>
                      <CustomIconButton
                        buttonSx={{
                          ...packagebuttonStyles,
                          background:
                            // selectedPackage?.id == item?.id
                            selectedPackage == item
                              ? colors.THEME_BLUE
                              : colors.light_gray,
                        }}
                        key={item}
                        onClick={(e: any) => {
                          e.preventDefault();
                          packageSelectHandler(e, index, item);
                        }}
                        variant={
                          // selectedPackage == item?.package_name
                          selectedPackage === item ? "contained" : "text"
                        }
                      >
                        <Typography
                          variant="smallText"
                          sx={{
                            color:
                              selectedPackage === item
                                ? colors.white
                                : colors.light_black,
                          }}
                        >
                          {packageName}
                        </Typography>
                      </CustomIconButton>
                    </>
                  );
                })}
            </Stack>
          </Stack>
        )}
      </Stack>
    </OutsideAlerter>
  );
};

export default CustomReactDateTimePicker;
