import { all } from "redux-saga/effects";
import authSaga from "./authSaga";
import homeSaga from "./homeSaga";
import rentSaga from "./rentSaga";
import checkoutSaga from "./checkoutSaga";
import profileSaga from "./profileSaga";
import globalSaga from "./globalSaga";
import bookingSaga from "./bookingSaga";
import userKycSaga from "../../rental-src/redux/sagas/userKycSaga";
import commonSaga from "./rentalCommonSaga";
import newAuthSaga from "../../rental-src/redux/sagas/authSaga";
import { rentalSaga } from "../../rental-src/redux/sagas";
import { yorSaga } from "../../yor-src/redux/saga";
import operatorSaga from "./operatorSaga";
import cmsDataSaga from "../../rental-src/redux/sagas/cmsDataSaga";

export function* rootSaga() {
  yield all([
    authSaga(),
    homeSaga(),
    rentSaga(),
    checkoutSaga(),
    // profileSaga(), ---> new in rental saga
    globalSaga(),
    bookingSaga(),
    commonSaga(),
    yorSaga(),
    operatorSaga(),
    cmsDataSaga(),
    // rental revamp saga
    ...rentalSaga,
  ]);
}
