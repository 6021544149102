import { Box, Stack, Typography } from "@mui/material";
import { New, upcomingcity } from "../assets";
import { LocalStorage } from "../constant/enum";
import { GenericObject, getLocalStorageData } from "../utills/helper";
import { WEB_URL } from "../config/apiUrls";

const CityBadge = ({
  ind,
  item,
  cityData,
  getSelectedCity,
  setIsUpcomingModal,
  filterCommingSoonCity,
}) => {
  let cityImageUrl = "";
  let cityImageName = "";
  let cityImageType = "";
  if (item?.images?.length > 0) {
    cityImageUrl = WEB_URL + '/' + item?.images[0]?.filePath;
    cityImageName = item?.images[0]?.fileName;
    cityImageType = item?.images[0]?.type;
  }
  const handleCityClick = () => {
    getSelectedCity(ind);
    /* if (item.status === 5) {
      setIsUpcomingModal(true);
    } */
  };

  // if (item.status === 5) cityExistZoho();
  const cityImgStyle = {
    height: "130px",
    marginTop: "20px",
    borderRadius: "20px",
    cursor: "pointer",
    objectFit: "cover",
    width: "140px",
  };
  if (item?.status === 2 || item?.status === 3 || item?.status === 4)
    return null;

  return (
    <>
      <Box onClick={handleCityClick}>
        <Stack className="city-model-wrap">
          {/* Upcoming / coming soon */}
          {item?.isUpcoming && (
            <Box>
              <img
                src={upcomingcity}
                className="city-new"
                style={{ width: "80px" }}
              />
            </Box>
          )}
          {/* New City */}
          {item?.isNew && (
            <Box>
              <img src={New} className="city-new" />
            </Box>
          )}
          <img src={cityImageUrl} alt="" />
          <Typography
            className={`${
              item?.city_name == cityData?.city_name ? "active-city" : ""
            }`}
            title={item.displayName}
          >
            {item.displayName}
          </Typography>
        </Stack>
      </Box>
    </>
  );
};

export default CityBadge;
