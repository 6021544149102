import { Paper } from "@mui/material";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React from "react";
import { GenericObject } from "../../../utills/helper";

interface VerticalTabsProps {
  tabs: {
    label: string;
    content: React.ReactNode;
  }[];
  value: number;
  handleChange: Function;
}

function TabPanel({ children, value, index }): any {
  return (
    value === index && (
      <Paper
        elevation={0}
        sx={{
          p: 3,
          width: 1,
          borderRadius: "12px",
          padding: "0px 24px 24px 24px",
        }}
      >
        {children}
      </Paper>
    )
  );
}

function getId(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const CustomVerticalTabs: React.FC<VerticalTabsProps> = ({
  tabs,
  value,
  handleChange,
}) => {
  return (
    <Stack
      direction="row"
      gap={2}
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        "& .MuiTabs-root , & .MuiTabs-vertical": {
          overflow: "visible !important",
        },
      }}
    >
      <Tabs
        orientation="vertical"
        value={value}
        onChange={(e: any, value: number) => handleChange(e, value)}
        aria-label="Vertical tabs example"
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
        sx={{
          minWidth: 158,
          "& .MuiTabs-scroller": {
            overflow: "visible !important",
          },
          "& .MuiTab-root": {
            position: "relative",
            overflow: "visible",
            fontSize: "16px",
          },
          "& .Mui-selected": {
            fontWeight: "bold",
            color: "rgba(0, 0, 0, 0.6) !important",
          },
          "& .Mui-selected::before": {
            content: '""',
            position: "absolute",
            borderLeft: "25px solid #1ce4f1",
            borderTop: "13px solid transparent",
            borderBottom: "12px solid transparent",
            height: "0",
            right: "-14px",
            transition: "all .6s ease",
            width: "0",
          },
        }}
      >
        {tabs?.map((tab: GenericObject, index: number) => (
          <Tab
            key={index}
            label={tab?.label}
            {...getId(index)}
            sx={{
              background:
                "transparent linear-gradient(90deg, #59f7da 0%, #18e3f2 100%) 0% 0% no-repeat padding-box",
              mb: index === tabs?.length - 1 ? 0 : 2,
              borderRadius: "3px",
              textTransform: "none",
              "&.MuiButtonBase-root, &.MuiTab-root": {
                height: 35,
                minHeight: "unset",
              },
              "&.Mui-selected, &:hover": {
                backgroundColor: "red",
                background:
                  "transparent linear-gradient(90deg, #59f7da 0%, #18e3f2 100%) 0% 0% no-repeat padding-box",
                backgroundImage: `url${"../../assets/images/arrow-right.png"}`,
              },
            }}
          />
        ))}
      </Tabs>
      {tabs?.map((tab: GenericObject, index: number) => (
        <TabPanel key={index} value={value} index={index}>
          {tab?.content}
        </TabPanel>
      ))}
    </Stack>
  );
};

export default CustomVerticalTabs;
