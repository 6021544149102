import { GenericObject } from "../../../constants/enum";
import { MASTER_ACTIONS } from "../../actions/actionTypes";

interface initialStateInterface {
  allAddresses: any;
  issueCategoryList: GenericObject;
}

const initialState: initialStateInterface = {
  allAddresses: [],
  issueCategoryList: [],
};

const MasterReducer = (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case MASTER_ACTIONS.GET_ISSUE_CATEGORY_LIST_REQUESTED:
    case MASTER_ACTIONS.GET_ALL_ADDRESSES_REQUESTED:
      return {
        ...state,
      };
    case MASTER_ACTIONS.GET_ALL_ADDRESSES_SUCCESS:
      return {
        ...state,
        allAddresses: payload,
      };
    case MASTER_ACTIONS.GET_ISSUE_CATEGORY_LIST_FAILED:
    case MASTER_ACTIONS.GET_ALL_ADDRESSES_FAILED:
      return {
        ...state,
      };

    case MASTER_ACTIONS.GET_ISSUE_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        issueCategoryList: payload,
      };

    default:
      return { ...state };
  }
};

export default MasterReducer;
