import {
  Box,
  FormControlLabel,
  Grid2 as Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { circleCheckSuccess, oldphone } from "../../../../assets";
import en from "../../../../locales/en.json";
import { RootState } from "../../../../redux/reducers";
import { CustomButton } from "../../../../sharedComponents/atom/Buttons/CustomButtons";
import Input from "../../../../sharedComponents/atom/InputFields/Input";
import InputSelect from "../../../../sharedComponents/atom/InputFields/InputSelect";
import { colors } from "../../../../themes/colors";
import { checkAlfa, checkPhone } from "../../../../utills/regex";
import {
  EmergencyContactRelationShip,
  EmergencyContactStatus,
  genders,
  TypographyConstants,
} from "../../../constants/enum";
import {
  profileDetailsProps,
  radioButtonObjectGender,
} from "../../../interfaces/profileInterface";
import Styles from "../index.module.css";

import OtpField from "../../../../yor-src/components/template/OtpField";
import { isEqualObject } from "../../../../utills/helper";
import { formatSecondsToTime } from "../../../utils/helper";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";

const {
  profile: { profileDetails },
} = en;

// array for radio buttons
const radioButtonArray: radioButtonObjectGender[] = [
  {
    id: genders.MALE,
    value: genders.MALE,
    label: profileDetails.male,
  },
  {
    id: genders.FEMALE,
    value: genders.FEMALE,
    label: profileDetails.female,
  },
  {
    id: genders.OTHERS,
    value: genders.OTHERS,
    label: profileDetails.others,
  },
];

// common input style object
const inputStyle = {
  containerStyle: {
    width: "100%",
  },
  customStyle: {
    minHeight: "36px",
    height: "36px",
  },
};

// rider realation array
const relationArray: { name: string; displayName: string }[] = [
  {
    name: EmergencyContactRelationShip.FAMILY,
    displayName: profileDetails.family,
  },
  {
    name: EmergencyContactRelationShip.FRIEND,
    displayName: profileDetails.friend,
  },
  {
    name: EmergencyContactRelationShip.COLLEAGUE,
    displayName: profileDetails.colleague,
  },
];

const ProfileDetails: React.FC<profileDetailsProps> = (props) => {
  const {
    onClickUpdateHere,
    handleRadiochange,
    fields,
    handleChangeFields,
    disableUpdateProfile,
    handleUpdateProfile,
    errFields,
    emergencyDetails,
    handleChangeEmergency,
    submitEmergencyDetails,
    errEmergencyDetails,
    onClickVerifyNow,
    emergencyDetailsCopy,
    onClickVerifyOtpEmergencyDetails,
    onClickResendOtp,
  } = props;

  /* data from auth reducer  */
  const { userProfileData = {} } = useSelector(
    (state: RootState) => state.newAuthReducer
  );

  /* data from profile reducer  */
  const {
    emergencyContactDetails,
    addEmergencyLoader,
    emergencyOtpScreen,
    verifyOtpLoader,
    generateOtpLoader,
    updateEmergencyDetailsLoader,
  } = useSelector((state: RootState) => state.newProfileReducer);

  /* state to store seconds */
  const [seconds, setSeconds] = useState<number>(0);

  useEffect(() => {
    if (seconds === 0) return;
    const interval = setInterval(() => {
      setSeconds((prevSeconds: number) => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds]);

  useEffect(() => {
    if (seconds === 0 && emergencyOtpScreen) {
      setSeconds(120);
    }
  }, [emergencyOtpScreen]);

  return (
    <Box>
      <Grid container spacing={3} sx={{ padding: "0px" }}>
        {/* user profile details section */}
        <Grid size={{ xs: 12, md: 8 }}>
          <Stack gap={"20px"}>
            <Typography
              variant={TypographyConstants.SUBTITLE1}
              sx={{
                color: colors.secondary_black,
                fontWeight: 500,
              }}
            >
              {profileDetails.myProfile}
            </Typography>

            <Stack gap={"12px"}>
              {/* first name and last name inputs */}
              <Box
                className={Styles.profileFieldsBox}
                sx={{
                  flexWrap: { xs: "wrap", md: "unset" },
                }}
              >
                <Stack gap={"4px"} width={"100%"}>
                  <Typography
                    variant={TypographyConstants.SUBTITLE2}
                    sx={{ color: colors.secondary_black }}
                  >
                    {profileDetails.firstName}
                  </Typography>
                  <Input
                    disabled
                    value={userProfileData.firstName}
                    {...inputStyle}
                  />
                </Stack>
                <Stack gap={"4px"} width={"100%"}>
                  <Typography
                    variant={TypographyConstants.SUBTITLE2}
                    sx={{ color: colors.secondary_black }}
                  >
                    {profileDetails.lastName}
                  </Typography>
                  <Input
                    disabled
                    value={userProfileData.lastName}
                    {...inputStyle}
                  />
                </Stack>
              </Box>

              {/* mobile no and email inputs */}
              <Box
                className={Styles.profileFieldsBox}
                sx={{
                  flexWrap: { xs: "wrap", md: "unset" },
                }}
              >
                <Stack gap={"4px"} width={"100%"}>
                  <Typography
                    variant={TypographyConstants.SUBTITLE2}
                    sx={{ color: colors.secondary_black }}
                  >
                    {profileDetails.mobileNumber}
                  </Typography>
                  <Input
                    disabled
                    value={userProfileData.mobileNumber}
                    {...inputStyle}
                  />
                </Stack>
                <Stack gap={"4px"} width={"100%"}>
                  <Typography
                    variant={TypographyConstants.SUBTITLE2}
                    sx={{ color: colors.secondary_black }}
                  >
                    {profileDetails.emailId}
                  </Typography>
                  <Input
                    value={fields.emailId}
                    {...inputStyle}
                    onChange={(e: any) =>
                      handleChangeFields("emailId", e.target.value)
                    }
                    errormessage={errFields.emailId}
                    placeholder={profileDetails.enterYourEmail}
                  />
                </Stack>
              </Box>

              {/* gender section */}
              <Stack>
                {/* Label */}
                <Typography
                  variant={TypographyConstants.SUBTITLE2}
                  sx={{ color: colors.secondary_black }}
                >
                  {profileDetails.gender}
                </Typography>

                {/* radio buttons container */}
                <Box>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={fields.gender}
                    name="radio-buttons-group"
                    value={fields.gender}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleRadiochange(event)
                    }
                    sx={{ display: "flex", flexDirection: "row" }}
                  >
                    {radioButtonArray.map((item: radioButtonObjectGender) => (
                      <FormControlLabel
                        id={item.id}
                        value={item?.value}
                        control={<Radio size="small" />}
                        label={item?.label}
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontSize: "14px",
                            color: colors.secondary_black,
                            fontWeight: 400,
                          },
                        }}
                      />
                    ))}
                  </RadioGroup>
                </Box>
              </Stack>

              {/* Update button and redirect to kyc section */}
              <Stack gap={"6px"}>
                <CustomButton
                  variant={"contained"}
                  label={"Update Profile"}
                  disabled={disableUpdateProfile()}
                  wrapperStyles={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  style={{
                    minHeight: "20px",
                    padding: "5px 15px",
                    width: "75%",
                  }}
                  onClick={handleUpdateProfile}
                />

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "4px",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant={TypographyConstants.BODY1}
                    sx={{ color: "#1c2541" }}
                  >
                    {profileDetails.updatedYourKycMsg}
                  </Typography>
                  <Box onClick={(event: any) => onClickUpdateHere(event, 1)}>
                    <Typography
                      variant={TypographyConstants.BODY1}
                      sx={{
                        fontSize: "13px",
                        color: colors.primary,
                        cursor: "pointer",
                      }}
                    >
                      {profileDetails.updateHere}
                    </Typography>
                  </Box>
                </Box>
              </Stack>
            </Stack>
          </Stack>
        </Grid>

        {/* emergency details section */}
        <Grid size={{ xs: 12, md: 4 }}>
          {emergencyOtpScreen ? (
            <Stack className={Styles.emergencyOtpScreenWrapper}>
              <Stack sx={{ transform: "rotateY(180deg)", gap: "10px" }}>
                <Stack>
                  <Typography
                    variant={TypographyConstants.SUBTITLE1}
                    sx={{ fontWeight: 500, textAlign: "center" }}
                  >
                    {en.AuthScreens.OTPVerification}
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "4px",
                    }}
                  >
                    <Typography>{profileDetails.enterTheOtp}</Typography>
                    <Typography
                      sx={{ fontWeight: 700 }}
                    >{`+91${emergencyDetails.mobileNumber}`}</Typography>
                  </Box>
                </Stack>

                <OtpField
                  separator={<></>}
                  value={emergencyDetails.otp ?? ""}
                  onChange={(key: string, value: string) => {
                    handleChangeEmergency("otp", value);
                  }}
                  length={6}
                  containerStyle={{ margin: "20px 0px 20px 6px" }}
                />

                <CustomButton
                  variant={"contained"}
                  label={profileDetails.verify}
                  loading={verifyOtpLoader}
                  disabled={
                    emergencyDetails.otp?.length !== 6 || verifyOtpLoader
                  }
                  wrapperStyles={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    marginBottom: "10px",
                  }}
                  style={{
                    minHeight: "20px",
                    height: "36px",
                    width: "60%",
                  }}
                  onClick={onClickVerifyOtpEmergencyDetails}
                />

                <Stack>
                  {seconds ? (
                    <Typography
                      sx={{
                        textAlign: "center",
                        color: "rgba(33, 37, 41, 0.75)",
                      }}
                    >{`Resend OTP in ${formatSecondsToTime(
                      seconds
                    )}`}</Typography>
                  ) : null}
                  <Box
                    sx={{
                      display: "flex",
                      gap: "3px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant={TypographyConstants.SUBTITLE1}
                      sx={{ color: "rgba(33, 37, 41, 0.75)" }}
                    >
                      {profileDetails.didNotReceiveCode}
                    </Typography>

                    <Link
                      style={{
                        fontSize: "14px",
                        fontWeight: 400,
                        color:
                          seconds < 30
                            ? colors.primary
                            : "rgba(33, 37, 41, 0.75)",
                      }}
                      to=""
                      onClick={() => {
                        setSeconds(120);
                        onClickResendOtp();
                      }}
                    >
                      {profileDetails.requestAgain}
                    </Link>
                  </Box>
                </Stack>
              </Stack>
            </Stack>
          ) : (
            <Stack className={Styles.emergencyDetailsWrapper}>
              <Box sx={{ display: "flex", gap: "6px", alignItems: "center" }}>
                <img src={oldphone} style={{ width: "18px", height: "15px" }} />
                <Typography
                  variant={TypographyConstants.SUBTITLE1}
                  sx={{ fontWeight: 500 }}
                >
                  {profileDetails.emergencyDetails}
                </Typography>
              </Box>
              <Stack sx={{ width: "100%", gap: "10px" }}>
                <Input
                  topHeading={profileDetails.contactName}
                  topHeadingStyle={{ marginBottom: "0px" }}
                  placeholder={profileDetails.enterYourEmergencyContactName}
                  value={emergencyDetails.name}
                  onChange={(e: any) => {
                    if (
                      e.target.value === "" ||
                      (e.target.value?.length < 50 && checkAlfa(e.target.value))
                    )
                      handleChangeEmergency(
                        "name",
                        e.target.value?.trimStart()
                      );
                  }}
                  {...inputStyle}
                  errormessage={errEmergencyDetails.name}
                />
                <Input
                  topHeading={profileDetails.contactNumber}
                  topHeadingStyle={{ marginBottom: "0px" }}
                  placeholder={profileDetails.enterYourEmergencyContactNumber}
                  value={emergencyDetails.mobileNumber}
                  onChange={(e: any) => {
                    if (
                      e.target.value === "" ||
                      (checkPhone(e.target.value) &&
                        e.target.value?.length < 11)
                    )
                      handleChangeEmergency("mobileNumber", e.target.value);
                  }}
                  {...inputStyle}
                  errormessage={errEmergencyDetails.mobileNumber}
                />
                <Stack sx={{ width: "100%", gap: "2px" }}>
                  <Typography
                    variant={TypographyConstants.SUBTITLE2}
                    sx={{
                      color: colors.secondary_black,
                    }}
                  >
                    {profileDetails.relationWithRider}
                  </Typography>
                  <InputSelect
                    placeholder={profileDetails.selectRelation}
                    choice={relationArray}
                    value={emergencyDetails.relationship?.displayName}
                    defaultKey={"displayName"}
                    handleChange={(relation: any) => {
                      handleChangeEmergency("relationship", relation);
                    }}
                    customStyle={{
                      minHeight: "36px",
                      height: "36px",
                    }}
                    errormessage={errEmergencyDetails.relationship}
                  />
                </Stack>
              </Stack>

              {/* All scenerios on emergency details */}
              {/* 1. If user do any change in existing emergency details -- Update button */}
              {/* 2. If user has not verified emergency contact -- Verify Now (Link) */}
              {/* 3. If user has verified emergency contact     -- Verified Message */}
              {/* 4. If user don't have emergency details -- Save button (add emergency contact flow) */}

              {emergencyContactDetails?.length ? (
                isEqualObject(emergencyDetails, emergencyDetailsCopy) ? (
                  emergencyContactDetails[0]?.status ===
                  EmergencyContactStatus.VERIFIED ? (
                    /* code if  emergency contact verified*/
                    <Box
                      sx={{
                        display: "flex",
                        gap: "4px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography>
                        {profileDetails.savedEmergencyContactVerified}
                      </Typography>
                      <VerifiedOutlinedIcon
                        sx={{
                          height: "16px",
                          width: "16px",
                          color: colors.primary,
                        }}
                      />
                    </Box>
                  ) : (
                    /* code if user has emergency contact but not verified */
                    <Box sx={{ display: "flex", gap: "4px" }}>
                      <Typography>
                        {profileDetails.savedEmergencyContactNotVerified}
                      </Typography>
                      <Link
                        style={{ fontSize: "13px", fontWeight: 400 }}
                        to=""
                        onClick={() =>
                          generateOtpLoader ? () => {} : onClickVerifyNow()
                        }
                      >
                        {profileDetails.verifyNow}
                      </Link>
                    </Box>
                  )
                ) : (
                  /* code if there is update in emergency contact details */
                  <CustomButton
                    variant={"contained"}
                    label={profileDetails.update}
                    disabled={
                      !(
                        emergencyDetails.mobileNumber &&
                        emergencyDetails.name &&
                        emergencyDetails.relationship?.name
                      ) || updateEmergencyDetailsLoader
                    }
                    wrapperStyles={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                    style={{
                      minHeight: "20px",
                      height: "36px",
                      width: "100%",
                    }}
                    onClick={() => submitEmergencyDetails("update")}
                  />
                )
              ) : (
                /* code if emergency contact is not present */
                <CustomButton
                  variant={"contained"}
                  label={profileDetails.save}
                  loading={addEmergencyLoader}
                  disabled={
                    !(
                      emergencyDetails.mobileNumber &&
                      emergencyDetails.name &&
                      emergencyDetails.relationship?.name
                    ) || addEmergencyLoader
                  }
                  wrapperStyles={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                  style={{
                    minHeight: "20px",
                    height: "36px",
                    width: "100%",
                  }}
                  onClick={() => submitEmergencyDetails("add")}
                />
              )}
            </Stack>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
export default ProfileDetails;
