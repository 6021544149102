import { actionInterface } from "../../../interfaces/commonInterface";
import { CMS_ACTIONS } from "../../actions/actionTypes";

interface initialState {
  privacyPolicy: any;
  termsAndCondition: any;
  aboutUsData: any;
  homePageData: any;
  blogPageData: any;
}

const initialState: initialState = {
  privacyPolicy: {},
  termsAndCondition: {},
  aboutUsData: {},
  homePageData: {},
  blogPageData: {},
};
const cmsDataReducer = (state = initialState, action: actionInterface) => {
  const { type, payload } = action;

  switch (type) {
    case CMS_ACTIONS.GET_PRIVACY_POLICY_CMS_DATA_REQUESTED:
      return {
        ...state,
      };
    case CMS_ACTIONS.GET_PRIVACY_POLICY_CMS_DATA_SUCCESS:
      return {
        ...state,
        privacyPolicy: payload,
      };
    case CMS_ACTIONS.GET_PRIVACY_POLICY_CMS_DATA_FAILED:
      return {
        ...state,
      };
    case CMS_ACTIONS.GET_TERMS_CONDITION_CMS_DATA_REQUESTED:
      return {
        ...state,
      };
    case CMS_ACTIONS.GET_TERMS_CONDITION_CMS_DATA_SUCCESS:
      return {
        ...state,
        termsAndCondition: payload,
      };
    case CMS_ACTIONS.GET_TERMS_CONDITION_CMS_DATA_FAILED:
      return {
        ...state,
      };
    case CMS_ACTIONS.GET_ABOUT_US_CMS_DATA_REQUESTED:
      return {
        ...state,
      };
    case CMS_ACTIONS.GET_ABOUT_US_CMS_DATA_SUCCESS:
      return {
        ...state,
        aboutUsData: payload,
      };
    case CMS_ACTIONS.GET_ABOUT_US_CMS_DATA_FAILED:
      return {
        ...state,
      };
    case CMS_ACTIONS.GET_HOME_PAGE_DATA_REQUESTED:
      return {
        ...state,
      };
    case CMS_ACTIONS.GET_HOME_PAGE_DATA_SUCCESS:
      return {
        ...state,
        homePageData: payload,
      };
    case CMS_ACTIONS.GET_HOME_PAGE_DATA_FAILED:
      return {
        ...state,
      };

    case CMS_ACTIONS.GET_BLOG_PAGE_DATA_REQUESTED:
      return {
        ...state,
      };
    case CMS_ACTIONS.GET_BLOG_PAGE_DATA_SUCCESS:
      return {
        ...state,
        blogPageData: payload,
      };
    case CMS_ACTIONS.GET_BLOG_PAGE_DATA_FAILED:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default cmsDataReducer;
