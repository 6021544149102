import { BASE_URL, getApiUrl } from "../../../config/apiUrls";
import { customAxios } from "../../../config/axiosConfig";

/* update user profile service */
const updateProfile = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrl(payload?.customerId).updateProfile;
    return customAxios.put(url, payload?.apiBody).catch((err) => err);
  } catch (error) {
    return error;
  }
};

/* update profile image */
const updateProfileImage = (payload: any) => {
  try {
    const url: string = `${
      BASE_URL + getApiUrl(payload?.customerId).updateProfileImage
    }${payload?.attachmentId}`;
    return customAxios.put(url).catch((err) => err);
  } catch (error) {
    return error;
  }
};

/* get emergency contact data */
const getEmergencyContactDetails = (payload: any) => {
  try {
    const url: string =
      BASE_URL + getApiUrl(payload?.customerId).getEmergencyContactData;
    return customAxios.get(url).catch((err) => err);
  } catch (error) {
    return error;
  }
};

/* add emergency contact data */
const addEmergencyContactDetails = (payload: any) => {
  try {
    const url: string =
      BASE_URL + getApiUrl(payload?.customerId).addEmergencyContactData;
    return customAxios.post(url, payload?.apiBody).catch((err) => err);
  } catch (error) {
    return error;
  }
};

/* add emergency contact data */
const updateEmergencyContactDetails = (payload: any) => {
  try {
    const url: string =
      BASE_URL +
      getApiUrl(`${payload?.customerId}/${payload?.emergencyContactId}`)
        .updateEmergencyContactData;
    return customAxios.put(url, payload?.apiBody).catch((err) => err);
  } catch (error) {
    return error;
  }
};

/* add emergency contact data */
const generateOtpEmergencyContact = (payload: any) => {
  try {
    const url: string =
      BASE_URL +
      getApiUrl(`${payload?.customerId}/${payload?.emergencyContactId}`)
        .generateEmergencyOtp;
    return customAxios.get(url).catch((err) => err);
  } catch (error) {
    return error;
  }
};

/* resend otp emergency contact*/
const resendOtpEmergencyContact = (payload: any) => {
  try {
    const url: string =
      BASE_URL + getApiUrl(payload?.token).resendOtpEmergencyContact;
    return customAxios.put(url).catch((err) => err);
  } catch (error) {
    return error;
  }
};

/* otp verification emergency contact*/
const otpVerificationEmergencyContact = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrl().otpVerificationEmergencyContact;
    return customAxios.post(url, payload?.apiBody).catch((err) => err);
  } catch (error) {
    return error;
  }
};

export default {
  updateProfile,
  getEmergencyContactDetails,
  addEmergencyContactDetails,
  updateEmergencyContactDetails,
  resendOtpEmergencyContact,
  otpVerificationEmergencyContact,
  generateOtpEmergencyContact,
  updateProfileImage,
};
