import axios from "axios";
import { constructUrl } from "../../../utills/helper";
import { BASE_URL, getApiUrl } from "../../../config/apiUrls";
import { customAxios } from "../../../config/axiosConfig";

// service to get available vehicle for yor
const getAvailableVehicleYor = (payload: any) => {
  try {
    const apiurl: string = BASE_URL + getApiUrl().getVehicleAvailabilityYor;
    const url: string = constructUrl(apiurl, payload?.apiParams);
    return axios.get(url).catch((err) => err);
  } catch (error) {
    return error;
  }
};
//service to calculate charges
const calculateCharges = (payload: any) => {
  try {
    let url = BASE_URL + getApiUrl().calculateBookingCharges;
    return customAxios.post(url, payload).catch((err) => err);
  } catch (ex) {
    return ex;
  }
};
//service to get available plans
const getAvailablesPlans = (payload: any) => {
  try {
    let url = BASE_URL + getApiUrl().getAvailablePlans;
    const apiurl: string = constructUrl(url, payload);
    return customAxios.get(apiurl).catch((err) => err);
  } catch (err) {
    return err;
  }
};

//service to store booking
const storeBooking = (payload: any) => {
  try {
    let url = BASE_URL + getApiUrl().storeBooking;
    return customAxios.post(url, payload).catch((err) => err);
  } catch (err) {
    return err;
  }
};

//service to create order
const createOrder = (payload: any) => {
  try {
    let url = BASE_URL + getApiUrl().createOrder;
    return customAxios.post(url, payload).catch((err) => err);
  } catch (err) {
    return err;
  }
};

//service to get payment status
const getPaymentStatus = (payload: any) => {
  try {
    let url = BASE_URL + getApiUrl(payload?.id).getPaymentStatus;
    return customAxios.get(url).catch((err) => err);
  } catch (err) {
    return err;
  }
};
// get city V2 api
const getCityV2 = (payload: any) => {
  try {
    const apiUrl = BASE_URL + getApiUrl().getCityV2;
    const url: string = constructUrl(apiUrl, payload);
    return axios.get(url).catch((err) => err);
  } catch (error) {
    return error;
  }
};

//get booking details
const getBookingDetails = (payload: any) => {
  try {
    const apiUrl = BASE_URL + getApiUrl().getBookingDetails;
    const url: string = constructUrl(apiUrl, payload);
    return customAxios.get(url).catch((err) => err);
  } catch (error) {
    return error;
  }
};
//get all coupons
const getAllCoupons = (payload: any) => {
  try {
    const apiUrl = BASE_URL + getApiUrl().getAllCoupons;
    const url: string = constructUrl(apiUrl, payload);
    return customAxios.get(url).catch((err) => err);
  } catch (error) {
    return error;
  }
};

//cancelled booking
const cancelledBooking = (payload: any) => {
  try {
    const apiUrl = BASE_URL + getApiUrl(payload.bookingId).cancelledBooking;
    return customAxios.put(apiUrl).catch((err) => err);
  } catch (error) {
    return error;
  }
};

export default {
  getAvailableVehicleYor,
  calculateCharges,
  getAvailablesPlans,
  createOrder,
  storeBooking,
  getPaymentStatus,
  getCityV2,
  getBookingDetails,
  getAllCoupons,
  cancelledBooking,
};
