export enum MASTER_ACTIONS {
  GET_ALL_ADDRESSES_REQUESTED = "GET_ALL_ADDRESSES_REQUESTED",
  GET_ALL_ADDRESSES_SUCCESS = "GET_ALL_ADDRESSES_SUCCESS",
  GET_ALL_ADDRESSES_FAILED = "GET_ALL_ADDRESSES_FAILED",

  GET_ISSUE_CATEGORY_LIST_REQUESTED = "GET_ISSUE_CATEGORY_LIST_REQUESTED",
  GET_ISSUE_CATEGORY_LIST_SUCCESS = "GET_ISSUE_CATEGORY_LIST_SUCCESS",
  GET_ISSUE_CATEGORY_LIST_FAILED = "GET_ISSUE_CATEGORY_LIST_FAILED",
}

export enum MyBookingsActions {
  GET_BOOKING_HISTORY_REQUESTED = "GET_BOOKING_HISTORY_REQUESTED",
  GET_BOOKING_HISTORY_SUCCESS = "GET_BOOKING_HISTORY_SUCCESS",
  GET_BOOKING_HISTORY_FAILED = "GET_BOOKING_HISTORY_FAILED",

  GET_BOOKING_DETAILS_REQUESTED = "GET_BOOKING_DETAILS_REQUESTED",
  GET_BOOKING_DETAILS_SUCCESS = "GET_BOOKING_DETAILS_SUCCESS",
  GET_BOOKING_DETAILS_FAILED = "GET_BOOKING_DETAILS_FAILED",

  GET_YOR_BOOKING_HISTORY_REQUESTED = "GET_YOR_BOOKING_HISTORY_REQUESTED",
  GET_YOR_BOOKING_HISTORY_SUCCESS = "GET_YOR_BOOKING_HISTORY_SUCCESS",
  GET_YOR_BOOKING_HISTORY_FAILED = "GET_YOR_BOOKING_HISTORY_FAILED",

  GET_VEHICLE_DOCUMENTS_REQUESTED = "GET_VEHICLE_DOCUMENTS_REQUESTED",
  GET_VEHICLE_DOCUMENTS_SUCCESS = "GET_VEHICLE_DOCUMENTS_SUCCESS",
  GET_VEHICLE_DOCUMENTS_FAILED = "GET_VEHICLE_DOCUMENTS_FAILED",

  CLEAR_BOOKING_DETAILS = "CLEAR_BOOKING_DETAILS",

  DOWNLOAD_VEHICLE_DOCUMENT_REQUESTED = "DOWNLOAD_VEHICLE_DOCUMENT_REQUESTED",
  DOWNLOAD_VEHICLE_DOCUMENT_SUCCESS = "DOWNLOAD_VEHICLE_DOCUMENT_SUCCESS",
  DOWNLOAD_VEHICLE_DOCUMENT_FAILED = "DOWNLOAD_VEHICLE_DOCUMENT_FAILED",

  GET_BOOKING_CANCELLATION_CHARGES_REQUESTED = "GET_BOOKING_CANCELLATION_CHARGES_REQUESTED",
  GET_BOOKING_CANCELLATION_CHARGES_SUCCESS = "GET_BOOKING_CANCELLATION_CHARGES_SUCCESS",
  GET_BOOKING_CANCELLATION_CHARGES_FAILED = "GET_BOOKING_CANCELLATION_CHARGES_FAILED",

  CANCEL_USER_BOOKING_REQUESTED = "CANCEL_USER_BOOKING_REQUESTED",
  CANCEL_USER_BOOKING_SUCCESS = "CANCEL_USER_BOOKING_SUCCESS",
  CANCEL_USER_BOOKING_FAILED = "CANCEL_USER_BOOKING_FAILED",
}
export enum USER_KYC_ACTIONS {
  GET_TRUST_SCORE_REQUESTED = "GET_TRUST_SCORE_REQUESTED",
  GET_TRUST_SCORE_SUCCESS = "GET_TRUST_SCORE_SUCCESS",
  GET_TRUST_SCORE_FAILED = "GET_TRUST_SCORE_FAILED",

  GET_KYC_ATTACHMENT_ID_REQUESTED = "GET_KYC_ATTACHMENT_ID_REQUESTED",
  GET_KYC_ATTACHMENT_ID_SUCCESS = "GET_KYC_ATTACHMENT_ID_SUCCESS",
  GET_KYC_ATTACHMENT_ID_FAILED = "GET_KYC_ATTACHMENT_ID_FAILED",

  PUT_KYC_DOCUMENT_ON_SIGNED_URL_REQUESTED = "PUT_KYC_DOCUMENT_ON_SIGNED_URL_REQUESTED",
  PUT_KYC_DOCUMENT_ON_SIGNED_URL_SUCCESS = "PUT_KYC_DOCUMENT_ON_SIGNED_URL_SUCCESS",
  PUT_KYC_DOCUMENT_ON_SIGNED_URL_FAILED = "PUT_KYC_DOCUMENT_ON_SIGNED_URL_FAILED",

  SUBMIT_KYC_DOCUMENT_REQUESTED = "SUBMIT_KYC_DOCUMENT_REQUESTED",
  SUBMIT_KYC_DOCUMENT_SUCCESS = "SUBMIT_KYC_DOCUMENT_SUCCESS",
  SUBMIT_KYC_DOCUMENT_FAILED = "SUBMIT_KYC_DOCUMENT_FAILED",

  KYC_DOCUMENT_UPLOAD_SUCCESS = "KYC_DOCUMENT_UPLOAD_SUCCESS",
  KYC_DOCUMENT_UPLOAD_FAILED = "KYC_DOCUMENT_UPLOAD_FAILED",
  KYC_DOCUMENT_UPLOAD_REQUESTED = "KYC_DOCUMENT_UPLOAD_REQUESTED",

  CLEAR_KYC_REDUCER_DATA = "CLEAR_KYC_REDUCER_DATA",

  GET_URL_AADHAAR_VERIFICATION_REQUESTED = "GET_URL_AADHAAR_VERIFICATION_REQUESTED",
  GET_URL_AADHAAR_VERIFICATION_SUCCESS = "GET_URL_AADHAAR_VERIFICATION_SUCCESS",
  GET_URL_AADHAAR_VERIFICATION_FAILED = "GET_URL_AADHAAR_VERIFICATION_FAILED",
}

export enum CHECKOUT_ACTIONS {
  SET_RENTAL_CHECKOUT_BOOKING_DATA = "SET_RENTAL_CHECKOUT_BOOKING_DATA",
}
export enum CMS_ACTIONS {
  GET_PRIVACY_POLICY_CMS_DATA_REQUESTED = "GET_PRIVACY_POLICY_CMS_DATA_REQUESTED",
  GET_PRIVACY_POLICY_CMS_DATA_SUCCESS = "GET_PRIVACY_POLICY_CMS_DATA_SUCCESS",
  GET_PRIVACY_POLICY_CMS_DATA_FAILED = "GET_PRIVACY_POLICY_CMS_DATA_FAILED",

  GET_TERMS_CONDITION_CMS_DATA_REQUESTED = "GET_TTERMS_CONDITION_CMS_DATA_REQUESTED",
  GET_TERMS_CONDITION_CMS_DATA_SUCCESS = "GET_TTERMS_CONDITION_CMS_DATA_SUCCESS",
  GET_TERMS_CONDITION_CMS_DATA_FAILED = "GET_T TERMS_CONDITION_CMS_DATA_FAILED",

  GET_ABOUT_US_CMS_DATA_REQUESTED = "GET_TABOUT_US_CMS_DATA_REQUESTED",
  GET_ABOUT_US_CMS_DATA_SUCCESS = "GET_TABOUT_US_CMS_DATA_SUCCESS",
  GET_ABOUT_US_CMS_DATA_FAILED = "GET_T ABOUT_US_CMS_DATA_FAILED",

  GET_HOME_PAGE_DATA_REQUESTED = "GET_HOME_PAGE_DATA_REQUESTED",
  GET_HOME_PAGE_DATA_SUCCESS = "GET_HOME_PAGE_DATA_SUCCESS",
  GET_HOME_PAGE_DATA_FAILED = "GET_HOME_PAGE_DATA_FAILED",

  GET_BLOG_PAGE_DATA_REQUESTED = "GET_BLOG_PAGE_DATA_REQUESTED",
  GET_BLOG_PAGE_DATA_SUCCESS = "GET_BLOG_PAGE_DATA_SUCCESS",
  GET_BLOG_PAGE_DATA_FAILED = "GET_BLOG_PAGE_DATA_FAILED",
}

export enum PROFILE_ACTIONS {
  SET_PROFILE_REDUCER_KEYS = "SET_PROFILE_REDUCER_KEYS",

  UPDATE_USER_PROFILE_REQUESTED = "UPDATE_USER_PROFILE_REQUESTED",
  UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS",
  UPDATE_USER_PROFILE_FAILED = "UPDATE_USER_PROFILE_FAILED",

  GET_EMERGENCY_CONTACT_DETAILS_REQUESTED = "GET_EMERGENCY_CONTACT_DETAILS_REQUESTED",
  GET_EMERGENCY_CONTACT_DETAILS_SUCCESS = "GET_EMERGENCY_CONTACT_DETAILS_SUCCESS",
  GET_EMERGENCY_CONTACT_DETAILS_FAILED = "GET_EMERGENCY_CONTACT_DETAILS_FAILED",

  ADD_EMERGENCY_CONTACT_DETAILS_REQUESTED = "ADD_EMERGENCY_CONTACT_DETAILS_REQUESTED",
  ADD_EMERGENCY_CONTACT_DETAILS_SUCCESS = "ADD_EMERGENCY_CONTACT_DETAILS_SUCCESS",
  ADD_EMERGENCY_CONTACT_DETAILS_FAILED = "ADD_EMERGENCY_CONTACT_DETAILS_FAILED",

  UPDATE_EMERGENCY_CONTACT_DETAILS_REQUESTED = "UPDATE_EMERGENCY_CONTACT_DETAILS_REQUESTED",
  UPDATE_EMERGENCY_CONTACT_DETAILS_SUCCESS = "UPDATE_EMERGENCY_CONTACT_DETAILS_SUCCESS",
  UPDATE_EMERGENCY_CONTACT_DETAILS_FAILED = "UPDATE_EMERGENCY_CONTACT_DETAILS_FAILED",

  RESEND_OTP_EMERGENCY_CONTACT_REQUESTED = "RESEND_OTP_EMERGENCY_CONTACT_REQUESTED",
  RESEND_OTP_EMERGENCY_CONTACT_SUCCESS = "RESEND_OTP_EMERGENCY_CONTACT_SUCCESS",
  RESEND_OTP_EMERGENCY_CONTACT_FAILED = "RESEND_OTP_EMERGENCY_CONTACT_FAILED",

  GENERATE_OTP_EMERGENCY_CONTACT_REQUESTED = "GENERATE_OTP_EMERGENCY_CONTACT_REQUESTED",
  GENERATE_OTP_EMERGENCY_CONTACT_SUCCESS = "GENERATE_OTP_EMERGENCY_CONTACT_SUCCESS",
  GENERATE_OTP_EMERGENCY_CONTACT_FAILED = "GENERATE_OTP_EMERGENCY_CONTACT_FAILED",

  OTP_VERIFICATION_EMERGENCY_CONTACT_REQUESTED = "OTP_VERIFICATION_EMERGENCY_CONTACT_REQUESTED",
  OTP_VERIFICATION_EMERGENCY_CONTACT_SUCCESS = "OTP_VERIFICATION_EMERGENCY_CONTACT_SUCCESS",
  OTP_VERIFICATION_EMERGENCY_CONTACT_FAILED = "OTP_VERIFICATION_EMERGENCY_CONTACT_FAILED",

  UPDATE_USER_PROFILE_IMAGE_REQUESTED = "UPDATE_USER_PROFILE_IMAGE_REQUESTED",
  UPDATE_USER_PROFILE_IMAGE_SUCCESS = "UPDATE_USER_PROFILE_IMAGE_SUCCESS",
  UPDATE_USER_PROFILE_IMAGE_FAILED = "UPDATE_USER_PROFILE_IMAGE_FAILED",
}

export enum AUTH_ACTIONS {
  SET_USER_TOKEN = "SET_USER_TOKEN",
  TOGGLE_SIGN_IN_DRAWER = "TOGGLE_SIGN_IN_DRAWER",

  SIGNUP_REQUESTED = "SIGNUP_REQUESTED",
  SIGNUP_SUCCESS = "SIGNUP_SUCCESS",
  SIGNUP_FAILED = "SIGNUP_FAILED",

  SIGNUP_VERIFY_OTP_REQUESTED = "SIGNUP_VERIFY_OTP_REQUESTED",
  SIGNUP_VERIFY_OTP_SUCCESS = "SIGNUP_VERIFY_OTP_SUCCESS",
  SIGNUP_VERIFY_OTP_FAILED = "SIGNUP_VERIFY_OTP_FAILED",

  SIGNUP_RESEND_OTP_REQUESTED = "SIGNUP_RESEND_REQUESTED",
  SIGNUP_RESEND_OTP_SUCCESS = "SIGNUP_RESEND_SUCCESS",
  SIGNUP_RESEND_OTP_FAILED = "SIGNUP_RESEND_FAILED",

  LOGIN_REQUESTED = "LOGIN_REQUESTED",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_FAILED = "LOGIN_FAILED",

  LOGIN_VERIFY_OTP_REQUESTED = "LOGIN_VERIFY_OTP_REQUESTED",
  LOGIN_VERIFY_OTP_SUCCESS = "LOGIN_VERIFY_OTP_SUCCESS",
  LOGIN_VERIFY_OTP_FAILED = "LOGIN_VERIFY_OTP_FAILED",

  LOGIN_RESEND_OTP_REQUESTED = "LOGIN_RESEND_REQUESTED",
  LOGIN_RESEND_OTP_SUCCESS = "LOGIN_RESEND_SUCCESS",
  LOGIN_RESEND_OTP_FAILED = "LOGIN_RESEND_FAILED",

  LOGOUT_REQUESTED = "LOGOUT_REQUESTED",
  LOGOUT_SUCCESS = "LOGOUT_SUCCESS",
  LOGOUT_FAILED = "LOGOUT_FAILED",

  GET_USER_PROFILE_DATA_REQUESTED = "GET_USER_PROFILE_DATA_REQUESTED",
  GET_USER_PROFILE_DATA_SUCCESS = "GET_USER_PROFILE_DATA_SUCCESS",
  GET_USER_PROFILE_DATA_FAILED = "GET_USER_PROFILE_DATA_FAILED",

  SEND_QUERY_DATA_REQUESTED = "SEND_QUERY_DATA_REQUESTED",
  SEND_QUERY_DATA_SUCCESS = "SEND_QUERY_DATA_SUCCESS",
  SEND_QUERY_DATA_FAILED = "SEND_QUERY_DATA_FAILED",

  GUEST_LOGIN_DATA_REQUESTED = "GUEST_LOGIN_DATA_REQUESTED",
  GUEST_LOGIN_DATA_SUCCESS = "GUEST_LOGIN_DATA_SUCCESS",
  GUEST_LOGIN_DATA_FAILED = "GUEST_LOGIN_DATA_FAILED",
}

export enum USER_ADDRESSES_ACTIONS {
  ADD_NEW_ADDRESS_REQUESTED = "ADD_NEW_ADDRESS_REQUESTED",
  ADD_NEW_ADDRESS_SUCCESS = "ADD_NEW_ADDRESS_SUCCESS",
  ADD_NEW_ADDRESS_FAILED = "ADD_NEW_ADDRESS_FAILED",

  UPDATE_ADDRESS_REQUESTED = "UPDATE_ADDRESS_REQUESTED",
  UPDATE_ADDRESS_SUCCESS = "UPDATE_ADDRESS_SUCCESS",
  UPDATE_ADDRESS_FAILED = "UPDATE_ADDRESS_FAILED",

  DELETE_ADDRESS_REQUESTED = "DELETE_ADDRESS_REQUESTED",
  DELETE_ADDRESS_SUCCESS = "DELETE_ADDRESS_SUCCESS",
  DELETE_ADDRESS_FAILED = "DELETE_ADDRESS_FAILED",

  CLEAR_NEW_ADDRESS = "CLEAR_NEW_ADDRESS",
}
