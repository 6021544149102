import { BASE_URL, getApiUrl } from "../../../config/apiUrls";
import { customAxios } from "../../../config/axiosConfig";
import { constructUrl } from "../../../utills/helper";

// service to get user data
const getUserProfileDataService = (payload: any) => {
  try {
    const url: string =
      BASE_URL + getApiUrl(payload?.customerId).getUserProfile;
    const apiUrlWithParams: string = constructUrl(url, payload?.apiParams);
    return customAxios.get(apiUrlWithParams).catch((err) => err);
  } catch (error) {
    return error;
  }
};

// login user
const loginUserService = (payload: any) => {
  try {
    const id: string = payload?.phoneNo || "";
    const url: string = BASE_URL + getApiUrl(id).loginUser;
    return customAxios.get(url).catch((err) => err);
  } catch (error) {
    return error;
  }
};

//login verify otp
const loginVerifyService = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrl().verifyLoginOtp;
    return customAxios.post(url, { data: payload.data }).catch((err) => err);
  } catch (error) {
    return error;
  }
};

// login resend otp
const loginResendOtpService = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrl(payload?.token).resendLoginOtp;
    return customAxios.put(url, payload).catch((err) => err);
  } catch (error) {
    return error;
  }
};

// signup user
const signUpUserService = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrl().signUpUser;
    return customAxios.post(url, payload).catch((err) => err);
  } catch (error) {
    return error;
  }
};

// verify signup otp  user
const signUpVerifyService = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrl().verifySignupOtp;
    return customAxios.post(url, payload).catch((err) => err);
  } catch (error) {
    return error;
  }
};

// signup otp resend 
const signUpResendOtpService = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrl(payload?.token).resendSignUpOtp;
    return customAxios.post(url, payload).catch((err) => err);
  } catch (error) {
    return error;
  }
};

// logout user
const logoutUserService = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrl(payload?.customerId).logoutUser;
    return customAxios.put(url, payload).catch((err) => err);
  } catch (error) {
    return error;
  }
};

//get in touch or need help query data
const sendQueryDataService = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrl().helpDesk;
    return customAxios.post(url, payload).catch((err) => err);
  } catch (error) {
    return error;
  }
};

//guest login
const guestLoginService = () => {
  try {
    const url: string = BASE_URL + getApiUrl().guestLogin;
    return customAxios.post(url).catch((err) => err);
  } catch (error) {
    return error;
  }
};



export default {
  getUserProfileDataService,
  loginUserService,
  loginVerifyService,
  loginResendOtpService,
  signUpUserService,
  signUpVerifyService,
  signUpResendOtpService,
  logoutUserService,
  sendQueryDataService,
  guestLoginService
};
