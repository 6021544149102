import en from "../../locales/en.json";

export const validateEmail = (email: string) => {
  const emailRegex =
    /^[A-Z0-9_-]+([\.][A-Z0-9_]+)*@[A-Z0-9-]+(\.[a-zA-Z]{2,3})+$/i;
  email = email?.trim();
  if (email == "" || email == undefined || email == null)
    return { status: false, error: validateStatus.required };
  else if (!emailRegex.test(email))
    return { status: false, error: validateStatus.validateRegEx };
  else return { status: true, error: "" };
};

export const alfaNumericValidation = (alfa: string) => {
  let regex = /^[0-9a-zA-Z]+$/;
  return regex.test(alfa);
};

/* inputDate - Date obj or timestamp */
export const getFormattedDate = (inputDate: any, type: string) => {
  let resultDate = "N/A";
  let lastLoggedIn;
  if (inputDate) {
    lastLoggedIn = new Date(Number(inputDate));
    if (lastLoggedIn) {
      let dateOfMonth = lastLoggedIn.getDate();
      dateOfMonth =
        dateOfMonth < 10
          ? dateOfMonth.toString().padStart(2, "0")
          : dateOfMonth;
      let month = lastLoggedIn.getMonth() + 1;
      month = month < 10 ? month.toString().padStart(2, "0") : month;
      let fullYear = lastLoggedIn.getFullYear();
      let hours = (lastLoggedIn.getHours() + 24) % 12 || 12;
      let minutes =
        (lastLoggedIn.getMinutes() < 10 ? "0" : "") + lastLoggedIn.getMinutes();
      let seconds =
        (lastLoggedIn.getSeconds() < 10 ? "0" : "") + lastLoggedIn.getSeconds();
      let timeFormat = lastLoggedIn.getHours() >= 12 ? "PM" : "AM";

      if (type === "date") {
        resultDate = `${dateOfMonth}-${month}-${fullYear}`;
      }
      if (type === "time") {
        resultDate = `${hours}:${minutes}:${seconds} ${timeFormat}`;
      }
      if (type === "dateTime") {
        resultDate = `${dateOfMonth}-${month}-${fullYear} ${hours}:${minutes} ${timeFormat}`;
      }
      if (type === "hours") {
        resultDate = `${hours}:${minutes} ${timeFormat}`;
      }
    }
  }
  return resultDate;
};

// convert seconds to 00:00 time format
export const formatSecondsToTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${
    remainingSeconds >= 10 ? remainingSeconds : "0" + remainingSeconds
  }`;
};

export const getDeliveryAddress = (address: any) => {
  let formatteDeliveryAddress = "";
  formatteDeliveryAddress = address?.addressLine1
    ? `${address?.addressLine1}`
    : "";
  formatteDeliveryAddress += address?.addressLine2
    ? `, ${address?.addressLine2}`
    : "";
  formatteDeliveryAddress += address?.landmark ? `, ${address?.landmark}` : "";
  formatteDeliveryAddress += address?.pinCode ? `, ${address?.pinCode}` : "";
  formatteDeliveryAddress = formatteDeliveryAddress
    ? formatteDeliveryAddress
    : "N/A";
  return formatteDeliveryAddress;
};

export const getFormattedINR = (amount: number) => {
  let formattedAmount = "";
  // if (amount > 0) {
  amount = amountConvertToRupee(amount);
  formattedAmount = amount.toLocaleString("yoren-IN", {
    maximumFractionDigits: 0,
    style: "currency",
    currency: "INR",
  });
  // }
  return formattedAmount;
};
export const amountConvertToRupee = (num: number) => {
  let result = 0;
  if (num > 0) {
    result = num / 100;
  }
  return result;
};
export const convertTo12hrs = (time: any) => {
  // Check correct time format and split into components
  time = time?.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];
  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
};
export const validateStatus = {
  required: "The field is required",
  validateRegEx: "Invalid Email Address",
  minLength: "minLength",
};

export const validate = (
  fields: any = {},
  errorFields: object = {},
  optionalField: string[] = [],
  validateOnly: string[] = []
) => {
  const validateFields = Object.keys(fields);
  let obj = errorFields;
  let error = false;
  validateFields.forEach((each) => {
    if (obj[each] === undefined || optionalField.includes(each)) return;
    if (typeof fields[each] === "string" || typeof fields[each] === "number") {
      if (!fields[each]) {
        error = true;
        obj[each] = en.errorMessages.requiredField;
      } else {
        switch (true) {
          case each?.includes("firstName") || each?.includes("name"):
            if (typeof fields[each] === "string" && fields[each]?.length < 3) {
              error = true;
              obj[each] = en.errorMessages.FirstName;
            }
            break;
          case each?.includes("lastName"):
            if (typeof fields[each] === "string" && fields[each]?.length < 3) {
              error = true;
              obj[each] = en.errorMessages.FirstName;
            }
            break;
          case each?.toLocaleLowerCase()?.includes("firstName"):
            if (typeof fields[each] === "string" && fields[each]?.length < 2) {
              error = true;
              obj[each] = en.errorMessages.FirstName;
            }
            break;
          case each?.toLocaleLowerCase()?.includes("lastName"):
            if (typeof fields[each] === "string" && fields[each]?.length < 2) {
              error = true;
              obj[each] = en.errorMessages.FirstName;
            }
            break;
          case each?.toLocaleLowerCase()?.includes("email"):
            if (!validateEmail(fields[each] as string).status) {
              error = true;
              obj[each] = en.errorMessages.InvalidEmail;
            }
            break;
          case each?.toLocaleLowerCase()?.includes("pincode"):
            if (typeof fields[each] === "string" && fields[each]?.length < 6) {
              error = true;
              obj[each] = en.errorMessages.InvalidPincode;
            }
            break;
          case each?.toLocaleLowerCase()?.includes("imei"):
            if (typeof fields[each] === "string" && fields[each]?.length < 15) {
              error = true;
              obj[each] = en.errorMessages.imeiError;
            }
            break;
          case each?.toLocaleLowerCase()?.includes("mobile") ||
            each?.toLocaleLowerCase()?.includes("secoundary") ||
            each?.toLocaleLowerCase()?.includes("phone") ||
            each?.toLocaleLowerCase()?.includes("mobileNumber"):
            if (typeof fields[each] === "string" && fields[each]?.length < 10) {
              error = true;
              obj[each] = en.errorMessages.mobileError;
            }
            break;
          default:
            break;
        }
      }
    } else if (typeof fields[each] === "object") {
      // @ts-ignore: Unreachable code error
      if (!Object.keys(fields[each]).length) {
        error = true;
        obj[each] = en.errorMessages.requiredField;
      }
      if (fields[each].name == "") {
        error = true;
        obj[each] = en.errorMessages.requiredField;
      }
    }
  });
  return { errorFields: { ...errorFields, ...obj }, error };
};
