import { getAadhaarKycUrlPayload } from "../../../interfaces/kycInterfaces";
import { USER_KYC_ACTIONS } from "../actionTypes";

// action to get trust score
export const getTrustScore = (payload: any) => ({
  type: USER_KYC_ACTIONS.GET_TRUST_SCORE_REQUESTED,
  payload,
});

//action to get attachment ids
export const getKycAttachmentIds = (payload: any) => ({
  type: USER_KYC_ACTIONS.GET_KYC_ATTACHMENT_ID_REQUESTED,
  payload,
});

//action to put attachment docs on signed url
export const putKycDocSignedurl = (payload: any) => ({
  type: USER_KYC_ACTIONS.PUT_KYC_DOCUMENT_ON_SIGNED_URL_REQUESTED,
  payload,
});

// action to submit kyc documents
export const submitKycDocuments = (payload: any) => ({
  type: USER_KYC_ACTIONS.SUBMIT_KYC_DOCUMENT_REQUESTED,
  payload,
});

//submit docs v2
export const kycDocUpload = (payload: any) => ({
  type: USER_KYC_ACTIONS.KYC_DOCUMENT_UPLOAD_REQUESTED,
  payload,
});

export const clearUserKycReducerData = (payload: any) => ({
  type: USER_KYC_ACTIONS.CLEAR_KYC_REDUCER_DATA,
  payload,
});

// action to get url for aadhaar digital verification through digilocker
export const getAadhaarKycUrl = (payload: getAadhaarKycUrlPayload) => ({
  type: USER_KYC_ACTIONS.GET_URL_AADHAAR_VERIFICATION_REQUESTED,
  payload,
});
