import { Box, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import CurrentBooking from "./sections/CurrentBooking";
import BookingList from "./sections/BookingList";
import { CustomButton } from "../../../../sharedComponents/atom/Buttons/CustomButtons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBookingHistory,
  getYorBookingHistory,
} from "../../../redux/actions";
import { RootState } from "../../../../redux/reducers";

const MyBookingsScreen = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTab, setActiveTab] = useState("Rentals");


  const { userProfileData } = useSelector(
    (state: RootState) => state.newAuthReducer
  );

  const { bookingHistory, yorBookingHistory } = useSelector(
    (state: RootState) => state.MyBookingReducer
  );

  const { id } = userProfileData;
  const currentBooking = bookingHistory?.bookings?.find(
    (booking) => booking.status === "UPCOMING" || booking.status === "ONGOING"
  );
  const dispatch = useDispatch();

  //get rental and yor booking history
  useEffect(() => {
    if (activeTab === "Rentals") {
      dispatch(
        getBookingHistory({
          page: currentPage,
          pageSize: 2,
          customerId: id,
          customerSort: true,
        })
      );
    } else {
      dispatch(
        getYorBookingHistory({
          page: currentPage,
          pageSize: 4,
        })
      );
    }
  }, [currentPage, activeTab]);
  return (
    <Box sx={{ padding: "30px" }}>
      <Box sx={{ display: "flex", gap: "10px" }}>
        <CustomButton
          variant={activeTab === "Rentals" ? "contained" : "outlined"}
          label="Rentals"
          onClick={() => {
            setActiveTab("Rentals");
            setCurrentPage(1);
          }}
          sx={{ borderRadius: "30px" }}
        />
        <CustomButton
          variant={activeTab === "Subscription" ? "contained" : "outlined"}
          label="Subscription"
          onClick={() => {
            setActiveTab("Subscription");
            setCurrentPage(1);
          }}
          sx={{ borderRadius: "30px" }}
        />
      </Box>
      <Grid container spacing={2}>
        {activeTab === "Rentals" ? (
          <>
            <Grid size={{ sm: 12, md: 6 }} width="100%">
              <CurrentBooking currentBooking={currentBooking} />
            </Grid>
            <Grid size={{ sm: 12, md: 6 }}>
              <BookingList
                bookings={bookingHistory?.bookings}
                pageData={bookingHistory?.pageData}
                custId={id}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                activeTab={activeTab}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid size={12}>
              <BookingList
                bookings={yorBookingHistory?.bookings}
                pageData={yorBookingHistory?.pageData}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                activeTab={activeTab}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};
export default MyBookingsScreen;
