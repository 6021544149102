import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { colors } from "../../../themes/colors";
import YORCheckoutTab from "./sections/CheckoutTab";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { TypographyConstants, YorPlanTabKeys } from "../../../constant/enum";
import styles from "./planDetails.module.css";
import TabIcons from "../../../assets/images/svgComponents/TabIcons";
import {
  getAvailablePlans,
  setUserBookingDataYor,
} from "../../redux/actions/yorActions";
import { useLocation, useNavigate } from "react-router-dom";
import { Routes } from "../../../constant/routeContants";
import { getTimestampAtStartOfDay } from "../../../utills/helper";
import PlanDetailsTab from "./sections/planDetailsTab";

interface initialStateInterface {
  selectedVehicle: string;
  rideStartDate: any;
  selectedRentalPeriod: any;
  selectedPlan: object;
  slots: any;
  helmets: {
    PREMIUM: number;
    HALF_FACE: number;
    FULL_FACE: number;
    KIDS: number;
  };
  selectedTimeSlot: any;
  complementaryHelmet: boolean;
  checkedPlan: any;
  branch: string;
  couponCode: any;
  selectedCoupon: any;
}

const YORPlanDetails = () => {
  const [selectedTab, setSelectedTab] = useState<any>({
    index: 0,
    key: YorPlanTabKeys.PLAN_DETAILS_TAB,
  });

  const initialState: initialStateInterface = {
    selectedVehicle: "",
    rideStartDate: new Date(),
    selectedRentalPeriod: [],
    selectedPlan: {},
    slots: "",
    helmets: {
      PREMIUM: 0,
      HALF_FACE: 0,
      FULL_FACE: 0,
      KIDS: 0,
    },
    selectedTimeSlot: {},
    complementaryHelmet: false,
    checkedPlan: "",
    branch: "",
    couponCode: "",
    selectedCoupon: "",
  };
  const [fields, setFields] = useState({ ...initialState });
  const [errorFields, setErrorFields] = useState({ maxHelmets: "" });
  const [filteredPlan, setfilteredPlan] = useState<any>();
  const { availablePlans, helmetTypes } = useSelector(
    (state: RootState) => state.yorReducer
  );
  const { isAuthenticated } = useSelector(
    (state: RootState) => state.newAuthReducer
  );

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const durationInMonthsArr = availablePlans?.map((plan: any) => {
    return plan.durationInMonths;
  });

  //plans duration array
  const durationInMonths = durationInMonthsArr.filter(
    (value: any, index: any, self: string | any[]) => {
      return self.indexOf(value) === index;
    }
  );

  // use to change top tabs (onClick)
  const handleTabChange = (index: number, key?: string) => {
    setSelectedTab((prev: any) => ({
      ...prev,
      index: index,
      key: key,
    }));
  };

  // filter the plans on basis of duration
  const filteredPlans = () => {
    let plans = availablePlans?.filter(
      (plans: any) => plans?.durationInMonths === fields?.selectedRentalPeriod
    );
    return plans;
  };

  //handle Fields
  const handleFields = (field: any, value: any) => {
    if (field === "selectedRentalPeriod") {
      setFields((prev: any) => ({
        ...prev,
        [field]: value,
        helmets: {
          PREMIUM: 0,
          HALF_FACE: 0,
          FULL_FACE: 0,
          KIDS: 0,
        },
      }));
    } else {
      setFields((prev: any) => ({ ...prev, [field]: value }));
    }
  };
  useEffect(() => {
    if (availablePlans?.length > 0) {
      let plans = filteredPlans();
      setfilteredPlan(plans);
      setFields((prevState: any) => ({
        ...prevState,
        selectedPlan: plans[0],
        selectedRentalPeriod: durationInMonths[0],
        checkedPlan: plans[0]?.id,
        rideStartDate: getTimestampAtStartOfDay(
          location?.state?.availableStartDate
        ),
      }));
    }
    if (!availablePlans?.length) {
      setFields((prevState: any) => ({
        ...prevState,
        selectedPlan: "",
        selectedRentalPeriod: "",
      }));
      setfilteredPlan([]);
    }
  }, [availablePlans]);

  //get availables plans
  useEffect(() => {
    if (!isAuthenticated || !location?.state) {
      navigate(Routes.YOR_HOME_SCREEN);
    } else {
      dispatch(
        getAvailablePlans({
          branchName: location?.state?.branchName,
          modelName: location?.state?.modelDetails?.name,
          startDate: getTimestampAtStartOfDay(
            location?.state?.availableStartDate
          ),
        })
      );
    }
    dispatch(setUserBookingDataYor({}));
  }, []);

  //set selected plan
  useEffect(() => {
    let plans = filteredPlans();
    setfilteredPlan(plans);
    if (fields?.selectedRentalPeriod && plans?.length) {
      setFields((prevState: any) => ({
        ...prevState,
        selectedPlan: plans[0],
      }));
    }
  }, [fields?.selectedRentalPeriod]);

  // to load as per state value (selected tab)
  const componentLoad: any = {
    [YorPlanTabKeys.PLAN_DETAILS_TAB]: (
      <PlanDetailsTab
        fields={fields}
        setFields={setFields}
        handleTabChange={handleTabChange}
        RentalsPlans={availablePlans}
        durationInMonths={durationInMonths}
        filteredPlan={filteredPlan}
        handleFields={handleFields}
        modelDetails={
          location?.state?.modelDetails?.details?.specificSpecifications
        }
        modelName={location?.state?.modelDetails?.displayName}
        imagePath={location?.state?.modelDetails?.imagePath}
        images={location?.state?.modelDetails?.images}
        manufacturer={location?.state?.modelDetails?.manufacturer}
        vehicleType={location?.state?.modelDetails?.type}
      />
    ),
    [YorPlanTabKeys.CHECKOUT_TAB]: (
      <YORCheckoutTab
        fields={fields}
        setFields={setFields}
        additionalHelmets={helmetTypes}
        setErrorFields={setErrorFields}
        errorFields={errorFields}
        handleTabChange={handleTabChange}
        modelDetails={location?.state?.modelDetails}
        handleChange={handleFields}
        imagePath={location?.state?.modelDetails?.imagePath}
        images={location?.state?.modelDetails?.images}
      />
    ),
  };
  return (
    <Box sx={{ padding: { xs: "20px", sm: "20px 150px" } }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <>
          <Box
            sx={{ position: "relative", cursor: "pointer" }}
            onClick={() => {
              handleTabChange(0, YorPlanTabKeys.PLAN_DETAILS_TAB);
            }}
          >
            <Box>
              <TabIcons fill={selectedTab.index == 0 ? colors.primary : ""} />
            </Box>
            <Typography
              className={styles.planTabs}
              variant={TypographyConstants.SUBHEADING}
              sx={{
                left: "50%",
                color: selectedTab.index == 0 ? colors.white : colors.dark_gray,
              }}
            >
              Plan Details
            </Typography>
          </Box>
          <Box
            sx={{
              position: "relative",
              cursor: availablePlans?.length ? "pointer" : "not-allowed",
              marginLeft: "-22px",
            }}
            onClick={() => {
              if (availablePlans?.length) {
                handleTabChange(1, YorPlanTabKeys.CHECKOUT_TAB);
              }
            }}
          >
            <Box>
              <TabIcons
                fill={
                  availablePlans?.length
                    ? selectedTab.index == 1
                      ? colors.primary
                      : ""
                    : colors.light_gray
                }
              />
            </Box>
            <Typography
              className={styles.planTabs}
              variant={TypographyConstants.SUBHEADING}
              sx={{
                right: "33%",
                color: selectedTab.index == 1 ? colors.white : colors.dark_gray,
              }}
            >
              Checkout
            </Typography>
          </Box>
        </>
      </Box>
      {componentLoad[selectedTab.key]}
    </Box>
  );
};

export default YORPlanDetails;
