import { Box, Container, Divider, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { showSEOFooterRoutes } from "../../../constant/routeContants";
import en from "../../../locales/en.json";
import { RootState } from "../../../redux/reducers";
import { colors } from "../../../themes/colors";
import styles from "./index.module.css";

const ExploreContent = () => {
  const { footer } = en;
  const { pathname = "" } = useLocation();
  const showSEOFooter = showSEOFooterRoutes[pathname];
  const { homePageData = [] } = useSelector(
    (state: RootState) => state.cmsDataReducer
  );

  return (
    <Box className={styles.exploreContentMain}>
      {showSEOFooter ? (
        <Container>
          <Divider className={styles.divider} />
          <Typography
            variant="h6"
            color={colors.white}
            fontWeight={500}
            textTransform="uppercase"
          >
            {homePageData[0]?.exploreFooter?.title}
          </Typography>
          <Box className={styles.freedoSeo}>
            <Typography
              variant="caption"
              sx={{
                color: "white",
                textAlign: "left",
              }}
            >
              {homePageData[0]?.exploreFooter?.description}
            </Typography>
            {homePageData[0]?.exploreFooter?.points?.map((data: any) => {
              return (
                <Stack gap={2}>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "white",
                      textDecoration: "underline",
                      textAlign: "left",
                    }}
                  >
                    {data?.title}
                  </Typography>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.content,
                    }}
                  />
                </Stack>
              );
            })}
          </Box>
        </Container>
      ) : null}

      <Box className={styles.copyRight}>{footer["2025FreedoRentals"]}</Box>
    </Box>
  );
};

export default ExploreContent;
