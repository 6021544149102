import { InputBase } from "@mui/material";
import { Box, styled } from "@mui/system";
import * as React from "react";
import { GenericObject } from "../../../interfaces/commonInterface";
import { checkNumeric } from "../../../utills/regex";

const OtpField = React.forwardRef(
  (
    {
      separator,
      length,
      value,
      onChange,
      containerStyle = {},
    }: {
      separator: React.ReactNode;
      length: number;
      value: string;
      onChange: any | Function;
      containerStyle?: GenericObject;
    },
    ref
  ) => {
    const [otp, setOtp] = React.useState<any>([]);
    const inputRefs = React.useRef<HTMLInputElement[]>(
      new Array(length).fill(null)
    );

    const focusInput = (targetIndex: number) => {
      const targetInput = inputRefs.current[targetIndex];
      targetInput?.focus();
    };

    const selectInput = (targetIndex: number) => {
      const targetInput = inputRefs.current[targetIndex];
      targetInput?.select();
    };

    const handleKeyDown = (
      event: React.KeyboardEvent<HTMLInputElement>,
      currentIndex: number
    ) => {
      switch (event.key) {
        case "ArrowUp":
        case "ArrowDown":
        case " ":
          event.preventDefault();
          break;
        case "ArrowLeft":
          event.preventDefault();
          if (currentIndex > 0) {
            focusInput(currentIndex - 1);
            selectInput(currentIndex - 1);
          }
          break;
        case "ArrowRight":
          event.preventDefault();
          if (currentIndex < length - 1) {
            focusInput(currentIndex + 1);
            selectInput(currentIndex + 1);
          }
          break;
        case "Delete":
          event.preventDefault();
          if (currentIndex >= 0) {
            const otpCopy = [...otp];
            otpCopy[currentIndex] = "";
            setOtp(otpCopy);
            onChange("otp", otpCopy.join(""));
          }
          if (currentIndex > 0) {
            focusInput(currentIndex - 1);
            selectInput(currentIndex - 1);
          }
          break;
        case "Backspace":
          event.preventDefault();
          if (currentIndex >= 0) {
            const otpCopy = [...otp];
            otpCopy[currentIndex] = "";
            setOtp(otpCopy);
            onChange("otp", otpCopy.join(""));
          }
          if (currentIndex > 0) {
            focusInput(currentIndex - 1);
            selectInput(currentIndex - 1);
          }
          break;
        default:
          break;
      }
    };

    const handleChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      currentIndex: number
    ) => {
      const currentValue = event.target.value;
      let indexToEnter = 0;
      while (indexToEnter <= currentIndex) {
        if (
          inputRefs.current[indexToEnter].value &&
          indexToEnter < currentIndex
        ) {
          indexToEnter += 1;
        } else {
          break;
        }
      }
      const otpArray = value.split("");
      const lastValue = currentValue[currentValue.length - 1];
      otpArray[indexToEnter] = lastValue;
      const updatedValue = otpArray.join("");

      if (currentValue !== "") {
        if (currentIndex < length - 1) {
          focusInput(currentIndex + 1);
        }
      }
    };

    const handleClick = (
      event: React.MouseEvent<HTMLInputElement, MouseEvent>,
      currentIndex: number
    ) => {
      selectInput(currentIndex);
    };

    const handlePaste = (
      event: React.ClipboardEvent<HTMLInputElement>,
      currentIndex: number
    ) => {
      event.preventDefault();
      const clipboardData = event.clipboardData;
      if (clipboardData.types.includes("text/plain")) {
        let pastedText = clipboardData.getData("text/plain");
        pastedText = pastedText.substring(0, length).trim();
        let indexToEnter = 0;
        while (indexToEnter <= currentIndex) {
          if (
            inputRefs.current[indexToEnter].value &&
            indexToEnter < currentIndex
          ) {
            indexToEnter += 1;
          } else {
            break;
          }
        }
        const otpArray = value.split("");
        for (let i = indexToEnter; i < length; i += 1) {
          const lastValue = pastedText[i - indexToEnter] ?? " ";
          otpArray[i] = lastValue;
        }
        setOtp(otpArray);
        onChange("otp", otpArray.join(""));
      }
    };

    return (
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          ...containerStyle,
        }}
      >
        {new Array(length).fill(null).map((_, index) => (
          <React.Fragment key={index}>
            <InputBase
              slots={{ input: InputElement }}
              inputRef={(ele: any) => {
                inputRefs.current[index] = ele!;
              }}
              autoFocus={index === 0}
              aria-label={`Digit ${index + 1} of OTP`}
              onKeyDown={(event: any) => handleKeyDown(event, index)}
              onChange={(event: any) => {
                if (checkNumeric(event?.target?.value)) {
                  const otpCopy = [...otp];
                  otpCopy[index] = event.target.value;
                  setOtp(otpCopy);
                  onChange("otp", otpCopy.join(""));
                  focusInput(index + 1);
                  selectInput(index + 1);
                }
              }}
              onClick={(event: any) => handleClick(event, index)}
              onPaste={(event: any) => handlePaste(event, index)}
              value={otp[index] ?? ""}
              inputProps={{ maxLength: 1 }}
            />
            {index === length - 1 ? null : separator}
          </React.Fragment>
        ))}
      </Box>
    );
  }
);

const blue = {
  100: "#daecff",
  200: "#80bfff",
  400: "#3399ff",
  500: "#007fff",
  600: "#0072e5",
  700: "#0059b2",
};

const grey = {
  50: "#f3f6f9",
  100: "#e5eaf2",
  200: "#dae2ed",
  300: "#c7d0dd",
  400: "#b0b8c4",
  500: "#9da8b7",
  600: "#6b7a90",
  700: "#434d5b",
  800: "#303740",
  900: "#1c2025",
};

const InputElement = styled("input")(
  ({ theme }) => `
  width: 40px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 0px;
  border-radius: 8px;
  text-align: center;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 4px ${
    theme.palette.mode === "dark" ? "rgba(0,0,0, 0.5)" : "rgba(0,0,0, 0.05)"
  };

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === "dark" ? blue[600] : blue[200]
    };
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

export default OtpField;
