import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { forwardRef, useState } from "react";
import { colors } from "../../../themes/colors";
import { TypographyConstants } from "../../../rental-src/constants/enum";
// import styles from "../customcomponents.module.css";

const Input = forwardRef((props: any, ref) => {
  const [focused, setFocused] = useState<boolean>(false);
  const {
    onIconClick,
    label = "",
    iconStart,
    icon,
    customStyle = {},
    inputContainerStyle = {},
    startAdornment: StartIcon,
    endAdornment: EndIcon,
    customInputProps = {},
    autoComplete = false,
    error = "",
    errormessage = "",
    containerStyle = null,
    topHeading = "",
    topHeadingStyle = {},
  } = props || {};

  return (
    <Box sx={{ ...containerStyle }}>
      {topHeading ? (
        <Typography
          variant={TypographyConstants.SUBTITLE2}
          sx={{
            color: colors.secondary_black,
            marginBottom: "5px",
            ...topHeadingStyle,
          }}
        >
          {topHeading}
        </Typography>
      ) : null}
      <TextField
        id="outlined-search"
        label={label}
        type="text"
        autoComplete={autoComplete ? "on" : "off"}
        InputProps={{
          sx: {
            borderRadius: "8px",
            fontSize: "12px",
            minHeight: "42px",
            maxHeight: "50px",
            height: "40px",
            border: "none",
            ...customStyle,
          },

          startAdornment: (
            <InputAdornment position="start">
              {StartIcon ? (
                typeof StartIcon === "string" ? (
                  StartIcon
                ) : StartIcon ? (
                  <StartIcon />
                ) : (
                  <img src={iconStart} />
                )
              ) : null}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end" sx={{ cursor: "pointer" }}>
              {EndIcon ? <img src={icon} onClick={onIconClick} /> : null}
            </InputAdornment>
          ),
          ...customInputProps,
        }}
        sx={{
          color: "rgba(60, 60, 67, 0.4)",
          width: "100%",
          borderRadius: "60px",
          // remove upper and lower icon in case of type number
          "& input[type=number]": {
            "-moz-appearance": "textfield",
          },
          "& input[type=number]::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
          "& input[type=number]::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
          ...inputContainerStyle,
        }}
        onBlur={() => setFocused(false)}
        onFocus={() => setFocused(true)}
        focused={focused}
        inputRef={ref}
        error={error ?? false}
        {...props}
      />

      <Box>
        {errormessage && (
          <Typography variant="errorMessage1" marginLeft="8px">
            {errormessage}
          </Typography>
        )}
      </Box>
    </Box>
  );
});

export default Input;
