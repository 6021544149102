import { Typography, Skeleton, Tooltip, Box } from "@mui/material";
import { Stack } from "@mui/system";
import { colors } from "../../../themes/colors";
import { TypographyConstants } from "../../../constant/enum";

interface DetailsTypographyProps {
  heading: string;
  value: string | JSX.Element;
  icon?: any;
  link?: string;
  loading?: boolean;
  render?: JSX.Element;
  clickhandler?: any;
  elementClassList?: string;
  width?: string | number;
  color?: any;
  visitStepData?: any;
  clickHandler?: any;
  iconStyle?: object;
}

const StyleObject: any = {
  bookingDetailsValue: {
    fontWeight: "400",
    fontSize: "13px !important",
    fontFamily: "Euclid Circular B !important",
    overflow: "hidden",
    /* width: 100px; */
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
};

const HeadingValueTypography = (props: DetailsTypographyProps) => {
  return (
    <Stack
      width={props?.width ? props?.width : "140px"}
      className={props.elementClassList}
    >
      <Typography
        variant={TypographyConstants.SUBHEADING}
        color={colors.text_gray}
        sx={{ fontSize: "11px" }}
      >
        {props.icon && (
          <img src={props?.icon} alt="" style={{ ...props.iconStyle }} />
        )}
        {props?.heading + " :"}
      </Typography>
      {props?.loading ? (
        <Skeleton />
      ) : (
        <Box sx={{ display: "flex" }}>
          {props?.value && (
            <Tooltip
              title={
                typeof props?.value === "string" && props?.value?.length > 18
                  ? props.value
                  : ""
              }
            >
              <Typography
                sx={{
                  padding: "0px",
                  fontSize: "14px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
                color={props?.color ? props?.color : colors.text_black}
              >
                {props?.value}
              </Typography>
            </Tooltip>
          )}

          {props?.link && (
            <Tooltip title={props?.link?.length > 15 ? props.link : ""}>
              <Typography
                color={colors.THEME_BLUE}
                sx={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  ...StyleObject.bookingDetailsValue,
                }}
                onClick={props?.clickhandler}
              >
                {props.link}
              </Typography>
            </Tooltip>
          )}
          {props?.visitStepData && (
            <Tooltip
              title={
                props?.visitStepData?.value?.length > 20
                  ? props?.visitStepData?.value
                  : ""
              }
            >
              <Typography
                color={props.color ? props.color : colors.THEME_BLUE}
                sx={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  ...StyleObject.bookingDetailsValue,
                }}
                onClick={() => props?.clickHandler(props?.visitStepData?.step)}
              >
                {props?.visitStepData?.value}
              </Typography>
            </Tooltip>
          )}
        </Box>
      )}
    </Stack>
  );
};

export default HeadingValueTypography;
