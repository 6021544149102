export enum LocalStorage {
  token = "token",
  userData = "userData",
  userCity = "user-city",
  zohoLeads = "zoho-leads",
  bookingDetails = "booking-details",
  userSelection = "user-selection",
  yorUserCity = "yor-user-city",
}
export enum AppLink {
  playstoreLink = "https://play.google.com/store/apps/details?id=com.heerofreedo",
  appStoreLink = "https://apps.apple.com/in/app/freedo-rentals/id1603635910",
}
export enum BOOKING_DATA {
  ADDITIONAL_HELMET = 100,
}
export enum STATUS {
  Pending = "Pending",
  Upcoming = "Upcoming",
  Ongoing = "Ongoing",
  Ended = "Ended",
  Cancelled = "Cancelled",
  Completed = "Completed",
}

export enum WAREHOUSE_STATUS {
  Rejected = "rejected",
  Accepted = "accepted",
  Inprocess = "in-process",
}

export enum GOOGLE_MAP {
  key = "AIzaSyC9fP2BopC2GADep1fbIOCrLvGSjywhcUs",
}

export enum windowSize {
  extraLarge = 1200,
  large = 992,
  medium = 768,
}

export enum thankyouPage {
  MITC_Verified = "mitc-verified",
}

export enum toastMessages {
  mandatoryFields = "All fields are mandatory",
}

export enum modalMessages {
  modifyBookingSuccess = "Booking Modification Successful",
  modifyBookingFailed = "Booking Modification Failed",
}

export enum gender {
  male = 1,
  female = 2,
  others = 3,
}

export enum VehicleTypeEnum {
  Bikes = "MOTORCYCLE",
  Scooters = "SCOOTER",
  All = "ALL",
}

export const VehicleTypesArray = ["Bikes", "Scooters"];
export enum VehicleImageTypeEnum {
  RIGHT = "RIGHT",
  LEFT = "LEFT",
  FRONT = "FRONT",
  BACK = "BACK",
}
export enum VehicleFuelTypeEnum {
  PETROL = "PETROL",
  ELECTRIC = "ELECTRIC",
}
export enum useCaseEnum {
  RENTALS = "RENTALS",
  YOR = "YOR",
}

export enum DeliveryModesEnum {
  CUSTOMER_LOCATION = "CUSTOMER_LOCATION",
  BRANCH = "BRANCH",
}

export enum DisplayDeliveryModesEnum {
  CUSTOMER_LOCATION = "Home Delivery",
  BRANCH = "Pick-up Vehicle",
}

export enum TimeSlotsEnum {
  morning = "morning",
  afternoon = "afternoon",
  evening = "evening",
}

export enum BookingPlansEnum {
  REGULAR = "regular",
  PREMIUM = "premium",
  UNLIMITED = "unlimited",
}

export enum HelmetTypesEnum {
  PREMIUM = "PREMIUM",
  FULL_FACE = "FULL_FACE",
  HALF_FACE = "HALF_FACE",
  KIDS = "KIDS",
}

export enum HelmetTypesDisplayEnum {
  PREMIUM = "Premium",
  FULL_FACE = "Full Face",
  HALF_FACE = "Half Face",
  KIDS = "Kids",
}
export enum yorFleetSort {
  LOW_TO_HIGH = "planCharges.monthlyCharges_asc",
  HIGH_TO_LOW = "planCharges.monthlyCharges_desc",
}

export enum Lob {
  yor = "R2O",
}

export enum TypographyConstants {
  YOR_HEADING = "yorHeading",
  EXTRA_SMALL = "extraSmall",
  SMALL_TEXT = "smallText",
  ERROR_MESSAGE1 = "errorMessage1",
  BODY1 = "body1",
  BODY1ITALIC = "body1Italic",
  SUBHEADING = "subheading",
  SUBTITLE1 = "subtitle1",
  SUBTITLE2 = "subtitle2",
  BODY2 = "body2",
  CAPTION = "caption",
}

export enum ChargeTypes {
  ACCESSORY = "ACCESSORY",
  ADDITIONAL = "ADDITIONAL",
  MONTHLY_FEE = "MONTHLY_FEE",
  SUBSCRIPTION_FEE = "SUBSCRIPTION_FEE",
  ADD_ONS = "ADD_ONS",
  MANDATE_REGISTRATION = "MANDATE_REGISTRATION",
  BOOKING_CLOSURE_FEE = "BOOKING_CLOSURE_FEE",
  OTHER = "OTHER",
}

export enum ChargeTypesDisplayName {
  ACCESSORY = "Accessory",
  ADDITIONAL = "Additional",
  MONTHLY_FEE = "Monthly Installment ",
  SUBSCRIPTION_FEE = "Subscription Fee",
  ADD_ONS = "Add Ons",
  MANDATE_REGISTRATION = "Mandate Registration",
  BOOKING_CLOSURE_FEE = "Booking Closure Fee",
  OTHER = "Other",
}

export enum YorPlanTabKeys {
  PLAN_DETAILS_TAB = "PlanDetailsTab",
  CHECKOUT_TAB = "CheckOutTab",
}

export enum planDetailsKeys {
  depositAmount = "freedo X Subscription",
  monthlyCharges = "Monthly Subscription",
  closureAmount = "Upgradation Fee",
}

export enum BenifitType {
  FREE_INSURANCE = "FREE_INSURANCE",
  FREE_MAINTAINANCE = "FREE_MAINTAINANCE",
  FREE_HELMET = "FREE_HELMET",
}

export enum BenefitTypesDisplay {
  FREE_INSURANCE = "Insurance covered",
  FREE_MAINTAINANCE = "Free First 3 servicing",
  FREE_HELMET = "Complementary helmet",
}

export const helmetsDisplayName = {
  PREMIUM: "Premium",
  HALF_FACE: "Half Face",
  FULL_FACE: "Full Face",
  KIDS: "Kids",
};

export enum operatorProcessFlow {
  OPERATOR_REGISTRATION = "operatorRegistration",
  E_SIGN_DOC = "eSignDoc",
  VERIFY_EMAIL = "verifyEmail",
}

export enum operatorLinkType {
  VERIFY_EMAIL = "verify-email",
  E_SIGN = "e-sign",
}

export const helmetsDisplay = {
  premium: "Premium",
  halfFace: "Half Face",
  fullFace: "Full Face",
  kids: "Kids",
};

export const helmetTypes = {
  premium: "PREMIUM",
  halfFace: "HALF_FACE",
  fullFace: "FULL_FACE",
  kids: "KIDS",
};

export enum VehicleTypeDisplay {
  MOTORCYCLE = "Motorcycle",
  SCOOTER = "Scooter",
  HERO = "Hero",
}

export enum PaymentStatus {
  SUCCESS = "SUCCESS",
  PENDING = "PENDING",
  FAILURE = "FAILURE",
}
export enum cityStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum SessionStorage {
  yorStartRide = "yor-start-ride",
}

export enum bookingStatusDisplayName {
  CREATED = "Created",
  PENDING = "Pending",
  BOOKED = "Upcoming",
  ACTIVE = "Ongoing",
  CANCELLED = "Cancelled",
  CLOSED = "Completed",
}

export enum operatorESignStatus {
  UNVERIFIED = "UNVERIFIED",
  VERIFIED = "VERIFIED",
}
