import { toastr } from "react-redux-toastr";
import { call, put, takeEvery } from "redux-saga/effects";
import { handleMessage } from "../../utills/helper";
import { OPERATOR_ACTIONS } from "../actions/actionTypes";
import {
  eSignGenerateOtp,
  eSignResendOtp,
  eSignVerifyOtp,
  fetchEsignDoc,
  getMasterCityList,
  operatorLeadGeneration,
  operatorLeadVerification,
  retryOtpForOperator,
  verifyOperatorEmail,
} from "../services/operatorServices";

const {
  GET_MASTER_CITY_REQUESTED,
  GET_MASTER_CITY_SUCCEEDED,
  GET_MASTER_CITY_FAILED,
  OPERATOR_LEAD_GENERATION_REQUESTED,
  OPERATOR_LEAD_GENERATION_SUCCEEDED,
  OPERATOR_LEAD_GENERATION_FAILED,
  OPERATOR_LEAD_VERIFICATION_REQUESTED,
  OPERATOR_LEAD_VERIFICATION_SUCCEEDED,
  OPERATOR_LEAD_VERIFICATION_FAILED,
  RETRY_OTP_FOR_OPERATOR_REQUESTED,
  RETRY_OTP_FOR_OPERATOR_SUCCEEDED,
  RETRY_OTP_FOR_OPERATOR_FAILED,
  VERIFY_OPERATOR_EMAIL_REQUESTED,
  VERIFY_OPERATOR_EMAIL_SUCCEEDED,
  VERIFY_OPERATOR_EMAIL_FAILED,
  FETCH_ESIGN_DOC_REQUESTED,
  FETCH_ESIGN_DOC_SUCCEEDED,
  FETCH_ESIGN_DOC_FAILED,
  ESIGN_GENERATE_OTP_REQUESTED,
  ESIGN_GENERATE_OTP_SUCCEEDED,
  ESIGN_GENERATE_OTP_FAILED,
  ESIGN_VERIFY_OTP_REQUESTED,
  ESIGN_VERIFY_OTP_SUCCEEDED,
  ESIGN_VERIFY_OTP_FAILED,
  ESIGN_RESEND_OTP_REQUESTED,
  ESIGN_RESEND_OTP_SUCCEEDED,
  ESIGN_RESEND_OTP_FAILED,
} = OPERATOR_ACTIONS;

// Master City List
function* getMasterCityListSaga(action: any): any {
  try {
    const result = yield call(getMasterCityList, action);
    if (result.status === 200) {
      yield put({
        type: GET_MASTER_CITY_SUCCEEDED,
        payload: result?.data?.data || {},
      });
    } else {
      yield put({ type: GET_MASTER_CITY_FAILED });
    }
  } catch (error: any) {
    yield put({ type: GET_MASTER_CITY_FAILED });
    toastr.error("Error", handleMessage(error));
  }
}

// Lead Generation
function* operatorLeadGenerationSaga(action: any): any {
  try {
    const result = yield call(operatorLeadGeneration, action?.payload);
    if (result.status === 200) {
      yield put({
        type: OPERATOR_LEAD_GENERATION_SUCCEEDED,
        payload: result?.data?.data || {},
      });
      //
      if (action?.apiSuccessFunction) {
        action?.apiSuccessFunction({
          ...action?.payload?.data,
          ...result?.data?.data,
        });
      }
      //
    } else {
      yield put({ type: OPERATOR_LEAD_GENERATION_FAILED });
      toastr.info("Info", handleMessage(result));
    }
  } catch (error: any) {
    yield put({ type: OPERATOR_LEAD_GENERATION_FAILED });
    toastr.error("Error", handleMessage(error));
  }
}

// Lead Verification
function* operatorLeadVerificationSaga(action: any): any {
  try {
    const result = yield call(operatorLeadVerification, action?.payload);
    if (result.status === 200) {
      yield put({
        type: OPERATOR_LEAD_VERIFICATION_SUCCEEDED,
        payload: result?.data?.data || {},
      });
      toastr.success("Success", handleMessage(result));
      //
      if (action?.apiSuccessFunction) {
        action?.apiSuccessFunction();
      }
      //
    } else {
      yield put({ type: OPERATOR_LEAD_VERIFICATION_FAILED });
      toastr.info("Info", handleMessage(result));
    }
  } catch (error: any) {
    yield put({ type: OPERATOR_LEAD_VERIFICATION_FAILED });
    toastr.error("Error", handleMessage(error));
  }
}

// Retry OTP For Operator
function* retryOtpForOperatorSaga(action: any): any {
  try {
    const result = yield call(retryOtpForOperator, action?.payload);
    if (result.status === 200) {
      yield put({
        type: RETRY_OTP_FOR_OPERATOR_SUCCEEDED,
        payload: result?.data?.data || {},
      });
      //
      if (action?.apiSuccessFunction) {
        action?.apiSuccessFunction();
      }
      //
    } else {
      yield put({ type: RETRY_OTP_FOR_OPERATOR_FAILED });
      toastr.info("Info", handleMessage(result));
    }
  } catch (error: any) {
    yield put({ type: RETRY_OTP_FOR_OPERATOR_FAILED });
    toastr.error("Error", handleMessage(error));
  }
}

// Verify Operator Email
function* verifyOperatorEmailSaga(action: any): any {
  try {
    const result = yield call(verifyOperatorEmail, action?.payload);
    if (result.status === 200) {
      yield put({
        type: VERIFY_OPERATOR_EMAIL_SUCCEEDED,
        payload: result?.data?.data || {},
      });
      toastr.success("Success", handleMessage(result));
    } else if (result.response.status === 422) {
      yield put({
        type: VERIFY_OPERATOR_EMAIL_SUCCEEDED,
        payload: { ...result?.response?.data?.error, statusCode: 422 },
      });
      toastr.info("Info", handleMessage(result));
    } else {
      yield put({ type: VERIFY_OPERATOR_EMAIL_FAILED });
      toastr.info("Info", handleMessage(result));
    }
  } catch (error: any) {
    yield put({ type: VERIFY_OPERATOR_EMAIL_FAILED });
    toastr.error("Error", handleMessage(error));
  }
}

// Fetch ESign Doc
function* fetchEsignDocSaga(action: any): any {
  try {
    const result = yield call(fetchEsignDoc, action?.payload);
    if (result.status === 200) {
      yield put({
        type: FETCH_ESIGN_DOC_SUCCEEDED,
        payload: result?.data?.data || {},
      });
      //
      if (action?.apiSuccessFunction) {
        action?.apiSuccessFunction();
      }
      //
    } else {
      yield put({ type: FETCH_ESIGN_DOC_FAILED });
      toastr.info("Info", handleMessage(result));
    }
  } catch (error: any) {
    yield put({ type: FETCH_ESIGN_DOC_FAILED });
    toastr.error("Error", handleMessage(error));
  }
}

// ESign Generate OTP
function* eSignGenerateOtpSaga(action: any): any {
  try {
    const result = yield call(eSignGenerateOtp, action?.payload);
    if (result.status === 200) {
      yield put({
        type: ESIGN_GENERATE_OTP_SUCCEEDED,
        payload: result?.data?.data || {},
      });
      //
      if (action?.apiSuccessFunction) {
        action?.apiSuccessFunction({ ...result?.data?.data });
      }
      //
    } else {
      yield put({ type: ESIGN_GENERATE_OTP_FAILED });
      toastr.info("Info", handleMessage(result));
    }
  } catch (error: any) {
    yield put({ type: ESIGN_GENERATE_OTP_FAILED });
    toastr.error("Error", handleMessage(error));
  }
}

// ESign Verify OTP
function* eSignVerifyOtpSaga(action: any): any {
  try {
    const result = yield call(eSignVerifyOtp, action?.payload);
    if (result.status === 200) {
      yield put({
        type: ESIGN_VERIFY_OTP_SUCCEEDED,
        payload: result?.data?.data || {},
      });
      toastr.success("Success", handleMessage(result));
      //
      if (action?.apiSuccessFunction) {
        action?.apiSuccessFunction();
      }
      //
    } else {
      yield put({ type: ESIGN_VERIFY_OTP_FAILED });
      toastr.info("Info", handleMessage(result));
    }
  } catch (error: any) {
    yield put({ type: ESIGN_VERIFY_OTP_FAILED });
    toastr.error("Error", handleMessage(error));
  }
}

// ESign Resend OTP
function* eSignResendOtpSaga(action: any): any {
  try {
    const result = yield call(eSignResendOtp, action?.payload);
    if (result.status === 200) {
      yield put({
        type: ESIGN_RESEND_OTP_SUCCEEDED,
        payload: result?.data?.data || {},
      });
      toastr.success("Success", handleMessage(result));
    } else {
      yield put({ type: ESIGN_RESEND_OTP_FAILED });
      toastr.info("Info", handleMessage(result));
    }
  } catch (error: any) {
    yield put({ type: ESIGN_RESEND_OTP_FAILED });
    toastr.error("Error", handleMessage(error));
  }
}

function* operatorSaga() {
  yield takeEvery(GET_MASTER_CITY_REQUESTED, getMasterCityListSaga);
  yield takeEvery(
    OPERATOR_LEAD_GENERATION_REQUESTED,
    operatorLeadGenerationSaga
  );
  yield takeEvery(
    OPERATOR_LEAD_VERIFICATION_REQUESTED,
    operatorLeadVerificationSaga
  );
  yield takeEvery(RETRY_OTP_FOR_OPERATOR_REQUESTED, retryOtpForOperatorSaga);
  yield takeEvery(VERIFY_OPERATOR_EMAIL_REQUESTED, verifyOperatorEmailSaga);
  yield takeEvery(FETCH_ESIGN_DOC_REQUESTED, fetchEsignDocSaga);
  yield takeEvery(ESIGN_GENERATE_OTP_REQUESTED, eSignGenerateOtpSaga);
  yield takeEvery(ESIGN_VERIFY_OTP_REQUESTED, eSignVerifyOtpSaga);
  yield takeEvery(ESIGN_RESEND_OTP_REQUESTED, eSignResendOtpSaga);
}

export default operatorSaga;
