import { Box, Typography } from "@mui/material";
import { useState } from "react";

const ShowMore = ({ text, characterToShow }) => {
  const [showFullDescription, setFullDescription] = useState(false);
  const showFullDescriptionHandler = () => {
    setFullDescription(!showFullDescription);
  };

  const description = showFullDescription
    ? text
    : text.slice(0, characterToShow);
  return (
    <Box>
      <Box>
        {description}{" "}
        {!showFullDescription && (
          <Typography component="span" style={{ marginLeft: "-3px" }}>...</Typography>
        )}
      </Box>
      <Box
        onClick={showFullDescriptionHandler}
        className="show-more pt-2 d-block text-decoration-underline"
      >
        Read {showFullDescription ? "less" : "more"}
      </Box>
    </Box>
  );
};
export default ShowMore;
