import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { Box, Stack, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { colors } from "../../../../themes/colors";
import styles from "../index.module.css";
import { Tag } from "./Tag";
export const BlogCardData = ({ data, classNameStyle }: any) => {
  const navigate = useNavigate();

  const handleReadMoreClick = () => {
    navigate(`/blog/${data?.title}`, {
      state: {
        detailPageData: data,
      },
    });
  };
  return (
    <Stack className={classNameStyle}>
      <Tag text={data?.buttonText} />
      <Stack gap="1px">
        <Typography
          variant="subtitle1"
          color={colors.black}
          fontWeight="500"
          lineHeight="1.4"
        >
          {data?.title}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: "3px" }}>
          <CalendarTodayIcon sx={{ fontSize: "12px" }} />
          <Typography variant="caption">
            {data?.date} / {data?.location}
          </Typography>
        </Box>
      </Stack>
      <Typography variant="body1" className={styles.blogContent}>
        <div
          dangerouslySetInnerHTML={{
            __html: data?.shortDescription,
          }}
        />
      </Typography>

      <Box onClick={() => handleReadMoreClick()}>
        <Typography
          variant="subtitle2"
          sx={{
            textDecoration: "underline",
          }}
        >
          Read More
        </Typography>
      </Box>
    </Stack>
  );
};
