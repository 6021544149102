import UploadIcon from "@mui/icons-material/Upload";
import { Box, Card, Grid2 as Grid, Stack, Typography } from "@mui/material";
import { FC, useRef } from "react";
import { cameraIcon } from "../../../../assets";
import en from "../../../../locales/en.json";
import { colors } from "../../../../themes/colors";
import { drivingLicenceProps } from "../../../interfaces/kycInterfaces";
import { SuccessContent } from "./SuccessScreen";
import Styles from "./userKyc.module.css";

const { kyc: kycLocale } = en.profile;

const DrivingLicence: FC<drivingLicenceProps> = (props) => {
  const { dlTrustScore, dlPreview, handleFilechange } = props;

  //create ref for file input
  const frontDlRef = useRef<HTMLInputElement | null>(null);
  const backDlRef = useRef<HTMLInputElement | null>(null);

  return (
    <Stack padding={{ xs: "25px", md: "30px" }}>
      {dlTrustScore?.isPresent ? (
        <SuccessContent
          msg={
            dlTrustScore?.isVerified
              ? kycLocale.dlVerifiedSuccess
              : kycLocale.dlUploadSuccess
          }
        />
      ) : (
        <Stack gap={"20px"}>
          <Typography
            variant="body1"
            sx={{
              color: colors.primary,
              fontSize: { xs: "14px", md: "16px" },
              textAlign: "center",
            }}
          >
            {kycLocale.uploadDrivingLicence}
          </Typography>
          <Grid container spacing={5}>
            <Grid
              size={{ xs: 12, md: 6 }}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Stack
                sx={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <Card className={Styles.uploadDrivingLicenceBox}>
                  <img
                    src={
                      dlPreview?.frontSide ? dlPreview.frontSide : cameraIcon
                    }
                    className={Styles.dlImageStyle}
                  />
                </Card>

                <Box
                  className={Styles.docUploadButton}
                  width={{ xs: "30px", md: "41px" }}
                  height={{ xs: "30px", md: "41px" }}
                  onClick={() => frontDlRef?.current?.click()}
                >
                  <input
                    ref={frontDlRef}
                    type="file"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleFilechange(e, "frontSide")
                    }
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <UploadIcon sx={{ color: "white" }} />
                </Box>
              </Stack>
            </Grid>

            <Grid
              size={{ xs: 12, md: 6 }}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Stack
                sx={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <Card className={Styles.uploadDrivingLicenceBox}>
                  <img
                    src={dlPreview?.backSide ? dlPreview.backSide : cameraIcon}
                    className={Styles.dlImageStyle}
                  />
                </Card>
                <Box
                  className={Styles.docUploadButton}
                  width={{ xs: "30px", md: "41px" }}
                  height={{ xs: "30px", md: "41px" }}
                  onClick={() => backDlRef?.current?.click()}
                >
                  <input
                    ref={backDlRef}
                    type="file"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleFilechange(e, "backSide")
                    }
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <UploadIcon sx={{ color: "white" }} />
                </Box>
              </Stack>
            </Grid>
          </Grid>

          <Stack>
            <Typography
              variant="subtitle2"
              sx={{
                color: colors.secondary_black,
                fontSize: "12px",
                textAlign: "center",
              }}
            >
              {kycLocale.uploadFrontBackLicence}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                color: colors.secondary_dark_gray,
                fontSize: "12px",
                textAlign: "center",
              }}
            >
              {kycLocale.supportedFormats}
            </Typography>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default DrivingLicence;
