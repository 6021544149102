import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { TypographyConstants } from "../../../../../../constant/enum";
import { colors } from "../../../../../../themes/colors";
import { getFormattedINR } from "../../../../../../utills/helper";
import en from "../../../../../../locales/en.json";
const VehiclePriceDetails = ({
  HeadingValue,
  paymentSummary,
  planSummary,
}: any) => {
  return (
    <Stack>
      <HeadingValue
        heading={en.bookingDetails.packageCharges}
        value={getFormattedINR(planSummary?.mainBookingPlanCharge?.amount)}
      />
      <HeadingValue
        heading={en.bookingDetails.surgeCharges}
        value={getFormattedINR(planSummary?.surgeCharges)}
      />
      <HeadingValue
        heading={en.bookingDetails.securityDeposit}
        value={getFormattedINR(planSummary?.securityCharge?.amount)}
      />
      <HeadingValue
        heading={en.bookingDetails.additionalHelmet}
        value={getFormattedINR(planSummary?.accessoryCharge?.amount)}
      />
      <HeadingValue
        heading={en.bookingDetails.homeDeliveryCharge}
        value={getFormattedINR(planSummary?.homeDeliveryCharge?.amount)}
      />
      <HeadingValue
        heading={en.bookingDetails.totalAmount}
        value={getFormattedINR(
          (planSummary?.mainBookingPlanCharge?.amount ?? 0) +
            (planSummary?.surgeCharges ?? 0) +
            (planSummary?.securityCharge?.amount ?? 0) +
            (planSummary?.accessoryCharge?.amount ?? 0) +
            (planSummary?.homeDeliveryCharge?.amount ?? 0)
        )}
      />
    </Stack>
  );
};

export default VehiclePriceDetails;
