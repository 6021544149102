import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { timeSlot123 } from "../assets";
import { TimeSlotsEnum } from "../constant/enum";
import en from "../locales/en.json";
import { getDisplayTimeSlots } from "../utills/helper";
const SelectTimeSlot = (props: any) => {
  const { availableTimeSlots } = useSelector(
    (state: any) => state.rentalsCommonReducer
  );
  const [selectedSlot, setSelectedSlot] = useState<string>(
    TimeSlotsEnum.morning
  );
  const [selectedtimeID, setSelectedTimeID] = useState<number>(0);
  const availableSlots = availableTimeSlots.slots ?? [];
  const { timeSlot } = en;

  // setting default timeslot
  useEffect(() => {
    if (availableSlots && Object.keys(availableSlots).length > 0) {
      let defaultSelected = null;
      if (
        availableSlots[TimeSlotsEnum.morning] &&
        availableSlots[TimeSlotsEnum.morning].length > 0
      ) {
        setSelectedSlot(TimeSlotsEnum.morning);
        setSelectedTimeID(availableSlots[TimeSlotsEnum.morning][0]);
        defaultSelected = availableSlots[TimeSlotsEnum.morning][0];
      } else if (
        availableSlots[TimeSlotsEnum.afternoon] &&
        availableSlots[TimeSlotsEnum.afternoon].length > 0
      ) {
        setSelectedSlot(TimeSlotsEnum.afternoon);
        setSelectedTimeID(availableSlots[TimeSlotsEnum.afternoon][0]);
        defaultSelected = availableSlots[TimeSlotsEnum.afternoon][0];
      } else if (
        availableSlots[TimeSlotsEnum.evening] &&
        availableSlots[TimeSlotsEnum.evening].length > 0
      ) {
        setSelectedSlot(TimeSlotsEnum.evening);
        setSelectedTimeID(availableSlots[TimeSlotsEnum.evening][0]);
        defaultSelected = availableSlots[TimeSlotsEnum.evening][0];
      }
      props.handleChange(defaultSelected);
    }
  }, []);

  // slot select handler
  const handleTimeChange = (timeSlot: any) => {
    setSelectedTimeID(timeSlot);
    props.handleChange(timeSlot);
  };

  return (
    <>
      <Stack className="time-slot-details">
        <Stack direction={"row"} alignItems={"center"} gap="0.5rem">
          <img src={timeSlot123} style={{ height: "1rem" }} />
          <Typography component="span">
            {props.isModifyRide ? timeSlot.selectNewSlot : timeSlot.selectSlot}
          </Typography>
        </Stack>

        {/* ---------------- Slots ---------------- */}
        <ul className="nav nav-tabs time-slot" role="tablist">
          <li className="nav-item" key={"morningTimeslot"}>
            <a
              className={`nav-link ${
                selectedSlot === TimeSlotsEnum.morning ? "active" : ""
              }`}
              onClick={() => setSelectedSlot(TimeSlotsEnum.morning)}
            >
              <span className="timeslot-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H4A1,1,0,0,0,5,12ZM5.64,17l-.71.71a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l.71-.71A1,1,0,0,0,5.64,17ZM12,5a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4A1,1,0,0,0,12,5Zm5.66,2.34a1,1,0,0,0,.7-.29l.71-.71a1,1,0,1,0-1.41-1.41L17,5.64a1,1,0,0,0,0,1.41A1,1,0,0,0,17.66,7.34Zm-12-.29a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41l-.71-.71A1,1,0,0,0,4.93,6.34ZM21,11H20a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2ZM18.36,17A1,1,0,0,0,17,18.36l.71.71a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41ZM12,6.5A5.5,5.5,0,1,0,17.5,12,5.51,5.51,0,0,0,12,6.5Zm0,9A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5ZM12,19a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V20A1,1,0,0,0,12,19Z" />
                </svg>
              </span>
              {timeSlot.morning}
            </a>
          </li>
          <li className="nav-item" key={"afternoonTimeslot"}>
            <a
              className={`nav-link ${
                selectedSlot === TimeSlotsEnum.afternoon ? "active" : ""
              }`}
              onClick={() => setSelectedSlot(TimeSlotsEnum.afternoon)}
            >
              <span className="timeslot-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="512"
                  height="512"
                  viewBox="0 0 135.467 135.467"
                >
                  <path
                    d="M17310.531 593.756a10 10 0 0 0-9.994 10.002v54.994a10 10 0 0 0 9.994 10.004 10 10 0 0 0 10.002-10.004v-54.994a10 10 0 0 0-10.002-10.002zm-120.31 32.2a10 10 0 0 0-4.864 1.343 10 10 0 0 0-3.662 13.656l27.506 47.635a10 10 0 0 0 13.656 3.662 10 10 0 0 0 3.661-13.664l-27.497-47.635a10 10 0 0 0-8.535-4.998 10 10 0 0 0-.265 0zm240.554 0a10 10 0 0 0-8.726 4.997l-27.496 47.635a10 10 0 0 0 3.654 13.664 10 10 0 0 0 13.662-3.662l27.498-47.635a10 10 0 0 0-3.654-13.656 10 10 0 0 0-4.938-1.344zm-119.293 68.458c-79.637-.507-145.416 61.444-150.615 139.998h-74.998a10 10 0 0 0-10.002 10.002 10 10 0 0 0 10.002 10.002h449.328a10 10 0 0 0 10.004-10.002 10 10 0 0 0-10.004-10.002h-75.008c-5.141-77.718-69.716-139.496-148.707-139.998zm-209.031 19.518a10 10 0 0 0-8.726 4.998 10 10 0 0 0 3.654 13.664l47.637 27.498a10 10 0 0 0 13.662-3.662 10 10 0 0 0-3.66-13.657l-47.637-27.505a10 10 0 0 0-4.93-1.336zm416.102 0a10 10 0 0 0-4.864 1.336l-47.636 27.505a10 10 0 0 0-3.66 13.657 10 10 0 0 0 13.662 3.662l47.629-27.498a10 10 0 0 0 3.662-13.664 10 10 0 0 0-8.528-4.998 10 10 0 0 0-.265 0zm-207.196.488c68.224.434 123.729 53.082 128.797 119.992h-259.232c5.135-67.63 61.653-120.43 130.435-119.992z"
                    color="#000"
                    fontFamily="sans-serif"
                    fontWeight="400"
                    overflow="visible"
                    transform="translate(-4928.709 -141.264) scale(.28864)"
                  ></path>
                </svg>
              </span>
              {timeSlot.afternoon}
            </a>
          </li>
          <li className="nav-item" key={"eveningTimeslot"}>
            <a
              className={`nav-link ${
                selectedSlot === TimeSlotsEnum.evening ? "active" : ""
              }`}
              onClick={() => setSelectedSlot(TimeSlotsEnum.evening)}
            >
              <span className="timeslot-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                >
                  <path d="M16 6a1 1 0 001-1V1a1 1 0 00-2 0v4a1 1 0 001 1zM16 26a1 1 0 00-1 1v4a1 1 0 002 0v-4a1 1 0 00-1-1zM6 16a1 1 0 00-1-1H1a1 1 0 000 2h4a1 1 0 001-1zM31 15h-4a1 1 0 000 2h4a1 1 0 000-2zM7.515 8.929a.999.999 0 101.414-1.414L6.101 4.686A.999.999 0 104.687 6.1l2.828 2.829zM24.485 23.071a.999.999 0 10-1.414 1.414l2.828 2.828a1 1 0 101.414-1.414l-2.828-2.828zM7.515 23.071l-2.828 2.828a.999.999 0 101.414 1.414l2.828-2.828a.999.999 0 10-1.414-1.414zM24.485 8.929L27.314 6.1A.999.999 0 1025.9 4.686l-2.828 2.828a.999.999 0 101.413 1.415zM16 7a9 9 0 00-9 9c0 4.971 4.029 9 9 9s9-4.029 9-9a9 9 0 00-9-9zm0 17V8a8 8 0 010 16z" />
                </svg>
              </span>
              {timeSlot.evening}
            </a>
          </li>
        </ul>

        {/* --------------- Slot Timimg ----------- */}
        <Box className="tab-content">
          <Box
            id="home"
            className={`tab-pane ${
              selectedSlot === TimeSlotsEnum.morning ? "active" : "fade"
            }`}
          >
            <Box className="timing-sets">
              {availableSlots.morning ? (
                availableSlots.morning.map((item: any, index: number) => {
                  const timeslots = item;
                  return (
                    <Box className="form-check" key={`morning${index}`}>
                      <input
                        className="form-check-input"
                        type="radio"
                        id={`morning${index}`}
                        name="timeslot"
                        value="huey"
                        checked={
                          selectedSlot === TimeSlotsEnum.morning &&
                          selectedtimeID === timeslots
                        }
                        onChange={() => handleTimeChange(timeslots)}
                      />
                      <label
                        htmlFor={`morning${index}`}
                        className="form-check-labe smallest-text"
                      >{`${getDisplayTimeSlots(
                        timeslots.start
                      )} - ${getDisplayTimeSlots(timeslots.end)}`}</label>
                    </Box>
                  );
                })
              ) : (
                <p>{timeSlot.NoSlot}</p>
              )}
            </Box>
          </Box>
          <Box
            id="menu1"
            className={`tab-pane ${
              selectedSlot === TimeSlotsEnum.afternoon ? "active" : "fade"
            }`}
          >
            <Box className="timing-sets">
              {availableSlots.afternoon ? (
                availableSlots.afternoon.map((item: any, index: number) => {
                  const timeslots = item;
                  return (
                    <Box className="form-check" key={`afternoon${index}`}>
                      <input
                        className="form-check-input"
                        type="radio"
                        id={`afternoon${index}`}
                        name="timeslot"
                        value="huey"
                        checked={
                          selectedSlot === TimeSlotsEnum.afternoon &&
                          selectedtimeID === timeslots
                        }
                        onChange={() => handleTimeChange(timeslots)}
                      />
                      <label
                        htmlFor={`afternoon${index}`}
                        className="form-check-labe smallest-text"
                      >{`${getDisplayTimeSlots(
                        timeslots.start
                      )} - ${getDisplayTimeSlots(timeslots.end)}`}</label>
                    </Box>
                  );
                })
              ) : (
                <p>{timeSlot.NoSlot}</p>
              )}
            </Box>
          </Box>
          <Box
            id="menu2"
            className={`tab-pane ${
              selectedSlot === TimeSlotsEnum.evening ? "active" : "fade"
            }`}
          >
            <Box className="timing-sets">
              {availableSlots.evening ? (
                availableSlots.evening.map((item: any, index: number) => {
                  const timeslots = item;
                  return (
                    <Box className="form-check" key={`evening${index}`}>
                      <input
                        className="form-check-input"
                        type="radio"
                        id={`evening${index}`}
                        name="timeslot"
                        value="huey"
                        checked={
                          selectedSlot === TimeSlotsEnum.evening &&
                          selectedtimeID === timeslots
                        }
                        onChange={() => handleTimeChange(timeslots)}
                      />
                      <label
                        htmlFor={`evening${index}`}
                        className="form-check-labe smallest-text"
                      >{`${getDisplayTimeSlots(
                        timeslots.start
                      )} - ${getDisplayTimeSlots(timeslots.end)}`}</label>
                    </Box>
                  );
                })
              ) : (
                <p>{timeSlot.NoSlot}</p>
              )}
            </Box>
          </Box>
        </Box>
        {props.deliveryType && availableSlots.homeDeliveryTime && (
          <Typography component="p" className="applicable-info-deliveryTime">
            <span>*</span>
            {` After ${availableSlots.homeDeliveryTime} , Home Delivery is not applicable.`}
          </Typography>
        )}
      </Stack>
    </>
  );
};

export default SelectTimeSlot;
