import { BASE_URL, getApiUrl } from "../../../config/apiUrls";
import { customAxios } from "../../../config/axiosConfig";
import { constructUrl } from "../../../utills/helper";

//get booking history
const getBookingHistory = (payload: any) => {
  try {
    const url = BASE_URL + getApiUrl().getBookingHistory;
    let urlWithParams = constructUrl(url, payload);
    return customAxios.get(urlWithParams).catch((err) => err);
  } catch (err) {
    return err;
  }
};

//get booking details
const getBookingDetails = (payload: any) => {
  try {
    const url = BASE_URL + getApiUrl(payload?.id).getLtrBookingDetails;
    return customAxios.get(url).catch((err) => err);
  } catch (err) {
    return err;
  }
};

//get yor booking history
const getYorBookingHistory = (payload: any) => {
  try {
    const url = BASE_URL + getApiUrl().getYorBookingHistory;
    let urlWithParams = constructUrl(url, payload);
    return customAxios.get(urlWithParams).catch((err) => err);
  } catch (err) {
    return err;
  }
};

//get vehicle documents
const getVehicleDocuments = (payload: any) => {
  try {
    const url = BASE_URL + getApiUrl().getVehicleDocuments;
    let urlWithParams = constructUrl(url, payload);
    return customAxios.get(urlWithParams).catch((err) => err);
  } catch (err) {
    return err;
  }
};

//download vehicle documents
const downloadDocument = (payload: any) => {
  try {
    const url = BASE_URL + getApiUrl(payload.ids).downloadVehicleDocument;
    return customAxios.get(url).catch((err) => err);
  } catch (err) {
    return err;
  }
};

/* get cancellation charges */
const getBookingCencellationCharges = (payload: any) => {
  try {
    const url =
      BASE_URL +
      getApiUrl(payload?.bookingId).getBookingCencellationCharges
    return customAxios.get(url).catch((err) => err);
  } catch (error) {
    return error;
  }
};

/* cancel user booking */
const cancelUserBooking = (payload: any) => {
  try {
    const url = BASE_URL + getApiUrl(payload?.bookingId).cancleBooking;
    return customAxios
      .delete(url, { data: payload?.apiBody })
      .catch((err) => err);
  } catch (error) {
    return error;
  }
};

export default {
  getBookingHistory,
  getBookingDetails,
  getYorBookingHistory,
  getVehicleDocuments,
  downloadDocument,
  getBookingCencellationCharges,
  cancelUserBooking,
};
