import * as React from "react";
import usePagination from "@mui/material/usePagination";
import { styled } from "@mui/material/styles";
import { colors } from "../../../../../themes/colors";

const List = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
});

export default function UsePagination({
  count,
  handlePagination,
  currentPage,
  itemsPerPage,
}: any) {
  const { items } = usePagination({
    count: Math.ceil(count / itemsPerPage),
    page: currentPage,
  });
  const handlePageChange = (e, page) => {
    handlePagination(page);
  };

  return (
    <nav>
      <List>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children: any = null;

          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = "…";
          } else if (type === "page") {
            children = (
              <button
                type="button"
                style={{
                  //fontWeight: selected ? "bold" : undefined,
                  padding: "4px 5px",
                  border: "1px solid #dee2e6",
                  color: selected ? "#FFF" : colors.THEME_BLUE,
                  backgroundColor: selected ? colors.THEME_BLUE : "#FFF",
                }}
                {...item}
                onClick={(event) => {
                  handlePageChange(event, page);
                }}
              >
                {page}
              </button>
            );
          } else {
            children = (
              <button
                className="pageChangeButton"
                type="button"
                {...item}
                style={{
                  padding: "4px 5px",
                  border: "1px solid #dee2e6",
                  borderRadius:
                    type === "previous"
                      ? "10px 0px 0px 10px"
                      : "0px 10px 10px 0px",
                  backgroundColor: "#FFF",
                  color: item.disabled ? colors.text_gray : colors.THEME_BLUE,
                }}
                onClick={(event) => handlePageChange(event, page)}
                //disabled={item.disabled ? true : false}
              >
                {type}
              </button>
            );
          }

          return <li key={index}>{children}</li>;
        })}
      </List>
    </nav>
  );
}
