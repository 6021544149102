import { Box, Popover } from "@mui/material";
import React, { ReactNode, useRef } from "react";
import { GenericObject } from "../../../utills/helper";

interface CustomPopoverProps {
  children: ReactNode;
  popoverContent: ReactNode;
  contentStyle?: GenericObject;
  popoverStyle?: GenericObject;
  open: boolean;
  onClose: () => void;
  [key: string]: any; // To accept rest of the props
}
const CustomPopover: React.FC<CustomPopoverProps> = ({
  children, // need to pass ui on which the Popover should be open relatively
  popoverContent, // need to ui of the Popover
  contentStyle, // pass the Popover Content Style
  popoverStyle, // pass the Popover Style
  open, // pass the Popover is open or close (boolean)
  onClose, // props to close popover function
  ...rest
}) => {
  // Ref to store the reference to the child element
  const anchorRef = useRef<HTMLDivElement | null>(null);

  return (
    <Box>
      <Box ref={anchorRef} onClick={onClose}>
        {children}
      </Box>

      {/* Popover component */}
      <Popover
        open={open}
        onClose={onClose}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          "& .MuiPopover-paper": {
            borderRadius: "4px",
            border: "1px solid rgba(0, 0, 0, 0.175)",
            boxShadow: "none",
            padding: "14px 18px",
          },
          ...popoverStyle,
        }}
        {...rest}
      >
        <Box sx={{ ...contentStyle }}>{popoverContent}</Box>
      </Popover>
    </Box>
  );
};
export default CustomPopover;
