import React, { useEffect, useState } from "react";
import "./component.css";
import "../themes/global.css";
import { useDispatch, useSelector } from "react-redux";
import { getVehicleDetailsAction } from "../redux/actions";
import Modal from "react-bootstrap/Modal";
import { getRentalModelDetails } from "../redux/actions/commonActions";
import { WEB_URL, modelImageBucketUrl } from "../config/apiUrls";
import { GenericObject } from "../utills/helper";
import { VehicleImageTypeEnum } from "../constant/enum";
import Typography from "@mui/material/Typography";
import GenericModal from "../sharedComponents/molecule/Modals/GenericModal";
import { Box, Stack } from "@mui/material";
import { colors } from "../themes/colors";
import {
  CustomButton,
  CustomIconButton,
} from "../sharedComponents/atom/Buttons/CustomButtons";

const initialState = {
  type: "",
  brand: "",
  model: "",
  consumption: "",
  hp: "",
  cc: "",
  images: [],
};
interface vecleInterface {
  type: string;
  brand: string;
  model: string;
  consumption: string;
  hp: string;
  cc: string;
  images: Array<string>;
}
const CustomModalVehicleDetail = ({
  showBookVehicle,
  setOpenDetailModal,
  handleBookVehicle,
  openDetailModal,
  modelId,
  rentingFleetLoader,
  vehicleModalToggleHandler,
}: any) => {
  const { rentalModelDetails, rentalCommonLoader } = useSelector(
    (state: any) => state.rentalsCommonReducer
  );
  const {
    name: modelName,
    manufacturer,
    displayName,
    type: ModelType,
    imagePath: vehicleImagePath,
    details: {
      fuelType = "",
      specificSpecifications: {
        averageEconomy = "",
        cubicCapacity = "",
        hp: modelHp = "",
      } = {},
    } = {},
    ...rest
  } = rentalModelDetails;
  const dispatch = useDispatch();

  useEffect(() => {
    if (openDetailModal) {
      const modelDetailPayload = {
        modelNames: [modelId],
      };
      dispatch(getRentalModelDetails(modelDetailPayload, handleSuccess));
    }
  }, [openDetailModal]);

  const handleSuccess = (data) => {
    const vehicleDetails = data?.vehicleDetails;
    const modalImagePath = data?.imagePath;
    // if (modalImagePath) setModalImagePath({ ...modalImagePath });
    // if (vehicleDetails) setVehicleData({ ...vehicleDetails });
  };

  return (
    <>
      {openDetailModal && (
        <GenericModal
          isOpen={openDetailModal}
          handleClose={vehicleModalToggleHandler}
          title={"Vehicle Details"}
          modalWidth={"70vw"}
        >
          <Stack
            direction="row"
            className="vehicle-specs vehicle-model"
            gap="10px"
          >
            {/* ----------------- Left Carousel ------------------------- */}
            <Box width="50%">
              <Box
                id="vehicleindicator"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <Box className="carousel-indicators">
                  {rentalModelDetails?.images?.length > 0 &&
                    rentalModelDetails.images.map((image, ind) => (
                      <button
                        style={{
                          backgroundImage: `url(${WEB_URL}/${vehicleImagePath}/${image?.type}-${image?.fileName})`,
                          backgroundPosition: "center",
                        }}
                        key={ind}
                        type="button"
                        data-bs-target="#vehicleindicator"
                        data-bs-slide-to={ind}
                        className={`${"slider-" + (ind + 1)} ${
                          ind == 0 && "active"
                        }`}
                        aria-current="true"
                        aria-label={`${"Slide" + " " + (ind + 1)}`}
                      ></button>
                    ))}
                </Box>
                <Box className="carousel-inner">
                  {rentalModelDetails?.images?.length > 0 &&
                    rentalModelDetails.images.map((image, ind) => {
                      return (
                        <Box
                          key={ind}
                          className={`carousel-item ${ind === 0 && "active"}`}
                        >
                          <img
                            className="w-100 img-responsive"
                            src={`${WEB_URL}/${vehicleImagePath}/${image?.type}-${image?.fileName}`}
                            alt="..."
                          />
                        </Box>
                      );
                    })}
                </Box>
                {/* ----------------- Carousel Controls ----------------- */}
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#vehicleindicator"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#vehicleindicator"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </Box>
            </Box>

            {/* ------------------------ Right Detail ------------------- */}
            <Stack width="50%" justifyContent={"space-between"}>
              <Stack>
                <Typography
                  variant="h6"
                  sx={{ color: colors.primary_red, margin: "3px 0" }}
                >
                  {manufacturer ?? "N/A"}
                </Typography>
                <Typography variant="h6">{displayName ?? "N/A"}</Typography>

                <Typography variant="h5" className="specific mt-3">
                  Specification
                </Typography>
                <ul className="specific-list">
                  <li>
                    <span>Type:</span>
                    <span>{ModelType?.name ?? "N/A"}</span>
                  </li>
                  <li>
                    <span>Brand:</span>
                    <span>{manufacturer ?? "N/A"}</span>
                  </li>

                  <li>
                    <span>Model:</span>
                    <span>{displayName || "N/A"}</span>
                  </li>

                  <li>
                    <span>Consumption:</span>
                    <span>{averageEconomy || "N/A"}</span>
                  </li>

                  <li>
                    <span>HP:</span>
                    <span>{modelHp || "N/A"}</span>
                  </li>

                  <li>
                    <span>CC:</span>
                    <span>{cubicCapacity || "N/A"}</span>
                  </li>
                </ul>
              </Stack>

              {/* --------------------- Book Vehicle ---------------------- */}
              {showBookVehicle && (
                <>
                  <CustomButton
                    loading={rentingFleetLoader}
                    onClick={() => {
                      handleBookVehicle();
                    }}
                    variant="contained"
                    sx={{
                      background: colors.button_bg_primary,
                      borderRadius: "50rem",
                      mt: 4
                    }}
                  >
                    Book Vehicle
                  </CustomButton>
                </>
              )}
            </Stack>
          </Stack>
        </GenericModal>
      )}
    </>
  );
};

export default CustomModalVehicleDetail;
