import React from "react";

function TabIcons(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="273"
      height="44"
      viewBox="0 0 273 44"
      fill="none"
    >
      <path
        stroke="#ECECEC"
        fill={props.fill ? props.fill : "none"}
        d="M252.52 43.5H1.87149L21.1112 22.3363L21.4169 22L21.1112 21.6637L1.87149 0.5H252.52L272.065 22L252.52 43.5Z"
      />
    </svg>
  );
}

export default TabIcons;
