import { Box, Container, Stack, Typography } from "@mui/material";
import en from "../../../locales/en.json";
import { Link, useNavigate } from "react-router-dom";
import { Routes } from "../../../constant/routeContants";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Grid from "@mui/material/Grid2";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeliveryMode from "../../../component/DeliveryMode";
import { getLocalStorageData } from "../../../utills/helper";
import {
  BookingPlansEnum,
  HelmetTypesEnum,
  DeliveryModesEnum,
  LocalStorage,
} from "../../../constant/enum";
import CheckoutAddress from "./sections/CheckoutAddress";
import CheckoutProductDetail from "./sections/CheckoutProductDetail";
import SelectTimeSlot from "../../../component/SelectTimeSlot";
import BillingDetails from "./sections/BillingDetails";
import { toastr } from "react-redux-toastr";
import { RootState } from "../../../redux/reducers";
import { checkoutScreenImg } from "../../../assets";
import {
  getAvailableTimeSlots,
  getBillingCalculatedCharges,
  getDeliverySlotPause,
} from "../../../redux/actions/commonActions";
import { PlanTypes } from "../../interfaces/checkoutInterface";

const { checkout, errorMessages } = en;

const topStepperSteps = [
  checkout.selectVehicle,
  checkout.reviewBooking,
  checkout.payment,
];
const CheckoutScreen = () => {
  const {
    customerAddresses,
    modelCheckoutDetails,
    availableTimeSlots,
    rentingFleetFilter,
    calculatedCharges,
  } = useSelector((state: any) => state.rentalsCommonReducer);
  const { userProfileData } = useSelector((state: any) => state.newAuthReducer);
  const { allAddresses } = useSelector((state: any) => state.MasterReducer);
  const {
    homeAddressList,
    addressNotDeliverable,
    // vehicleData: vehicleDataFromApi,
    branchSlots,
  } = useSelector((state: RootState) => state.checkoutReducer);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userCity = getLocalStorageData(LocalStorage.userCity);
  const userSelection = getLocalStorageData(LocalStorage.userSelection);
  const isVehicleData =
    modelCheckoutDetails && Object.keys(modelCheckoutDetails).length;
  let vehicleData = modelCheckoutDetails;
  const totalBranches = modelCheckoutDetails?.availability?.length;
  let defaultSelectedAddress =
    allAddresses && allAddresses.length > 0
      ? allAddresses.find((address, index) => address.default)
      : null;
  defaultSelectedAddress = defaultSelectedAddress
    ? defaultSelectedAddress
    : allAddresses[0];
  let defaultAddress = null;
  if (
    customerAddresses &&
    customerAddresses?.addresses &&
    customerAddresses?.addresses?.length > 0
  ) {
    let addressListArr = customerAddresses?.addresses;
    defaultAddress = addressListArr?.find((address: any) => address.default);
    if (!defaultAddress) {
      defaultAddress = addressListArr[0];
    }
  }
  const initialCheckout = {
    homeDelivery: false,
    selectedDeliveryMode: DeliveryModesEnum.BRANCH,
    bikeData: {},
    slot: null,
    selectedVehicle: modelCheckoutDetails?.modelName
      ? modelCheckoutDetails?.modelName
      : null,
    selectedBranch:
      totalBranches === 1 ? modelCheckoutDetails?.availability[0] : null,
    selectedBranchObj:
      totalBranches === 1 ? modelCheckoutDetails?.availability[0] : null,
    selectedHomeAddress: defaultSelectedAddress,
    selectedCoupon: null,
    helmetData: null,
    isCOD: false,
    isCompHelmetSelected: true,
    selectedPlan: BookingPlansEnum.REGULAR,
    deliveryDetails: {
      type: "BRANCH", //BRANCH
      slot:
        availableTimeSlots && availableTimeSlots?.length > 0
          ? availableTimeSlots?.slots[0]
          : {
              start: "11:00",
              end: "12:00",
            },
      // "addressId": "1M08KPAUE0HS" //optional
    },
    isAdditionalHelmetChecked: false,
    additionalHelmet: {
      [HelmetTypesEnum.PREMIUM]: 0,
      [HelmetTypesEnum.FULL_FACE]: 0,
      [HelmetTypesEnum.HALF_FACE]: 0,
      [HelmetTypesEnum.KIDS]: 0,
    }, // selectedBranch ->availability?.config - filter helmet type
  };
  const [checkoutData, setCheckoutData] = useState<any>(initialCheckout);
  const [additionalHelmet, setAdditionalHelmet] = useState<boolean>(false);
  const [additionalHelmetData, setAdditionalHelmetData] = useState<any>(null);
  const [payNowLoader, setPayNowLoader] = useState<boolean>(false);
  const [errorFields, setErrorFields] = useState({ maxHelmets: "" });

  // selected branch computation
  const getSelectedBranchDetails = (selectedBranch, availableBranchList) => {
    let selectedBranchObj = {
      branchDetails: {},
      config: {},
      pricing: {},
    };
    if (availableBranchList && availableBranchList.length > 0) {
      selectedBranchObj = availableBranchList.find(
        (branch) => branch.branchDetails.name === selectedBranch.name
      );
    }
    return selectedBranchObj;
  };

  let selectedBranchDetails = {
    branchDetails: {},
    config: {},
    pricing: {},
  };
  if (checkoutData?.selectedBranch) {
    selectedBranchDetails = getSelectedBranchDetails(
      checkoutData.selectedBranch,
      modelCheckoutDetails?.availability
    );
  }

  const isBranchSelected = checkoutData?.selectedBranchDetails
    ? Object.keys(checkoutData?.selectedBranchDetails).length > 0
    : false;
  const branchPricing = isBranchSelected
    ? checkoutData?.selectedBranchDetails?.pricing
    : {};
  const branchConfig = isBranchSelected
    ? checkoutData?.selectedBranchDetails?.config
    : {};

  const additionHelmetChangeHandler = (helmetType) => {
    setCheckoutData((prev) => {
      return {
        ...prev,
        additionalHelmet: {
          [HelmetTypesEnum[helmetType as keyof typeof HelmetTypesEnum]]: 1,
        },
      };
    });
  };

  // fetch updated charges for billing
  const getUpdatedBillingCharges = (selectedBranch) => {
    let branchName =
      Object.keys(selectedBranch).length > 0
        ? selectedBranch.name
        : checkoutData.selectedBranch.name;
    const chargePayload = {
      data: {
        ...rentingFleetFilter,
        modelName: checkoutData.selectedVehicle,
        branchName: branchName,
        customerId: userProfileData?.id,
        startDate: userSelection.startDate,
        endDate: userSelection?.endDate,
        complementaryHelmet: checkoutData?.isCompHelmetSelected,
        unlimitedSelected: false,
        premiumSelected: false,
        deliveryDetails: checkoutData?.deliveryDetails,
        helmetSelection: checkoutData?.additionalHelmet,
      },
    };

    dispatch(getBillingCalculatedCharges(chargePayload));
  };

  // fetch slot pause
  const getUpdatedDeliverySlotPause = (selectedBranch): void => {
    // Get branch slot pause - payload: {branch=IN_KA_BENGALURU_FREEDO_BENGALURU&startDate=1732291496021&endDate=1747843496021}

    const branchSlotPausePayload = {
      branch: selectedBranch?.name,
      startDate: userSelection?.endDate,
      endDate: userSelection?.startDate,
    };
    dispatch(getDeliverySlotPause(branchSlotPausePayload));
  };

  // fetch available time slots
  const getUpdatedAvailableTimeSlots = (selectedBranch) => {
    // Get available time slots - branchName: string, payload: {deliveryType,bookingStartDate}
    const timeSlotsPayload = {
      deliveryType: DeliveryModesEnum.BRANCH,
      bookingStartDate: userSelection?.startDate,
    };
    let branchName = selectedBranch
      ? selectedBranch.name
      : checkoutData.selectedBranch.name;
    dispatch(getAvailableTimeSlots(timeSlotsPayload, branchName));
  };

  // Map / List Branch change handler
  const branchChangeHandler = (selectedBranch) => {
    selectedBranchDetails = getSelectedBranchDetails(
      selectedBranch,
      modelCheckoutDetails?.availability
    );
    setCheckoutData((prev) => {
      return {
        ...prev,
        selectedBranch: selectedBranch,
        selectedBranchDetails: selectedBranchDetails,
        slot: null,
      };
    });

    getUpdatedDeliverySlotPause(selectedBranch);
    getUpdatedAvailableTimeSlots(selectedBranch);
    getUpdatedBillingCharges(selectedBranch);
  };

  // delivery mode change handler
  const handleDeliveryChange = (value: any) => {
    setCheckoutData((prevFields: any) => ({
      ...prevFields,
      slot: null,
      homeDelivery: value === DeliveryModesEnum.CUSTOMER_LOCATION,
      selectedDeliveryMode:
        DeliveryModesEnum[value as keyof typeof DeliveryModesEnum],
      deliveryDetails: {
        type: DeliveryModesEnum[value as keyof typeof DeliveryModesEnum],
        slot: null,
      },
    }));
    getUpdatedAvailableTimeSlots("");
  };

  //  home address change handler
  const handleHomeAddressSelected = (item: any) => {
    const data = { ...checkoutData };
    data.selectedHomeAddress = item;
    setCheckoutData(data);
  };

  // handle data change
  const updateCheckoutData = (type: string, value: any) => {
    const data = { ...checkoutData };
    data[type] = value;
    setCheckoutData(data);
  };

  // time slot change handler
  const handleSlotChange = (value: any) => {
    const data = { ...checkoutData };
    data.slot = value;
    setCheckoutData(data);
  };

  // profile page navigation
  const navigateToProfile = () => {
    navigate(Routes.PROFILE, {
      state: { values: "my-profile" },
    });
  };

  // Plan change handler
  const handlePlanChange = (planType: string) => {
    if (planType)
      setCheckoutData((prev) => {
        return {
          ...prev,
          selectedPlan: planType,
          // BookingPlansEnum[planType as keyof typeof BookingPlansEnum],
        };
      });
  };

  const toggleAdditionalHelmet = () => {
    setCheckoutData((prev) => {
      return {
        ...prev,
        isAdditionalHelmetChecked: !prev.isAdditionalHelmetChecked,
        additionalHelmet: initialCheckout?.additionalHelmet,
      };
    });
  };

  // Plan Types on Checkout Form Unlimited , Regular ,Premium
  const availablePlans: PlanTypes[] = [
    {
      key: BookingPlansEnum.REGULAR,
      heading: checkout.RegularPlan,
      amount: branchPricing?.amount - branchPricing?.discount || 0,
      kmLimit: branchPricing.kmLimit || 0,
      isAvailable: true,
    },
    {
      key: BookingPlansEnum.PREMIUM,
      heading: checkout.PremiumPlan,
      amount: branchPricing?.premium?.price || 0,
      kmLimit: branchPricing.premium?.kmLimit || 0,
      isAvailable: branchPricing?.premium?.available ?? false,
    },
    {
      key: BookingPlansEnum.UNLIMITED,
      heading: checkout.UnlimitedPlan,
      amount: branchPricing?.unlimited?.price || 0,
      kmLimit: branchPricing?.unlimited?.kmLimit || 0,
      isAvailable: branchPricing?.unlimited?.available ?? false,
    },
  ];

  // additional helmet selection handler
  const extraHelmetsChangeHandler = (e: any, type: string, action: string) => {
    let helmetCountArray = Object.values(checkoutData.additionalHelmet);
    let helmetCount: number =
      helmetCountArray?.length > 0
        ? helmetCountArray.reduce<number>(
            (partialSum: any, a: any) => partialSum + a,
            0
          )
        : 0;
    if (action === "INCREMENT") {
      if (helmetCount < 3) {
        setCheckoutData((prevState: any) => ({
          ...prevState,
          additionalHelmet: {
            ...prevState.additionalHelmet,
            [type]: prevState.additionalHelmet[type] + 1,
          },
        }));
        setErrorFields((prevState: any) => ({
          ...prevState,
          maxHelmets: "",
        }));
      } else {
        setErrorFields((prevState: any) => ({
          ...prevState,
          maxHelmets: errorMessages.maxHelmet,
        }));
      }
    }
    if (action === "DECREMENT") {
      setCheckoutData((prevState: any) => ({
        ...prevState,
        additionalHelmet: {
          ...prevState.additionalHelmet,
          [type]: prevState.additionalHelmet[type]
            ? prevState.additionalHelmet[type] - 1
            : prevState.additionalHelmet[type],
        },
      }));
      setErrorFields((prevState: any) => ({
        ...prevState,
        maxHelmets: "",
      }));
    }
  };
  
  return (
    <>
      <Box component="section" className="checkout-wrapper">
        <Container disableGutters={false}>
          <Box className="main-content-wrapper">
            <Stack justifyContent={"center"} gap="1rem">
              {/* ----------------------- Top Stepper ----------------------- */}
              <Box className="check-out-step-wrap">
                <Box className="steps-inner-wrap">
                  {topStepperSteps &&
                    topStepperSteps.map((step, index) => {
                      if (index > step.length - 1) {
                        return null;
                      }
                      let isActiveOrVisited = index < 2 ? "visited" : "active";
                      return (
                        <>
                          <a
                            className={`steps-body ${isActiveOrVisited}`}
                            key={index}
                          >
                            <Typography className="number">
                              {index + 1}
                            </Typography>
                            <Typography variant={"h6"}>{step}</Typography>
                            {index < 2 && <Box className="steps-border"></Box>}
                          </a>
                        </>
                      );
                    })}
                </Box>
              </Box>

              {/* ------------------- Title & Breadcrumbs ---------------- */}
              <Box>
                <h3>{checkout.checkout}</h3>
                <Breadcrumbs aria-label="breadcrumb">
                  <a href="/">Home</a>
                  <Link to={Routes.RENT}>Renting Fleet</Link>
                  <Typography>Checkout</Typography>
                </Breadcrumbs>
              </Box>

              {/* --------- Page Content ------------- */}
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                className=""
                alignItems={"center"}
              >
                {/* ------------ Google map component & customer address --------- */}
                <Grid size={7}>
                  {checkoutData.selectedBranch && (
                    <>
                      <DeliveryMode
                        handleChange={handleDeliveryChange}
                        cityData={userCity}
                        addressData={checkoutData?.selectedHomeAddress}
                        selectedBranch={checkoutData.selectedBranch}
                        selectedBranchDetails={selectedBranchDetails}
                        selectedBranchConfig={selectedBranchDetails?.config}
                      />
                    </>
                  )}

                  {isVehicleData ? (
                    <CheckoutAddress
                      checkoutData={checkoutData}
                      branchDetails={vehicleData?.availability}
                      handleAddressSelected={handleHomeAddressSelected}
                      handleCheckoutDataUpdate={updateCheckoutData}
                      branchChangeHandler={branchChangeHandler}
                    />
                  ) : undefined}

                  {/* ------------ Product Detail --------------------- */}
                  {isVehicleData ? (
                    <>
                      <CheckoutProductDetail
                        productData={vehicleData}
                        rentData={userSelection}
                      />
                    </>
                  ) : undefined}

                  {/* --------------- Time Slot ---------------------- */}
                  {checkoutData.selectedBranch && (
                    <SelectTimeSlot
                      deliveryType={checkoutData.homeDelivery}
                      slot={availableTimeSlots?.slots}
                      handleChange={handleSlotChange}
                    />
                  )}
                </Grid>

                {/* -------------- Right Content ------------------ */}
                {checkoutData.selectedBranch ? (
                  <BillingDetails
                    selectedBranchData={checkoutData.selectedBranch}
                    selectedBranchDetails={selectedBranchDetails}
                    checkoutData={checkoutData}
                    availablePlans={availablePlans}
                    selectedPlan={checkoutData?.selectedPlan}
                    handlePlanChange={handlePlanChange}
                    calculatedCharges={calculatedCharges}
                    additionHelmetChangeHandler={additionHelmetChangeHandler}
                    toggleAdditionalHelmet={toggleAdditionalHelmet}
                    extraHelmetsChangeHandler={extraHelmetsChangeHandler}
                    errorFields={errorFields}
                    // homeDeliverCharges={checkoutData.homeDeliveryCharge}
                    // isCOD={checkoutData.isCOD}
                    handleCheckoutDataUpdate={updateCheckoutData}
                    // handlePayClick={handleCheckEmergencyValidate}
                    modelName={vehicleData?.modelName}
                    selectedPackage={userSelection?.package ?? 0}
                    payNowLoader={payNowLoader}
                    handleNavigation={navigateToProfile}
                    addressNotDeliverable={addressNotDeliverable}
                    isHomeDelivery={checkoutData?.homeDelivery}
                    branchData={checkoutData.selectedBranch}
                    productData={vehicleData}
                    // selectedCouponData={selectedCouponData}
                    // setSelectedCoupon={setSelectedCoupon}
                    additionalHelmet={additionalHelmet}
                    setAdditionalHelmet={setAdditionalHelmet}
                    additionalHelmetData={additionalHelmetData}
                    setAdditionalHelmetData={setAdditionalHelmetData}
                  />
                ) : (
                  <Grid size={5} className="select-hub-wrapper">
                    <img src={checkoutScreenImg} style={{ width: "160px" }} />
                    <Box className="select-hub-box">
                      <h3>No Hub Selected</h3>
                      <p> Please select any hub to view the payment details</p>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Stack>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default CheckoutScreen;
