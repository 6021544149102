import { Box, Card, Divider, Stack, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Carousel from "react-material-ui-carousel";
import {
  BenefitcardYor1,
  CoinsIconYor,
  HarleyLogo,
  LocationIcon,
  PartyIconYor,
  RatingOne,
  ScooterIconYor,
  StartDateCalendarIcon,
  SubscriptionBanner,
  XplulseImage,
  BenefitCardSky,
  BenefitCardGreen,
  BenefitCardBlue,
  BenefitCardYellow,
  BenefitCardSkyblue,
  PercentTagYor,
  FlashIconYor,
  KeyIconYor,
  InfinityIconYor,
} from "../../../assets";
import Styles from "./index.module.css";

import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  cityStatus,
  LocalStorage,
  SessionStorage,
  TypographyConstants,
} from "../../../constant/enum";
import { Routes } from "../../../constant/routeContants";
import en from "../../../locales/en.json";
import { RootState } from "../../../redux/store";

import { Helmet } from "react-helmet";
import { colors } from "../../../themes/colors";
import {
  divideArrayTo2D,
  getFormattedDate,
  getLocalStorageData,
  getRandomElement,
  getSessionStorage,
  setSessionStorage,
} from "../../../utills/helper";
import {
  CustomButton,
  CustomIconButton,
} from "../../components/atom/Buttons/CustomButtons";
import CustomAccordion from "../../components/molecule/Accordion/customAccordion";
import CustomReactDateTimePicker from "../../components/molecule/DatePicker/CustomReactDateTimePicker";
import CustomSearchLayout from "../../components/molecule/UiComponents/CustomSearchLayout";
import { getCityV2, setUserCityYor } from "../../redux/actions/yorActions";
import { YORHeadingContent } from "./sections/yorCommonSections";
import CustomerReviewCard from "./sections/customerReviewCard";
import SelectCityModalYor from "./sections/selectCityModalYor";

const {
  yourOwnRide,
  home: { homePageCarousal },
} = en;

const subscriptionOffer: any[] = [
  {
    icon: HarleyLogo,
    heading: "Complimentary Helmet",
    subHeading: "on booking of YOR Subscription",
    description: "Valid until 01 February 2025",
  },
  {
    icon: HarleyLogo,
    heading: "20% Discount",
    subHeading: "on Freedo Merchandise*",
    description: "Valid until 01 February 2025",
  },
  {
    icon: RatingOne,
    heading: "10% Discount ",
    subHeading: "on Rentals*",
    description: "Valid until 03 March 2026",
  },
];

const customerReview = [
  {
    rating: 4,
    review:
      "I love how user-friendly this app is! It's so easy to add events and set reminders, and it's made my life so much more organized. how user-friendly this app is! It's so easy to add events and set reminders.",
    customerName: "Nikhil Tawane",
    customerRole: "Developer",
    customerEmoji: "",
    cardEmoji: "",
  },
  {
    rating: 4,
    review:
      "I love how user-friendly this app is! It's so easy to add events and set reminders, and it's made my life so much more organized. how user-friendly this app is! It's so easy to add events and set reminders.",
    customerName: "Nikhil Tawane",
    customerRole: "Developer",
    customerEmoji: "",
    cardEmoji: "",
  },
  {
    rating: 2,
    review:
      "I love how user-friendly this app is! It's so easy to add events and set reminders, and it's made my life so much more organized. how user-friendly this app is! It's so easy to add events and set reminders.",
    customerName: "Nikhil Tawane",
    customerRole: "Developer",
    customerEmoji: "",
    cardEmoji: "",
  },
  {
    rating: 4,
    review:
      "I love how user-friendly this app is! It's so easy to add events and set reminders, and it's made my life so much more organized. how user-friendly this app is! It's so easy to add events and set reminders.",
    customerName: "Nikhil Tawane",
    customerRole: "Developer",
    customerEmoji: "",
    cardEmoji: "",
  },
];

const faqs = [
  {
    question: "What are my liabilities in case of damage/accidents?",
    answer:
      "The vehicle is covered by commercial insurance to take care of any damage charges above Rs.5000. In case of irresponsible or negligent usage, there could be exceptions to this policy whereby you could be liable for all damages irrespective of whether or not the insurance was claimed. ",
  },
  {
    question: "Is there any restriction to where I can travel?",
    answer:
      "This is YOR vehicle, you are free to ride anywhere and use it for personal or commercial as long as it complies with the Motor Vehicle Act, of 1988. ",
  },
  {
    question: "How long does it take to subscribe to the vehicle?",
    answer:
      "It is a 15-minute process. Complete your digital KYC, and get your vehicle instantly.",
  },
  {
    question: "Are these new vehicles?",
    answer: "These would be current-year registered vehicles. ",
  },
  {
    question: "Whose name shall the vehicle be registered in?",
    answer:
      "The vehicle will be registered in the name of Hero MotoCorp, under the commercial category. The same would be transferred post-completion of the tenure.",
  },
];

const getStartedYor = [
  {
    id: 1,
    icon: PartyIconYor,
    heading: "Search vehicle",
    description: "Search by selecting vehicle model and ride start date",
  },
  {
    id: 2,
    icon: ScooterIconYor,
    heading: "Select Preferred vehicle",
    description: "Configure your booking details in the checkout page",
  },
  {
    id: 3,
    icon: CoinsIconYor,
    heading: "Select Plan Details",
    description: "Pay from our range of payment methods",
  },
  {
    id: 4,
    icon: PartyIconYor,
    heading: "Select Accessories",
    description: "Pay from our range of payment methods",
  },
  {
    id: 5,
    icon: PartyIconYor,
    heading: "Complete The Payment",
    description: "Pay from our range of payment methods",
  },
  {
    id: 6,
    icon: PartyIconYor,
    heading: "Ride YOR Dream Bike",
    description: "Pay from our range of payment methods",
  },
];

const optReasonYor = [
  {
    icon: PercentTagYor,
    title: "Affordable Price",
    background: BenefitCardGreen,
  },
  {
    icon: FlashIconYor,
    title: "Insurance & Maintenance covered",
    background: BenefitCardSky,
  },
  {
    icon: ScooterIconYor,
    title: "Large fleet of vehicles",
    background: BenefitCardBlue,
  },
  {
    icon: KeyIconYor,
    title: "Hassle free ownership transfer",
    background: BenefitCardYellow,
  },
  {
    icon: InfinityIconYor,
    title: "Unlimited Kms to drive",
    background: BenefitCardSkyblue,
  },
];

const backgroundImages = [BenefitcardYor1];

const subscriptionBetter = {
  content: [
    {
      key: 0,
      description: "On Road Cost",
      loan: 105832,
      subscription: 105832,
      benifits: 0,
    },
    {
      key: 1,
      description: "Down Payment",
      loan: 7500,
      subscription: 4999,
      benifits: 2501,
    },
    {
      key: 3,
      description: "Monthly Installment",
      loan: 6631,
      subscription: 4999,
      benifits: 1632,
    },
    {
      key: 4,
      description: "Residual Value",
      loan: 0,
      subscription: 14999,
      benifits: -14999,
    },
    {
      key: 5,
      description: "Insurance for Year 2",
      loan: 3470,
      subscription: 0,
      benifits: 3470,
    },
    {
      key: 6,
      description: "Service Cost*",
      loan: 5295,
      subscription: 0,
      benifits: 5295,
    },
    {
      key: 7,
      description: "Helmet Cost",
      loan: 750,
      subscription: 0,
      benifits: 750,
    },
    {
      key: 8,
      description: "Other Accessories",
      loan: 1074,
      subscription: 0,
      benifits: 1074,
    },
    {
      key: 9,
      description: "Hypothecation Charge",
      loan: 500,
      subscription: 0,
      benifits: 500,
    },
    {
      key: 10,
      description: "YOR Benefits",
      loan: 6500,
      subscription: "",
      benifits: 6500,
    },
    {
      key: 11,
      description: "5 Years",
      loan: "Included",
      subscription: "Included",
      benifits: "",
    },
    {
      key: 12,
      description: "Total",
      loan: 144447,
      subscription: 109980,
      benifits: 34467,
    },
  ],
  header: ["Loan", "Subscription", "Benifits"],
};

const OfferCard = ({ data }: any) => {
  return (
    <Box sx={{ position: "relative" }}>
      <Card
        sx={{ width: "290px", boxShadow: "none" }}
        className={Styles.subscriptionOfferCards}
      >
        <img
          style={{ width: "64px", height: "40px" }}
          src={data.icon}
          alt="icon"
        />
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            borderRight: "2px dashed #66666633",
          }}
        />

        <Stack
          sx={{
            display: "flex",
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <Stack>
            <Typography
              sx={{
                color: colors.dark_gray_secondary,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "136px",
                cursor: "pointer",
              }}
              variant={TypographyConstants.SUBTITLE1}
            >
              {data.heading}
            </Typography>
            <Tooltip title={data.subHeading}>
              <Typography
                sx={{
                  color: colors.dark_gray_secondary,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "126px",
                  cursor: "pointer",
                  fontWeight: 500,
                }}
                variant={TypographyConstants.SMALL_TEXT}
              >
                {data.subHeading}
              </Typography>
            </Tooltip>
          </Stack>
          <Typography
            sx={{
              color: "#0000004D",
            }}
            variant={TypographyConstants.SMALL_TEXT}
          >
            {data.description}
          </Typography>
        </Stack>
      </Card>
      <Box className={Styles.roundCutLeft}></Box>
      <Box className={Styles.roundCutRight}></Box>
    </Box>
  );
};

const YorHomescreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // get selected user city from local storage
  const yorCityData = getLocalStorageData(LocalStorage.yorUserCity);

  // get start ride date from session storage
  const startRideDate = getSessionStorage<{ startDate: string }>(
    SessionStorage.yorStartRide
  );

  // data from yor reducer
  const { cityV2 = [], userCityYor } = useSelector(
    (state: RootState) => state.yorReducer
  );

  // sate to handle city and date modal
  const [showCityModal, setShowCityModal] = useState(false);
  const [showStartDate, setShowStartDate] = useState(false);
  const [showStartDateMobile, setShowStartDateMobile] = useState(false);

  // state to store ride start date
  const [startDate, setStartDate] = useState<any>({
    date: null,
    displayStartDate: "Start Ride Date",
  });

  useEffect(() => {
    // scroll to top
    window.scrollBy({
      top: 0,
      behavior: "smooth",
    });

    dispatch(getCityV2({ page: 1, limit: 100, status: cityStatus.ACTIVE }));
    if (yorCityData) dispatch(setUserCityYor(yorCityData));

    if (startRideDate?.startDate) {
      // checking date store in session is less than current date
      const startDate: Date =
        new Date(startRideDate?.startDate).getTime() < new Date().getTime()
          ? new Date(startRideDate?.startDate)
          : new Date();

      setStartDate({
        date: startDate,
        displayStartDate: getFormattedDate(startDate, "date"),
      });
    }
  }, []);

  useEffect(() => {
    if (cityV2.length && !yorCityData) {
      setShowCityModal(true);
    }
  }, [cityV2]);

  // handle calendar cuty modal
  const cityModalToggleHandler = () => {
    setShowCityModal((prev) => !prev);
  };

  // handle calendar toggle
  const toggleStartDateCalendar = () => {
    setShowStartDate(!showStartDate);
  };

  // handle calendar toggle mobile view
  const toggleStartDateCalendarMobile = () => {
    setShowStartDateMobile(!showStartDateMobile);
  };

  // start ride date select handler
  const startDateChangeHandler = (date: any) => {
    setStartDate({
      date: date,
      displayStartDate: getFormattedDate(date, "date"),
    });
    toggleStartDateCalendar();
    toggleStartDateCalendarMobile();
    setSessionStorage(SessionStorage.yorStartRide, { startDate: date });
  };

  //success callback on fetching available vehicles data
  const onViewVehicle = () => {
    navigate(Routes.YOR_RENTING_FLEET);
  };

  // handle city click in city modal
  const handleCityClick = (index: number) => {
    dispatch(setUserCityYor(cityV2[index]));
    cityModalToggleHandler();
  };

  // crousel card city date selection
  const formFieldsContent = (
    <>
      <Stack>
        <CustomIconButton
          onClick={() => {
            cityModalToggleHandler();
          }}
        >
          <img
            src={LocationIcon}
            height="1rem"
            style={{ height: "15px", width: "25px" }}
          />
          <Typography
            variant={TypographyConstants.BODY1}
            color={colors.dark_cyan}
          >
            {userCityYor?.displayName
              ? userCityYor?.displayName
              : yourOwnRide.locateMe}
          </Typography>
        </CustomIconButton>
      </Stack>
      <Divider
        orientation="vertical"
        sx={{
          borderColor: colors.secondary_gray,
          height: "40px",
          borderWidth: 1,
        }}
      />

      {/* Start date */}
      <Stack>
        <CustomIconButton
          onClick={() => {
            setShowStartDate(true);
          }}
        >
          <img
            src={StartDateCalendarIcon}
            height="1rem"
            style={{ height: "15px", width: "25px" }}
          />
          <Typography
            variant={TypographyConstants.BODY1}
            sx={{
              color: colors.secondary_gray,
              padding: "0px 7px",
            }}
          >
            {startDate?.displayStartDate}
          </Typography>
        </CustomIconButton>
      </Stack>
    </>
  );

  // yor crousel array
  let yorCrousel: JSX.Element[] = [
    <Box>
      <Card
        className={Styles.yorCrouselOne}
        style={{
          backgroundImage: `url(${SubscriptionBanner})`,
          borderRadius: "0px",
          backgroundSize: "cover",
          backgroundPosition: "top",
        }}
      >
        <Box className={Styles.yorCarouselBox} sx={{ position: "relative" }}>
          <Stack gap={"25px"}>
            <Stack>
              <Typography
                sx={{
                  fontWeight: 900,
                  fontSize: "30px",
                  background:
                    "-webkit-linear-gradient(0deg, #2BC7C9, #2BC7C9),-webkit-linear-gradient(0deg, #305D61, #305D61)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  lineHeight: "32px",
                }}
              >
                {yourOwnRide?.yorHeading}
              </Typography>
              <Box className={Styles.poweredbyFreedo}>
                <Typography
                  variant={TypographyConstants.SUBHEADING}
                  sx={{
                    background:
                      "-webkit-linear-gradient(90.39deg, #305D61 21.34%, #2BC7C9 78.66%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  {yourOwnRide?.poweredbyFreedo}
                </Typography>
                <Box className={Styles.yorCarouselBoxTitleContentVector}></Box>
              </Box>
            </Stack>
            <Typography
              variant={TypographyConstants.BODY1}
              sx={{
                color: colors.dark_grayish_red,
              }}
            >
              {yourOwnRide?.crouselCardContent}
            </Typography>

            <CustomSearchLayout
              formFields={formFieldsContent}
              actionButton={
                <CustomButton
                  label={yourOwnRide.viewVehicles}
                  onClick={onViewVehicle}
                  variant="contained"
                  disabled={!(yorCityData?.name && startDate?.date)}
                />
              }
              isBackgroundStyle={false}
            />
          </Stack>

          {/* Start Ride Date Picker */}
          {showStartDate && (
            <Box
              display={{ xs: "none", sm: "none", md: "flex" }}
              sx={{ position: "absolute", top: "6px", left: "224px" }}
            >
              <CustomReactDateTimePicker
                selectedDate={startDate?.date}
                dateChangeHandler={startDateChangeHandler}
                toggleCalendarHandler={toggleStartDateCalendar}
                showTimeSelect={false}
                minDate={new Date()}
              />
            </Box>
          )}
        </Box>
      </Card>
    </Box>,
  ];

  return (
    <Fragment>
      {/* SEO ranking */}
      <Helmet>
        <title>YOR Subscription: Your Bike, One Fee, Zero Hassles</title>
        <meta
          name="title"
          content="YOR Subscription: Your Bike, One Fee, Zero Hassles"
        />
        <meta
          name="description"
          content="Ride worry-free with YOR Subscription! For a fixed monthly fee that covers your bike’s cost, insurance, and maintenance. No long-term commitments—just effortless, hassle-free riding!"
        />
        <meta name="url" content="https://freedo.rentals/yor" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://freedo.rentals/yor" />
        <meta
          property="og:title"
          content="YOR Subscription: Your Bike, One Fee, Zero Hassles"
        />
        <meta
          property="og:description"
          content="Ride worry-free with YOR Subscription! For a fixed monthly fee that covers your bike’s cost, insurance, and maintenance. No long-term commitments—just effortless, hassle-free riding!"
        />
        <meta property="twitter:url" content="https://freedo.rentals/yor" />
        <meta
          property="twitter:title"
          content="YOR Subscription: Your Bike, One Fee, Zero Hassles"
        />
        <meta
          property="twitter:description"
          content="Ride worry-free with YOR Subscription! For a fixed monthly fee that covers your bike’s cost, insurance, and maintenance. No long-term commitments—just effortless, hassle-free riding!"
        />
        <meta name="googlebot" content="index" />
        <link rel="canonical" href="https://freedo.rentals/yor" />
      </Helmet>

      {/* screen Ui */}
      <Stack gap={"20px"}>
        {/* crousels yor for desktop view*/}
        <Carousel
          animation="slide"
          duration={5000}
          indicatorContainerProps={{
            className: Styles.yorCarouselIndicatorsContainer,
          }}
          activeIndicatorIconButtonProps={{
            style: { color: colors.primary, top: "-1px" },
          }}
          indicatorIconButtonProps={{
            style: { color: colors.light_gray_secondary },
          }}
          sx={{ display: { xs: "none", md: "flex" } }}
        >
          {yorCrousel.map((item, i) => (
            <>{item}</>
          ))}
        </Carousel>

        {/* mobile view crousel */}
        <Stack
          display={{ sm: "flex", md: "none" }}
          className={Styles.mobileViewCrousel}
        >
          <Typography
            sx={{
              fontWeight: 900,
              fontSize: "30px",
              background:
                "-webkit-linear-gradient(0deg, #2BC7C9, #2BC7C9),-webkit-linear-gradient(0deg, #305D61, #305D61)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              lineHeight: "32px",
              textAlign: "center",
            }}
          >
            {yourOwnRide?.yorHeading}
          </Typography>

          <Divider
            orientation="horizontal"
            flexItem
            sx={{ borderColor: colors.secondary_green }}
          >
            <Typography
              variant={TypographyConstants.SUBHEADING}
              sx={{
                background:
                  "-webkit-linear-gradient(90.39deg, #305D61 21.34%, #2BC7C9 78.66%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              {yourOwnRide?.poweredbyFreedo}
            </Typography>
          </Divider>

          <Typography
            variant={TypographyConstants.BODY1}
            sx={{
              color: colors.dark_grayish_red,
              textAlign: "center",
            }}
          >
            {yourOwnRide?.crouselCardContent}
          </Typography>

          <Stack className={Styles.mobileViewDateCityPicker}>
            <CustomIconButton
              onClick={() => {
                cityModalToggleHandler();
              }}
              buttonSx={{
                width: "100%",
                position: "relative",
                maxWidth: "190px",
                border: "1px solid",
                borderColor: colors.grayish_cyan,
              }}
            >
              <img
                src={LocationIcon}
                height="1rem"
                style={{ height: "15px", width: "25px" }}
              />
              <Typography
                variant={TypographyConstants.BODY1}
                color={colors.dark_cyan}
              >
                {userCityYor?.displayName
                  ? userCityYor?.displayName
                  : yourOwnRide.locateMe}
              </Typography>
            </CustomIconButton>

            <CustomIconButton
              onClick={() => {
                setShowStartDateMobile(true);
              }}
              buttonSx={{
                width: "100%",
                position: "relative",
                maxWidth: "190px",
                border: "1px solid",
                borderColor: colors.grayish_cyan,
              }}
            >
              <img
                src={StartDateCalendarIcon}
                height="1rem"
                style={{ height: "15px", width: "25px" }}
              />
              <Typography
                variant={TypographyConstants.BODY1}
                sx={{
                  color: colors.secondary_gray,
                  padding: "0px 7px",
                }}
              >
                {startDate?.displayStartDate}
              </Typography>
            </CustomIconButton>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CustomButton
                label={yourOwnRide.viewVehicles}
                variant="contained"
                wrapperStyles={{ width: "100%", maxWidth: "189px" }}
                onClick={onViewVehicle}
                disabled={!(yorCityData?.name && startDate?.date)}
              />
            </Box>

            {/* Start Ride Date Picker */}
            {showStartDateMobile && (
              <Box
                display={{ xs: "flex", sm: "flex", md: "none" }}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <CustomReactDateTimePicker
                  selectedDate={startDate?.date}
                  dateChangeHandler={startDateChangeHandler}
                  toggleCalendarHandler={toggleStartDateCalendarMobile}
                  showTimeSelect={false}
                  minDate={new Date()}
                />
              </Box>
            )}
          </Stack>
        </Stack>

        {/*  offers cards*/}
        <YORHeadingContent
          heading={yourOwnRide.offersForYou}
          content={
            <Box>
              <Grid container spacing={3}>
                {subscriptionOffer.map((item: any) => {
                  return (
                    <Grid
                      size={{ xs: 12, sm: 12, md: 4 }}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <OfferCard data={item} />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          }
        />

        {/* yor opt reasons */}
        <YORHeadingContent
          heading={yourOwnRide.optReason}
          content={
            <Box>
              <Grid
                container
                spacing={2}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                {optReasonYor?.map((reason: any) => {
                  return (
                    <Grid sx={{ display: "flex", justifyContent: "center" }}>
                      <Card
                        className={Styles.optReasonCard}
                        sx={{
                          backgroundImage: `url(${reason.background})`,
                        }}
                      >
                        <Stack height={"100%"} justifyContent={"space-between"}>
                          <img style={{ width: "40px" }} src={reason?.icon} />
                          <Typography
                            variant={TypographyConstants.BODY1}
                            sx={{
                              fontWeight: 500,
                              color: colors.secondary_green,
                            }}
                          >
                            {reason.title}
                          </Typography>
                        </Stack>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          }
        />

        {/* get started with yor */}
        <YORHeadingContent
          heading={yourOwnRide.getStartedYor}
          content={
            <Box>
              <Grid container spacing={2}>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Grid
                    container
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    {getStartedYor?.map((item: any) => {
                      return (
                        <Grid>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <Box
                              sx={{
                                width: "8px",
                                height: "23px",
                                backgroundColor:
                                  item?.id === 1
                                    ? colors.primary
                                    : colors.white,
                                borderRadius: "8px",
                              }}
                            />

                            <Box
                              className={Styles.getStartedYorBox}
                              sx={{
                                backgroundColor:
                                  item?.id === 1
                                    ? colors.dark_desaturated_cyan
                                    : colors.white,
                              }}
                            >
                              <Box className={Styles.alignJustifyCenter}>
                                <img
                                  style={{ height: "30px", width: "30px" }}
                                  src={item?.icon}
                                />
                              </Box>
                              <Stack>
                                <Typography
                                  variant={TypographyConstants.BODY1}
                                  sx={{
                                    fontWeight: 500,
                                    color:
                                      item?.id === 1
                                        ? colors.white
                                        : colors.very_dark_gray,
                                  }}
                                >
                                  {item?.heading}
                                </Typography>
                                <Typography
                                  variant={TypographyConstants.BODY1}
                                  sx={{
                                    color:
                                      item?.id === 1
                                        ? colors.very_light_gray
                                        : "#888888",
                                  }}
                                >
                                  {item?.description}
                                </Typography>
                              </Stack>
                            </Box>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
                <Grid size={{ md: 6 }} display={{ xs: "none", md: "flex" }}>
                  <img
                    style={{
                      height: "100%",
                      objectFit: "cover",
                    }}
                    src={XplulseImage}
                  />
                </Grid>
              </Grid>
            </Box>
          }
        />

        {/* why subscription is better */}
        <YORHeadingContent
          heading={yourOwnRide.whySubscribeBetter}
          content={
            <Box sx={{ maxWidth: "800px" }}>
              <Grid container columnSpacing={2}>
                <>
                  <Grid
                    size={5}
                    minHeight={"40px"}
                    sx={{
                      backgroundColor: colors.white,
                      display: "flex",
                      alignItems: "center",
                      padding: "0px 16px",
                    }}
                  >
                    <Typography
                      variant={TypographyConstants.BODY1}
                      sx={{ color: "#343434" }}
                    >
                      {""}
                    </Typography>
                  </Grid>
                  <Grid size={7}>
                    <Grid container minHeight={"50px"}>
                      <Grid size={4}>
                        <Box
                          sx={{
                            height: "90%",
                            backgroundColor: colors.dark_cyan,
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            borderRadius: "5px 5px 0px 0px",
                            marginTop: "5px",
                          }}
                        >
                          <Typography
                            variant={TypographyConstants.CAPTION}
                            sx={{ color: colors.white }}
                            fontSize={{ xs: "12px", md: "16px" }}
                          >
                            {subscriptionBetter.header[0]}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid size={4}>
                        <Box
                          sx={{
                            height: "90%",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            borderRadius: "5px 5px 0px 0px",
                            background:
                              "linear-gradient(0deg, #60D297, #60D297),linear-gradient(89.92deg, #1BD8DA 1.67%, rgba(27, 196, 166, 0.95) 123.92%)",
                            boxShadow: "0px 0px 16px 0px #0000001A",
                            marginTop: "-10px",
                          }}
                        >
                          <Typography
                            variant={TypographyConstants.CAPTION}
                            sx={{ color: colors.white }}
                            fontSize={{ xs: "12px", md: "16px" }}
                          >
                            {subscriptionBetter.header[1]}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid size={4}>
                        <Box
                          sx={{
                            height: "90%",
                            backgroundColor: colors.dark_cyan,
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            borderRadius: "5px 5px 0px 0px",
                            marginTop: "5px",
                          }}
                        >
                          <Typography
                            variant={TypographyConstants.CAPTION}
                            sx={{ color: colors.white }}
                            fontSize={{ xs: "12px", md: "16px" }}
                          >
                            {subscriptionBetter.header[2]}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
                {subscriptionBetter?.content.map((item: any, index: number) => (
                  <>
                    <Grid
                      size={5}
                      minHeight={"40px"}
                      sx={{
                        backgroundColor: index % 2 ? colors.white : "#F5F5F5",
                        display: "flex",
                        alignItems: "center",
                        padding: "0px 16px",
                      }}
                    >
                      <Typography
                        variant={TypographyConstants.BODY1}
                        sx={{ color: "#343434" }}
                        fontSize={{ xs: "12px", md: "16px" }}
                      >
                        {item?.description}
                      </Typography>
                    </Grid>
                    <Grid size={7}>
                      <Grid container minHeight={"40px"}>
                        <Grid size={4}>
                          <Box
                            sx={{
                              height: "100%",
                              backgroundColor: colors.very_light_gray_secondary,
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <Typography
                              variant={TypographyConstants.CAPTION}
                              fontSize={{ xs: "12px", md: "16px" }}
                            >
                              {item?.loan}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid size={4}>
                          <Box
                            sx={{
                              height: "100%",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              background: colors.white,
                            }}
                          >
                            <Typography
                              variant={TypographyConstants.CAPTION}
                              fontSize={{ xs: "12px", md: "16px" }}
                            >
                              {item?.subscription}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid size={4}>
                          <Box
                            sx={{
                              height: "100%",
                              backgroundColor: colors.very_light_gray_secondary,
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <Typography
                              variant={TypographyConstants.CAPTION}
                              fontSize={{ xs: "12px", md: "16px" }}
                            >
                              {item?.benifits}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ))}
              </Grid>

              <Box>
                <Typography>{yourOwnRide.disclaimer}</Typography>
                <Typography>{yourOwnRide.disclaimerText}</Typography>
              </Box>
            </Box>
          }
        />

        {/* testimonials section web and large screen */}
        <Box className={Styles.customerTestimonials}>
          <YORHeadingContent
            heading={yourOwnRide.whatCustomerSays}
            padding={"0px"}
            gap="0px"
            content={
              <Carousel
                animation="slide"
                duration={500}
                height={"400px"}
                sx={{
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                {divideArrayTo2D(customerReview, 4)?.map((item: any[]) => {
                  return (
                    <Box className={Styles.customerReviewBox}>
                      {item?.map((review: any, index: number) => {
                        return (
                          <CustomerReviewCard
                            data={review}
                            index={index}
                            isLength={item?.length === 4}
                          />
                        );
                      })}
                    </Box>
                  );
                })}
              </Carousel>
            }
          />
        </Box>

        {/* testimonials section tab view  */}
        <Box className={Styles.customerTestimonialsTab}>
          <YORHeadingContent
            heading={yourOwnRide.whatCustomerSays}
            padding={"0px"}
            content={
              <Carousel
                animation="slide"
                duration={500}
                height={"310px"}
                sx={{
                  width: "100%",
                  flexDirection: "column",
                }}
                stopAutoPlayOnHover={true}
                swipe={true}
              >
                {divideArrayTo2D(customerReview, 2)?.map((item: any[]) => {
                  return (
                    <Box className={Styles.customerReviewBox}>
                      {item?.map((review: any, index: number) => {
                        return (
                          <CustomerReviewCard
                            data={review}
                            index={index}
                            isLength={item?.length === 4}
                          />
                        );
                      })}
                    </Box>
                  );
                })}
              </Carousel>
            }
          />
        </Box>

        {/* testimonials section mobile view  */}
        <Box className={Styles.customerTestimonialsMobile}>
          <YORHeadingContent
            heading={yourOwnRide.whatCustomerSays}
            padding={"0px"}
            content={
              <Carousel
                animation="slide"
                duration={500}
                height={"305px"}
                sx={{
                  width: "100%",
                  flexDirection: "column",
                }}
                stopAutoPlayOnHover={true}
              >
                {customerReview?.map((item: any, index: number) => {
                  return (
                    <Box className={Styles.customerReviewBox}>
                      <CustomerReviewCard
                        data={item}
                        index={index}
                        isLength={item?.length === 4}
                      />
                    </Box>
                  );
                })}
              </Carousel>
            }
          />
        </Box>

        {/* faqs section */}
        <YORHeadingContent
          heading={yourOwnRide.faqs}
          content={
            <Stack className={Styles.faqsBox}>
              {faqs?.map((item: any) => {
                return (
                  <CustomAccordion
                    headerText={item.question}
                    customStyle={{
                      backgroundColor: colors.light_grayish_cyan,
                      width: "90%",
                      boxShadow: "none",
                      borderRadius: 0,
                    }}
                  >
                    <Typography
                      variant={TypographyConstants.BODY1}
                      sx={{ color: "#8B8B8B" }}
                    >
                      {item.answer}
                    </Typography>
                  </CustomAccordion>
                );
              })}
            </Stack>
          }
        />

        {/* select yor city modal */}
        <SelectCityModalYor
          cityList={cityV2}
          handleCityClick={handleCityClick}
          openModal={showCityModal}
          handleClose={cityModalToggleHandler}
        />
      </Stack>
    </Fragment>
  );
};

export default YorHomescreen;
