import { Box, Stack, Typography } from "@mui/material";
import {
  BankIcon,
  ElectricityIcon,
  Other,
  RentalIcon,
  VoterIdIcon,
} from "../../../../assets";
import styles from "./userKyc.module.css";
import UploadIcon from "@mui/icons-material/Upload";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import GenericModal from "../../../../sharedComponents/molecule/Modals/GenericModal";
import { useCallback, useRef, useState } from "react";
import TakeSelfieModalContent from "./TakeSelfieModalContent";
import { OtherDocumentTypes, kycTypes } from "../../../constants/enum";

const OtherDocUploadCard = ({
  data,
  handleImageInput,
  handleUploadImgClick,
  callGetAttachment,
  setOtherDocsUpload,
  otherDocsUpload,
}: any) => {
  //web cam open
  const webcamRef = useRef<any>(null);
  const [showCaptureImgModal, setShowCaptureImgModal] =
    useState<boolean>(false);

  //toggle webcam modal
  const toogleImageCapModal = () => {
    setShowCaptureImgModal((prev) => !prev);
  };

  //handle capture image
  const handleCaptureImage = useCallback(() => {
    const imageSrc = webcamRef?.current?.getScreenshot();

    if (!imageSrc) {
      return;
    }
    setOtherDocsUpload((prev: any) => {
      return {
        ...prev,
        [data.name]: true,
      };
    });
    callGetAttachment(
      imageSrc,
      `${data?.name}.jpeg`,
      kycTypes.OTHER_DOCUMENT,
      data.name
    );
    toogleImageCapModal();
  }, [webcamRef]);

  return (
    <Box className={styles.otherdocswrap}>
      <img src={data.image} alt={data.label} style={{ width: 50 }} />
      <Typography variant="body1" fontWeight="500">
        {data.label}
      </Typography>
      {otherDocsUpload[data.name] && (
        <Typography variant="body1">Document Uploaded Successfully</Typography>
      )}

      <Box className={styles.uploadIconBox}>
        <Box onClick={() => handleImageInput(data.name)}>
          <input
            accept="image/*"
            type="file"
            onChange={(event: any) => {
              handleUploadImgClick(
                event,
                ["image/jpeg", "image/png", "image/jpg", "application/pdf"],
                kycTypes.OTHER_DOCUMENT,
                data?.name
              );
            }}
            style={{ display: "none" }}
            id={data.name}
          />
          <UploadIcon className={styles.cameraIcon} />
        </Box>
        <Typography variant="body1">OR</Typography>
        <PhotoCameraIcon
          className={styles.cameraIcon}
          onClick={toogleImageCapModal}
        />
      </Box>
      {showCaptureImgModal && (
        <GenericModal
          isOpen={showCaptureImgModal}
          handleClose={toogleImageCapModal}
          title={"Please place the document before camera"}
        >
          <TakeSelfieModalContent
            webcamRef={webcamRef}
            handleCaptureSelfie={handleCaptureImage}
          />
        </GenericModal>
      )}
    </Box>
  );
};
export const OtherDocUpload = ({
  handleImageInput,
  handleUploadImgClick,
  callGetAttachment,
  setOtherDocsUpload,
  otherDocsUpload,
}: any) => {
  //document type arr
  const documentType = [
    {
      name: OtherDocumentTypes.VOTER_ID,
      label: "Voter Id",
      image: VoterIdIcon,
      id: 1,
    },
    {
      name: OtherDocumentTypes.BANK_DETAIL,
      label: "Bank Detail",
      image: BankIcon,
      id: 2,
    },
    {
      name: OtherDocumentTypes.ELECTRICITY_BILL,
      label: "Electricity Bill",
      image: ElectricityIcon,
      id: 3,
    },
    {
      name: OtherDocumentTypes.RENT_BILL,
      label: "Rent Bill",
      image: RentalIcon,
      id: 4,
    },
    {
      name: OtherDocumentTypes.OTHER,
      label: "Other",
      image: Other,
      id: 5,
    },
  ];

  return (
    <Stack gap="5px" width="100%">
      {documentType?.map((data: any) => {
        return (
          <OtherDocUploadCard
            data={data}
            handleImageInput={handleImageInput}
            handleUploadImgClick={handleUploadImgClick}
            callGetAttachment={callGetAttachment}
            setOtherDocsUpload={setOtherDocsUpload}
            otherDocsUpload={otherDocsUpload}
          />
        );
      })}
    </Stack>
  );
};
