import { Box, Chip, Link, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import { bike } from "../../../../../assets";
import { CustomButton } from "../../../../../sharedComponents/atom/Buttons/CustomButtons";
import { colors } from "../../../../../themes/colors";
import HeadingValueTypography from "../../../../../sharedComponents/template/HeadingValueTypography";
import PackageDetailsAccordion from "./Accordions/PackageDetailsAccordion";
import ControlledAccordions from "../../../../../sharedComponents/molecule/Accordions/ControlledAccordions";
import DeliveryDetailsAccordion from "./Accordions/DeliveryDetailsAccordion";
import VehiclePriceDetails from "./Accordions/VehiclePriceDetails";
import { TypographyConstants } from "../../../../../constant/enum";
import { useParams } from "react-router-dom";
import { getDecryptedText } from "../../../../../utills/helper";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelUserBooking,
  clearBookingDetails,
  getbookingCancellationCharges,
  getBookingDetails,
  getVehicleDocuments,
} from "../../../../redux/actions";
import { RootState } from "../../../../../redux/reducers";
import {
  convertTo12hrs,
  getDeliveryAddress,
  getFormattedDate,
} from "../../../../utils/helper";
import {
  BookingStatus,
  BookingStatusColorCodes,
  BookingStatusDisplay,
} from "../../../../constants/enum";
import MyDocumentsAccordion from "./Accordions/MyDocumentsAccordion";
import en from "../../../../../locales/en.json";
import style from "./bookingDetails.module.css";
import GenericModal from "../../../../../sharedComponents/molecule/Modals/GenericModal";
import CancelBooking from "./cancelBooking";

const HeadingValue = ({ heading, value }: any) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Typography
        variant={TypographyConstants.SUBHEADING}
        color={colors.text_gray}
        sx={{ fontSize: "11px" }}
      >
        {heading}
      </Typography>
      <Typography>{value}</Typography>
    </Box>
  );
};
function ViewBooking() {
  const [expandedPanel, setExpandedPanel] = useState<string | false>(false);
  const {
    bookingDetails,
    paymentSummary,
    planSummary,
    loading,
    vehicleDocuments,
    cancellationChargeLoader,
    cancellationCharges,
  } = useSelector((state: RootState) => state.MyBookingReducer);
  const { duration, mainBookingPlanCharge } = planSummary;

  const params = useParams();
  const dispatch = useDispatch();
  const { encrypted } = params;
  const { bookingId } = JSON.parse(getDecryptedText(encrypted));

  const onChangeHandler = (panel: string, isExpanded: string | boolean) => {
    setExpandedPanel(isExpanded ? panel : false);
  };

  /* state to handle cancel booking modal */
  const [openCancelBooking, setOpenCancelBooking] = useState<boolean>(false);

  //booking descriptions
  let bookingDescriptionArray = [
    {
      displayKey: en.bookingDetails.pickUpDate,
      value: getFormattedDate(bookingDetails?.startDate, "date"),
    },
    {
      displayKey:
        bookingDetails?.deliveryDetails?.type === "BRANCH"
          ? en.bookingDetails.pickUpLocation
          : en.bookingDetails.homeDeliveryLocation,
      value: getDeliveryAddress(
        bookingDetails?.deliveryDetails?.address ?? "-NA-"
      ),
    },
    {
      displayKey: en.bookingDetails.handOverTime,
      value:
        getFormattedDate(bookingDetails?.actualStartDateTime, "dateTime") ??
        "-NA-",
    },
    {
      displayKey: en.bookingDetails.plannedDropOffDate,
      value: getFormattedDate(bookingDetails?.endDate, "date"),
    },
    {
      displayKey:
        bookingDetails?.returnDetails?.type == "BRANCH"
          ? en.bookingDetails.dropOffLocation
          : en.bookingDetails.HomePickUpLocation,
      value:
        bookingDetails?.returnDetails?.type == "BRANCH"
          ? getDeliveryAddress(
              bookingDetails?.deliveryDetails?.address ?? "-NA-"
            )
          : getDeliveryAddress(
              bookingDetails?.returnDetails?.address ?? "-NA-"
            ),
    },
    {
      displayKey: en.bookingDetails.actualDropOffDate,
      value:
        getFormattedDate(bookingDetails?.actualEndDateTime, "dateTime") ??
        "-NA-",
    },
    { displayKey: en.bookingDetails.appliedCoupon, value: "-NA-" },
    {
      displayKey: en.bookingDetails.vehicleRegistration,
      value: bookingDetails?.bookingVehicleDetails?.registration ?? "-NA-",
    },
    {
      displayKey: en.bookingDetails.startOdometerReading,
      value: bookingDetails?.bookingVehicleDetails?.startReading ?? "-NA-",
    },
    {
      displayKey: en.bookingDetails.Duration,
      value: planSummary?.duration ?? "-NA-",
    },
    { displayKey: en.bookingDetails.excessKmCharges, value: "-NA-" },
    { displayKey: en.bookingDetails.ExcessHourlyCharges, value: "-NA-" },
    {
      displayKey: en.bookingDetails.pickUpSlot,
      value:
        convertTo12hrs(bookingDetails?.deliveryDetails?.slot?.start ?? "") +
        "-" +
        convertTo12hrs(bookingDetails?.deliveryDetails?.slot?.end),
    },
    {
      displayKey: en.bookingDetails.dropOffSlot,
      value: `${
        convertTo12hrs(bookingDetails?.returnDetails?.slot.start) +
        "-" +
        convertTo12hrs(bookingDetails?.returnDetails?.slot.end)
      }`,
    },
  ];

  //accordions
  const accordionData = [
    {
      title: en.bookingDetails.rentalPackageDetails,
      content: (
        <PackageDetailsAccordion
          duration={duration}
          mainBookingPlanCharges={mainBookingPlanCharge}
        />
      ),
    },
    {
      title: en.bookingDetails.deliveryAddressDetails,
      content: (
        <DeliveryDetailsAccordion
          deliveryDetails={bookingDetails?.deliveryDetails}
        />
      ),
    },
    {
      title: en.bookingDetails.vehiclePriceDetails,
      content: (
        <VehiclePriceDetails
          HeadingValue={HeadingValue}
          paymentSummary={paymentSummary}
          planSummary={planSummary}
        />
      ),
    },
    {
      title: en.bookingDetails.extraCharges,
      content: (
        <PackageDetailsAccordion
          duration={duration}
          mainBookingPlanCharges={mainBookingPlanCharge}
        />
      ),
    },
  ];

  if (bookingDetails?.status == "ONGOING") {
    accordionData.splice(3, 0, {
      title: en.bookingDetails.myDocuments,
      content: <MyDocumentsAccordion documents={vehicleDocuments} />,
    });
  }

  //get booking details
  useEffect(() => {
    dispatch(getBookingDetails({ id: bookingId }));
    return () => {
      dispatch(clearBookingDetails());
    };
  }, []);

  //get vehicle Documents
  useEffect(() => {
    if (bookingDetails?.status === "ONGOING") {
      dispatch(
        getVehicleDocuments({
          vehicleIds: [bookingDetails?.bookingVehicleDetails?.vehicleId],
        })
      );
    }
  }, [bookingDetails]);

  /* function to toggle cancel booking modal */
  const toogleCancelBookingmodal = () =>
    setOpenCancelBooking(!openCancelBooking);

  /* handles cancel booking click */
  const onClickCancelBooking = () => {
    dispatch(
      getbookingCancellationCharges({
        bookingId: bookingDetails?.id,
        successCallback: toogleCancelBookingmodal,
      })
    );
  };

  /* on click of cancel booking */
  const onCancelBooking = () => {
    dispatch(
      cancelUserBooking({
        apiBody: {
          data: {
            refundAmount: cancellationCharges?.refundAmount,
          },
        },
        bookingId: bookingDetails?.id,
        successCallback: toogleCancelBookingmodal,
      })
    );
  };

  return (
    <Box sx={{ margin: "20px" }}>
      <Box className={style.flexBox}>
        <Typography variant="h6">Booking Details</Typography>
        <Link href="" target="_blank">
          Need Help?
        </Link>
      </Box>
      <Grid container spacing={2}>
        <Grid size={{ sm: 12, md: 7 }}>
          <Stack gap={2}>
            <Box className={style.bookingDetailsBox}>
              <Box className={style.flexBox}>
                <Box
                  sx={{ display: "flex", gap: "30px", alignItems: "center" }}
                >
                  <img src={bike} alt="" style={{ width: "25%" }} />
                  <Box>
                    <Typography variant="h6">
                      {bookingDetails?.bookingVehicleDetails?.displayName}
                    </Typography>
                    <Typography>{`Rental Plan: ${planSummary?.duration} ${
                      planSummary?.duration == 1 ? "Day" : "Days"
                    } plan`}</Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {bookingDetails?.status === BookingStatus.UPCOMING ? (
                    <CustomButton
                      variant="outlined"
                      label={en.bookingDetails.cancelRide}
                      onClick={onClickCancelBooking}
                      sx={{
                        color: colors.error,
                        border: "1px solid red",
                        padding: "6px 15px",
                        borderRadius: "20px",
                        minHeight: "30px",
                        "&:hover": {
                          background: "red",
                          color: "white",
                          border: "1px solid transparent",
                        },
                        "&:active": {
                          background: "red",
                          color: "white",
                          border: "1px solid transparent",
                        },
                      }}
                      loading={cancellationChargeLoader}
                    />
                  ) : null}
                </Box>
              </Box>
            </Box>
            <Box className={style.bookingDetailsBox}>
              <Box
                className={style.flexBox}
                sx={{
                  marginBottom: "20px",
                }}
              >
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "14px",
                      color: colors.text_gray,
                      fontWeight: 400,
                    }}
                  >
                    Booking ID:
                  </Typography>
                  <Typography color={colors.THEME_BLUE}>
                    {bookingDetails?.id}
                  </Typography>
                </Box>
                <Chip
                  icon={<></>}
                  label={BookingStatusDisplay[bookingDetails?.status]}
                  sx={{
                    color: colors.white,
                    backgroundColor:
                      BookingStatusColorCodes[bookingDetails?.status],
                  }}
                />
              </Box>
              <Stack direction="row" flexWrap="wrap" gap="20px" padding="20px">
                {bookingDescriptionArray?.map((data: any) => {
                  return (
                    <HeadingValueTypography
                      heading={data?.displayKey}
                      value={data?.value}
                      link={data?.link}
                      color={data?.color}
                      visitStepData={data?.visitStepData}
                      clickHandler={data?.clickHandler}
                      loading={loading}
                      width="196px"
                    />
                  );
                })}
              </Stack>
            </Box>
          </Stack>
        </Grid>
        <Grid size={{ sm: 12, md: 5 }} width="100%">
          <ControlledAccordions
            accordianDataArray={accordionData}
            onChangeHandler={onChangeHandler}
            expandedPanel={expandedPanel}
            titleKey="title"
            contentKey="content"
          />
        </Grid>
      </Grid>

      {/* modal for cancel booking */}
      <GenericModal
        isOpen={openCancelBooking}
        handleClose={toogleCancelBookingmodal}
        title={en.bookingDetails.cancelRide}
        modalWidth={"66%"}
      >
        <CancelBooking onCancelBooking={onCancelBooking} />
      </GenericModal>
    </Box>
  );
}

export default ViewBooking;
