import {
  Box,
  Divider,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { email, name, phone } from "../../../../assets";
import en from "../../../../locales/en.json";
import { RootState } from "../../../../redux/store";
import { CustomButton } from "../../../../sharedComponents/atom/Buttons/CustomButtons";
import { colors } from "../../../../themes/colors";
import { validate } from "../../../../utills/helper";
import { checkNumeric } from "../../../../utills/regex";
import Input from "../../../../yor-src/components/atom/InputFields/Input";
import { GenericObject } from "../../../constants/enum";
import {
  resendSignUpOtp,
  signUpUser,
  toggleSignInDrawer,
  verifySignUpOtp,
} from "../../../redux/actions/authActions";
import authStyles from "../styleObject";
import SignInEnterOtp from "./EnterOtp";

const { AuthScreens } = en;

interface SignUpProps {
  fields: GenericObject;
  handleChange: (key: string, value: any) => void;
}

const genderValues = [
  { value: "MALE", label: "Male" },
  { value: "FEMALE", label: "Female" },
  { value: "OTHER", label: "Other" },
];

const SignUp = (props: SignUpProps) => {
  const dispatch = useDispatch();
  const { fields, handleChange } = props;
  const { signUpPhoneNo, isEnterSignUpOtp } = fields;

  const { userAuthData, signUpLoader } = useSelector(
    (state: RootState) => state.newAuthReducer
  );
  const { token = "" } = userAuthData;

  // handle submit otp , sign up api and toggle sign in drawer
  const handleCreateMyAccount = () => {
    const validation = validate(
      {
        firstName: fields.firstName,
        lastName: fields.lastName,
        signUpPhoneNo: fields.signUpPhoneNo,
        email: fields.email,
        gender: fields.gender,
      },
      fields.errorFields
    );

    if (validation.error) {
      handleChange("errorFields", { ...validation.errorFields });
      return;
    }

    const payload = {
      data: {
        mobileNumber: signUpPhoneNo,
        firstName: fields.firstName,
        lastName: fields.lastName,
        email: fields.email,
        gender: fields.gender,
        profileImage: fields.profileImage,
      },
      onSuccess: () => {
        handleChange("isEnterSignUpOtp", true);
      },
    };
    dispatch(signUpUser(payload));
  };

  // signup verify otp Api and toggle sign in drawer
  const handleSubmitOtp = () => {
    const payloadData = { data: { otp: fields.signUpOtp, token } };

    dispatch(
      verifySignUpOtp({
        ...payloadData,
        onSuccess: () => {
          dispatch(toggleSignInDrawer(false));
        },
      })
    );
  };

  // on otp change
  const onChangeOtp = (key: string, value: string) => {
    handleChange("signUpOtp", value);
  };

  // on resend otp
  const handleResendOtp = () => {
    dispatch(resendSignUpOtp({ token }));
  };

  if (isEnterSignUpOtp)
    return (
      <SignInEnterOtp
        phoneNo={fields.signUpPhoneNo}
        key={en.AuthScreens.SignIn}
        otpValue={fields.signUpOtp}
        onChangeOtp={onChangeOtp}
        handleSubmitOtp={handleSubmitOtp}
        handleResendOtp={handleResendOtp}
        isLoading={signUpLoader}
      />
    );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        padding: "122px 40px",
        overflowY: "auto",
      }}
    >
      <Typography variant="h5" sx={{ textAlign: "center" }}>
        {en.AuthScreens.SignUp}
      </Typography>
      <Divider
        style={{
          width: "35px",
          borderBottomWidth: "3px",
          borderColor: colors.THEME_BLUE,
          opacity: 1,
          marginTop: "10px",
          marginBottom: "10px",
        }}
      />
      <Typography variant="caption">
        {en.AuthScreens.alreadyHasAccount}{" "}
        <span
          style={{
            textDecoration: "underline",
            fontWeight: 600,
            fontSize: "12px",
            cursor: "pointer",
          }}
          onClick={() => handleChange("isSignIn", true)}
        >
          {en.AuthScreens.SignIn}
        </span>
      </Typography>
      <Box sx={{ width: "100%" }}>
        <InputLabel
          shrink
          htmlFor="firstName"
          sx={{ fontSize: "15px", marginTop: "13px", color: "unset" }}
        >
          {AuthScreens["firstName"]}
          <span style={authStyles.mandatoryStyle}> *</span>
        </InputLabel>
        <Input
          type="text"
          id="firstName"
          name="firstName"
          autoFocus
          placeholder="Enter first name"
          value={fields.firstName}
          errormessage={fields.errorFields?.firstName}
          inputProps={{ maxLength: 50 }}
          onChange={(e) => {
            handleChange("firstName", e.target.value);
            handleChange("errorFields", {
              ...fields.errorFields,
              firstName: "",
            });
          }}
          startAdornment={() => {
            return (
              <>
                <img src={name} />
                <Divider
                  orientation={"vertical"}
                  sx={{ width: "5px", height: "18px" }}
                />
              </>
            );
          }}
        />

        <InputLabel
          shrink
          htmlFor="lastName"
          sx={{ fontSize: "15px", marginTop: "13px", color: "unset" }}
        >
          {AuthScreens["lastName"]}
          <span style={authStyles.mandatoryStyle}> *</span>
        </InputLabel>

        <Input
          type="text"
          id="lastName"
          name="lastName"
          required
          placeholder="Enter last name"
          value={fields.lastName}
          inputProps={{ maxLength: 50 }}
          onChange={(e) => {
            handleChange("lastName", e.target.value);
            handleChange("errorFields", {
              ...fields.errorFields,
              lastName: "",
            });
          }}
          errormessage={fields.errorFields?.lastName}
          startAdornment={() => {
            return (
              <>
                <img src={name} />
                <Divider
                  orientation={"vertical"}
                  sx={{ width: "5px", height: "18px" }}
                />
              </>
            );
          }}
        />

        <InputLabel
          shrink
          htmlFor="emailInput"
          sx={{ fontSize: "15px", marginTop: "13px", color: "unset" }}
        >
          {AuthScreens["Email"]}
          <span style={authStyles.mandatoryStyle}> *</span>
        </InputLabel>

        <Input
          type="text"
          required
          placeholder="Enter email address"
          id="emailInput"
          value={fields.email}
          inputProps={{ maxLength: 70 }}
          onChange={(e) => {
            handleChange("email", e.target.value);
            handleChange("errorFields", {
              ...fields.errorFields,
              email: "",
            });
          }}
          name="email"
          maxLength={100}
          errormessage={fields.errorFields?.email}
          startAdornment={() => {
            return (
              <>
                <img src={email} />
                <Divider
                  orientation={"vertical"}
                  sx={{ width: "5px", height: "18px" }}
                />
              </>
            );
          }}
        />

        <InputLabel
          shrink
          htmlFor="phone-input"
          sx={{ fontSize: "15px", marginTop: "13px", color: "unset" }}
        >
          {AuthScreens.PhoneNumber}
          <span style={authStyles.mandatoryStyle}> *</span>
        </InputLabel>
        <Input
          onChange={(e: any) => {
            if (checkNumeric(e.target.value) && e.target.value.length <= 10) {
              handleChange("signUpPhoneNo", e.target.value);
              handleChange("errorFields", {
                ...fields.errorFields,
                signUpPhoneNo: "",
              });
            }
          }}
          value={signUpPhoneNo}
          id="phone-input"
          type="number"
          required
          placeholder="Enter mobile number"
          startAdornment={() => {
            return (
              <>
                <img src={phone} />
                <Divider
                  orientation={"vertical"}
                  sx={{ width: "5px", height: "10px" }}
                />
              </>
            );
          }}
          containerStyle={{ width: "100%" }}
          errormessage={fields.errorFields?.signUpPhoneNo}
          inputMode="numeric"
          pattern="[0-9]*"
        />
        <InputLabel
          shrink
          id="gender-radio"
          sx={{ fontSize: "15px", marginTop: "13px", color: "unset" }}
        >
          {AuthScreens.gender}
          <span style={authStyles.mandatoryStyle}> *</span>
        </InputLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          sx={{ "& .MuiFormControlLabel-label": { fontSize: "10px" } }}
          onChange={(e) => {
            handleChange("gender", e.target.value);
            handleChange("errorFields", {
              ...fields.errorFields,
              gender: "",
            });
          }}
        >
          <FormControlLabel
            value={genderValues[0].value}
            control={<Radio size="small" />}
            label={genderValues[0].label}
            sx={{ fontSize: "12px" }}
          />
          <FormControlLabel
            value={genderValues[1].value}
            control={<Radio size="small" />}
            label={genderValues[1].label}
          />
          <FormControlLabel
            value={genderValues[2].value}
            control={<Radio size="small" />}
            label={genderValues[2].label}
          />
        </RadioGroup>
        <FormHelperText sx={{ color: colors.red }}>
          {fields.errorFields?.gender}
        </FormHelperText>
      </Box>

      <CustomButton
        label={AuthScreens["CreateMyAccount"]}
        variant={"contained"}
        wrapperStyles={{ width: "100%", marginTop: "15px" }}
        loading={signUpLoader}
        onClick={handleCreateMyAccount}
      />
      <Typography
        variant="caption"
        sx={{ fontWeight: 500, textAlign: "center", marginTop: "10px" }}
      >
        {AuthScreens["tNcNoteSignIn"]}
        <a
          style={{ textDecoration: "underline", fontWeight: 600 }}
          href="/terms-conditions"
          target="_blank"
        >
          {AuthScreens["termsAndConditions"]}
        </a>
        {AuthScreens["and"]}
        {"\n"}
        <a
          href="/privacy-policy"
          style={{ textDecoration: "underline", fontWeight: 600 }}
          target="_blank"
        >
          {AuthScreens["privacyPolicy"]}
        </a>
      </Typography>
    </Box>
  );
};

export default SignUp;
