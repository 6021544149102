import { Box, Link, Typography } from "@mui/material";
import { GoogleMap } from "@react-google-maps/api";
import React from "react";
import { getDeliveryAddress } from "../../../../../utils/helper";
import Grid from "@mui/material/Grid2";

function DeliveryDetailsAccordion({ deliveryDetails }: any) {
  return (
    <Grid container spacing={2}>
      <Grid size={{ sm: 12, md: 5 }}>
        <Box>
          <GoogleMap
            mapContainerStyle={{
              width: "auto",
              height: "20vh",
            }}
            center={{
              lat: deliveryDetails?.address?.locationData?.coordinates?.lat,
              lng: deliveryDetails?.address?.locationData?.coordinates?.lng,
            }}
            zoom={12}
            options={{ fullscreenControl: false }}
            // clickableIcons={false}
          />
        </Box>
      </Grid>
      <Grid size={{ sm: 12, md: 7 }}>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <Typography variant="h6">
              {deliveryDetails?.type === "BRANCH" ? "Pick Up" : "Home Delivery"}
            </Typography>
            <Typography>
              {getDeliveryAddress(deliveryDetails?.address)}
            </Typography>
            <Link
              href={`https://maps.google.com/?q=${deliveryDetails?.address?.locationData?.coordinates?.lat},${deliveryDetails?.address?.locationData?.coordinates?.lng}`}
              target="_blank"
            >
              View Map
            </Link>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default DeliveryDetailsAccordion;
