import { Box, Container, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RentingFleetCard from "../../../../component/RentingFleetCard";
import {
  LocalStorage,
  VehicleImageTypeEnum,
  VehicleTypeEnum,
} from "../../../../constant/enum";
import { Routes } from "../../../../constant/routeContants";
import { RootState } from "../../../../redux/reducers";
import { GenericObject, getLocalStorageData } from "../../../../utills/helper";

const FreedoRentingFleet = () => {
  const navigate = useNavigate();
  const { locale } = useSelector((state: RootState) => state.localeReducer);
  const { rentingFleet } = locale["home"];

  const { masterPlanDurationsData, filteredModelList, rentingFleetFilter } =
    useSelector((state: any) => state.rentalsCommonReducer);

  const [packageName, setPackageName] = useState("");
  const [selectedPackage, setSelectedPackage] = useState({});
  const [vehicleFilterType, setVehicleFilterType] = useState("");

  const filteredModelTypeList = vehicleFilterType
    ? filteredModelList?.availableModels.filter(
        (item: any) => item.vehicleType === vehicleFilterType
      )
    : filteredModelList?.availableModels;

  const handleShowMore = () => {
    navigate(Routes.RENT);
  };
  return (
    <Box component="section" className="Renting-wrapper">
      <Container>
        <Stack className="rent-wrap" gap="1rem">
          <Typography variant="h4" sx={{ textAlign: "center" }}>
            {rentingFleet["RentingFleet"]}
          </Typography>
          <Box className="rent-tab-wrapper">
            <Box className="rent-tab-wrap">
              {/* --------------------- Renting Fleet Filter ----------------------- */}
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                {(rentingFleet["Show"] as []).map((item: any, ind) => (
                  <li className="nav-item" role="presentation" key={item.name}>
                    <button
                      className={`nav-link ${ind == 0 && "active"}`}
                      id={`pills-${item.value}-tab`}
                      data-bs-toggle="pill"
                      data-bs-target={`#pills-${item.value}`}
                      type="button"
                      role="tab"
                      aria-controls={`pills-${item.value}`}
                      aria-selected="true"
                      onClick={(e) => {
                        setVehicleFilterType(
                          VehicleTypeEnum[
                            item.value as keyof typeof VehicleTypeEnum
                          ]
                        );
                      }}
                    >
                      {item.value}
                    </button>
                  </li>
                ))}
              </ul>
            </Box>

            {/* ----------------------- Packages Filter ---------------------------- */}
            <Box className="rent-days-wrap">
              <form>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setPackageName(e.target.value);
                  }}
                  value={packageName}
                >
                  {masterPlanDurationsData &&
                    masterPlanDurationsData?.map((item: any, ind: number) => (
                      <option id={item} key={ind}>
                        {typeof item !== "string" ? `${item} Day` : item}
                      </option>
                    ))}
                </select>
              </form>
            </Box>

            {/* ----------------------- Renting Fleet data  ------------------------ */}
            <Box className="tab-content" id="pills-tabContent">
              <Box
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                {filteredModelTypeList?.length ? (
                  <Box className="vehicle-wrapper">
                    {filteredModelTypeList?.map((item: any, ind: number) => {
                      let imageObj: GenericObject = {};
                      imageObj = item?.images.find(
                        (item) => item.type === VehicleImageTypeEnum.RIGHT
                      );
                      if (ind < 4)
                        return (
                          <RentingFleetCard
                            key={ind}
                            ind={ind}
                            data={item}
                            rentingFleet={rentingFleet}
                            filters={{
                              ...rentingFleetFilter,
                              package_days: selectedPackage,
                            }}
                            imagePath={imageObj?.fileName}
                            imageObj={imageObj}
                            // imagePath={""}
                            isHomePage={true}
                          />
                        );
                    })}
                  </Box>
                ) : (
                  <Typography sx={{ textAlign: "center", m: 2 }}>
                    No records found
                  </Typography>
                )}
              </Box>
            </Box>

            {/* ----------------------- Handle More -------------------------------- */}
            <Stack direction={"row"} justifyContent={"center"}>
              {filteredModelTypeList?.length > 0 && (
                <button
                  className="freedo-outline-btn rounded-pill"
                  onClick={() => handleShowMore()}
                >
                  {rentingFleet["ShowMore"]}
                </button>
              )}
            </Stack>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default FreedoRentingFleet;
