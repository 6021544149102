import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import TopFilter from "./sections/TopFilter";
import Filters from "./sections/Filters";
import VehicleWrapper from "./sections/VehicleWrapper";
import {
  addDays,
  amountConvertToPaise,
  amountConvertToRupee,
  debounce,
  findEndDate,
  getDifferenceInDays,
  getLocalStorageData,
  getTimestampAtEndOfDay,
  getTimestampAtStartOfDay,
  setLocalStorageData,
} from "../../../utills/helper";
import { RootState } from "../../../redux/reducers";
import moment from "moment";
import { LocalStorage, VehicleTypeEnum } from "../../../constant/enum";
import { Helmet } from "react-helmet";
import { homePageFilters } from "../../constants/enum";
import { Box, Container } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { getFilteredVehicleModelList } from "../../../redux/actions/commonActions";
interface stateType {
  [key: string]: any;
}
const Rent = () => {
  const isMobileDevice = window.screen.width < 768;
  const dispatch = useDispatch();

  const { locale } = useSelector((state: RootState) => state.localeReducer);
  const {
    masterPlanDurationsData,
    filteredModelList,
    carousalFilter,
    rentingFleetFilter,
    modelFilterType,
    masterModelForCity,
  } = useSelector((state: any) => state.rentalsCommonReducer);

  const rentDetails: any = locale["rent"];

  const cityData = getLocalStorageData(LocalStorage.userCity);
  const activeFilters =
    modelFilterType === homePageFilters.carousalFilter
      ? carousalFilter
      : rentingFleetFilter;
  const filterStartDate = activeFilters["startDate"]
    ? new Date(activeFilters["startDate"])
    : new Date();
  const filterEndDate = activeFilters["endDate"]
    ? new Date(activeFilters["endDate"])
    : addDays(new Date(), 1);

  // get package days
  const getPackageDaysValue = (packageDays: number | string) => {
    let selectedPackageDays: number | string = 0;
    if (packageDays) {
      if (masterPlanDurationsData.length > 0) {
        selectedPackageDays = masterPlanDurationsData.find(
          (item: any) => item === packageDays
        );
      }
      if (!selectedPackageDays) {
        selectedPackageDays = "Custom";
      }
    }
    return selectedPackageDays;
  };

  let filterPackageDays: number | string =
    getDifferenceInDays(filterStartDate, filterEndDate) - 1;
  filterPackageDays = getPackageDaysValue(filterPackageDays);
  const filterPackage = filterPackageDays;

  const maximumPrice = amountConvertToRupee(
    filteredModelList?.maximumPrice ?? 0
  );
  const minimumPrice = amountConvertToRupee(
    filteredModelList?.minimumPrice ?? 0
  );

  // Primary filter Initial value
  const initialPrimaryState = {
    // check if package exist in masterPlanDurationsData else Custom
    selectedPackage: filterPackage,
    startDate: filterStartDate,
    endDate: filterEndDate,
  };
  // Secondary filter Initial value
  const initialSecondaryState = {
    vehicleType: "",
    isElectric: false,
    priceRange: [minimumPrice, maximumPrice],
    vehicleModel: null,
    records: 6,
    sortBy: "ASC",
    selectedModels: [],
  };
  const [primaryFilter, setPrimaryFilter] =
    useState<stateType>(initialPrimaryState);
  const [secondaryFilter, setSecondaryFilter] = useState<stateType>(
    initialSecondaryState
  );

  useEffect(() => {
    dispatch(
      getFilteredVehicleModelList({
        FilterPayload: { ...rentingFleetFilter, cities: [cityData?.name] },
        setMasterModel: true,
      })
    );
  }, [cityData?.name]);

  // setting secondary filter (left Panel)
  useEffect(
    () => {
      if (
        filteredModelList?.availableModels?.length > 0 &&
        masterModelForCity
      ) {
        // let newState = [...filteredModelList?.availableModels].map((item) => {
        let newState = masterModelForCity.map((item) => {
          return {
            id: item?.modelName,
            checked: false,
            model_name: item.displayName,
          };
        });
        const maximumPrice = amountConvertToRupee(
          filteredModelList?.maximumPrice
        );
        const minimumPrice = amountConvertToRupee(
          filteredModelList?.minimumPrice
        );
        setSecondaryFilter((pre) => {
          return {
            ...pre,
            priceRange: [minimumPrice, maximumPrice],
            vehicleModel: newState,
          };
        });
      }
    },
    [
      /* filteredModelList?.maximumPrice,
    filteredModelList?.minimumPrice,
    filteredModelList?.availableModels.length, */
    ]
  );

  // Call model availibilty api
  const getFilteredModelList = (
    models: string[] = [],
    selectedType: string = ""
  ) => {
    const { vehicleType, priceRange, selectedModels } = secondaryFilter;
    const FilterPayload = {
      ...rentingFleetFilter,
      startDate: getTimestampAtStartOfDay(primaryFilter?.startDate),
      endDate: getTimestampAtEndOfDay(primaryFilter?.endDate),
      cities: [cityData?.name],
    };

    if (selectedType) {
      if (selectedType !== VehicleTypeEnum.All) {
        FilterPayload.vehicleType =
          VehicleTypeEnum[selectedType as keyof typeof VehicleTypeEnum];
      }
    } else if (vehicleType) {
      if (vehicleType !== VehicleTypeEnum.All) {
        FilterPayload.vehicleType =
          VehicleTypeEnum[vehicleType as keyof typeof VehicleTypeEnum];
      }
    }
    if (priceRange && priceRange?.length > 0) {
      if (priceRange[0])
        FilterPayload.minPrice = amountConvertToPaise(priceRange[0]);
      if (priceRange[1])
        FilterPayload.maxPrice = amountConvertToPaise(priceRange[1]);
    }
    if (models && models?.length > 0) {
      FilterPayload.models = models;
    } else if (selectedModels && selectedModels?.length > 0) {
      FilterPayload.models = selectedModels;
    }
    const filterPayload = {
      FilterPayload,
      // filterType: homePageFilters.carousalFilter,
    };
    dispatch(getFilteredVehicleModelList(filterPayload));
  };

  // Triggered when package is changes
  const handlePackageChange = (packageObj) => {
    let currentPackage =
      masterPlanDurationsData.find((item: any) => item === packageObj?.name) ||
      masterPlanDurationsData[0];

    let endDate = findEndDate(primaryFilter.startDate, currentPackage);
    setPrimaryFilter((prev) => {
      return {
        ...prev,
        selectedPackage: currentPackage,
        endDate,
      };
    });
  };

  // triggred when Input type 'text' or 'radio' button
  const handleChange = (
    name: string,
    value: string,
    isMobile: boolean | undefined
  ) => {
    setSecondaryFilter({
      ...secondaryFilter,
      // [name]: name === "vehicleType" ? VehicleTypeEnum[value as keyof typeof VehicleTypeEnum] : value,
      [name]: value,
    });
    getFilteredModelList([], value);
  };

  // Vehicle Model Filter handler
  const handleCheckboxChange = (
    name: any,
    id: number | string,
    checked: boolean,
    isMobile: boolean | undefined
  ) => {
    let selectedModels: string[] = secondaryFilter.selectedModels;
    const copyModels = [...secondaryFilter[name]];
    const modifiedModels =
      copyModels.length > 0 &&
      copyModels.map((model) => {
        if (!id) {
          model.checked = false;
          selectedModels = [];
        } else if (id === model.id) {
          model.checked = checked;
          if (!selectedModels.includes(model.id) && checked) {
            //checking weather array contain the id
            selectedModels.push(model.id); //adding to array because value doesnt exists
          }
          if (!checked) {
            selectedModels.splice(selectedModels.indexOf(model.id), 1); //deleting
          }
        }
        return model;
      });
    setSecondaryFilter((prev) => {
      return {
        ...prev,
        [name]: modifiedModels,
        selectedModels: selectedModels,
      };
    });
    getFilteredModelList(selectedModels);
  };

  // Debounce price range for API calls
  const debouncedChangeHandler = useCallback(
    debounce(getFilteredModelList, 1000),
    []
  );

  // Price Range Change Handler
  const handlePriceRangeChange = (
    event: Event,
    newValue: number | number[]
  ) => {
    setSecondaryFilter((prev) => {
      return {
        ...prev,
        priceRange: newValue as number[],
      };
    });
    debouncedChangeHandler();
  };

  // Rest filter handler
  const handleResetstate = () => {
    setSecondaryFilter({
      ...initialSecondaryState,
    });
  };

  return (
    <>
      <Helmet>
        <title>Freedo: Bike Rentals | Two Wheelers Rentals App</title>
        <meta
          name="title"
          content="Freedo: Bike Rentals | Two Wheelers Rentals App"
        />
        <meta
          name="description"
          content="We provide great options for bike and scooter rental in Noida NCR. Get most reasonable rates with flexible km plans. Rent a bike now from our wide range fleet"
        />
        <meta name="url" content="https://freedo.rentals/rent" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://freedo.rentals/rent" />
        <meta
          property="og:title"
          content="Book Bike On Rent in Noida | Greater Noida | Delhi NCR"
        />
        <meta
          property="og:description"
          content="We provide great options for bike and scooter rental in Noida NCR. Get most reasonable rates with flexible km plans. Rent a bike now from our wide range fleet"
        />
        <meta property="twitter:url" content="https://freedo.rentals/rent" />
        <meta
          property="twitter:title"
          content="Book Bike On Rent in Noida | Greater Noida | Delhi NCR"
        />
        <meta
          property="twitter:description"
          content="We provide great options for bike and scooter rental in Noida NCR. Get most reasonable rates with flexible km plans. Rent a bike now from our wide range fleet"
        />
        <meta name="googlebot" content="index" />
        <link rel="canonical" href="https://freedo.rentals/rent" />
      </Helmet>
      <Box component="section" className="renting-wrapper ">
        <Container disableGutters={true} fixed={true}>
          <Box className="renting-wrap">
            <Grid
              container
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              className=""
            >
              {/* ----------- Left Section ---------- */}
              <Filters
                handleChange={handleChange}
                handleCheckboxChange={handleCheckboxChange}
                rentDetails={rentDetails}
                maxPrice={maximumPrice}
                minPrice={minimumPrice}
                handleResetstate={handleResetstate}
                setSecondaryFilter={setSecondaryFilter}
                secondaryFilter={secondaryFilter}
                handlePriceRangeChange={handlePriceRangeChange}
              />

              {/* ------------- Right Section ------------ */}
              <Grid size={{ xs: 12, md: 8, lg: 9 }}>
                <Box
                  component="section"
                  className="Renting-wrapper rent-filter-result"
                >
                  <Box className="rent-wrap">
                    {/* Top section with city, dates etc... */}
                    <TopFilter
                      cityData={cityData}
                      handlePackageChange={handlePackageChange}
                      findEndDate={findEndDate}
                      setPrimaryFilter={setPrimaryFilter}
                      primaryFilter={primaryFilter}
                      getFilteredModelList={getFilteredModelList}
                    />
                    {/* Vehicles card section */}
                    <VehicleWrapper
                      state={{ ...primaryFilter, ...secondaryFilter }}
                      setSecondaryFilter={setSecondaryFilter}
                      secondaryFilter={secondaryFilter}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Rent;
