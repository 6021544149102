import { Box, Modal, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { useDispatch, useSelector } from "react-redux";
import {
  BikeIcon,
  ExtremeBikeImg,
  FilterIcon,
  FilterIconYor,
  noVehicleFound,
  SortIconYor,
  YorBackground1,
  YorBackground2,
} from "../../../assets";

import {
  LocalStorage,
  SessionStorage,
  TypographyConstants,
  yorFleetSort,
} from "../../../constant/enum";
import { RootState } from "../../../redux/store";

import CancelIcon from "@mui/icons-material/Cancel";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { WEB_URL } from "../../../config/apiUrls";
import { Routes } from "../../../constant/routeContants";
import {
  filterProps,
  rentingFleetFields,
  rentingFleetFilter,
} from "../../../interfaces/yorInterfaces";
import en from "../../../locales/en.json";
import { colors } from "../../../themes/colors";
import {
  convertPaiseToRupee,
  convertTimestampToDate,
  getLocalStorageData,
  getSessionStorage,
  getTimestampAtStartOfDay,
  isEqualObject,
  setSessionStorage,
  toggleSignInDrawer,
} from "../../../utills/helper";
import {
  CustomButton,
  CustomChip,
} from "../../components/atom/Buttons/CustomButtons";
import InputDatePicker from "../../components/atom/InputFields/InputDatePicker";
import {
  filterVehicleModel,
  getAvailableVehicleYor,
  setUserBookingDataYor,
  sortYorRentingFleet,
} from "../../redux/actions/yorActions";
import Styles from "../YorHomeScreen/index.module.css";
import RentingFleetCard, {
  RentingFleetCardLoader,
} from "../YorHomeScreen/sections/rentingFleetCard";
import CustomSelect from "../../components/atom/InputFields/inputSelect";

// locale data
const { yourOwnRide } = en;

//initial state
const initialState: rentingFleetFields = {
  date: null,
  hub: { name: "", displayName: "" },
  vehicleType: { name: "", displayName: "" },
  vehicleModel: { name: "", displayName: "" },
  sort: yorFleetSort.LOW_TO_HIGH,
};

const YorRentingFleet = () => {
  const cityData = getLocalStorageData(LocalStorage.yorUserCity);
  const startRideDate = getSessionStorage<{ startDate: string }>(
    SessionStorage.yorStartRide
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // checks city or ride start date exist or not , if not it will navigate to YOR home screen
  if (!cityData || !startRideDate?.startDate) {
    navigate(Routes.YOR_HOME_SCREEN);
    return <></>;
  }

  // fields for filters
  const [fields, setFields] = useState<rentingFleetFields>({
    ...initialState,
  });

  // state to hande mobile view filter (open and close)
  const [mobileFilter, setMobileFilter] = useState<rentingFleetFilter>({
    filter: false,
    sort: false,
  });

  // data from reducer
  const {
    getAvailableVehicleLoader,
    getAvailableVehicles: { branches = [], models = [], vehicleTypes = [] },
    allAvailableVehicles = [],
    yorBookingData = {},
  } = useSelector((state: RootState) => state.yorReducer);

  // data from auth reducer
  const { isAuthenticated, userProfileData } = useSelector(
    (state: RootState) => state.newAuthReducer
  );

  // side effect to get all available vehicles api calls
  useEffect(() => {
    getRentingFleet(startRideDate?.startDate);
    setFields((prev: rentingFleetFields) => ({
      ...prev,
      date: dayjs(startRideDate?.startDate),
    }));
  }, []);

  //side effect to navigate to checkout page after authentication
  useEffect(() => {
    if (isAuthenticated && Object.keys(yorBookingData)?.length) {
      navigate(Routes.YOR_PLAN_DETAILS, {
        state: { ...yorBookingData },
      });
    }
  }, [isAuthenticated]);

  // on view vehicle click
  const getRentingFleet = (
    startDate: any | null = null,
    branches: string | null = null,
    modelNames: string | null = null
  ) => {
    const payload = {
      apiParams: {
        cityNames: [cityData?.name],
        startDate: startDate ? getTimestampAtStartOfDay(startDate) : null,
        branches: branches ? [branches] : null,
        modelNames: modelNames ? [modelNames] : null,
      },
    };
    dispatch(getAvailableVehicleYor(payload));
  };

  // handles filter fields change
  const handleChange = (key: string, value: any) => {
    let fieldsCopy: rentingFleetFields = { ...fields };

    if (key === "vehicleModel")
      fieldsCopy.vehicleType = initialState.vehicleType;

    fieldsCopy[key] = value;

    setFields((prev: rentingFleetFields) => ({
      ...prev,
      ...fieldsCopy,
    }));

    if (key === "vehicleType")
      dispatch(filterVehicleModel({ vehicleType: value?.name }));
  };

  // handle mobile view filter open close
  const handleMobileFilters = (key: string) => {
    let tempObj: rentingFleetFilter = { ...mobileFilter };
    if (key === "filter") {
      tempObj.filter = !mobileFilter.filter;
      tempObj.sort = false;
    } else {
      tempObj.filter = false;
      tempObj.sort = !mobileFilter.sort;
    }
    setMobileFilter((prev: rentingFleetFilter) => ({
      ...prev,
      ...tempObj,
    }));
  };

  // handle reset filter
  const handleResetFilter = () => {
    /* api call */
    getRentingFleet(fields?.date);

    setFields((prev: rentingFleetFields) => ({
      ...prev,
      ...initialState,
      date: prev.date,
    }));
  };

  // handle date change
  const handleDateChange = (date: any) => {
    setFields((prev: rentingFleetFields) => ({ ...prev, date }));
    getRentingFleet(date, fields?.hub?.name, fields.vehicleModel?.name);
    setSessionStorage(SessionStorage.yorStartRide, { startDate: date });
  };

  // on click proceed
  const onClickProceed = (data: any = {}) => {
    if (!isAuthenticated || Object.keys(userProfileData)?.length == 0) {
      toggleSignInDrawer();
      dispatch(
        setUserBookingDataYor({
          ...data,
          availableStartDate: data?.nextAvailableOn
            ? data.nextAvailableOn
            : getTimestampAtStartOfDay(fields.date),
        })
      );
      return;
    }

    navigate(Routes.YOR_PLAN_DETAILS, {
      state: {
        ...data,
        availableStartDate: data?.nextAvailableOn
          ? data.nextAvailableOn
          : getTimestampAtStartOfDay(fields.date),
      },
    });
  };

  return (
    <Stack>
      <Box sx={{ display: "flex" }}>
        {/* Filters */}
        <Box display={{ xs: "none", sm: "none", md: "flex" }}>
          <Filters
            fields={fields}
            models={models}
            hub={branches}
            vehicleTypes={vehicleTypes}
            handleChange={handleChange}
            getRentingFleet={getRentingFleet}
            handleResetFilter={handleResetFilter}
            onChangeDate={handleDateChange}
          />
        </Box>

        {/* Renting fleets */}
        <Stack
          className={Styles.rentingFleetBoxContent}
          sx={{
            backgroundImage: `url(${YorBackground2})`,
          }}
        >
          {/* breadcrums and quick sort */}{" "}
          {/* ---------------temporary------------ */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {/* breadcrums */}
            <Stack>
              <Typography
                variant={TypographyConstants.SUBTITLE2}
                sx={{ color: colors.secondary_gray }}
              >
                {yourOwnRide.browseAllVehicle}
              </Typography>
              <Box sx={{ display: "flex", gap: "6px" }}>
                <Box
                  onClick={() => navigate(Routes.YOR_HOME_SCREEN)}
                  sx={{ cursor: "pointer" }}
                >
                  <Typography>{yourOwnRide.home}</Typography>
                </Box>
                <Typography sx={{ color: colors.primary }}>
                  {yourOwnRide.browseAll}
                </Typography>
              </Box>
            </Stack>

            {/* fleet sorting */}
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                gap: "10px",
                alignItems: "center",
              }}
            >
              <Typography
                variant={TypographyConstants.SUBTITLE2}
                sx={{ fontWeight: 600, color: colors.secondary_gray }}
              >
                {yourOwnRide?.sortBy}
              </Typography>
              <CustomChip
                label={yourOwnRide?.priceLowToHigh}
                customStyle={{
                  padding: "15px 12px",
                  "& .MuiChip-label": {
                    fontWeight: 500,
                    fontSize: "14px",
                    color:
                      fields?.sort === yorFleetSort.LOW_TO_HIGH
                        ? colors.white
                        : colors.secondary_gray,
                  },
                }}
                variant={
                  fields?.sort === yorFleetSort.LOW_TO_HIGH
                    ? "filled"
                    : "outlined"
                }
                onClick={() => {
                  setFields((prev: rentingFleetFields) => ({
                    ...prev,
                    sort: yorFleetSort.LOW_TO_HIGH,
                  }));

                  // dispatch to sort renting feet data low to high
                  dispatch(
                    sortYorRentingFleet({ sortType: yorFleetSort.LOW_TO_HIGH })
                  );
                }}
              />
              <CustomChip
                label={yourOwnRide.priceHighToLow}
                customStyle={{
                  padding: "15px 12px",
                  "& .MuiChip-label": {
                    fontWeight: 500,
                    fontSize: "14px",
                    color:
                      fields?.sort === yorFleetSort.HIGH_TO_LOW
                        ? colors.white
                        : colors.secondary_gray,
                  },
                }}
                variant={
                  fields?.sort === yorFleetSort.HIGH_TO_LOW
                    ? "filled"
                    : "outlined"
                }
                onClick={() => {
                  setFields((prev: rentingFleetFields) => ({
                    ...prev,
                    sort: yorFleetSort.HIGH_TO_LOW,
                  }));

                  // dispatch to sort renting feet data high to low
                  dispatch(
                    sortYorRentingFleet({ sortType: yorFleetSort.HIGH_TO_LOW })
                  );
                }}
              />
            </Box>
          </Box>
          {/* renting fleet cards */}
          {getAvailableVehicleLoader ? (
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: {
                  xs: "center",
                  sm: "center",
                  md: "normal",
                },
              }}
            >
              {[...Array(3)].map(() => (
                <Grid
                  size={{ sm: 12, md: 6, lg: 4, xl: 3 }}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <RentingFleetCardLoader />
                </Grid>
              ))}
            </Grid>
          ) : (
            <>
              {allAvailableVehicles?.length ? (
                <Stack gap="20px">
                  {/* results */}
                  <Typography
                    variant={TypographyConstants.CAPTION}
                    sx={{ color: colors?.dark_grayish_cyan }}
                  >
                    {`Showing ${allAvailableVehicles?.length} Subscription in ${cityData?.displayName}`}
                  </Typography>
                  {/* fleets */}
                  <Box>
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: {
                          xs: "center",
                          sm: "center",
                          md: "normal",
                        },
                      }}
                    >
                      {allAvailableVehicles?.map((item: any) => (
                        <Grid
                          size={{ sm: 12, md: 6, lg: 4, xl: 3 }}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <RentingFleetCard
                            bikeModel={item?.modelDetails?.displayName}
                            hubName={item?.branchDetails?.displayName}
                            displacement={`${item?.modelDetails?.details?.specificSpecifications?.cubicCapacity} CC`}
                            power={`${item?.modelDetails?.details?.specificSpecifications?.hp}`}
                            fuelEconomy={`${item?.modelDetails?.details?.specificSpecifications?.averageEconomy}`}
                            planPrice={`${convertPaiseToRupee(
                              item?.planDetails?.monthlyCharges
                            )}/month`}
                            onClickProceed={() => onClickProceed(item)}
                            loadingProceed={false}
                            overlaytext={
                              item?.nextAvailableOn
                                ? `Available from: ${convertTimestampToDate(
                                    item?.nextAvailableOn
                                  )}`
                                : `Available`
                            }
                            bikeImage={
                              item?.modelDetails?.imagePath &&
                              item?.modelDetails?.images
                                ? `${WEB_URL}/${item?.modelDetails?.imagePath}/${item?.modelDetails?.images[0]?.type}-${item?.modelDetails?.images[0]?.fileName}`
                                : ExtremeBikeImg
                            }
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Stack>
              ) : (
                <div className="mt-5">
                  <img
                    src={noVehicleFound}
                    className="no-vehicle-found"
                    alt="no-vehicle found"
                  />
                  {/* <video width="100%" className="" autoPlay muted loop>
              <source src={noVehicleFound} type="video/mp4" />
              <source src="movie.ogg" type="video/ogg" />
            </video> */}
                  <div className="text-center">
                    <h5 className="mb-1">Oops! No vehicles found</h5>
                    <p>Please modify your search and try again</p>
                  </div>
                </div>
              )}
            </>
          )}
        </Stack>
      </Box>

      {/* Mobile filters bottom buttons */}
      <Box className={Styles.mobileFiltersFleet}>
        <Box
          className={Styles.mobilefilterHeading}
          onClick={() => handleMobileFilters("filter")}
        >
          <img src={FilterIconYor} />
          <Typography
            variant={TypographyConstants.BODY1}
            sx={{ color: colors.secondary_green }}
          >
            {yourOwnRide.filters}
          </Typography>
        </Box>
        <Box
          className={Styles.mobileSortHeading}
          onClick={() => handleMobileFilters("sort")}
        >
          <img src={SortIconYor} />
          <Typography
            variant={TypographyConstants.BODY1}
            sx={{ color: colors.secondary_green }}
          >
            {yourOwnRide.sort}
          </Typography>
        </Box>
      </Box>

      {/* Mobile view filters */}
      <Modal
        open={mobileFilter.filter}
        onClose={() => {
          handleMobileFilters("filter");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus={true}
      >
        <Box
          display={{ xs: "flex", sm: "flex", md: "none" }}
          sx={{ width: "100%", height: "100%" }}
        >
          <Filters
            fields={fields}
            models={models}
            hub={branches}
            vehicleTypes={vehicleTypes}
            handleChange={handleChange}
            getRentingFleet={getRentingFleet}
            width={"100%"}
            onClose={() => {
              handleMobileFilters("filter");
            }}
            showCloseIcon={true}
            handleResetFilter={handleResetFilter}
            onChangeDate={handleDateChange}
            showApplyFilter={true}
          />
        </Box>
      </Modal>

      {/* Mobile view sort */}
      <Modal
        open={mobileFilter.sort}
        onClose={() => {
          handleMobileFilters("sort");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus={true}
      >
        <Stack
          className={Styles.mobileSortContainer}
          display={{ xs: "flex", sm: "flex", md: "none" }}
        >
          <Box
            sx={{
              borderBottom: "1px solid #ededed",
              padding: "20px 20px 10px 20px",
            }}
          >
            <Typography
              variant={TypographyConstants.SUBTITLE2}
              sx={{ fontWeight: 600, color: colors.secondary_gray }}
            >
              {yourOwnRide?.sortBy}
            </Typography>
          </Box>
          <Box className={Styles.mobileSortChipContainer}>
            <CustomChip
              label={yourOwnRide?.priceLowToHigh}
              sx={{
                padding: "18px 10px",
                borderRadius: "38px",
                "& .MuiChip-label": {
                  fontWeight: 500,
                  fontSize: "14px",
                  color:
                    fields?.sort === yorFleetSort.LOW_TO_HIGH
                      ? colors.white
                      : colors.secondary_gray,
                },
              }}
              variant={
                fields?.sort === yorFleetSort.LOW_TO_HIGH
                  ? "filled"
                  : "outlined"
              }
              onClick={() => {
                setFields((prev: rentingFleetFields) => ({
                  ...prev,
                  sort: yorFleetSort.LOW_TO_HIGH,
                }));
                // dispatch to sort renting feet data low to high
                dispatch(
                  sortYorRentingFleet({ sortType: yorFleetSort.LOW_TO_HIGH })
                );

                // close mobile filter sort
                handleMobileFilters("sort");
              }}
            />
            <CustomChip
              label={yourOwnRide.priceHighToLow}
              sx={{
                padding: "18px 10px",
                borderRadius: "38px",
                "& .MuiChip-label": {
                  fontWeight: 500,
                  fontSize: "14px",
                  color:
                    fields?.sort === yorFleetSort.HIGH_TO_LOW
                      ? colors.white
                      : colors.secondary_gray,
                },
              }}
              variant={
                fields?.sort === yorFleetSort.HIGH_TO_LOW
                  ? "filled"
                  : "outlined"
              }
              onClick={() => {
                setFields((prev: rentingFleetFields) => ({
                  ...prev,
                  sort: yorFleetSort.HIGH_TO_LOW,
                }));

                // dispatch to sort renting feet data high to low
                dispatch(
                  sortYorRentingFleet({ sortType: yorFleetSort.HIGH_TO_LOW })
                );

                // close mobile filter sort
                handleMobileFilters("sort");
              }}
            />
          </Box>
        </Stack>
      </Modal>
    </Stack>
  );
};

/* filters component */
const Filters = (props: filterProps) => {
  const {
    fields,
    vehicleTypes,
    hub,
    models,
    handleChange,
    getRentingFleet,
    onChangeDate,
    width = "300px",
    onClose = () => {},
    showCloseIcon = false,
    handleResetFilter = () => {},
    showApplyFilter = false,
  } = props;

  return (
    <Stack
      className={Styles.rentingFleetContainer}
      sx={{
        backgroundImage: `url(${YorBackground1})`,
        backgroundRepeat: "no-repeat",
        width: width,
      }}
    >
      <Box className={Styles.rentingFleetFiltersBox}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <img src={FilterIcon} />
          <Typography
            variant={TypographyConstants.CAPTION}
            sx={{ fontSize: "12px", color: colors.secondary_green }}
          >
            {yourOwnRide.filters}
          </Typography>
        </Box>

        {showCloseIcon && (
          <Box onClick={() => onClose()}>
            <CancelIcon sx={{ color: colors.dark_grayish_cyan }} />
          </Box>
        )}
      </Box>
      <Stack className={Styles.filtersSectionscontainer}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <CustomChip
            label={yourOwnRide.clear}
            customStyle={{ padding: "10px 10px" }}
            onClick={() => handleResetFilter()}
            disabled={isEqualObject(initialState, fields)}
          />
        </Box>
        <Typography
          variant={TypographyConstants.SUBTITLE2}
          sx={{ color: colors.secondary_green }}
        >
          {yourOwnRide.availability}
        </Typography>

        <InputDatePicker
          placeholder={yourOwnRide.selectDate}
          value={fields.date}
          onChange={onChangeDate}
          placement={"right-end"}
          minDate={dayjs()}
        />
      </Stack>

      <Stack className={Styles.filtersSectionscontainer}>
        <Typography
          variant={TypographyConstants.SUBTITLE2}
          sx={{ color: colors.secondary_green }}
        >
          {yourOwnRide.location}
        </Typography>

        <Stack>
          <CustomSelect
            icon={BikeIcon}
            placeholder={yourOwnRide.hub}
            choice={hub}
            value={fields?.hub?.displayName}
            defaultKey={"displayName"}
            handleChange={(hub: any) => {
              handleChange("hub", hub);

              /* api call */
              getRentingFleet(
                fields.date,
                hub?.name,
                fields.vehicleModel?.name
              );
            }}
          />
        </Stack>
      </Stack>

      <Stack className={Styles.filtersSectionscontainer}>
        <Typography
          variant={TypographyConstants.SUBTITLE2}
          sx={{ color: colors.secondary_green }}
        >
          {yourOwnRide.vehicle}
        </Typography>

        <Stack gap={"10px"}>
          <CustomSelect
            icon={BikeIcon}
            placeholder={yourOwnRide.vehicleType}
            choice={vehicleTypes}
            value={fields?.vehicleType?.displayName}
            defaultKey={"displayName"}
            handleChange={(vehicleType: any) => {
              handleChange("vehicleType", vehicleType);
            }}
          />

          <CustomSelect
            icon={BikeIcon}
            placeholder={yourOwnRide.vehicleModel}
            choice={models}
            value={fields?.vehicleModel?.displayName}
            defaultKey={"displayName"}
            handleChange={(vehicleModel: any) => {
              handleChange("vehicleModel", vehicleModel);

              /* api call */
              getRentingFleet(
                fields.date,
                fields?.hub?.name,
                vehicleModel?.name
              );
            }}
          />
        </Stack>
      </Stack>

      {showApplyFilter && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            padding: "20px",
          }}
        >
          <CustomButton
            label={yourOwnRide.apply}
            variant={"outlined"}
            wrapperStyles={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              maxWidth: "160px",
            }}
            sx={{ width: "100%", maxWidth: "160px" }}
            onClick={onClose}
          />
        </Box>
      )}
    </Stack>
  );
};
1;
export default YorRentingFleet;
