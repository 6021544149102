import { Box, Grid2, Typography } from "@mui/material";
import styles from "../../cmsComponent.module.css";
import { colors } from "../../../../../themes/colors";

interface bannerInterface {
  item: any;
}

const Banner = ({ item }: bannerInterface) => {
  const { title = "", description = "", id, tagline } = item;

  const getImage =
    item?.image?.length &&
    Object.keys(item?.image[0]).length &&
    item?.image[0]?.formats?.small;

  return (
    <Box className={styles.banner}>
      <Grid2
        container
        spacing={3}
        direction={{
          xs: "column",
          md: id % 2 !== 0 ? "row-reverse" : "row",
        }}
      >
        <Grid2 size={{ sm: 12, md: 5 }} width="100%">
          <img
            src={getImage}
            alt="Freedo's Story"
            style={{
              borderRadius: "16px",
              maxWidth: "100%",
              objectFit: "contain",
              width: "100%",
            }}
          />
        </Grid2>
        <Grid2 size={{ sm: 12, md: 7 }} className={styles.gridStyleBanner}>
          <Box className={styles.rightText}>
            <Typography variant="h4" color={colors.secondary_black}>
              {title}
            </Typography>
            <Typography
              variant="body2"
              lineHeight="normal"
              className={styles.bannerTypo}
            >
              {description}
            </Typography>

            <Typography variant="subtitle2">{tagline}</Typography>
          </Box>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default Banner;
