import React from "react";
import Carousel from "react-material-ui-carousel";
import styles from "../planDetails.module.css";

function CustomCarousel(props: any) {
  return (
    <Carousel className={styles.carouselStyle}>
      {props?.items?.map((item, i) => {
        return <props.Item key={i} item={item} />;
      })}
    </Carousel>
  );
}

export default CustomCarousel;
