import { Box, Chip, Stack, Typography } from "@mui/material";
//import styles from "../components.module.css";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { fullFace, halfFace } from "../../../assets";
import { colors } from "../../../themes/colors";
const styles = {
  helmetChipContent: {
    minHeight: "35px",
    height: "40px",
    padding: "0px 10px",
    borderRadius: "22px ",
    border: "1px solid #ECECEC",
    gap: "0px",
  },

  badge: {
    border: "1px solid #ECECEC",
    borderRadius: "50%",
    position: "absolute",
    top: "-15px",
    right: "42%",
    /* z-index: 1; */
    backgroundColor: "white",
    padding: "2px",
    cursor: "pointer",
  },

  chipBadge: {
    height: "20px",
    position: "absolute",
    top: "-10px",
    right: "19%",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
  },
};

const HelmetIcons = {
  PREMIUM: fullFace,
  FULL_FACE: fullFace,
  HALF_FACE: halfFace,
  KIDS: halfFace,
};
interface HelmetBadgeInterface {
  title: string;
  type: string;
  price: string | number;
  helmetTypeCount: number;
  helmetIconType: string;
  clickHandler: Function;
}
function Helmetbadge({
  title,
  type,
  price,
  helmetTypeCount,
  helmetIconType,
  clickHandler,
}: HelmetBadgeInterface) {
  return (
    <Box sx={{ position: "relative", display: "inline-block" }}>
      <Chip
        icon={
          <Stack sx={{ padding: "5px 0px" }}>
            <img
              src={HelmetIcons[helmetIconType]}
              alt="helmetIcon"
              style={{ height: "20px" }}
            />
          </Stack>
        }
        label={
          <Stack sx={{ paddingTop: "5px 0px" }}>
            <Typography variant={"caption"}>
              {title} ({price})
            </Typography>
          </Stack>
        }
        variant="outlined"
        sx={{
          fontSize: helmetTypeCount === 0 ? "14px" : "12px",
          //   pt: "5px",
          ...styles.helmetChipContent,
        }}
      />
      {helmetTypeCount === 0 ? (
        <Stack
          direction="row"
          sx={{ ...styles.badge }}
          onClick={(e: any) => clickHandler(e, type, "INCREMENT")}
        >
          <AddIcon sx={{ fontSize: "1rem" }} />
        </Stack>
      ) : (
        <Box sx={{ ...styles.chipBadge }}>
          <Chip
            label={
              <Stack
                direction={"row"}
                justifyContent="space-between"
                alignItems={"center"}
                width={"62px"}
              >
                <RemoveIcon
                  onClick={(e) => clickHandler(e, type, "DECREMENT")}
                  style={{ cursor: "pointer" }}
                  sx={{ fontSize: "1rem" }}
                />
                <Typography
                  sx={{ fontWeight: 600, color: colors.primary_dark_blue }}
                >
                  {helmetTypeCount}
                </Typography>
                <AddIcon
                  onClick={(e) => clickHandler(e, type, "INCREMENT")}
                  style={{ cursor: "pointer" }}
                  sx={{ fontSize: "1rem" }}
                />
              </Stack>
            }
            variant="outlined"
            sx={{
              height: "22px",
            }}
          />
        </Box>
      )}
    </Box>
  );
}

export default Helmetbadge;
