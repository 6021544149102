import { Box, Link, Typography } from "@mui/material";
import { Grid2 as Grid } from "@mui/material";
import { GenericObject } from "../../../../../utills/helper";
import { WEB_URL } from "../../../../../config/apiUrls";
import { bike } from "../../../../../assets";
import { Stack } from "react-bootstrap";
import {
  getDeliveryAddress,
  getFormattedDate,
  getFormattedINR,
} from "../../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/reducers";
import en from "../../../../../locales/en.json";
import { TypographyConstants } from "../../../../constants/enum";
import { colors } from "../../../../../themes/colors";
import { CustomButton } from "../../../../../sharedComponents/atom/Buttons/CustomButtons";
import { NavLink } from "react-router-dom";
import { cancelUserBooking } from "../../../../redux/actions";
import { Routes } from "../../../../../constant/routeContants";

interface cancelBookingProps {
  onCancelBooking: Function;
}

const CancelBooking: React.FC<cancelBookingProps> = (props) => {
  const { onCancelBooking } = props;

  /* data from booking reducer */
  const {
    bookingDetails,
    paymentSummary,
    planSummary,
    cancellationCharges,
    cancelBookingLoader,
  } = useSelector((state: RootState) => state.MyBookingReducer);

  /* array of key value pair for cancellation details */
  const keyValueArray: { key: string; value: string }[] = [
    {
      key:
        bookingDetails?.deliveryDetails?.type === "BRANCH"
          ? en.bookingDetails.pickUpLocation
          : en.bookingDetails.homeDeliveryLocation,
      value: getDeliveryAddress(
        bookingDetails?.deliveryDetails?.address ?? en.global.NA
      ),
    },
    {
      key: en.bookingDetails.pickUpDate,
      value: getFormattedDate(bookingDetails?.startDate, "date"),
    },
    {
      key: en.bookingDetails.plannedDropOffDate,
      value: getFormattedDate(bookingDetails?.endDate, "date"),
    },
    {
      key: en.bookingDetails.Duration,
      value: planSummary?.duration
        ? planSummary?.duration === 1
          ? `${planSummary?.duration} Day`
          : `${planSummary?.duration} Days`
        : en.global.NA,
    },
    {
      key: en.bookingDetails.totalAmountPaid,
      value: getFormattedINR(paymentSummary?.paid),
    },
  ];

  if (cancellationCharges?.deductCharges) {
    keyValueArray.push(
      {
        key: en.bookingDetails.cancellationCharges,
        value: getFormattedINR(cancellationCharges?.cancellationCharge?.amount),
      },
      {
        key: en.bookingDetails.refundedAmount,
        value: getFormattedINR(cancellationCharges?.refundAmount),
      }
    );
  }

  return (
    <Box sx={{ padding: "18px" }}>
      <Grid container spacing={3}>
        <Grid
          size={{ xs: 12, md: 6 }}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "90%" }}>
            <img
              src={
                bookingDetails?.modelDetails?.images?.length
                  ? `${WEB_URL}/${bookingDetails?.modelDetails?.path}/${bookingDetails?.modelDetails?.images[0]?.type}-${bookingDetails?.modelDetails?.images[0]?.fileName}` // need to change bike image
                  : bike
              }
              style={{ width: "100%", objectFit: "cover" }}
            />
          </Box>
        </Grid>
        <Grid
          size={{ xs: 12, md: 6 }}
          sx={{ display: "flex", flexDirection: "column", gap: "8px" }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant={TypographyConstants.SUBTITLE2}
              sx={{ color: colors.primary_red, fontWeight: 500 }}
            >
              {bookingDetails?.modelDetails?.modelManufacturerName}
            </Typography>
            <Typography
              variant={TypographyConstants.H5}
              sx={{ color: colors.secondary_black, fontWeight: 500 }}
            >
              {bookingDetails?.modelDetails?.modelDisplayName}
            </Typography>
          </Box>

          <Grid container spacing={3}>
            <Grid size={4}>
              <Typography
                variant={TypographyConstants.SUBTITLE2}
                sx={{ fontWeight: 600 }}
              >
                {`${en.bookingDetails.bookingId}`}
              </Typography>
            </Grid>
            <Grid size={8}>
              <Typography
                variant={TypographyConstants.SUBTITLE2}
                sx={{ fontWeight: 600, color: colors.primary }}
              >
                {bookingDetails?.id}
              </Typography>
            </Grid>
          </Grid>

          <Grid container columnSpacing={3} rowSpacing={1}>
            {keyValueArray?.map((item: any) => (
              <>
                <Grid size={4}>
                  <Typography
                    variant={TypographyConstants.SUBTITLE2}
                    sx={{ fontWeight: 400 }}
                  >
                    {item?.key}
                  </Typography>
                </Grid>
                <Grid size={8}>
                  <Typography
                    variant={TypographyConstants.SUBTITLE2}
                    sx={{ fontWeight: 400 }}
                  >
                    {item?.value}
                  </Typography>
                </Grid>
              </>
            ))}
          </Grid>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "3px",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "14px",
            }}
          >
            <CustomButton
              variant={"contained"}
              label={en.bookingDetails.cancelBooking}
              onClick={onCancelBooking}
              loading={cancelBookingLoader}
              wrapperStyles={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
              style={{
                padding: "5px 15px",
                width: "68%",
              }}
            />
            <Link
              href={`${WEB_URL}${Routes.TERMS_CONDITIONS}`}
              target="_blank"
              sx={{ fontSize: "12px" }}
            >
              {en.bookingDetails.refundAndCancelPolicy}
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CancelBooking;
