import { Box, Stack, Typography } from "@mui/material";
import { SoftStar } from "../../../../assets";
import { TypographyConstants } from "../../../../constant/enum";
import Styles from "../index.module.css";
export const YORHeadingContent = ({
  heading,
  content,
  padding = "20px",
  gap = "30px",
}) => {
  return (
    <Stack
      className={Styles.YORHeadingContent}
      sx={{ padding: padding, gap: gap }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <img src={SoftStar} />
        <Typography
          // variant={TypographyConstants.YOR_HEADING}
          sx={{ textAlign: "center" }}
        >
          {heading}
        </Typography>
        <img src={SoftStar} />
      </Box>
      {content}
    </Stack>
  );
};
