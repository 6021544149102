import { FC, useEffect, useState, lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EndDateCalendarIcon,
  LocationIcon,
  SearchButtonIcon,
  StartDateCalendarIcon,
} from "../../../../assets";
import en from "../../../../locales/en.json";
import { actions } from "../../../../redux";
import {
  addDays,
  getDifferenceInDays,
  getLocalStorageData,
  getTimestampAtEndOfDay,
  getTimestampAtStartOfDay,
} from "../../../../utills/helper";
import { Box, Stack, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { LocalStorage } from "../../../../constant/enum";
import { Routes } from "../../../../constant/routeContants";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import {
  getDeliverySlotPause,
  getFilteredVehicleModelList,
} from "../../../../redux/actions/commonActions";
import { RootState } from "../../../../redux/reducers";
import { CustomIconButton } from "../../../../sharedComponents/atom/Buttons/CustomButtons";
import CustomReactDateTimePicker from "../../../../sharedComponents/molecule/DatePicker/CustomReactDateTimePicker";
import GenericModal from "../../../../sharedComponents/molecule/Modals/GenericModal";
import CustomSearchLayout from "../../../../sharedComponents/molecule/UiComponents/CustomSearchLayout";
import { homePageFilters } from "../../../constants/enum";
import { getFormattedDate } from "../../../utils/helper";
const SelectCityModalContent = lazy(() => import("./SelectCityModalContent"));

const { homePageCarousal } = en.home;

const HomePageCarousal = ({ cmsData }: any) => {
  return (
    <section className="slider-wrapper">
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators">
          {cmsData?.map((_data: any, index: number) => {
            return (
              <button
                key={_data.id}
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to={index}
                className="active"
                aria-current="true"
                aria-label={"Slide " + (index + 1)}
              ></button>
            );
          })}
        </div>
        <div className="carousel-inner">
          <CarousalItem cmsData={cmsData} />
        </div>
      </div>
    </section>
  );
};

const CarousalItem = (props: any) => {
  return (
    <>
      {props.cmsData?.slice(0, 1)?.map((_details: any, index: number) => {
        return (
          <div
            className={"carousel-item " + (index === 0 ? " active " : "")}
            data-bs-interval="10000"
            key={index}
          >
            <div className="container-fluid">
              <div className="main-content-wrapper main-content-wrapper-slider">
                <div className="row slider-content">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <h5>{_details.title}</h5>
                    <h1>{_details.subTitle}</h1>
                    <p>{_details.description}</p>
                    {index == 0 ? (
                      <LocationModal />
                    ) : (
                      <Link
                        to={Routes.RENT}
                        className="freedo-primary-button rounded-pill"
                      >
                        Explore our fleet
                        <i className="fa-solid fa-right-long"></i>
                      </Link>
                    )}
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="row justify-content-end slider-right-wrap">
                      <div className="slider-img-wrap">
                        <img
                          src={_details?.image1}
                          alt=""
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = _details?.image1;
                          }}
                        />

                        <img
                          src={_details?.image2}
                          className="slide-2"
                          alt=""
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = _details?.image2;
                          }}
                        />
                      </div>
                      <div className="slider-img-wrap">
                        <img
                          src={_details?.image3}
                          className="slide-3"
                          alt=""
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = _details?.image3;
                          }}
                        />
                        <img
                          src={_details?.image4}
                          alt=""
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = _details?.image4;
                          }}
                        />
                      </div>

                      <div className="top-content rounded-pill">
                        {homePageCarousal.HassleFreeRentalSolution}
                        <i className="fa-solid fa-circle-check"></i>
                      </div>
                      <div className="bottom-content rounded-pill">
                        {homePageCarousal.AffordableRentalRides}
                        <i className="fa-solid fa-circle-check"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
const jrnyData = {
  city: null,
  date: null,
  endDate: null,
  package: null,
};
interface RideDateInterface {
  date: any;
  displayStartDate?: any;
  displayEndDate?: any;
}
const initialStartRide: RideDateInterface = {
  date: null,
  displayStartDate: "Start Ride Date",
};
const initialEndRide: RideDateInterface = {
  date: null,
  displayEndDate: "End Ride Date",
};
const packagebuttonStyles = {
  padding: "2px 4px",
  borderRadius: "10%",
};
const LocationModal: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { masterPlanDurationsData, carousalFilter, branchSlotPause } =
    useSelector((state: any) => state.rentalsCommonReducer);

  const [journyData, setJournyData] = useState<any>(jrnyData);
  const cityData = getLocalStorageData(LocalStorage.userCity);
  const [startDate, setStartDate] = useState(
    initialStartRide
    // setHours(setMinutes(new Date(), 30), 17)
  );
  const [endDate, setEndDate] = useState(
    initialEndRide
    // { date: new Date(), displayEndDate: "End Ride Date" }
    // { date: null, displayEndDate: "End Ride Date" }
    // setHours(setMinutes(new Date(), 30), 17)
  );
  const [showStartDate, setShowStartDate] = useState(false);
  const [showEndDate, setShowEndDate] = useState(false);
  const [selectedPackageDays, setSelectedPackageDays] = useState<
    string | number
  >(0);
  const [showCityModal, setShowCityModal] = useState(false);
  const [journeyErrors, setJourneyErrors] = useState("");
  const userSelection = getLocalStorageData(LocalStorage.userSelection);

  useEffect(() => {
    if (!cityData) {
      setShowCityModal(true);
    }
  }, []);

  const minEndDate = startDate?.date ? addDays(startDate?.date, 1) : new Date();

  // toggle city model handler
  const cityModalToggleHandler = () => {
    if (cityData) setShowCityModal((prev) => !prev);
  };

  const handleCityChange = (newCity: any) => {
    cityModalToggleHandler();
    if (newCity) {
      setJournyData((prev: any) => ({ ...prev, city: newCity }));
      const branchSlotPausePayload = {
        city: newCity?.name,
        startDate: userSelection?.startDate
          ? userSelection?.startDate
          : getTimestampAtStartOfDay(new Date()),
        endDate: userSelection?.endDate
          ? userSelection?.endDate
          : getTimestampAtEndOfDay(addDays(new Date(), 1)),
      };
      dispatch(getDeliverySlotPause(branchSlotPausePayload));
    }
  };
  const toggleStartDateCalendar = () => {
    setShowEndDate(false);
    setShowStartDate((prev) => {
      if (!prev) {
      }
      return !prev;
    });
  };
  const toggleEndDateCalendar = () => {
    setShowStartDate(false);
    setShowEndDate((prev) => {
      if (!prev) {
      }
      return !prev;
    });
  };

  // get package days
  const getPackageDaysValue = (packageDays: number | string) => {
    let selectedPackageDays: number | string = 0;
    if (packageDays) {
      if (masterPlanDurationsData.length > 0) {
        selectedPackageDays = masterPlanDurationsData.find(
          (item: any) => item === packageDays
        );
      }
      if (!selectedPackageDays) {
        selectedPackageDays = "Custom";
      }
    }
    return selectedPackageDays;
  };

  // package select handler
  const handlePackageSelected = (
    _e: any,
    indx: number,
    seletedPackage: any
  ) => {
    if (seletedPackage) {
      if (!startDate) {
        alert("Select Start Date First");
      }
      let selectedStartDate = startDate?.date ? startDate.date : new Date();

      let calculatedEndDate =
        seletedPackage && typeof seletedPackage === "number"
          ? addDays(new Date(selectedStartDate), seletedPackage)
          : null;

      let seletedPackageDays = getPackageDaysValue(seletedPackage);
      setSelectedPackageDays(seletedPackageDays);

      setEndDate((prev: any) => ({
        ...prev,
        date: calculatedEndDate ? calculatedEndDate : initialEndRide.date,
        displayEndDate: calculatedEndDate
          ? getFormattedDate(calculatedEndDate, "date")
          : initialEndRide.displayEndDate,
      }));

      setJournyData((prev) => {
        return { ...prev, endDate: calculatedEndDate, package: seletedPackage };
      });
    }
  };

  // type = "startDate" | "endDate"
  const updateJourneyData = (
    selectedDate: any,
    selectedPackage: any,
    type: string
  ) => {
    let seletedPackageDays: number | string = 0;

    // start date change
    if (type === "startDate") {
      setStartDate({
        date: selectedDate,
        displayStartDate: getFormattedDate(selectedDate, "date"),
      });
      if (selectedDate && endDate) {
        let packageDays = getDifferenceInDays(selectedDate, endDate?.date);
        if (packageDays > 0) {
          seletedPackageDays = getPackageDaysValue(packageDays);
          setSelectedPackageDays(seletedPackageDays);
        }
      }
      setJournyData((prev) => {
        return {
          ...prev,
          startDate: selectedDate,
          package: seletedPackageDays,
        };
      });
    }

    // end date change
    if (type === "endDate") {
      if (startDate && selectedDate) {
        let packageDays = getDifferenceInDays(startDate?.date, selectedDate);
        // setSelectedPackageDays(packageDays);
        if (packageDays > 0) {
          seletedPackageDays = getPackageDaysValue(packageDays);
          setSelectedPackageDays(seletedPackageDays);
        }
      }
      setJournyData((prev) => {
        return {
          ...prev,
          endDate: selectedDate,
          package: seletedPackageDays,
        };
      });
    }
  };

  // start ride date select handler
  const startDateChangeHandler = (date: any) => {
    let selectedStartDate = date;
    setStartDate({
      date: date,
      displayStartDate: getFormattedDate(date, "date"),
    });
    toggleStartDateCalendar();
    let selectedPackageObj = {};
    updateJourneyData(selectedStartDate, selectedPackageObj, "startDate");
  };

  // end ride date select handler
  const endDateChangeHandler = (date: any) => {
    let selectedEndDate = date;
    setEndDate((prev: any) => ({
      ...prev,
      date: date,
      displayEndDate: date ? getFormattedDate(date, "date") : null,
    }));

    toggleEndDateCalendar();
    let selectedPackageObj = {};
    updateJourneyData(selectedEndDate, selectedPackageObj, "endDate");
  };

  // Location form fields
  const formFieldsContent = (
    <>
      <Stack>
        <CustomIconButton
          // onClick={() => dispatch(toggleCityModal(true))}
          onClick={() => {
            cityModalToggleHandler();
          }}
        >
          {/* <LocationOnIcon style={{ height: "15px", width: "25px" }} /> */}

          <img
            src={LocationIcon}
            alt={"Calendar Icon"}
            height="1rem"
            style={{ height: "15px", width: "25px" }}
          />
          <Typography variant="caption" color="#01353D">
            {cityData ? cityData.displayName : homePageCarousal.LocateMe}
          </Typography>
        </CustomIconButton>
      </Stack>
      {/* Start date */}
      <Stack>
        <CustomIconButton onClick={toggleStartDateCalendar}>
          <img
            src={StartDateCalendarIcon}
            alt={"Calendar Icon"}
            height="1rem"
            style={{ height: "15px", width: "25px" }}
          />
          <Typography variant="caption">
            {startDate?.displayStartDate}
          </Typography>
        </CustomIconButton>
      </Stack>

      {/* End date */}
      <Stack>
        <CustomIconButton onClick={toggleEndDateCalendar}>
          <img
            src={EndDateCalendarIcon}
            alt={"Calendar Icon"}
            height="1rem"
            style={{ height: "15px", width: "25px" }}
          />
          {/* <SvgIcon component={calendarIcon} inheritViewBox /> */}
          <Typography variant="caption">{endDate?.displayEndDate}</Typography>
        </CustomIconButton>
      </Stack>
    </>
  );

  // validate journey form
  const validateJourneyForm = () => {
    let errorFlag = false;
    let errorMsg = "";
    if (!cityData?.name && !errorFlag) {
      // if ((!cityData?.name || !journyData?.city?.name) && !errorFlag) {
      errorFlag = true;
      errorMsg = en.errorMessages.SelectCity;
    }
    if (!journyData?.startDate && !errorFlag) {
      errorFlag = true;
      errorMsg = en.errorMessages.SelectStartDate;
    }
    if (!journyData?.endDate && !errorFlag) {
      errorFlag = true;
      errorMsg = en.errorMessages.SelectEndDate;
    }
    if (errorFlag) {
      setJourneyErrors(errorMsg);
    }
    return errorFlag;
  };

  // Journey search handler
  const carouselSearchRideHandler = (e: any) => {
    setJourneyErrors("");

    if (!validateJourneyForm()) {
      // dispatch set carousal filter
      // dispatch fetch models -> redirect to model rent page

      const FilterPayload = {
        ...carousalFilter,
        startDate: getTimestampAtStartOfDay(journyData?.startDate),
        endDate: getTimestampAtEndOfDay(journyData?.endDate),
        cities: [cityData?.name],
        // cities: [journyData?.city?.name],
      };
      const filterPayload = {
        FilterPayload,
        filterType: homePageFilters.carousalFilter,
        navigate,
      };
      // dispatch(setCarouselFilters(carouselFilterPayload));
      if (
        journyData?.startDate &&
        journyData?.endDate &&
        (cityData?.name || journyData?.city?.name)
      ) {
        dispatch(getFilteredVehicleModelList(filterPayload));
      }
    } else {
    }
  };

  const excludedDates =
    branchSlotPause && branchSlotPause.length > 0
      ? branchSlotPause.map((timestamp) => {
          return new Date(timestamp);
        })
      : [];

  return (
    <Box sx={{ marginTop: "10px" }} className="carouselWrapper">
      {/* Form */}
      <form className="locate-me-model-wrap">
        <CustomSearchLayout
          titleContent={
            <Typography variant="subtitle1">Search your next ride </Typography>
          }
          formFields={formFieldsContent}
          actionButton={
            <img
              src={SearchButtonIcon}
              alt={"Search Btn"}
              width="45px"
              style={{ cursor: "pointer" }}
              onClick={(e: any) => {
                carouselSearchRideHandler(e);
              }}
            />
          }
        />
      </form>
      {journeyErrors && (
        <Box>
          <Typography variant="errorMessage1">{journeyErrors}</Typography>
        </Box>
      )}

      {/* Start Ride Date Picker */}
      {showStartDate && (
        <>
          <CustomReactDateTimePicker
            selectedDate={startDate?.date}
            dateChangeHandler={startDateChangeHandler}
            showPackages
            packages={masterPlanDurationsData}
            packagebuttonStyles={packagebuttonStyles}
            selectedPackage={selectedPackageDays}
            packageSelectHandler={handlePackageSelected}
            toggleCalendarHandler={toggleStartDateCalendar}
            excludedDates={excludedDates}
          />
        </>
      )}

      {/* End Ride Date Picker */}
      {showEndDate && (
        <>
          <CustomReactDateTimePicker
            selectedDate={endDate?.date}
            dateChangeHandler={endDateChangeHandler}
            minDate={minEndDate}
            showPackages
            packages={masterPlanDurationsData}
            selectedPackage={selectedPackageDays}
            packagebuttonStyles={packagebuttonStyles}
            packageSelectHandler={handlePackageSelected}
            toggleCalendarHandler={toggleEndDateCalendar}
            excludedDates={excludedDates}
          />
        </>
      )}

      {/* Select city Modal */}
      {showCityModal && (
        <Suspense fallback={<Box>Loading...</Box>}>
          <GenericModal
            isOpen={showCityModal}
            handleClose={cityModalToggleHandler}
            title={"Select City"}
          >
            <SelectCityModalContent
              handleChange={(cityData: any) => {
                handleCityChange(cityData);
              }}
            />
          </GenericModal>
        </Suspense>
      )}
    </Box>
  );
};


export default HomePageCarousal;
