import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Box,
} from "@mui/material";
import { planCardBackground } from "../../../assets";
import { TypographyConstants } from "../../../constant/enum";

interface CustomCardProps {
  headerNotRequired?: boolean;
  title?: string;
  subtitle?: string;
  content: React.ReactNode;
  footerNotRequired?: boolean;
  footerContent?: React.ReactNode;
  onButtonClick?: () => void;
  showCheckbox?: boolean;
  checkboxChecked?: any;
  onCheckboxChange?: (checked: boolean) => void;
  contentStyle?: any;
  footerStyle?: any;
  data?: any;
}

const CustomCard = ({
  headerNotRequired = false,
  footerNotRequired = false,
  title,
  subtitle,
  content,
  footerContent,
  onButtonClick,
  showCheckbox = false, // Default is not to show checkbox
  checkboxChecked = {},
  contentStyle = {},
  footerStyle = {},
  data = {},
  onCheckboxChange,
}: CustomCardProps) => {
  const [checked, setChecked] = useState(checkboxChecked);

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    data
  ) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);
    if (onCheckboxChange) onCheckboxChange(data);
  };

  return (
    <Card
      sx={{
        minWidth: 210,
        boxShadow:
          checkboxChecked === data?.id
            ? "5px 7px 0px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,3.14),0px 1px 3px 0px rgba(0,0,0,0.12)"
            : "",
        ":hover": {
          boxShadow:
            "5px 7px 0px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,3.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
        },
      }}
    >
      {/* Card Header */}
      {!headerNotRequired && (
        <CardHeader
          title={title}
          subheader={subtitle}
          sx={{
            backgroundImage: `url(${planCardBackground})`,
            height: "59px",
            paddingRight: 0,
          }}
          titleTypographyProps={{ variant: TypographyConstants.SUBTITLE2 }}
          action={
            showCheckbox ? (
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkboxChecked === data?.id}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label=""
                />
              </Box>
            ) : null
          }
        />
      )}
      {/* Card Content */}
      <CardContent sx={{ ...contentStyle }}>{content}</CardContent>

      {/* Card Footer / Actions */}
      {!footerNotRequired && (
        <CardActions sx={{ ...footerStyle }}>{footerContent}</CardActions>
      )}
    </Card>
  );
};

export default CustomCard;
