import * as actionTypes from "../actionTypes";
const {
  GET_CITY_LIST_MASTERS,
  GET_PACKAGE_MASTERS,
  SET_PACKAGE_MASTERS,
  GET_VEHICLE_LIST,
  SET_VEHICLE_LIST,
  SET_BASIC_FILTER,
  GET_CAROUSEL_DATA,
  SET_CAROUSEL_DATA,
  SET_SELECTED_CITY,
  GET_NEAREST_CITY_REQUESTED,
  ZOHO_UPCOMING_CITY_LEAD_REQUESTED,
  TOGGLE_CITY_MODAL,
  TOGGLE_JOURNEY_MODAL,
} = actionTypes.HOME_ACTIONS;

export const getPackageMasters = (payload: any) => {
  return { type: GET_PACKAGE_MASTERS, payload };
};

export const setPackageMasters = (payload: any) => {
  return { type: SET_PACKAGE_MASTERS, payload };
};

export const getVehicleList = (payload: any) => {
  return { type: GET_VEHICLE_LIST, payload };
};

export const setVehicleList = (payload: any) => {
  return { type: SET_VEHICLE_LIST, payload };
};
export const getCityListMasters = (payload: any) => {
  return { type: GET_CITY_LIST_MASTERS, payload };
};
export const saveBasicFilter = (payload: any) => {
  return { type: SET_BASIC_FILTER, payload };
};
export const getCarouselData = (payload: any) => {
  return { type: GET_CAROUSEL_DATA, payload };
};
export const setCarouselData = (payload: any) => {
  return { type: SET_CAROUSEL_DATA, payload };
};
export const saveSelectedCity = (payload: any) => {
  return { type: SET_SELECTED_CITY, payload };
};
export const setZohoLeads = (payload: any) => {
  return { type: ZOHO_UPCOMING_CITY_LEAD_REQUESTED, payload };
};

export const toggledetectmodal = (payload: any) => {
  return { type: actionTypes.HOME_ACTIONS.TOGGLE_DETECTION_LOADER, payload };
};
export const getNearestCity = (payload: any, cityList, handleSuccess) => {
  return {
    // type: GET_NEAREST_CITY,
    type: GET_NEAREST_CITY_REQUESTED,
    payload,
    cityList,
    handleSuccess,
  };
};

export const toggleCityModal = (payload: any) => {
  return { type: TOGGLE_CITY_MODAL, payload };
};

export const toggleJourneyModal = (payload: any) => {
  return { type: TOGGLE_JOURNEY_MODAL, payload };
};
