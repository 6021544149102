import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/reducers";
import { delivermode } from "../assets";
import { getHomeDeliverCharge } from "../redux/actions";
import { MitcFormsSkelaton } from "./SkelatonLoader/MitcFormsSkelaton";
import Grid from "@mui/material/Grid2";
import { Box, Typography } from "@mui/material";
import { DeliveryModesEnum, DisplayDeliveryModesEnum } from "../constant/enum";

const DeliveryMode = (props: any) => {
  const { cityData, handleChange, selectedBranchConfig } = props;
  const [isHomeDelivery, setIsHomedelivery] = useState<boolean>(false);
  const [selectedDeliveryMode, setSelectedDeliveryMode] = useState<string>(
    DeliveryModesEnum.BRANCH
  );
  const { homeDeliverCharges, homeAddressList, addressNotDeliverable } =
    useSelector((state: RootState) => state.checkoutReducer);
  const hasAddress = homeAddressList.length > 0;
  const deliveryModes = selectedBranchConfig?.deliveryModes;

  const handleDeliveryModeChange = (value: string) => {
    setIsHomedelivery(value === DeliveryModesEnum.CUSTOMER_LOCATION);
    setSelectedDeliveryMode(value);
    handleChange(value);
  };

  return (
    <Grid
      container
      rowSpacing={1}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      alignItems={"center"}
      textAlign={"center"}
      className="mode-delivery row"
    >
      <Grid size={{ sm: 4, md: 5 }} textAlign={"left"}>
        <img src={delivermode} width="10" className="mode-of-delivery" />
        <Typography
          variant="caption"
          component="span"
          className="mode-of-delivery"
        >
          Mode Of Delivery
        </Typography>
      </Grid>
      <Grid size={{ sm: 8, md: 7 }}>
        {deliveryModes &&
          deliveryModes.map((mode: string, index: number) => {
            return (
              <Box className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  id={`mode${index}`}
                  name="drone"
                  value="huey"
                  checked={selectedDeliveryMode === mode}
                  onChange={() => handleDeliveryModeChange(mode)}
                />
                <label htmlFor={`mode${index}`} className="form-check-label">
                  {
                    DisplayDeliveryModesEnum[
                      mode as keyof typeof DisplayDeliveryModesEnum
                    ]
                  }
                </label>
              </Box>
            );
          })}
      </Grid>

      {/* Address info section */}
      {isHomeDelivery && (
        <Box className="applicable-info">
          <p className="pay-now-term-and-condition">
            <em
              className="add-address-home-delivery text-decoration-underline"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvaschangeaddress"
              aria-controls="offcanvaschangeaddress"
            >
              Add address
            </em>
            for home delivery
          </p>
        </Box>
      )}
    </Grid>
  );
};

export default DeliveryMode;
