import axios from "axios";
import { BASE_URL, getApiUrl } from "../../../config/apiUrls";
import { customAxios } from "../../../config/axiosConfig";
import {
  attachmentPayload,
  getAadhaarKycUrlPayload,
  submitDocumentApiPayload,
} from "../../interfaces/kycInterfaces";

// get trust score api
const getTrustScore = (payload: any) => {
  try {
    const url: string =
      BASE_URL + getApiUrl(payload?.customerId).getUserTrustScore;
    return customAxios.get(url).catch((err) => {
      return err;
    });
  } catch (error) {
    return error;
  }
};

// get doc attachment ids
const getAttachmentIds = (payload: attachmentPayload) => {
  try {
    const url: string = BASE_URL + getApiUrl().getAttachmentIds;
    return customAxios.post(url, payload).catch((err) => err);
  } catch (error) {
    return error;
  }
};

//submit kyc documents
const submitKycDocuments = (payload: submitDocumentApiPayload) => {
  try {
    const url: string =
      BASE_URL + getApiUrl(payload?.customerId).submitUserDocument;
    return customAxios.post(url, payload?.apiPayload).catch((err) => err);
  } catch (error) {
    return error;
  }
};

// upload doc on signed url
const uploadKycDocumentService = (payload: any) => {
  try {
    const fileData = new Blob([payload?.file], {
      type: payload?.file?.type,
    });
    return axios
      .put(payload.url, fileData, {
        headers: { "Content-Type": payload?.file?.type },
      })
      .catch((ex) => {
        return ex;
      });
  } catch (ex) {
    return ex;
  }
};

// service to get digilocker aadhaar verificaton url
const getAadhaarKycUrl = (payload: getAadhaarKycUrlPayload) => {
  try {
    const url: string =
      BASE_URL + getApiUrl(payload?.customerId).getAadhaarKycUrl;
    return customAxios.post(url, payload?.apiPayload).catch((err) => err);
  } catch (error) {
    return error;
  }
};

//submit kyc documents
const kycDocUpload = (payload: submitDocumentApiPayload) => {
  try {
    const url: string = BASE_URL + getApiUrl(payload?.customerId).kycDocUpload;
    return customAxios.post(url, payload?.apiPayload).catch((err) => err);
  } catch (error) {
    return error;
  }
};

export default {
  getTrustScore,
  getAttachmentIds,
  submitKycDocuments,
  uploadKycDocumentService,
  getAadhaarKycUrl,
  kycDocUpload
};
