import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPrivacyPolicyCmsData } from "../../redux/actions";
import { Box, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { banner } from "../../../assets";
import styles from "./cmsComponent.module.css";
import { colors } from "../../../themes/colors";
import { Helmet } from "react-helmet";
import { Routes } from "../../../constant/routeContants";
import { RootState } from "../../../redux/reducers";

export const PrivacyPolicy = () => {
  const dispatch = useDispatch();

  // data from kyc reducer
  const { privacyPolicy = [] } = useSelector(
    (state: RootState) => state.cmsDataReducer
  );

  useEffect(() => {
    dispatch(getPrivacyPolicyCmsData());
  }, []);

  return (
    <>
      <Helmet>
        <title>Freedo: Bike Rentals | Two Wheelers Rentals App</title>
        <meta
          name="description"
          content="Freedo rentals offers scooter & bike rentals in Gurgaon online at cheap prices. Wide Range of two wheelers at affordable hourly rates."
        />
        <meta name="author" content="Freedo Rentals" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Bike Rentals in Gurgaon | Bike For Rent in Gurgaon"
        />
        <meta
          property="og:description"
          content="Freedo rentals offers scooter & bike rentals in Gurgaon online at cheap prices. Wide Range of two wheelers at affordable hourly rates."
        />
        <meta
          property="og:url"
          content="https://freedo.rentals/privacy-policy"
        />
        <meta
          property="og:image"
          content="https://freedo.rentals/page image url"
        />
        <meta property="og:site_name" content="Freedo Rentals" />
        <link rel="canonical" href="https://freedo.rentals/privacy-policy/" />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <Box
        className={styles?.cms}
        style={{ backgroundImage: `url(${banner})` }}
      >
        <Typography variant="h3" sx={{ color: colors.white, fontWeight: 500 }}>
          FREEDO PRIVACY POLICY
        </Typography>
      </Box>
      <Container className={styles.cmsContainer}>
        <Box className={styles.cmsBox}>
          {privacyPolicy?.length &&
            Object?.keys(privacyPolicy[0]).length &&
            Object?.keys(privacyPolicy[0])?.map((key: string) => {
              if (key.includes("section")) {
                return (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: privacyPolicy[0][key],
                    }}
                  />
                );
              } else {
                return;
              }
            })}
          <Link to={Routes.DELETE_ACCOUNT}>
            <Typography variant="h6">DELETE ACCOUNT</Typography>
          </Link>
        </Box>
      </Container>
    </>
  );
};
