import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import MyBookingServices from "../services/MyBookingServices";
import { toastr } from "react-redux-toastr";
import { handleMessage } from "../../../utills/helper";
import { actionInterface } from "../../interfaces/commonInterface";

//get booking history
function* getBookingHistory(action: any) {
  try {
    const data = yield call(
      MyBookingServices.getBookingHistory,
      action.payload
    );
    if (data?.status == 200) {
      yield put({
        type: actionTypes.MyBookingsActions.GET_BOOKING_HISTORY_SUCCESS,
        payload: data?.data?.data,
      });
    } else {
      yield put({
        type: actionTypes.MyBookingsActions.GET_BOOKING_HISTORY_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (err) {
    yield put({
      type: actionTypes.MyBookingsActions.GET_BOOKING_HISTORY_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}

//get booking details
function* getBookingDetails(action: any) {
  try {
    const data = yield call(
      MyBookingServices.getBookingDetails,
      action.payload
    );
    if (data?.status == 200) {
      yield put({
        type: actionTypes.MyBookingsActions.GET_BOOKING_DETAILS_SUCCESS,
        payload: data?.data?.data,
      });
    } else {
      yield put({
        type: actionTypes.MyBookingsActions.GET_BOOKING_DETAILS_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (err) {
    toastr.error("", handleMessage(err));
    yield put({
      type: actionTypes.MyBookingsActions.GET_BOOKING_DETAILS_FAILED,
    });
  }
}

//get yor booking details
function* getYorBookingHistory(action: any) {
  try {
    const data = yield call(
      MyBookingServices.getYorBookingHistory,
      action.payload
    );
    if (data?.status === 200) {
      yield put({
        type: actionTypes.MyBookingsActions.GET_YOR_BOOKING_HISTORY_SUCCESS,
        payload: data.data.data,
      });
    } else {
      yield put({
        type: actionTypes.MyBookingsActions.GET_YOR_BOOKING_HISTORY_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (err) {
    toastr.error("", handleMessage(err));
    yield put({
      type: actionTypes.MyBookingsActions.GET_YOR_BOOKING_HISTORY_FAILED,
    });
  }
}

//get vehicle documents
function* getVehicleDocumets(action: any) {
  try {
    const data = yield call(
      MyBookingServices.getVehicleDocuments,
      action.payload
    );
    if (data?.status === 200) {
      yield put({
        type: actionTypes.MyBookingsActions.GET_VEHICLE_DOCUMENTS_SUCCESS,
        payload: data.data.data,
      });
    } else {
      yield put({
        type: actionTypes.MyBookingsActions.GET_VEHICLE_DOCUMENTS_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (err) {
    toastr.error("", handleMessage(err));
    yield put({
      type: actionTypes.MyBookingsActions.GET_VEHICLE_DOCUMENTS_FAILED,
    });
  }
}

//download vehicle documents
function* downloadVehicleDocument(action: any) {
  try {
    const data = yield call(MyBookingServices.downloadDocument, action.payload);
    data?.data?.data?.signedUrl &&
      window.open(data?.data?.data?.signedUrl, "_blank");
    if (data?.status === 200) {
      yield put({
        type: actionTypes.MyBookingsActions.DOWNLOAD_VEHICLE_DOCUMENT_SUCCESS,
        payload: data?.data?.data?.signedUrl,
      });
    } else {
      yield put({
        type: actionTypes.MyBookingsActions.DOWNLOAD_VEHICLE_DOCUMENT_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (err) {
    toastr.error("", handleMessage(err));
    yield put({
      type: actionTypes.MyBookingsActions.DOWNLOAD_VEHICLE_DOCUMENT_FAILED,
    });
  }
}

//get booking cancellation charges
function* getBookingCencellationCharges(action: actionInterface) {
  try {
    const data = yield call(
      MyBookingServices.getBookingCencellationCharges,
      action.payload
    );
    if (data?.status === 200) {
      yield put({
        type: actionTypes.MyBookingsActions
          .GET_BOOKING_CANCELLATION_CHARGES_SUCCESS,
        payload: data.data.data,
      });

      action?.payload?.successCallback && action?.payload?.successCallback();
    } else {
      yield put({
        type: actionTypes.MyBookingsActions
          .GET_BOOKING_CANCELLATION_CHARGES_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (err) {
    toastr.error("", handleMessage(err));
    yield put({
      type: actionTypes.MyBookingsActions
        .GET_BOOKING_CANCELLATION_CHARGES_FAILED,
    });
  }
}

//cancel user booking
function* cancelUserBooking(action: actionInterface) {
  try {
    const data = yield call(
      MyBookingServices.cancelUserBooking,
      action.payload
    );
    if (data?.status === 200) {
      yield put({
        type: actionTypes.MyBookingsActions.CANCEL_USER_BOOKING_SUCCESS,
        payload: data.data.data,
      });

      action?.payload?.successCallback && action?.payload?.successCallback();
    } else {
      yield put({
        type: actionTypes.MyBookingsActions.CANCEL_USER_BOOKING_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (err) {
    toastr.error("", handleMessage(err));
    yield put({
      type: actionTypes.MyBookingsActions.CANCEL_USER_BOOKING_FAILED,
    });
  }
}

function* MyBookingSaga() {
  yield takeLatest(
    actionTypes.MyBookingsActions.GET_BOOKING_HISTORY_REQUESTED,
    getBookingHistory
  );

  yield takeLatest(
    actionTypes.MyBookingsActions.GET_BOOKING_DETAILS_REQUESTED,
    getBookingDetails
  );

  yield takeLatest(
    actionTypes.MyBookingsActions.GET_YOR_BOOKING_HISTORY_REQUESTED,
    getYorBookingHistory
  );
  yield takeLatest(
    actionTypes.MyBookingsActions.GET_VEHICLE_DOCUMENTS_REQUESTED,
    getVehicleDocumets
  );
  yield takeLatest(
    actionTypes.MyBookingsActions.DOWNLOAD_VEHICLE_DOCUMENT_REQUESTED,
    downloadVehicleDocument
  );

  yield takeLatest(
    actionTypes.MyBookingsActions.GET_BOOKING_CANCELLATION_CHARGES_REQUESTED,
    getBookingCencellationCharges
  );

  yield takeLatest(
    actionTypes.MyBookingsActions.CANCEL_USER_BOOKING_REQUESTED,
    cancelUserBooking
  );
}

export default MyBookingSaga;
