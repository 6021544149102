import { Box, Container, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { banner } from "../../../assets";
import { colors } from "../../../themes/colors";
import { getTermsAndConditions } from "../../redux/actions";
import styles from "./cmsComponent.module.css";
import { Routes } from "../../../constant/routeContants";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { RootState } from "../../../redux/reducers";

export const TermsAndConditions = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  // terms and conditions for redirect from app
  const TERMS_CONDITIONS_APP =
    location.pathname === Routes.TERMS_CONDITIONS_APP;

  // data from cms reducer
  const { termsAndCondition = [] } = useSelector(
    (state: RootState) => state.cmsDataReducer
  );

  //initial useffect
  useEffect(() => {
    dispatch(getTermsAndConditions());
  }, []);

  const { Annexure = {}, termsOfUse = {} } = termsAndCondition[0] || [];

  return (
    <>
      <Helmet>
        <title>Freedo: Bike Rentals | Two Wheelers Rentals App</title>
        <meta
          name="description"
          content="Freedo rentals offers scooter & bike rentals in Gurgaon online at cheap prices. Wide Range of two wheelers at affordable hourly rates."
        />
        <meta name="author" content="Freedo Rentals" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Bike Rentals in Gurgaon | Bike For Rent in Gurgaon"
        />
        <meta
          property="og:description"
          content="Freedo rentals offers scooter & bike rentals in Gurgaon online at cheap prices. Wide Range of two wheelers at affordable hourly rates."
        />
        <meta
          property="og:url"
          content="https://freedo.rentals/privacy-policy"
        />
        <meta
          property="og:image"
          content="https://freedo.rentals/page image url"
        />
        <meta property="og:site_name" content="Freedo Rentals" />
        <link rel="canonical" href="https://freedo.rentals/privacy-policy/" />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {!TERMS_CONDITIONS_APP && (
        <Box
          className={styles?.cms}
          style={{ backgroundImage: `url(${banner})` }}
        >
          <Typography
            variant="h3"
            sx={{ color: colors.white, fontWeight: 500 }}
          >
            FREEDO TERMS & CONDITIONS
          </Typography>
        </Box>
      )}

      <Container
        className={
          TERMS_CONDITIONS_APP ? styles?.cmsContainerApp : styles.cmsContainer
        }
      >
        <Box
          className={TERMS_CONDITIONS_APP ? styles?.cmsBoxApp : styles.cmsBox}
        >
          {termsAndCondition?.length &&
            Object?.keys(termsAndCondition[0]).length &&
            Object?.keys(termsAndCondition[0])?.map((key: string) => {
              if (key.includes("section")) {
                return (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: termsAndCondition[0][key],
                    }}
                  />
                );
              } else {
                return;
              }
            })}

          <Typography
            variant="h5"
            color={colors.list_color}
            marginTop="20px"
            marginBottom="20px"
          >
            TERMS OF USE
          </Typography>
          {termsAndCondition?.length &&
            termsOfUse?.length &&
            termsOfUse?.map((data: any) => {
              return (
                <Stack>
                  <Typography
                    variant="h6"
                    color={colors.list_color}
                    fontWeight="700"
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data?.title,
                      }}
                    />
                  </Typography>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.content,
                    }}
                  />
                </Stack>
              );
            })}
          {termsAndCondition?.length &&
            Annexure?.length &&
            Annexure?.map((data: any) => {
              return (
                <Stack>
                  <Typography
                    variant="h6"
                    color={colors.list_color}
                    fontWeight="700"
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data?.title,
                      }}
                    />
                  </Typography>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.content,
                    }}
                  />
                </Stack>
              );
            })}
        </Box>
      </Container>
    </>
  );
};
