import React from "react";
import { speak1, speak2, speak3, fb, insta } from "../../../../assets";
import en from "../../../../locales/en.json";
import ShowMore from "../../../../component/ShowMore";
import { Box, Container, Stack, Typography } from "@mui/material";
interface CardPictureInterface {
  src: string;
  title: string;
  content: string;
  logoSrc?: string;
  ind?: number;
}
const { freedoPictureSpeak } = en.home;

const arrData = [
  {
    id: 1,
    src: speak1,
    title: "Prarabdh Mishra",
    content: `Riding Harley's legendary Fat Bob 114 was on my bucket list for a long, it was amazing to have the ease of renting it, getting it delivered to my home by the professional team, easy pickup, and the best memories made out of it. Thanks, Team Freedo!`,
    logoSrc: fb,
  },
  {
    id: 2,
    src: speak2,
    title: "Arya",
    content: `It was the best decision to rent the bike from Freedo. Their services were great, they provided full support in every aspect. We drove 1000kms across Karnataka and the bike was superb, we didnt face any issue regarding the bike. Totally worth the money!`,
    logoSrc: insta,
  },
  {
    id: 3,
    src: speak3,
    title: "Amit Dubey",
    content: `"Three years with Freedo Rentals across India—seamless process, no deposits, excellent maintenance. Affordable rates, beats competition. Thanks, Freedo team, for reliable and budget-friendly service. Highly recommended! Will look forward to freedo rentals `,
    logoSrc: insta,
  },
];

const CardPicture = ({ src, title, content, ind }: CardPictureInterface) => {
  return (
    <Box key={ind}>
      <Box className="card testimonial">
        <Box className="testimonial-img-container">
          <img
            className="card-img-top img-responsive"
            src={src}
            alt="Freedo Customer Speaks"
          />
        </Box>
        <Stack padding={"1rem"}>
          <Stack direction="row" gap={"5px"} alignItems={"center"}>
            {/* <img src={logoSrc} alt="" className="testimonial-logo" /> */}
            <Typography variant="h6" className="card-title">
              {title}
            </Typography>
          </Stack>

          <Typography variant="subtitle1" className="card-text">
            <ShowMore text={content} characterToShow={100} />
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};

const FreedoPictureSpeak = ({ cmsData }: any) => {
  return (
    <Box component="section">
      <Container disableGutters={true} maxWidth={false}>
        <Stack className="blog-wrap">
          <Typography variant="h4" textAlign={"center"} margin={"30px 0"}>
            {cmsData?.title}
          </Typography>
          <Stack direction="row" gap="1rem">
            {cmsData?.cards?.map((data: any) => {
              return (
                <CardPicture
                  src={data?.image}
                  title={data?.name}
                  content={data?.description}
                  // logoSrc={logoSrc}
                />
              );
            })}
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default FreedoPictureSpeak;
