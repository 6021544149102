import { Box, Card, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import {
  EngineIcon,
  FuelIconYor,
  LocationIcon,
  PowerIconYor,
  RentingFleetBackground,
} from "../../../../assets";
import Styles from "../index.module.css";
import { TypographyConstants } from "../../../../constant/enum";
import en from "../../../../locales/en.json";
import { colors } from "../../../../themes/colors";
import { CustomButton } from "../../../components/atom/Buttons/CustomButtons";

const { yourOwnRide } = en;

interface RentingFleetcard {
  overlaytext?: string;
  bikeImage?: string;
  bikeModel?: string;
  hubName?: string;
  displacement?: string;
  power?: string;
  fuelEconomy: string;
  planPrice: string;
  onClickProceed: Function;
  loadingProceed?: boolean;
}

const specificationsTypography = {
  fontWeight: 500,
  color: colors.secondary_dark_gray,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "40px",
  cursor: "pointer",
};

const RentingFleetCard = (props: RentingFleetcard) => {
  const {
    overlaytext,
    bikeImage,
    bikeModel,
    hubName,
    displacement,
    power,
    fuelEconomy,
    planPrice,
    loadingProceed = false,
    onClickProceed = () => {},
  } = props;

  return (
    <Box>
      <Card
        className={Styles.rentingFleetcard}
        style={{
          backgroundImage: `url(${RentingFleetBackground})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <Stack>
          <Box>
            <Box className={Styles.rentingFleetcardOverlay}>
              <Typography
                variant={TypographyConstants.SUBTITLE2}
                sx={{ fontSize: "12px", color: colors.secondary_green }}
              >
                {overlaytext}
              </Typography>
            </Box>
            <Box className={Styles.alignJustifyCenter}>
              <img
                style={{ width: "290px", height: "176px", objectFit: "contain" }}
                src={bikeImage}
              />
            </Box>
          </Box>
          <Stack sx={{ padding: "14px", display: "flex", gap: "10px" }}>
            <Stack>
              <Typography
                variant={TypographyConstants.CAPTION}
                sx={{ fontWeight: 600, color: colors.vivid_red }}
              >
                {yourOwnRide.hero}
              </Typography>
              <Typography
                variant={TypographyConstants.SUBTITLE2}
                sx={{
                  color: colors.secondary_gray,
                }}
              >
                {bikeModel}
              </Typography>
            </Stack>

            <Box sx={{ display: "flex", marginLeft: "-6px" }}>
              <img src={LocationIcon} />
              <Typography
                variant={TypographyConstants.CAPTION}
                sx={{
                  color: colors.secondary_gray,
                }}
              >
                {hubName}
              </Typography>
            </Box>

            {/* Bike specification */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "4px",
              }}
            >
              <Box className={Styles.rentingCardFeature}>
                <img
                  style={{ height: "24px", width: "24px" }}
                  src={EngineIcon}
                />
                <Tooltip title={displacement}>
                  <Typography
                    variant={TypographyConstants.CAPTION}
                    sx={{
                      ...specificationsTypography,
                    }}
                  >
                    {displacement}
                  </Typography>
                </Tooltip>
              </Box>
              <Box className={Styles.rentingCardFeature}>
                <img
                  style={{ height: "24px", width: "24px" }}
                  src={PowerIconYor}
                />
                <Tooltip title={power}>
                  <Typography
                    variant={TypographyConstants.CAPTION}
                    sx={{
                      ...specificationsTypography,
                    }}
                  >
                    {power}
                  </Typography>
                </Tooltip>
              </Box>
              <Box className={Styles.rentingCardFeature}>
                <img
                  style={{ height: "24px", width: "24px" }}
                  src={FuelIconYor}
                />
                <Tooltip title={fuelEconomy}>
                  <Typography
                    variant={TypographyConstants.CAPTION}
                    sx={{
                      ...specificationsTypography,
                    }}
                  >
                    {fuelEconomy}
                  </Typography>
                </Tooltip>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant={TypographyConstants.SUBTITLE1}
                sx={{ color: colors.dark_moderate_cyan }}
              >
                {planPrice}
              </Typography>

              <CustomButton
                sx={{ borderRadius: "36px" }}
                label={yourOwnRide.proceed}
                variant={"outlined"}
                onClick={onClickProceed}
                loading={loadingProceed}
              />
            </Box>
          </Stack>
        </Stack>
      </Card>
    </Box>
  );
};

export const RentingFleetCardLoader = () => {
  return (
    <Box>
      <Card className={Styles.rentingFleetcard}>
        <Stack>
          <Skeleton
            variant="rectangular"
            width={"100%"}
            height={"200px"}
            animation="wave"
          />
          <Stack sx={{ padding: "14px", display: "flex", gap: "7px" }}>
            <Stack>
              <Skeleton width={"50px"} animation="wave" height={"18px"} />
              <Skeleton width={"110px"} animation="wave" height={"18px"} />
            </Stack>

            <Box sx={{ display: "flex" }}>
              <Skeleton width={"160px"} animation="wave" height={"18px"} />
            </Box>

            {/* Bike specification */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "4px",
              }}
            >
              <Skeleton width={"85px"} animation="wave" height={"45px"} />
              <Skeleton width={"85px"} animation="wave" height={"45px"} />
              <Skeleton width={"85px"} animation="wave" height={"45px"} />
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Skeleton width={"80px"} animation="wave" height={"22px"} />

              <Skeleton width={"125px"} animation="wave" height={"50px"} />
            </Box>
          </Stack>
        </Stack>
      </Card>
    </Box>
  );
};

export default RentingFleetCard;
