import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { CustomButton } from "../../../../sharedComponents/atom/Buttons/CustomButtons";
import { RootState } from "../../../../redux/store";
import { Box, Divider, Typography } from "@mui/material";
import en from "../../../../locales/en.json";
import { colors } from "../../../../themes/colors";
import OtpField from "../../../../yor-src/components/template/OtpField";

const { AuthScreens } = en;

const SignInEnterOtp = ({
  handleSubmitOtp,
  otpValue = "",
  handleResendOtp,
  onChangeOtp,
  isLoading,
  phoneNo,
}) => {
  const [seconds, setSeconds] = useState<number>(30);

  useEffect(() => {
    return () => {
      onChangeOtp("", "");
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        clearInterval(interval);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        padding: "122px 40px",
      }}
    >
      <Typography variant="h5" sx={{ textAlign: "center" }}>
        {en.AuthScreens.OTPVerification}
      </Typography>
      <Divider
        style={{
          width: "35px",
          borderBottomWidth: "3px",
          borderColor: colors.THEME_BLUE,
          opacity: 1,
          marginTop: "10px",
          marginBottom: "10px",
        }}
        // color={colors.THEME_BLUE}
      />
      <Box>
        <p className="mt-3 text-muted text-center">{AuthScreens.OTPWarning}</p>
        <p className="text-center mt-1 fw-bold">+91 {phoneNo}</p>
        <OtpField
          separator={<></>}
          value={otpValue}
          onChange={onChangeOtp}
          length={6}
          containerStyle={{ margin: "20px 0px 0px 6px" }}
        />
        <CustomButton
          disabled={otpValue.length < 6}
          variant="contained"
          sx={{ marginTop: "20px" }}
          label={AuthScreens["VerifyCode"]}
          loading={isLoading}
          onClick={(e) => handleSubmitOtp(otpValue, e)}
        />
        <div className="text-center">
          {seconds ? (
            <>
              <span>{`Resend OTP in : ` + seconds}</span>
            </>
          ) : (
            <Link
              to=""
              onClick={(e: any) => {
                e.preventDefault();
                setSeconds(30);
                handleResendOtp();
              }}
            >
              {AuthScreens["resendOtp"]}
            </Link>
          )}
        </div>
      </Box>
    </Box>
  );
};

export default React.memo(SignInEnterOtp);
