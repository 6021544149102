import { Axios, customAxios } from "../../config/axiosConfig";
import {
  API_URLS,
  BASE_URL,
  LatestVersion,
  getApiUrl,
} from "../../config/apiUrls";
import { constructUrl } from "../../utills/helper";

// Fetch master plan durations.
const getMasterPlanDurationsService = (payload: any) => {
  try {
    const url: string = BASE_URL + API_URLS.getMasterPlanDurations;
    return customAxios.get(url);
  } catch (err) {
    return err;
  }
};

// Fetch master plan durations.
const getFilteredModelListService = (payload: any) => {
  try {
    const url: string = BASE_URL + API_URLS.getFilteredModelListUrl;
    const apiUrlWithParams: string = constructUrl(url, payload?.FilterPayload);
    return customAxios.get(apiUrlWithParams);
  } catch (err) {
    return err;
  }
};

// Fetch master plan durations.
const getRentalMasterCityListService = (payload: any) => {
  try {
    const url: string = BASE_URL + API_URLS.getRentalMasterCityListURL;
    return customAxios.get(url);
  } catch (err) {
    return err;
  }
};

const findRentalNearestCityService = (payload: any) => {
  try {
    const url: string = BASE_URL + API_URLS.getRentalNearestCityUrl;
    const apiUrlWithParams: string = constructUrl(url, payload);
    return customAxios.get(apiUrlWithParams);
  } catch (error) {}
};

const getRentalModelDetailsService = (payload: any) => {
  try {
    const url: string = BASE_URL + API_URLS.getRentalModelDetailsUrl;
    const apiUrlWithParams: string = constructUrl(url, payload);
    return customAxios.get(apiUrlWithParams);
  } catch (error) {}
};

// get customer addresses service
const getCustomerAddressesService = (payload: any) => {
  try {
    const url: string =
      BASE_URL + getApiUrl(payload?.customerId).getCustomerAddressesUrl;
    return customAxios.get(url);
  } catch (error) {}
};

// add customer address
const addCustomerAddressService = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrl().addCustomerAddressUrl;
    return customAxios.post(url, payload).catch((err) => err);
  } catch (error) {}
};

// get model checkout deatils service
const getModelCheckoutDetailsService = (
  payload: any,
  modelName: string
) => {
  try {
    const url: string =
      BASE_URL + getApiUrl(modelName).getModelCheckoutDetailsUrl;
    const apiUrlWithParams: string = constructUrl(url, payload);
    return customAxios.get(apiUrlWithParams);
  } catch (error) {}
};

// get calculate charges service
const getCalculatedChargesService = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrl().getCalculateChargesUrl;
    return customAxios.post(url, payload).catch((err) => err);
  } catch (error) {}
};

// get delivery slot pause for branch
const getDeliverySlotPauseService = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrl().getDeliverySlotPauseUrl;
    const apiUrlWithParams: string = constructUrl(url, payload);
    return customAxios.get(apiUrlWithParams);
  } catch (error) {}
};

// get available time slots for branch
const getBranchTimeSlotsService = (payload: any, branchName: string) => {
  try {
    const url: string = BASE_URL + getApiUrl(branchName).getBranchTimeSlotsUrl;
    const apiUrlWithParams: string = constructUrl(url, payload);
    return customAxios.get(apiUrlWithParams);
  } catch (error) {}
};

export default {
  getMasterPlanDurationsService,
  getFilteredModelListService,
  getRentalMasterCityListService,
  findRentalNearestCityService,
  getRentalModelDetailsService,
  getCustomerAddressesService,
  addCustomerAddressService,
  getModelCheckoutDetailsService,
  getCalculatedChargesService,
  getBranchTimeSlotsService,
  getDeliverySlotPauseService,
};
