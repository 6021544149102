export enum YOR_ACTION {
  CALCULATE_CHARGES_REQUESTED = "CALULATE_CHARGES_REQUESTED",
  CALCULATE_CHARGES_SUCCESS = "CALCULATE_CHARGES_SUCCESS",
  CALCULATE_CHARGES_FAILED = "CALCULATE_CHARGES_FAILED",

  PLAN_AVAILABLES_REQUESTED = "PLAN_AVAILABLES_REQUESTED",
  PLAN_AVAILABLES_SUCCESS = "PLAN_AVAILABLES_SUCCESS",
  PLAN_AVAILABLES_FAILED = "PLAN_AVAILABLES_FAILED",

  YOR_GET_AVAILABLE_VEHICLES_REQUESTED = "YOR_GET_AVAILABLE_VEHICLES_REQUESTED",
  YOR_GET_AVAILABLE_VEHICLES_SUCCESS = "YOR_GET_AVAILABLE_VEHICLES_SUCCESS",
  YOR_GET_AVAILABLE_VEHICLES_FAILED = "YOR_GET_AVAILABLE_VEHICLES_FAILED",

  STORE_BOOKING_REQUESTED = "STORE_BOOKING_REQUESTED",
  STORE_BOOKING_SUCCESS = "STORE_BOOKING_SUCCESS",
  STORE_BOOKING_FAILED = "STORE_BOOKING_FAILED",

  CREATE_ORDER_REQUESTED = "CREATE_ORDER_REQUESTED",
  CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS",
  CREATE_ORDER_FAILED = "CREATE_ORDER_FAILED",
  FILTER_VEHICLE_MODEL_RENTING_FLEET = "FILTER_VEHICLE_MODEL_RENTING_FLEET",

  GET_PAYMENT_STATUS_REQUESTED = "GET_PAYMENT_STATUS_REQUESTED",
  GET_PAYMENT_STATUS_SUCCESS = "GET_PAYMENT_STATUS_SUCCESS",
  GET_PAYMENT_STATUS_FAILED = "GET_PAYMENT_STATUS_FAILED",

  CLEAR_ORDER_DATA = "CLEAR_ORDER_DATA",
  GET_CITY_V2_REQUESTED = "GET_CITY_V2_REQUESTED",
  GET_CITY_V2_SUCCESS = "GET_CITY_V2_SUCCESS",
  GET_CITY_V2_FAILED = "GET_CITY_V2_FAILED",

  SET_USER_CITY_YOR = "SET_USER_CITY_YOR",

  SET_YOR_BOOKING_DATA = "SET_YOR_BOOKING_DATA",

  GET_YOR_BOOKINGS_DETAILS_REQUESTED = "GET_YOR_BOOKINGS_DETAILS_REQUESTED",
  GET_YOR_BOOKINGS_DETAILS_SUCCESS = "GET_YOR_BOOKINGS_DETAILS_SUCCESS",
  GET_YOR_BOOKINGS_DETAILS_FAILED = "GET_YOR_BOOKINGS_DETAILS_FAILED",

  SORT_YOR_RENTING_FLEET = "SORT_YOR_RENTING_FLEET",

  GET_ALL_COUPONS_REQUESTED = "GET_ALL_COUPONS_REQUESTED",
  GET_ALL_COUPONS_SUCCESS = "GET_ALL_COUPONS_SUCCESS",
  GET_ALL_COUPONS_FAILED = "GET_ALL_COUPONS_FAILED",

  CANCELLED_BOOKING_REQUESTED = "CANCELLED_BOOKING_REQUESTED",
  CANCELLED_BOOKING_SUCCESS = "CANCELLED_BOOKING_SUCCESS",
  CANCELLED_BOOKING_FAILED = "CANCELLED_BOOKING_FAILED",
}
