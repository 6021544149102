import { MASTER_ACTIONS } from "../actionTypes";

//actiopn for get all addresses
export const getUserAllAddresses = (payload: any) => {
  return {
    type: MASTER_ACTIONS.GET_ALL_ADDRESSES_REQUESTED,
    payload,
  };
};

//action to get issue category list
export const issueCategoryListAction = () => {
  return {
    type: MASTER_ACTIONS.GET_ISSUE_CATEGORY_LIST_REQUESTED,
  };
};
