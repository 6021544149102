import React from "react";

function HalfFaceHelmetIcon() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 22 22"
        fill="none"
      >
        <g stroke="#2BC7C9">
          <path
            d="M19.6719 10.8728C19.64 8.53779 18.6 6.32271 16.8046 4.7664C14.8972 3.11915 12.3585 2.33426 9.81986 2.60683C5.79173 3.01882 2.57554 6.04706 2.03198 9.93943C1.69322 12.6888 2.67628 15.4364 4.69827 17.3915L4.82197 17.5061C4.82197 17.5061 8.15295 20.3508 11.8661 19.1507V17.3915V13.5504"
            stroke-opacity="0.8"
          />
          <path
            d="M6.85665 7.89906C7.58945 7.07023 8.58277 6.49786 9.68378 6.27026C9.98751 6.20391 10.1906 5.92593 10.1545 5.62597C10.1354 5.45637 10.0432 5.30244 9.90066 5.20241C9.75824 5.10252 9.57867 5.06561 9.40664 5.10105C8.05326 5.38475 6.83233 6.08864 5.92872 7.10597C5.81378 7.23588 5.76168 7.40709 5.78524 7.57669C5.80895 7.7463 5.90606 7.89803 6.05241 7.99382C6.30644 8.16459 6.65215 8.12388 6.85665 7.89906Z"
            fill="#2BC7C9"
          />
          <path
            d="M10.6301 10.9551H9.39289V12.1549H10.6301V10.9551Z"
            fill="#2BC7C9"
          />
          <path
            d="M10.6301 13.3545H9.39289V14.5543H10.6301V13.3545Z"
            fill="#2BC7C9"
          />
          <path
            d="M8.15576 10.9551H6.91852V12.1549H8.15576V10.9551Z"
            fill="#2BC7C9"
          />
          <path
            d="M8.15576 13.3545H6.91852V14.5543H8.15576V13.3545Z"
            fill="#2BC7C9"
          />
          <path
            d="M11.8677 17.5508C11.8677 16.7508 11.8677 15.2378 11.8677 14.7048V11.8555C11.8677 11.3895 11.8677 11.1566 11.9438 10.9728C12.0453 10.7278 12.24 10.5331 12.485 10.4316C12.6688 10.3555 12.9017 10.3555 13.3677 10.3555H19.6382"
            stroke-opacity="0.8"
          />
          <path
            d="M19.2234 11.1258L19.2079 11.0711C19.187 10.9968 19.1759 10.9239 19.1717 10.855H12.7674C12.5466 10.855 12.3675 11.034 12.3675 11.2548C12.3675 11.4757 12.5466 11.6547 12.7674 11.6547H18.8231C19.0989 11.6547 19.2983 11.3912 19.2234 11.1258Z"
            stroke-opacity="0.8"
          />
        </g>
      </svg>
    </>
  );
}

export default HalfFaceHelmetIcon;
