import { GenericObject } from "../../../constants/enum";
import { actionInterface } from "../../../interfaces/commonInterface";
import { USER_KYC_ACTIONS } from "../../actions/actionTypes";

interface initialState {
  userTrustScoreData: GenericObject;
  documentAttachmentData: GenericObject;
  userTrustScoreDataLoader: boolean;
  getAttachmentLoader: boolean;
  submitDocLoader: boolean;
  uploadFileData: GenericObject;
}

const initialState: initialState = {
  userTrustScoreData: {},
  documentAttachmentData: {},
  userTrustScoreDataLoader: false,
  getAttachmentLoader: false,
  submitDocLoader: false,
  uploadFileData: {},
};

const userKycReducer = (state = initialState, action: actionInterface) => {
  const { type, payload } = action;

  switch (type) {
    case USER_KYC_ACTIONS.GET_TRUST_SCORE_REQUESTED:
      return {
        ...state,
      };

    case USER_KYC_ACTIONS.GET_TRUST_SCORE_SUCCESS:
      return {
        ...state,
        userTrustScoreData: payload,
      };

    case USER_KYC_ACTIONS.GET_TRUST_SCORE_FAILED:
      return {
        ...state,
        userTrustScoreData: {},
      };

    case USER_KYC_ACTIONS.GET_KYC_ATTACHMENT_ID_REQUESTED:
      return {
        ...state,
        documentAttachmentData: {},
        getAttachmentLoader: true,
      };

    case USER_KYC_ACTIONS.GET_KYC_ATTACHMENT_ID_SUCCESS:
      return {
        ...state,
        documentAttachmentData: payload,
        getAttachmentLoader: false,
      };

    case USER_KYC_ACTIONS.GET_KYC_ATTACHMENT_ID_FAILED:
      return {
        ...state,
        documentAttachmentData: {},
        getAttachmentLoader: false,
      };

    case USER_KYC_ACTIONS.PUT_KYC_DOCUMENT_ON_SIGNED_URL_REQUESTED:
      return {
        ...state,
      };

    case USER_KYC_ACTIONS.PUT_KYC_DOCUMENT_ON_SIGNED_URL_SUCCESS:
      return {
        ...state,
        uploadFileData: {
          ...state.uploadFileData,
          ...payload,
        },
      };

    case USER_KYC_ACTIONS.PUT_KYC_DOCUMENT_ON_SIGNED_URL_FAILED:
      return {
        ...state,
      };

    case USER_KYC_ACTIONS.SUBMIT_KYC_DOCUMENT_REQUESTED:
      return {
        ...state,
        submitDocLoader: true,
      };

    case USER_KYC_ACTIONS.SUBMIT_KYC_DOCUMENT_SUCCESS:
      return {
        ...state,
        submitDocLoader: false,
        uploadFileData: {},
      };

    case USER_KYC_ACTIONS.SUBMIT_KYC_DOCUMENT_FAILED:
      return {
        ...state,
        submitDocLoader: false,
      };

    case USER_KYC_ACTIONS.KYC_DOCUMENT_UPLOAD_REQUESTED:
      return {
        ...state,
        submitDocLoader: true,
      };

    case USER_KYC_ACTIONS.KYC_DOCUMENT_UPLOAD_SUCCESS:
      return {
        ...state,
        submitDocLoader: false,
        uploadFileData: {},
      };

    case USER_KYC_ACTIONS.KYC_DOCUMENT_UPLOAD_FAILED:
      return {
        ...state,
        submitDocLoader: false,
      };

    case USER_KYC_ACTIONS.CLEAR_KYC_REDUCER_DATA:
      return {
        ...state,
        ...payload,
      };

    default:
      return { ...state };
  }
};

export default userKycReducer;
