import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { actions } from "../redux";
import {
  GenericObject,
  addDays,
  amountConvertToRupee,
  capitalizeFirstLetter,
  commmaSeparator,
  findEndDate,
  getLocalStorageData,
  getTimestampAtEndOfDay,
  getTimestampAtStartOfDay,
  toggleSignInDrawer,
} from "../utills/helper";
import moment from "moment";
import { RootState } from "../redux/store";
import CustomModalVehicleDetail from "../component/CustomModalVehicleDetail";
import { setBookingData } from "../redux/actions";
import { DeliveryModesEnum, LocalStorage } from "../constant/enum";
import { Routes } from "../constant/routeContants";
import { Button1 } from "./Button";
import { FirebaseEvents } from "../utills/firebaseEvents";
import { WEB_URL, modelImageBucketUrl } from "../config/apiUrls";
import { homePageFilters } from "../rental-src/constants/enum";
import {
  getAvailableTimeSlots,
  getBillingCalculatedCharges,
  getFilteredVehicleModelList,
  getModelCheckoutDetails,
  getRentalModelDetails,
} from "../redux/actions/commonActions";
import { Box, Typography } from "@mui/material";
import { setRentalBookingData } from "../rental-src/redux/actions/checkoutActions";
interface rentingFleetInterface {
  data: {
    manufacturerName: string;
    TotalPrice: string;
    price: number;
    PerDay: string;
    UnlimitedKms: string;
    ZeroDepositFee: string;
    BookVehicle: string;
    modelName: string;
    displayName: string;
    isUnlimitedKM: boolean;
    isZeroDeposit: boolean;
    imageName: string;
    modelId: number | null;
    brandId: number | null;
    planPackageId: number | null;
    tag: string;
    packageDays: number | any;
    planDetails: any;
  };
  ind: number;
  rentingFleet: any;
  filters?: any;
  imagePath: string;
  isHomePage: boolean;
  imageObj?: GenericObject;
}

const RentingFleetCard = ({
  data,
  ind,
  rentingFleet,
  filters,
  imagePath,
  isHomePage,
  imageObj,
}: rentingFleetInterface) => {
  const {
    manufacturerName,
    price,
    modelName,
    displayName,
    isUnlimitedKM,
    isZeroDeposit,
    imageName,
    modelId,
    brandId,
    tag,
    packageDays,
    planDetails,
  } = data;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cityData = getLocalStorageData(LocalStorage.userCity);
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const { isAuthenticated, userProfileData } = useSelector(
    (state: RootState) => state.newAuthReducer
  );
  const {
    masterPlanDurationsData,
    filteredModelList,
    rentingFleetFilter,
    rentalCommonLoader,
    modelCheckoutDetails,
    calculatedCharges,
    calculateChargesPayload,
    availableTimeSlots,
    branchSlotPause,
  } = useSelector((state: any) => state.rentalsCommonReducer);
  const { package_days, startDate, endDate } = filters;

  const [rentingFleetLoader, setRentingFleetLoader] = useState<boolean>(false);

  const modelAvailability = modelCheckoutDetails?.availability;

  const vehicleModalToggleHandler = () => {
    setOpenDetailModal((prev) => !prev);
  };
  const handleRentingFleetLoader = () => {
    setRentingFleetLoader(false);
    setOpenDetailModal(false);
  };

  // Book vehicle handler
  const handleBookVehicle = () => {
    let selectedStartDate = getTimestampAtStartOfDay(
      startDate ? startDate : new Date()
    );
    let calculatedEndDate =
      package_days && typeof package_days === "number"
        ? addDays(new Date(selectedStartDate), package_days)
        : null;
    let selectedEndDate = calculatedEndDate
      ? getTimestampAtEndOfDay(calculatedEndDate)
      : getTimestampAtEndOfDay(addDays(new Date(selectedStartDate), 1));

    if (!isAuthenticated || Object.keys(userProfileData)?.length == 0) {
      setRentingFleetLoader(false);
      setOpenDetailModal(false);
      // toggleSignInDrawer();
      navigate("/");
    } else {
      // navigate to rent page
      setRentingFleetLoader(true);

      const FilterPayload = {
        ...rentingFleetFilter,
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        cities: [cityData?.name],
        package: package_days
      };

      /* 
        1. Model Details - get model checkout details,
        if single branch then - 
          a. Get available time slots
          b. Get branch slot pause
          If time slots available then - 
            i> Calculate Charges with regular plan.
      */
      dispatch(
        getModelCheckoutDetails(
          FilterPayload,
          modelName,
          userProfileData,
          navigate,
          modelCheckoutCallback
        )
      );
      const modelDetailPayload = {
        modelNames: [modelName],
      };

      // get model details
      dispatch(getRentalModelDetails(modelDetailPayload, null));
    }
  };

  const modelCheckoutCallback = (payload: any) => {
    // branchSlotPause should be considered if first index lies in it
    const chargePayload = {
      data: {
        ...payload,
        deliveryDetails: {
          type: DeliveryModesEnum.BRANCH,
          slot: availableTimeSlots?.slots[0],
        },
      },
    };
    // calculate charge using timeslots
    dispatch(getBillingCalculatedCharges(chargePayload, () => {}));
  };

  return (
    <Box className="card" key={ind}>
      <Box>
        <img
          onClick={() => {
            setOpenDetailModal(!openDetailModal);
          }}
          data-bs-toggle="modal"
          data-bs-target="#bookvehicle"
          style={{ cursor: "pointer" }}
          src={`${modelImageBucketUrl}${modelName}/${imageObj?.type}-${imageObj?.fileName}`}
          alt=""
        />
        <Box className={`populer-tag ${!tag && "invisible"}`}>
          {capitalizeFirstLetter(tag)}
        </Box>
        <Typography variant="h6" className="bike-brand">{manufacturerName}</Typography>
        <Box className="name-price-wrap">
          <Typography variant="h6"
            onClick={() => {
              setOpenDetailModal(!openDetailModal);
            }}
          >
            {displayName}
          </Typography>
          <Typography component={"p"} className="price">
            {commmaSeparator(
              amountConvertToRupee(planDetails?.discountedAmount)
            )}
            {"/-"}
          </Typography>
        </Box>
        <Typography component={"p"}  className="perday-rent">
          {amountConvertToRupee(planDetails?.perDayPrice)}
          {"/-"} <i>{rentingFleet?.PerDay}</i>
        </Typography>
        <Box className="deposite-wrap">
          {!planDetails?.unlimitedPackage && (
            <Typography component="span" variant={"smallText"}>
              <i className="fa-solid fa-infinity"></i>
              {rentingFleet?.UnlimitedKms}
            </Typography>
          )}
          {!planDetails?.securityDepositRequired && (
            <Typography component="span" variant={"smallText"}>
              <i className="fa-solid fa-ban"></i> {rentingFleet?.ZeroDepositFee}
            </Typography>
          )}
        </Box>
      </Box>

      <Box className="footer-btn">
        <button
          onClick={handleBookVehicle}
          disabled={rentingFleetLoader}
          className="freedo-primary-button rounded-pill"
        >
          {rentingFleetLoader && !openDetailModal ? (
            <Box className="spinner-border spinner-border-sm text-light">
              <Box className="visually-hidden" aria-hidden="true">
                Loading...
              </Box>
            </Box>
          ) : (
            <>{rentingFleet.BookVehicle}</>
          )}
        </button>
      </Box>

      {/* -------Vehicle detail model-------- */}
      {openDetailModal && (
        <CustomModalVehicleDetail
          setOpenDetailModal={setOpenDetailModal}
          handleBookVehicle={handleBookVehicle}
          showBookVehicle={true}
          ind={ind}
          openDetailModal={openDetailModal}
          modelId={modelName}
          rentingFleetLoader={rentingFleetLoader}
          vehicleModalToggleHandler={vehicleModalToggleHandler}
        />
      )}
    </Box>
  );
};

export default RentingFleetCard;
