import axios from "axios";
import { BASE_URL_CUSTOMER } from "./apiUrls";

import moment from "moment";
import { toastr } from "react-redux-toastr";
import { storeData } from "../config/filterStorage";
import { LocalStorage } from "../constant/enum";
import { actions, ACTIONS } from "../redux";
import store from "../redux/store";
import { showUnderMaintenance, userLogout } from "../redux/actions";
import CONSTANTS from "../locales/en.json";

let auth: boolean = true;

export const customAxios = axios.create({});
export const Axios = axios.create({});

interface IGuestHeaders {
  "x-client-id": string;
  "x-platform": string;
  "x-bn": string;
  "x-channel": string;
  "x-user-role": string;
}

// Guest-specific headers
const guestLoginHeaders: IGuestHeaders = {
  "x-client-id": "FREEDO",
  "x-platform": "CUSTOMER",
  "x-bn": "BnHead",
  "x-channel": "WEB",
  "x-user-role": "GUEST_CUSTOMER",
};

interface IDefaultHeaders {
  "x-platform": string;
  "x-channel": string;
  "x-bn": string | any;
  "x-client-id": string;
  "user-agent": string;
  requestfrom: string;
  platform: string;
}

export const defaultHeaders: IDefaultHeaders | any = {
  "x-platform": CONSTANTS?.requestHeaders?.["x-platform"],
  "x-channel": CONSTANTS?.requestHeaders?.["x-channel"],
  "x-client-id": CONSTANTS?.requestHeaders?.["x-client-id"],
  "x-bn": process.env.REACT_APP_BUILD_NUMBER,
  "user-agent": navigator.userAgent,
  requestfrom: CONSTANTS?.requestHeaders?.requestfrom, // old header
  platform: CONSTANTS?.requestHeaders?.platform, // old header
};

customAxios.defaults.baseURL = BASE_URL_CUSTOMER;

customAxios.defaults.headers.common = { ...defaultHeaders };
Axios.defaults.headers.common = { ...defaultHeaders };

customAxios.interceptors.request.use(
  async (config: any) => {
    const localStore: any = store?.getState();

    //Check if guest login
    const isGuestLogin = !localStore?.newAuthReducer?.userAuthData?.customerId;

    //get token from reducer
    const token = localStore?.newAuthReducer?.refreshToken;

    //Api header object for guest and authentication
    const apiHeader = isGuestLogin
      ? guestLoginHeaders
      : { ...defaultHeaders, Authorization: `Bearer ${token}` };

    if (!auth) {
      throw new axios.Cancel("Operation canceled due to Invalid Token");
    }
    if (localStore?.newAuthReducer?.refreshToken) {
      if (
        moment(localStore?.newAuthReducer?.ExpireTime ?? "").diff(
          moment(),
          "minute"
        ) < 5
      ) {
        axios
          .get(`${BASE_URL_CUSTOMER}token`, {
            headers: apiHeader,
          })
          .then((data) => {
            auth = true;
            setAuthToken(data?.data?.Token);
            store.dispatch(
              actions.setUserToken({
                ExpireTime: data?.data?.ExpireTime ?? "",
                token: data?.data?.Token ?? "",
                refreshToken: token ?? "",
              })
            );

            storeData(LocalStorage.token, {
              token: data?.data?.Token ?? "",
              refreshToken: token ?? "",
              ExpireTime: data?.data?.ExpireTime ?? "",
            }).catch((err) => {
              throw new Error("Invalid token");
            });
          })
          .catch((err) => {
            console.log(err, 7776);
          });
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { status } = error.response;
    if (status === 502) {
      store.dispatch(showUnderMaintenance(true));
      return Promise.reject({ Message: "Under Maintenance" });
    }
    return Promise.reject(error);
  }
);

Axios.interceptors.request.use(
  async (config: any) => {
    return config;
  },
  (error) => {
    console.error(error);
  }
);

customAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { status } = error.response;
    if (status === 401) {
      store.dispatch(userLogout());
    }
    if (status === 502) {
      store.dispatch(showUnderMaintenance(true));
      return Promise.reject({ Message: "Under Maintenance" });
    }
    return Promise.reject(error);
  }
);

export const setAuthToken = (token: string) =>
  (customAxios.defaults.headers.common = {
    ...defaultHeaders,
    Authorization: `Bearer ${token}`,
  });
