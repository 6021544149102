import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import RentingFleetCard from "../../../../component/RentingFleetCard";
import en from "../../../../locales/en.json";
import { RootState } from "../../../../redux/reducers";
import { noVehicleFound } from "../../../../assets";
import {
  VehicleFuelTypeEnum,
  VehicleImageTypeEnum,
  VehicleTypeEnum,
} from "../../../../constant/enum";
import { GenericObject } from "../../../../utills/helper";
import { Box } from "@mui/material";
const rent = en.rent;

const VehicleWrapper = ({ state, setSecondaryFilter, secondaryFilter }) => {
  const { locale } = useSelector((state: RootState) => state.localeReducer);
  const { vehicleList } = useSelector((state: RootState) => state.homeReducer);

  const { masterPlanDurationsData, filteredModelList, rentingFleetFilter } =
    useSelector((state: any) => state.rentalsCommonReducer);

  const { rentingFleet } = locale["home"];

  const [vehicles, setVehicles] = useState<any>([]);
  const vehicleListLength = filteredModelList?.availableModels?.length;
  const showRecords =
    vehicleListLength > state.records ? state.records : vehicleListLength;

  useEffect(() => {
    setVehicles(filteredModelList?.availableModels || []);
  }, [filteredModelList?.availableModels]);

  let filteredModelTypeList = vehicles;

  // Filter Electric vehicle - Local
  filteredModelTypeList =
    state.isElectric && state.vehicleType
      ? vehicles.filter(
          (item: any) =>
            item.fuelType === VehicleFuelTypeEnum.ELECTRIC &&
            item.vehicleType ===
              VehicleTypeEnum[state.vehicleType as keyof typeof VehicleTypeEnum]
        )
      : state.isElectric
      ? vehicles.filter(
          (item: any) => item.fuelType === VehicleFuelTypeEnum.ELECTRIC
        )
      : state.vehicleType
      ? vehicles.filter(
          (item: any) =>
            item.vehicleType ===
            VehicleTypeEnum[state.vehicleType as keyof typeof VehicleTypeEnum]
        )
      : vehicles;

  return (
    <>
      <div className="display-result-filter">
        <p>
          Displaying {showRecords || 0} {showRecords > 1 ? "Results" : "Result"}
        </p>
        {/*  <select
          value={state.sortBy}
          onChange={(e) => {
            setSecondaryFilter((pre) => {
              return {
                ...pre,
                sortBy: e.target.value,
              };
            });
            secondaryFilterChanged("sortBy", e.target.value);
          }}
          className="form-select form-select-sm"
          aria-label="vehicleSort"
        >
          <option value="ASC">Price - Low to High</option>
          <option value="DESC">Price - High to Low</option>
        </select> */}
      </div>
      <div className="row rent-fleet">
        {filteredModelTypeList?.length > 0 ? (
          <div className="vehicle-wrapper">
            {filteredModelTypeList?.length > 0 &&
              filteredModelTypeList
                ?.slice(0, state.records)
                .map((item, ind) => {
                  let imageObj: GenericObject = {};
                  imageObj = item?.images.find(
                    (item) => item.type === VehicleImageTypeEnum.RIGHT
                  );
                  return (
                    <>
                      <RentingFleetCard
                        key={item.modelName}
                        ind={ind}
                        data={item}
                        rentingFleet={rentingFleet}
                        filters={state} //  filters selected, package selected etc..
                        imagePath={imageObj?.fileName}
                        imageObj={imageObj}
                        isHomePage={false}
                      />
                    </>
                  );
                })}
          </div>
        ) : (
          <Box className="mt-5">
            <img
              src={noVehicleFound}
              className="no-vehicle-found"
              alt="no-vehicle found"
            />
            <Box className="text-center">
              <h5 className="mb-1">Oops! No vehicles found</h5>
              <p>Please modify your search and try again</p>
            </Box>
          </Box>
        )}
        {vehicleListLength > 0 && vehicleListLength > state.records && (
          <button
            className="freedo-outline-btn rounded-pill rent-card-btn"
            onClick={() => {
              setSecondaryFilter((pre) => {
                return { ...pre, records: pre.records + 6 };
              });
            }}
            disabled={vehicleList?.totalItems <= state.records}
          >
            {rent.ShowMore}
          </button>
        )}
      </div>
    </>
  );
};

export default VehicleWrapper;
