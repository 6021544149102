import { useSelector } from "react-redux";
import { delv } from "../../../../assets";
import { RootState } from "../../../../redux/reducers";
import Grid from "@mui/material/Grid2";
import AddressListModel from "../../../../component/AddressListModel";
import en from "../../../../locales/en.json";
import { GoogleMapComponent } from "./GoogleMapComponent";

const CheckoutAddress = (props: any) => {
  const {
    checkoutData,
    branchDetails,
    handleCheckoutDataUpdate,
    branchChangeHandler,
  } = props;
  const { selectedBranch, homeDelivery, selectedHomeAddress } = checkoutData;
  const { locale } = useSelector((state: RootState) => state.localeReducer);
  const addressModel = locale["addressModel"];

  const { checkout } = en;

  const selectdMarkerLocation = (item: any) => {
    branchChangeHandler(item);
  };

  const handleHomeAddressChange = (item: any) => {
    props.handleAddressSelected(item);
  };
  return (
    <>
      <GoogleMapComponent
        branchDetails={branchDetails}
        selectdMarkerLocation={selectdMarkerLocation}
        handleCheckoutDataUpdate={handleCheckoutDataUpdate}
        branchChangeHandler={branchChangeHandler}
        selectedBranch={checkoutData?.selectedBranch}
      />

      {/* Delivery Address section */}
      {homeDelivery && (
        <Grid container className="delivery-details row">
          <Grid size={{ md: 7 }}>
            <img src={delv} className="delv-image" />
            <span className="del-detail">{checkout.deliverHere}</span>
            <span className="head">{selectedHomeAddress?.name}</span>
            <span className="text">
              {selectedHomeAddress?.addressLine1
                ? selectedHomeAddress?.addressLine1
                : en.errorMessages.addAddressHomeDelivery}
            </span>
            <span>
              {(addressModel["addressType"] as [])?.map(
                (type) =>
                  selectedHomeAddress?.type === type["name"] && (
                    <button className="btn-home smallest-text cursor-default">
                      <i className={type["className"]} aria-hidden="true"></i>
                      &nbsp;&nbsp;{type["name"]}
                    </button>
                  )
              )}
            </span>
          </Grid>
          <Grid
            size={{ md: 5, sm: 5 }}
            className="text-end change-address-wrapper"
          >
            <button
              className="freedo-outline-btn rounded-pill"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvaschangeaddress"
              aria-controls="offcanvaschangeaddress"
            >
              {selectedHomeAddress
                ? checkout.changeAddress
                : checkout.addAddress}
            </button>
            <AddressListModel
              addAddess={!selectedHomeAddress}
              handleChange={handleHomeAddressChange}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default CheckoutAddress;
