import {
  Avatar,
  AvatarGroup,
  Box,
  Card,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import { CommaIcon, HeartEmoji } from "../../../../assets";
import { TypographyConstants } from "../../../../constant/enum";
import Styles from "../index.module.css";

const CustomerReviewCard = ({ data, index, isLength }: any) => {
  return (
    <Box
      className={Styles.customerReviewCardBox}
      sx={{
        marginTop: isLength && (index === 0 || index === 3) ? "140px" : "30px",
      }}
    >
      <img src={CommaIcon} className={Styles.commaIconStyle} />
      <Box className={Styles.emojiBox}>
        <img
          src={HeartEmoji}
          style={{
            width: "35px",
            height: "35px",
          }}
        />
      </Box>
      <Card className={Styles.customerReviewCard}>
        <Rating name="read-only" value={data?.rating} readOnly />

        <Typography
          variant="body1"
          sx={{ lineHeight: "20px", color: "#6b6b78" }}
        >
          {data?.review}
        </Typography>

        <Box className={Styles.customerReviewDesc}>
          <AvatarGroup max={3}>
            <Avatar
              alt={"Nikhil"} // work in progress
              src={"Nikhil"}
              sx={{ width: 34, height: 34, fontSize: "14px" }}
            />
          </AvatarGroup>
          <Stack gap="5px">
            <Typography
              variant={TypographyConstants.SMALL_TEXT}
              sx={{ fontWeight: "600", color: "#2E2E2E", padding: "0px" }}
            >
              {data?.customerName}
            </Typography>
            <Typography
              variant={TypographyConstants.SMALL_TEXT}
              sx={{ padding: "0px" }}
            >
              {data?.customerRole}
            </Typography>
          </Stack>
        </Box>
      </Card>
      <Box className={Styles.customerReviewCardBottomBox} />
    </Box>
  );
};
export default CustomerReviewCard;
