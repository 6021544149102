import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import React, { useState } from "react";
import { Dayjs } from "dayjs";
// Define the type for the component props
interface InputDatePickerProps {
  textFieldStyle?: React.CSSProperties;
  placeholder?: string;
  value?: Dayjs | null;
  onChange?: (newValue: Dayjs | null) => void; // Only taking newValue
  inputFormat?: string;
  views?: ("day" | "month" | "year")[];
  padding?: string;
  width?: string;
  defaultValue?: Dayjs;
  shouldDisableDate?: (date: Dayjs) => boolean;
  maxDate?: Dayjs;
  minDate?: Dayjs;
  readOnly?: boolean;
  disabled?: boolean;
  placement?:
    | "top-start"
    | "top"
    | "top-end"
    | "bottom-start"
    | "bottom"
    | "bottom-end"
    | "left-start"
    | "left"
    | "left-end"
    | "right-start"
    | "right"
    | "right-end";
  ref?: React.Ref<HTMLInputElement>;
}
const InputDatePicker: React.FC<InputDatePickerProps> = (props) => {
  const {
    textFieldStyle = {},
    placeholder = "",
    value,
    onChange,
    inputFormat = "DD-MM-YYYY",
    views,
    padding = "14px 14px",
    width = "100%",
    defaultValue,
    shouldDisableDate,
    maxDate,
    minDate,
    readOnly,
    disabled,
    placement = "top-start",
    ...rest
  } = props;
  const [open, setOpen] = useState(false);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <DatePicker
        open={open}
        ref={props.ref}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        label={placeholder}
        value={value || null}
        onChange={(newValue) => {
          if (onChange) {
            onChange(newValue);
          }
        }}
        inputFormat={inputFormat}
        views={views}
        renderInput={(params) => (
          <TextField
            {...params}
            onFocus={(e) => (e.target.readOnly = true)}
            variant="outlined"
            sx={{
              "& .MuiInputLabel-root": {
                left: "-4px",
                top: "2px",
              },
              ...textFieldStyle,
            }}
          />
        )}
        defaultCalendarMonth={defaultValue}
        className="custom-react-datepicker"
        shouldDisableDate={shouldDisableDate}
        maxDate={maxDate}
        minDate={minDate}
        readOnly={readOnly}
        disabled={disabled}
        PopperProps={{
          placement,
        }}
        {...rest}
      />
    </LocalizationProvider>
  );
};
export default InputDatePicker;
