import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import { VehicleDocumentType } from "../../../../../constants/enum";
import { TypographyConstants } from "../../../../../../constant/enum";
import { colors } from "../../../../../../themes/colors";
import { useDispatch } from "react-redux";
import { downloadVehicleDocument } from "../../../../../redux/actions";
function MyDocumentsAccordion({ documents }) {
  const dispatch = useDispatch();
  const handleDownloadDocument = (documentId, vehicleId) => {
    const payload = {
      ids: `${vehicleId}/${documentId}`,
    };
    dispatch(downloadVehicleDocument(payload));
  };
  return documents?.documentDetails?.map((item: any) => {
    return (
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant={TypographyConstants.CAPTION}>
          {VehicleDocumentType[item?.type]}
        </Typography>
        <SimCardDownloadIcon
          onClick={() => {
            handleDownloadDocument(item?.id, item?.vehicleId);
          }}
          sx={{
            cursor: "pointer",
            color: colors.THEME_BLUE,
            ":hover": {
              color: colors.bluish_gray,
            },
          }}
        />
      </Box>
    );
  });
}

export default MyDocumentsAccordion;
