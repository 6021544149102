import React from "react";

function PremiumHelmetIcon() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 22 22"
        fill="none"
      >
        <path
          d="M18.8173 18.9371L18.8209 18.9371C19.3345 18.9408 19.8056 18.7237 20.0988 18.3737L20.0988 18.3736L20.1008 18.3713C20.3802 18.0419 20.4657 17.6251 20.3493 17.2408L20.3489 17.2397L18.3266 10.5056L18.3066 10.4388L18.3056 10.3691C18.276 8.33751 17.311 6.39723 15.6232 5.02557C13.8227 3.56812 11.4167 2.86739 9.00352 3.11027L9.00117 3.1105C5.17218 3.47763 2.16791 6.16598 1.6607 9.55615C1.34639 11.9585 2.25985 14.3709 4.16371 16.0988L4.18198 16.1146L18.8033 18.9371L18.8173 18.9371Z"
          stroke="#2BC7C9"
        />
        <path
          d="M18.7553 18.25H18.7067L18.6589 18.2406L3.32125 15.227C3.57493 15.5362 3.85713 15.8268 4.16541 16.0956C4.16554 16.0957 4.16568 16.0958 4.16581 16.0959L4.1874 16.1147L18.8062 18.9371H18.8203V18.9371L18.8232 18.9372C19.3359 18.9402 19.806 18.7231 20.0986 18.3737L20.0986 18.3737L20.1 18.372C20.253 18.191 20.3475 17.9838 20.3823 17.7701C19.9324 18.0842 19.3814 18.2537 18.8154 18.25H18.7553Z"
          fill="#2BC7C9"
          stroke="#2BC7C9"
        />
        <path
          d="M5.99091 7.57382C6.72371 6.79683 7.71704 6.26026 8.81806 6.04689C9.12178 5.98469 9.32492 5.7241 9.28882 5.4429C9.26964 5.28391 9.17751 5.13961 9.03494 5.04583C8.89252 4.95219 8.71294 4.91759 8.54092 4.95082C7.18753 5.21677 5.96659 5.87664 5.06297 6.83033C4.94804 6.95212 4.89593 7.11262 4.91949 7.27162C4.9432 7.43061 5.04032 7.57286 5.18667 7.66265C5.4407 7.82274 5.78641 7.78457 5.99091 7.57382Z"
          fill="#2BC7C9"
        />
        <path
          d="M9.7644 10.4385H8.52716V11.5632H9.7644V10.4385Z"
          fill="#2BC7C9"
        />
        <path
          d="M9.7644 12.688H8.52716V13.8128H9.7644V12.688Z"
          fill="#2BC7C9"
        />
        <path
          d="M7.28979 10.4385H6.05255V11.5632H7.28979V10.4385Z"
          fill="#2BC7C9"
        />
        <path
          d="M7.28979 12.688H6.05255V13.8128H7.28979V12.688Z"
          fill="#2BC7C9"
        />
        <path
          d="M12.0205 14.5503L20.1933 16.7204L18.3454 10.5673C18.3258 10.502 18.3143 10.4377 18.3085 10.3765H12.5018C12.262 10.3765 12.1072 10.3767 11.9887 10.3848C11.8747 10.3926 11.8313 10.4059 11.8105 10.4145C11.688 10.4653 11.5906 10.5626 11.5399 10.6851C11.5312 10.7059 11.5179 10.7493 11.5102 10.8633C11.5021 10.9818 11.5018 11.1367 11.5018 11.3765V13.9538C11.5018 14.1988 11.6837 14.4608 12.0205 14.5502C12.0205 14.5502 12.0205 14.5502 12.0205 14.5503Z"
          stroke="#2BC7C9"
        />
      </svg>
    </>
  );
}

export default PremiumHelmetIcon;
