import { PROFILE_ACTIONS } from "../actionTypes";

/* action to update user profile */
export const updateProfile = (payload: any) => ({
  type: PROFILE_ACTIONS.UPDATE_USER_PROFILE_REQUESTED,
  payload,
});

/* action to update user profile image*/
export const updateProfileImage = (payload: any) => ({
  type: PROFILE_ACTIONS.UPDATE_USER_PROFILE_IMAGE_REQUESTED,
  payload,
});

/* action to get emergency contact details */
export const getEmergencyContactDetails = (payload: any) => ({
  type: PROFILE_ACTIONS.GET_EMERGENCY_CONTACT_DETAILS_REQUESTED,
  payload,
});

/* action to add emergency contact details */
export const addEmergencyContactDetails = (payload: any) => ({
  type: PROFILE_ACTIONS.ADD_EMERGENCY_CONTACT_DETAILS_REQUESTED,
  payload,
});

/* action to update emergency contact details */
export const updateEmergencyContactDetails = (payload: any) => ({
  type: PROFILE_ACTIONS.UPDATE_EMERGENCY_CONTACT_DETAILS_REQUESTED,
  payload,
});

/* action to generate otp emergency contact details */
export const generateOtpEmergencyContact = (payload: any) => ({
  type: PROFILE_ACTIONS.GENERATE_OTP_EMERGENCY_CONTACT_REQUESTED,
  payload,
});

/* action to resend otp emergency contact details */
export const resendOtpEmergencyContact = (payload: any) => ({
  type: PROFILE_ACTIONS.RESEND_OTP_EMERGENCY_CONTACT_REQUESTED,
  payload,
});

/* action to verify otp emergency contact details */
export const otpVerificationEmergencyContact = (payload: any) => ({
  type: PROFILE_ACTIONS.OTP_VERIFICATION_EMERGENCY_CONTACT_REQUESTED,
  payload,
});

/* action to set or change profile reducer key */
export const setProfileReducerKeys = (payload: any) => ({
  type: PROFILE_ACTIONS.SET_PROFILE_REDUCER_KEYS,
  payload,
});
