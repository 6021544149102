export enum homePageFilters {
  carousalFilter = "carousalFilter",
  rentingFleetFilter = "rentingFleetFilter",
}

// Define a type with an index signature // Valid for either 'empty object' or 'object with dynamic key-value pairs'
export type GenericObject = {
  [key: string]: string | number | any;
};

// enums for response status codes
export enum apiStatusCode {
  SUCCESS = 200,
  CREATED = 201,
}

// types for kyc document upload
export enum kycTypes {
  SELFIE = "SELFIE",
  DRIVING_LICENCE = "DRIVING_LICENCE",
  AADHAAR = "AADHAAR",
  OTHER_DOCUMENT = "OTHER_DOCUMENT",
}

// subtypes for kyc document upload
export enum kycSubTypes {
  FRONT_IMAGE_DRIVING_LICENCE = "frontImageAttachmentId",
  BACK_IMAGE_DRIVING_LICENCE = "backImageAttachmentId",
  SELFIE_ATTACHMENT_ID = "selfieAttachmentId",
}

export enum DocumentUploadConstants {
  PDF_FILE = ".pdf",
  TO_MANY_FILES = "too-many-files",
  FILE_TOO_LARGE = "file-too-large",
  FILE_INVALID_TYPE = "file-invalid-type",
}

// Other kyc document types
export enum OtherDocumentTypes {
  VOTER_ID = "VOTER_ID",
  BANK_DETAIL = "BANK_DETAIL",
  ELECTRICITY_BILL = "ELECTRICITY_BILL",
  RENT_BILL = "RENT_BILL",
  PASSPORT = "PASSPORT",
  OTHER = "OTHER",
}

// Display name for other kyc document
export enum OtherDocumentDisplayTypes {
  VOTER_ID = "Voter ID (Image and Pdf only)",
  BANK_DETAIL = "Bank Detail (Image and Pdf only)",
  ELECTRICITY_BILL = "Electricity Bill (Image and Pdf only)",
  RENT_BILL = "Rent Bill (Image and Pdf only)",
  PASSPORT = "Passport ID (Image and Pdf only)",
  OTHER = "Other Documents (Image and Pdf only)",
}

// Generate signed url cases
export enum generateSignedUrlDocType {
  KYC_DOCUMENT = "KYC_DOCUMENT",
  PROFILE_IMAGE = "PROFILE_IMAGE",
}

export enum statusCode {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

/* enums for profile tabs */
export enum profileTabs {
  ADDRESSES = "addresses",
  BOOKINGS = "bookings",
  KYC = "kyc",
  DETAILS = "details",
}

/* Typography constants */
export enum TypographyConstants {
  H1 = "h1",
  H2 = "h2",
  H3 = "h3",
  H4 = "h4",
  H5 = "h5",
  H6 = "h6",
  YOR_HEADING = "yorHeading",
  EXTRA_SMALL = "extraSmall",
  SMALL_TEXT = "smallText",
  ERROR_MESSAGE1 = "errorMessage1",
  BODY1 = "body1",
  SUBTITLE1 = "subtitle1",
  SUBTITLE2 = "subtitle2",
  BODY2 = "body2",
  CAPTION = "caption",
}

export enum genders {
  MALE = "MALE",
  FEMALE = "FEMALE",
  OTHERS = "OTHERS",
}

export enum displayGenderEnums {
  MALE = "Male",
  FEMALE = "Female",
  OTHERS = "Others",
}

export enum EmergencyContactRelationShip {
  FAMILY = "FAMILY",
  COLLEAGUE = "COLLEAGUE",
  FRIEND = "FRIEND",
}

export enum EmergencyContactStatus {
  VERIFIED = "VERIFIED",
  UNVERIFIED = "UNVERIFIED",
}

export enum BookingStatusDisplay {
  ONGOING = "Ongoing",
  UPCOMING = "Upcoming",
  ENDED = "Ended",
  COMPLETED = "Completed",
  CANCELLED = "Cancelled",
  PENDING = "Pending",
}

export enum BookingStatusColorCodes {
  PENDING = "#FF7A00",
  UPCOMING = "#FF7A00",
  COMPLETED = "#1BD8DA",
  ONGOING = "#2ABDBD",
  ENDED = "#FF3B30",
  CANCELLED = "#FF3B30",
}

export enum VehicleDocumentType {
  REGISTRATION_CERTIFICATE = "Registration Certificate",
  DRIVING_LICENCE = "Driving Licence",
  PUC = "PUC Certificate",
  FITNESS_CERTIFICATE = "Fitness Certificate",
  INSURANCE = "Insurance Certificate",
  OTHERS = "Others Documents",
}

export enum addressTypes {
  Office = "OFFICE",
  Other = "OTHER",
  Home = "HOME",
}

export enum BookingStatus {
  PENDING = "PENDING",
  UPCOMING = "UPCOMING",
  ONGOING = "ONGOING",
  ENDED = "ENDED",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
}