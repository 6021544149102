import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import { toastr } from "react-redux-toastr";
import { handleMessage } from "../../../utills/helper";
import { yorServices } from "../services";

// saga to get available renting fleet
function* getAvailableVeicle(action: any): any {
  try {
    const data = yield call(yorServices.getAvailableVehicleYor, action.payload);
    if (data?.status === 200) {
      const response = data?.data?.data;
      yield put({
        type: actionTypes.YOR_ACTION.YOR_GET_AVAILABLE_VEHICLES_SUCCESS,
        payload: response,
      });
    } else if (
      data?.response?.data?.error?.httpStatusCode === 422 ||
      data?.status === 422
    ) {
      yield put({
        type: actionTypes.YOR_ACTION.YOR_GET_AVAILABLE_VEHICLES_FAILED,
      });
    } else {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.YOR_ACTION.YOR_GET_AVAILABLE_VEHICLES_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.YOR_ACTION.YOR_GET_AVAILABLE_VEHICLES_FAILED,
    });
  }
}

//calculate charges
function* calculateCharges(action: any) {
  try {
    let data = yield call(yorServices.calculateCharges, action.payload);
    if (data.status === 200) {
      yield put({
        type: actionTypes.YOR_ACTION.CALCULATE_CHARGES_SUCCESS,
        payload: action?.payload?.data?.couponCode
          ? { ...data?.data?.data, closeModal: true }
          : data?.data?.data,
      });
    } else {
      yield put({
        type: actionTypes.YOR_ACTION.CALCULATE_CHARGES_FAILED,
      });
      toastr.error("", handleMessage(data?.response?.data?.error));
    }
  } catch (err) {
    yield put({
      type: actionTypes.YOR_ACTION.CALCULATE_CHARGES_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}

function* getAvailablesPlans(action) {
  try {
    let data = yield call(yorServices.getAvailablesPlans, action.payload);
    if (data?.status === 200) {
      yield put({
        type: actionTypes.YOR_ACTION.PLAN_AVAILABLES_SUCCESS,
        payload: data?.data?.data,
      });
    } else {
      yield put({
        type: actionTypes.YOR_ACTION.PLAN_AVAILABLES_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (err) {
    yield put({
      type: actionTypes.YOR_ACTION.PLAN_AVAILABLES_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}

//store booking
function* storeBooking(action: any) {
  try {
    let data = yield call(yorServices.storeBooking, action.payload.data);
    if (data?.status === 201) {
      let orderPayload = {
        data: {
          orderId: data?.data?.data?.orderDetails?.id,
          paymentFlow: action.payload.paymentFlow,
        },
      };
      yield put({
        type: actionTypes.YOR_ACTION.STORE_BOOKING_SUCCESS,
        payload: data?.data?.data,
      });
      yield put({
        type: actionTypes.YOR_ACTION.CREATE_ORDER_REQUESTED,
        payload: orderPayload,
      });
    } else if (
      data?.response?.data?.error?.httpStatusCode === 422 ||
      data?.status === 422 ||
      data?.status === 400
    ) {
      if (data?.response?.data?.error?.data?.id) {
        const cancelBookingPayload = {
          bookingId: data?.response?.data?.error?.data?.id,
          createOrderAfterCancellation: true,
          storeBookingPayload: action.payload,
        };
        yield put({
          type: actionTypes.YOR_ACTION.CANCELLED_BOOKING_REQUESTED,
          payload: cancelBookingPayload,
        });
      } else {
        yield put({
          type: actionTypes.YOR_ACTION.STORE_BOOKING_FAILED,
        });
        toastr.error("", handleMessage(data?.response?.data?.error));
      }
    } else {
      yield put({
        type: actionTypes.YOR_ACTION.STORE_BOOKING_FAILED,
      });
      toastr.error("", handleMessage(data?.response?.data?.error));
    }
  } catch (err) {
    yield put({
      type: actionTypes.YOR_ACTION.STORE_BOOKING_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}
//create order
function* createOrder(action: any) {
  try {
    let data = yield call(yorServices.createOrder, action.payload);
    if (data?.status === 201) {
      yield put({
        type: actionTypes.YOR_ACTION.CREATE_ORDER_SUCCESS,
        payload: data?.data?.data,
      });
    } else {
      yield put({
        type: actionTypes.YOR_ACTION.CREATE_ORDER_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (err) {
    yield put({
      type: actionTypes.YOR_ACTION.CREATE_ORDER_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}

//get payment status
function* getPaymentStatus(action: any) {
  try {
    let data = yield call(yorServices.getPaymentStatus, action.payload);
    if (data?.status === 201 || data?.status === 200) {
      yield put({
        type: actionTypes.YOR_ACTION.GET_PAYMENT_STATUS_SUCCESS,
        payload: data?.data?.data,
      });
    } else {
      yield put({
        type: actionTypes.YOR_ACTION.GET_PAYMENT_STATUS_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (err) {
    yield put({
      type: actionTypes.YOR_ACTION.GET_PAYMENT_STATUS_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}

// function to get city V2 for yor city modal
function* getCityV2(action: any) {
  try {
    const data = yield call(yorServices.getCityV2, action.payload);
    if (data?.status === 200) {
      const response = data?.data?.data;
      yield put({
        type: actionTypes.YOR_ACTION.GET_CITY_V2_SUCCESS,
        payload: response,
      });
    } else {
      toastr.error("", handleMessage(data));
      yield put({
        type: actionTypes.YOR_ACTION.GET_CITY_V2_FAILED,
      });
    }
  } catch (error) {
    toastr.error("", handleMessage(error));
    yield put({
      type: actionTypes.YOR_ACTION.GET_CITY_V2_FAILED,
    });
  }
}

function* getBookingDetails(action: any) {
  try {
    let data = yield call(yorServices.getBookingDetails, action.payload);
    if (data?.status === 201 || data?.status === 200) {
      yield put({
        type: actionTypes.YOR_ACTION.GET_YOR_BOOKINGS_DETAILS_SUCCESS,
        payload: data?.data?.data,
      });
    } else {
      yield put({
        type: actionTypes.YOR_ACTION.GET_YOR_BOOKINGS_DETAILS_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (err) {
    yield put({
      type: actionTypes.YOR_ACTION.GET_YOR_BOOKINGS_DETAILS_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}
//get all coupons
function* getAllCoupons(action: any) {
  try {
    let data = yield call(yorServices.getAllCoupons, action.payload);
    if (data?.status === 201 || data?.status === 200) {
      yield put({
        type: actionTypes.YOR_ACTION.GET_ALL_COUPONS_SUCCESS,
        payload: data?.data?.data,
      });
    } else {
      yield put({
        type: actionTypes.YOR_ACTION.GET_ALL_COUPONS_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (err) {
    yield put({
      type: actionTypes.YOR_ACTION.GET_ALL_COUPONS_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}

function* cancelledBookig(action: any) {
  try {
    let data = yield call(yorServices.cancelledBooking, action.payload);
    if (data?.status === 200) {
      if (action.payload.createOrderAfterCancellation) {
        yield put({
          type: actionTypes.YOR_ACTION.STORE_BOOKING_REQUESTED,
          payload: action.payload.storeBookingPayload,
        });
      }
      yield put({
        type: actionTypes.YOR_ACTION.CANCELLED_BOOKING_SUCCESS,
        payload: data?.data?.data,
      });
    } else {
      yield put({
        type: actionTypes.YOR_ACTION.CANCELLED_BOOKING_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (err) {
    yield put({
      type: actionTypes.YOR_ACTION.CANCELLED_BOOKING_FAILED,
    });
    toastr.error("", handleMessage(err));
  }
}

function* yorSaga() {
  yield takeLatest(
    actionTypes.YOR_ACTION.YOR_GET_AVAILABLE_VEHICLES_REQUESTED,
    getAvailableVeicle
  );

  yield takeLatest(
    actionTypes.YOR_ACTION.CALCULATE_CHARGES_REQUESTED,
    calculateCharges
  );

  yield takeLatest(
    actionTypes.YOR_ACTION.PLAN_AVAILABLES_REQUESTED,
    getAvailablesPlans
  );

  yield takeLatest(
    actionTypes.YOR_ACTION.STORE_BOOKING_REQUESTED,
    storeBooking
  );
  yield takeLatest(actionTypes.YOR_ACTION.CREATE_ORDER_REQUESTED, createOrder);
  yield takeLatest(
    actionTypes.YOR_ACTION.GET_PAYMENT_STATUS_REQUESTED,
    getPaymentStatus
  );
  yield takeLatest(actionTypes.YOR_ACTION.GET_CITY_V2_REQUESTED, getCityV2);
  yield takeLatest(
    actionTypes.YOR_ACTION.GET_YOR_BOOKINGS_DETAILS_REQUESTED,
    getBookingDetails
  );
  yield takeLatest(
    actionTypes.YOR_ACTION.GET_ALL_COUPONS_REQUESTED,
    getAllCoupons
  );
  yield takeLatest(
    actionTypes.YOR_ACTION.CANCELLED_BOOKING_REQUESTED,
    cancelledBookig
  );
}

export default yorSaga;
