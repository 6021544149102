import { call, put, select, takeLatest } from "redux-saga/effects";
import { apiStatusCode } from "../../constants/enum";
import { newAuthServices } from "../services";
import { actionInterface } from "../../interfaces/commonInterface";
import { AUTH_ACTIONS } from "../actions/actionTypes";
import authService from "../services/authService";
import { toastr } from "react-redux-toastr";
import { handleMessage } from "../../../utills/helper";
import { CHECKOUT_ACTIONS } from "../../../redux";
import { RootState } from "../../../redux/reducers";
import { PROFILE_ACTIONS } from "../actions/actionTypes";

/* function to get user profile data */
function* getUserProfile(action: actionInterface) {
  try {
    const response = yield call(
      newAuthServices.getUserProfileDataService,
      action?.payload
    );

    if (response.status === apiStatusCode.SUCCESS) {
      yield put({
        type: AUTH_ACTIONS.GET_USER_PROFILE_DATA_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      yield put({
        type: AUTH_ACTIONS.GET_USER_PROFILE_DATA_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: AUTH_ACTIONS.GET_USER_PROFILE_DATA_FAILED,
    });
  }
}

// signup user
function* signUpUser(action: actionInterface): any {
  try {
    const { onSuccess } = action.payload;
    const response = yield call(authService.signUpUserService, action.payload);

    if (response.status === apiStatusCode.SUCCESS) {
      yield put({
        type: AUTH_ACTIONS.SIGNUP_SUCCESS,
        payload: response?.data?.data,
      });
      onSuccess && onSuccess(response);
    } else {
      yield put({
        type: AUTH_ACTIONS.SIGNUP_FAILED,
      });
      toastr.error("", handleMessage(response.response));
    }
  } catch (error: any) {
    yield put({
      type: AUTH_ACTIONS.SIGNUP_FAILED,
    });
    toastr.error("", handleMessage(error));
  }
}

// signUp verify otp
function* signUpVerifyOtp(action: actionInterface): any {
  try {
    const response = yield call(
      authService.signUpVerifyService,
      action.payload
    );

    if (response.status === apiStatusCode.SUCCESS) {
      yield put({
        type: AUTH_ACTIONS.SIGNUP_VERIFY_OTP_SUCCESS,
        payload: response?.data?.data,
      });
      toastr.success("", handleMessage(response));
    } else {
      yield put({
        type: AUTH_ACTIONS.SIGNUP_VERIFY_OTP_FAILED,
      });
      toastr.error("", handleMessage(response.response));
    }
  } catch (error: any) {
    yield put({
      type: AUTH_ACTIONS.SIGNUP_VERIFY_OTP_FAILED,
    });
    toastr.error("", handleMessage(error));
  }
}

// signUp verify otp
function* signUpResendOtp(action: actionInterface): any {
  try {
    const response = yield call(
      authService.signUpResendOtpService,
      action.payload
    );

    if (response.status === apiStatusCode.SUCCESS) {
      yield put({
        type: AUTH_ACTIONS.SIGNUP_RESEND_OTP_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      yield put({
        type: AUTH_ACTIONS.SIGNUP_RESEND_OTP_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: AUTH_ACTIONS.SIGNUP_RESEND_OTP_FAILED,
    });
    toastr.error("", handleMessage(error));
  }
}

// login user
function* loginUser(action: actionInterface): any {
  try {
    const { onSuccess } = action.payload;
    const response = yield call(authService.loginUserService, action.payload);

    if (response.status === apiStatusCode.SUCCESS) {
      yield put({
        type: AUTH_ACTIONS.LOGIN_SUCCESS,
        payload: response?.data?.data,
      });
      onSuccess && onSuccess(response);
    } else {
      toastr.error("", handleMessage(response.response));
      yield put({
        type: AUTH_ACTIONS.LOGIN_FAILED,
      });
    }
  } catch (error: any) {
    yield put({
      type: AUTH_ACTIONS.LOGIN_FAILED,
    });
    toastr.error("", error);
  }
}

// login verify opt
function* loginVerify(action: actionInterface): any {
  try {
    const { onSuccess } = action.payload;
    const response = yield call(authService.loginVerifyService, action.payload);

    if (response.status === apiStatusCode.SUCCESS) {
      yield put({
        type: AUTH_ACTIONS.LOGIN_VERIFY_OTP_SUCCESS,
        payload: response?.data?.data,
      });

      const userAuthData = yield select(
        (state: RootState) => state.newAuthReducer.userAuthData
      );

      /* call to user profile api*/
      yield put({
        type: AUTH_ACTIONS.GET_USER_PROFILE_DATA_REQUESTED,
        payload: {
          customerId: userAuthData?.customerId,
          apiParams: { ipi: true },
        },
      });

      yield put({
        type: PROFILE_ACTIONS.GET_EMERGENCY_CONTACT_DETAILS_REQUESTED,
        payload: {
          customerId: userAuthData?.customerId,
        },
      });

      const bookingData = yield select(
        (state) => state.checkoutReducer.bookingData
      );

      // yield put({ type: PROFILE_ACTIONS.GET_USER_PROFILE });
      if (bookingData?.payload?.model_id) {
        yield put({
          type: CHECKOUT_ACTIONS.GET_VEHICLE_DATA,
          payload: {
            payload: bookingData.payload,
            journyData: bookingData.journyData,
            navigate: action.payload.navigate,
            handleRentingFleetLoader: null,
          },
        });

        yield put({
          type: CHECKOUT_ACTIONS.SET_BOOKING_DATA,
          payload: { payload: {}, journyData: {} },
        });
      }
      onSuccess && onSuccess(response);
    } else {
      yield put({
        type: AUTH_ACTIONS.LOGIN_VERIFY_OTP_FAILED,
      });
      toastr.error("", handleMessage(response.response));
    }
  } catch (error: any) {
    yield put({
      type: AUTH_ACTIONS.LOGIN_VERIFY_OTP_FAILED,
    });
    toastr.error("", error);
  }
}

// login resend opt
function* loginResendOtp(action: actionInterface): any {
  try {
    const response = yield call(
      authService.loginResendOtpService,
      action.payload
    );

    if (response.status === apiStatusCode.SUCCESS) {
      yield put({
        type: AUTH_ACTIONS.LOGIN_RESEND_OTP_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      yield put({
        type: AUTH_ACTIONS.LOGIN_RESEND_OTP_FAILED,
      });
      toastr.error("", handleMessage(response));
    }
  } catch (error: any) {
    yield put({
      type: AUTH_ACTIONS.LOGIN_RESEND_OTP_FAILED,
    });
    toastr.error("", handleMessage(error));
  }
}

function* sendQueryDataSaga(action: actionInterface) {
  try {
    const response = yield call(
      authService.sendQueryDataService,
      action?.payload
    );

    if (response.status === apiStatusCode.SUCCESS) {
      yield put({
        type: AUTH_ACTIONS.SEND_QUERY_DATA_SUCCESS,
        payload: response?.data?.data,
      });

      action.payload.successCallback && action.payload.successCallback();
      
    } else {
      yield put({
        type: AUTH_ACTIONS.SEND_QUERY_DATA_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: AUTH_ACTIONS.SEND_QUERY_DATA_FAILED,
    });
  }
}

//Guest Login saga
function* guestLoginSaga(action: actionInterface) {
  try {
    const response = yield call(authService.guestLoginService);

    if (response.status === apiStatusCode.SUCCESS) {
      yield put({
        type: AUTH_ACTIONS.GUEST_LOGIN_DATA_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      yield put({
        type: AUTH_ACTIONS.GUEST_LOGIN_DATA_SUCCESS,
      });
    }
  } catch (error) {
    yield put({
      type: AUTH_ACTIONS.GUEST_LOGIN_DATA_SUCCESS,
    });
  }
}

/* master function */
function* newAuthSaga() {
  yield takeLatest(AUTH_ACTIONS.SIGNUP_REQUESTED, signUpUser);
  yield takeLatest(AUTH_ACTIONS.SIGNUP_VERIFY_OTP_REQUESTED, signUpVerifyOtp);
  yield takeLatest(AUTH_ACTIONS.SIGNUP_RESEND_OTP_REQUESTED, signUpResendOtp);
  yield takeLatest(AUTH_ACTIONS.LOGIN_REQUESTED, loginUser);
  yield takeLatest(AUTH_ACTIONS.LOGIN_VERIFY_OTP_REQUESTED, loginVerify);
  yield takeLatest(AUTH_ACTIONS.LOGIN_RESEND_OTP_REQUESTED, loginResendOtp);
  // yield takeLatest(AUTH_ACTIONS.LOGOUT_REQUESTED, getUserProfile);
  yield takeLatest(
    AUTH_ACTIONS.GET_USER_PROFILE_DATA_REQUESTED,
    getUserProfile
  );
  yield takeLatest(AUTH_ACTIONS.SEND_QUERY_DATA_REQUESTED, sendQueryDataSaga);
  yield takeLatest(AUTH_ACTIONS.GUEST_LOGIN_DATA_REQUESTED, guestLoginSaga);
}

export default newAuthSaga;
