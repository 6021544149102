import { Box, Grid2 } from "@mui/material";
import { BlogCardData } from "./BlogCardData";
import styles from "../index.module.css";

export const BlogFirstCard = ({ cardDetails }: any) => {
  return (
    <Box marginBottom="40px">
      <Grid2 container alignItems="center">
        <Grid2 size={{ sm: 12, md: 5 }}>
          <img
            src={cardDetails?.image}
            alt={cardDetails?.title}
            style={{ maxWidth: "100%", objectFit: "cover" }}
          />
        </Grid2>
        <Grid2 size={{ sm: 12, md: 7 }}>
          <BlogCardData
            data={cardDetails}
            classNameStyle={styles.blogFirstCard}
          />
        </Grid2>
      </Grid2>
    </Box>
  );
};
