import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import { commonServices } from "../services";
import {
  categorizeSlots,
  findObjectInArray,
  getLocalStorageData,
  handleMessage,
  isArrayNotEmpty,
  setLocalStorageData,
} from "../../utills/helper";
import { toastr } from "react-redux-toastr";
import rentalEn from "../../locales/en.json";
import { homePageFilters } from "../../rental-src/constants/enum";
import {
  DeliveryModesEnum,
  LocalStorage,
  useCaseEnum,
} from "../../constant/enum";

const {
  GET_MASTER_PLAN_DURATIONS_REQUESTED,
  GET_MASTER_PLAN_DURATIONS_SUCCESS,
  GET_MASTER_PLAN_DURATIONS_FAILED,

  GET_FILTERED_MODEL_LIST_REQUESTED,
  GET_FILTERED_MODEL_LIST_SUCCESS,
  GET_FILTERED_MODEL_LIST_FAILED,

  SET_CAROUSAL_FILTER,
  SET_RENTAL_FLEET_FILTER,

  GET_RENTAL_MASTER_CITY_LIST_REQUESTED,
  GET_RENTAL_MASTER_CITY_LIST_SUCCESS,
  GET_RENTAL_MASTER_CITY_LIST_FAILED,

  GET_NEAREST_LOCATED_CITY_REQUESTED,
  GET_NEAREST_LOCATED_CITY_SUCCESS,
  GET_NEAREST_LOCATED_CITY_FAILED,

  GET_RENTAL_MODEL_DETAILS_REQUESTED,
  GET_RENTAL_MODEL_DETAILS_SUCCESS,
  GET_RENTAL_MODEL_DETAILS_FAILED,

  GET_CUSTOMER_ADDRESSES_REQUESTED,
  GET_CUSTOMER_ADDRESSES_SUCCESS,
  GET_CUSTOMER_ADDRESSES_FAILED,

  GET_MODEL_CHECKOUT_DETAILS_REQUESTED,
  GET_MODEL_CHECKOUT_DETAILS_SUCCESS,
  GET_MODEL_CHECKOUT_DETAILS_FAILED,

  GET_CALCULATED_CHARGES_REQUESTED,
  GET_CALCULATED_CHARGES_SUCCESS,
  GET_CALCULATED_CHARGES_FAILED,

  GET_AVAILABLE_TIMESLOTS_REQUESTED,
  GET_AVAILABLE_TIMESLOTS_SUCCESS,
  GET_AVAILABLE_TIMESLOTS_FAILED,

  GET_DELIVERY_SLOT_PAUSE_REQUESTED,
  GET_DELIVERY_SLOT_PAUSE_SUCCESS,
  GET_DELIVERY_SLOT_PAUSE_FAILED,
  SET_MASTER_MODEL_FOR_CITY,
} = actionTypes.COMMON_ACTIONS;

// fetch master plan durations
function* getMasterPlanDurations(action: any): any {
  try {
    const data = yield call(
      commonServices.getMasterPlanDurationsService,
      action.payload
    );
    if (data?.status === 200 || data?.status === 201) {
      const masterPlanDuration = isArrayNotEmpty(data?.data?.data)
        ? data?.data?.data
        : [];

      // if (action.payload) {
      masterPlanDuration.push("Custom");
      // }
      yield put({
        type: GET_MASTER_PLAN_DURATIONS_SUCCESS,
        /* payload: {
          masterPlanDurationsData: masterPlanDuration,
        }, */
        payload: masterPlanDuration,
      });
    } else {
      toastr.info(rentalEn?.toastTypes?.info, handleMessage(data));
    }
  } catch (error) {
    toastr.error(rentalEn?.toastTypes?.error, handleMessage(error));
    yield put({
      type: GET_MASTER_PLAN_DURATIONS_FAILED,
    });
  }
}

// get vehicle model list
function* getFilteredModelList(action: any): any {
  try {
    const payload = action?.payload;
    const data = yield call(
      commonServices.getFilteredModelListService,
      payload
    );
    if (data?.status === 200) {
      yield put({
        type: GET_FILTERED_MODEL_LIST_SUCCESS,
        payload: data?.data?.data,
      });

      let masterModels = data?.data?.data;
      if (payload?.setMasterModel && masterModels && Object.keys(masterModels).length > 0) {
        let availableModels = masterModels?.availableModels;
        availableModels = availableModels.map((model) => {
          return {
            modelName: model?.modelName,
            displayName: model.displayName,
          };
        })
        yield put({
          type: SET_MASTER_MODEL_FOR_CITY,
          payload: availableModels,
        });
      }

      // set vehicle model filters
      if (
        payload?.filterType &&
        payload?.filterType === homePageFilters.carousalFilter
      ) {
        yield put({
          type: SET_CAROUSAL_FILTER,
          payload: payload?.FilterPayload,
        });
        // redirect to rent page
        action.payload.navigate(`/rent`, {
          replace: true,
        });
      }
      if (
        payload?.filterType &&
        payload?.filterType === homePageFilters.rentingFleetFilter
      ) {
        yield put({
          type: SET_RENTAL_FLEET_FILTER,
          payload: payload?.FilterPayload,
        });
      }
    } else {
      yield put({
        type: GET_FILTERED_MODEL_LIST_FAILED,
        payload: {},
      });
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    yield put({
      type: GET_FILTERED_MODEL_LIST_FAILED,
      payload: {},
    });
    toastr.error("", handleMessage(error));
  }
}

// get city list
function* getRentalMasterCityList(action: any) {
  try {
    const payload = action?.payload;
    const data = yield call(
      commonServices.getRentalMasterCityListService,
      payload?.payload
    );
    if (data?.status === 200) {
      yield put({
        type: GET_RENTAL_MASTER_CITY_LIST_SUCCESS,
        payload: data?.data?.data,
      });
    } else {
      yield put({
        type: GET_RENTAL_MASTER_CITY_LIST_FAILED,
        payload: {},
      });
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    yield put({
      type: GET_RENTAL_MASTER_CITY_LIST_FAILED,
      payload: {},
    });
    toastr.error("", handleMessage(error));
  }
}

// Detect Location (City)
function* getRentalNearestLocatedCity(action: any) {
  try {
    const data = yield call(
      commonServices.findRentalNearestCityService,
      action.payload
    );
    if (data?.status === 200) {
      let currentCity = data?.data?.data;
      if (currentCity && currentCity.length > 0) {
        let selectedCityObj = currentCity[0];
        yield put({ type: GET_NEAREST_LOCATED_CITY_SUCCESS, selectedCityObj });
        // success callback
        if (action.handleSuccess) {
          action.handleSuccess(selectedCityObj);
        }
      } else {
        toastr.error("", rentalEn?.errorMessages?.NoCityFound);
        yield put({ type: GET_NEAREST_LOCATED_CITY_FAILED });
      }
    } else {
      toastr.error("", rentalEn?.errorMessages?.NoCityFound);
      yield put({ type: GET_NEAREST_LOCATED_CITY_FAILED });
    }
  } catch (error) {
    toastr.error("", rentalEn?.errorMessages?.NoCityFound);
    yield put({ type: GET_NEAREST_LOCATED_CITY_FAILED });
  }
}

// get model details for preview
function* getRentalModelDetails(action: any) {
  try {
    const data = yield call(
      commonServices.getRentalModelDetailsService,
      action.payload
    );
    if (data?.status === 200 && data?.data) {
      let modelDetails = data?.data?.data?.modelDetails;
      yield put({
        type: GET_RENTAL_MODEL_DETAILS_SUCCESS,
        payload: modelDetails?.length > 0 ? modelDetails[0] : {},
      });
    } else {
      toastr.error("", rentalEn?.errorMessages?.NoModelDetailsFound);
      yield put({ type: GET_RENTAL_MODEL_DETAILS_FAILED });
    }
  } catch (error) {
    toastr.error("", rentalEn?.errorMessages?.NoModelDetailsFound);
    yield put({ type: GET_RENTAL_MODEL_DETAILS_FAILED });
  }
}

// get customer addresses saga
function* getCustomerAddresses(action: any) {
  try {
    const data = yield call(
      commonServices.getCustomerAddressesService,
      action.payload
    );
    if (data?.status === 200) {
      yield put({
        type: GET_CUSTOMER_ADDRESSES_SUCCESS,
        payload: data?.data?.data,
      });
    } else {
      yield put({
        type: GET_CUSTOMER_ADDRESSES_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    yield put({
      type: GET_CUSTOMER_ADDRESSES_FAILED,
    });
    toastr.error("", handleMessage(error));
  }
}

// get vehicle model's checkout details
function* getModelCheckoutDetails(action: any) {
  try {
    const data = yield call(
      commonServices.getModelCheckoutDetailsService,
      action.payload,
      action.modelName
    );
    if (data?.status === 200 || data?.status === 201) {
      const checkoutModelData = data?.data?.data;
      yield put({
        type: GET_MODEL_CHECKOUT_DETAILS_SUCCESS,
        payload: checkoutModelData,
      });
      const modelAvailability = checkoutModelData?.availability;
      const userData = action.userData;
      const FilterPayloads = action.payload;

      let userSelection = {
        startDate: FilterPayloads?.startDate,
        endDate: FilterPayloads?.endDate,
        model: checkoutModelData?.modelName,
        city: FilterPayloads?.cities,
        package: FilterPayloads?.package
      };
      setLocalStorageData(LocalStorage.userSelection, userSelection);
      action.navigate("/checkout");

      /*  
        Calculate Charge API logic
        1. If single branch - show pre-selected
        2. If only regular plan i.e if premium & unlimited plans are unavailable.
       */
      if (modelAvailability && modelAvailability.length === 1) {
        const modelAvailabilityFirst = modelAvailability[0];
        const branchName = modelAvailabilityFirst["branchDetails"]["name"];
        /* 
          1. Get available time slots
          2. Get branch slot pause
          3. Calculate charge with regular plan
        */

        // Get available time slots - branchName: string, payload: {deliveryType,bookingStartDate}
        const timeSlotsPayload = {
          deliveryType: DeliveryModesEnum.BRANCH,
          bookingStartDate: FilterPayloads?.startDate,
        };
        yield put({
          type: GET_AVAILABLE_TIMESLOTS_REQUESTED,
          branchName: branchName,
          payload: timeSlotsPayload,
        });

        // Get branch slot pause - payload: {branch=IN_KA_BENGALURU_FREEDO_BENGALURU&startDate=1732291496021&endDate=1747843496021}
        const branchSlotPausePayload = {
          branch: branchName,
          startDate: FilterPayloads?.endDate,
          endDate: FilterPayloads?.startDate,
        };
        yield put({
          type: GET_DELIVERY_SLOT_PAUSE_REQUESTED,
          payload: branchSlotPausePayload,
        });

        // On Slot & slot pause success - Calculate charge with regular plan
        const pricingLength = Object.keys(
          modelAvailabilityFirst["pricing"]
        ).length;

        const isComplementary =
          pricingLength > 0 &&
          modelAvailabilityFirst?.pricing?.benefits?.length > 0 &&
          modelAvailabilityFirst?.pricing?.benefits?.findIndex(
            (item, index) => item.type === "COMPLEMENTARY_HELMET"
          ) !== -1
            ? true
            : false;

        const chargePayload = {
          modelName: action.modelName,
          branchName: branchName,
          useCase: useCaseEnum.RENTALS,
          customerId: userData?.id,
          startDate: FilterPayloads?.startDate,
          endDate: FilterPayloads?.endDate,
          complementaryHelmet: isComplementary,
          unlimitedSelected: false,
          premiumSelected: false,
          deliveryDetails: {},
          helmetSelection: {
            PREMIUM: 0,
            HALF_FACE: 0,
            FULL_FACE: 0,
            KIDS: 0,
          },
        };

        // call calculate charge
        action.modelCheckoutCallback(chargePayload);

        // If other plans not available
        if (
          pricingLength > 0 &&
          !modelAvailability[0]["pricing"]["premium"]["available"] &&
          !modelAvailability[0]["pricing"]["unlimited"]["available"]
        ) {
        }
      }
    } else {
      yield put({
        type: GET_MODEL_CHECKOUT_DETAILS_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    yield put({ type: GET_MODEL_CHECKOUT_DETAILS_FAILED });
    toastr.error("", handleMessage(error));
  }
}

// get time slots for delivery
function* getAvailableTimeSlots(action: any) {
  try {
    const data = yield call(
      commonServices.getBranchTimeSlotsService,
      action.payload,
      action.branchName
    );
    if (data?.status === 200 || data?.status === 201) {
      const slotsResponse = data?.data?.data;
      const categorizedSlots = categorizeSlots(slotsResponse?.slots);
      yield put({
        type: GET_AVAILABLE_TIMESLOTS_SUCCESS,
        payload: { ...slotsResponse, slots: categorizedSlots },
      });
    } else {
      yield put({
        type: GET_AVAILABLE_TIMESLOTS_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    yield put({ type: GET_AVAILABLE_TIMESLOTS_FAILED });
    toastr.error("", handleMessage(error));
  }
}

// get calculated charges
function* getCalculatedCharges(action: any) {
  try {
    const data = yield call(
      commonServices.getCalculatedChargesService,
      action.payload
    );
    if (data?.status === 200 || data?.status === 201) {
      yield put({
        type: GET_CALCULATED_CHARGES_SUCCESS,
        payload: data?.data?.data,
      });
    } else {
      yield put({
        type: GET_CALCULATED_CHARGES_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    yield put({ type: GET_CALCULATED_CHARGES_FAILED });
    toastr.error("", handleMessage(error));
  }
}

// get slot pause for branch
function* getDeliverySlotPause(action: any) {
  try {
    const data = yield call(
      commonServices.getDeliverySlotPauseService,
      action.payload
    );
    if (data?.status === 200 || data?.status === 201) {
      yield put({
        type: GET_DELIVERY_SLOT_PAUSE_SUCCESS,
        payload: data?.data?.data?.slotPauseDates,
      });
    } else {
      yield put({
        type: GET_DELIVERY_SLOT_PAUSE_FAILED,
      });
      toastr.error("", handleMessage(data));
    }
  } catch (error) {
    yield put({ type: GET_DELIVERY_SLOT_PAUSE_FAILED });
    toastr.error("", handleMessage(error));
  }
}

function* commonSaga() {
  yield takeLatest(GET_MASTER_PLAN_DURATIONS_REQUESTED, getMasterPlanDurations);
  yield takeLatest(GET_FILTERED_MODEL_LIST_REQUESTED, getFilteredModelList);
  yield takeLatest(
    GET_RENTAL_MASTER_CITY_LIST_REQUESTED,
    getRentalMasterCityList
  );
  yield takeLatest(
    GET_NEAREST_LOCATED_CITY_REQUESTED,
    getRentalNearestLocatedCity
  );
  yield takeLatest(GET_RENTAL_MODEL_DETAILS_REQUESTED, getRentalModelDetails);
  yield takeLatest(GET_CUSTOMER_ADDRESSES_REQUESTED, getCustomerAddresses);
  yield takeLatest(
    GET_MODEL_CHECKOUT_DETAILS_REQUESTED,
    getModelCheckoutDetails
  );
  yield takeLatest(GET_CALCULATED_CHARGES_REQUESTED, getCalculatedCharges);
  yield takeLatest(GET_AVAILABLE_TIMESLOTS_REQUESTED, getAvailableTimeSlots);
  yield takeLatest(GET_DELIVERY_SLOT_PAUSE_REQUESTED, getDeliverySlotPause);
}

export default commonSaga;
