import { Box, Typography } from "@mui/material";
import styles from "../index.module.css";
import { colors } from "../../../../themes/colors";

export const Tag = ({ text }: any) => {
  return (
    <Box className={styles.tag}>
      <Typography variant="body1" color={colors.white}>
        {text}
      </Typography>
    </Box>
  );
};
