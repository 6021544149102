import { GenericObject } from "../../../utills/helper";
import { MyBookingsActions } from "../actions/actionTypes";
interface initialStateInterface {
  bookingHistory: object;
  bookingDetails: object;
  paymentSummary: object;
  planSummary: object;
  loading: boolean;
  yorBookingHistory: object;
  vehicleDocuments: object;
  documentSignedUrl: string;
  cancellationChargeLoader: boolean;
  cancellationCharges: GenericObject;
  cancelBookingLoader: boolean;
}
const initialState: initialStateInterface = {
  bookingHistory: {},
  bookingDetails: {},
  paymentSummary: {},
  planSummary: {},
  loading: false,
  yorBookingHistory: {},
  vehicleDocuments: {},
  documentSignedUrl: "",
  cancellationChargeLoader: false,
  cancellationCharges: {},
  cancelBookingLoader: false,
};
const MyBookingReducer = (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case MyBookingsActions.GET_BOOKING_HISTORY_REQUESTED:
      return {
        ...state,
      };
    case MyBookingsActions.GET_BOOKING_HISTORY_SUCCESS:
      return {
        ...state,
        bookingHistory: payload,
      };
    case MyBookingsActions.GET_BOOKING_HISTORY_FAILED:
      return {
        ...state,
      };

    case MyBookingsActions.GET_BOOKING_DETAILS_REQUESTED:
      return {
        ...state,
        bookingDetails: {},
        paymentSummary: {},
        planSummary: {},
        loading: true,
      };
    case MyBookingsActions.GET_BOOKING_DETAILS_SUCCESS:
      return {
        ...state,
        bookingDetails: payload?.bookingDetails,
        paymentSummary: payload?.paymentSummary,
        planSummary: payload?.planSummary,
        loading: false,
      };
    case MyBookingsActions.GET_BOOKING_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
      };

    case MyBookingsActions.GET_YOR_BOOKING_HISTORY_REQUESTED:
      return {
        ...state,
      };

    case MyBookingsActions.GET_YOR_BOOKING_HISTORY_SUCCESS:
      return {
        ...state,
        yorBookingHistory: payload,
      };
    case MyBookingsActions.GET_YOR_BOOKING_HISTORY_FAILED:
      return {
        ...state,
      };

    case MyBookingsActions.GET_VEHICLE_DOCUMENTS_REQUESTED:
      return {
        ...state,
      };
    case MyBookingsActions.GET_VEHICLE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        vehicleDocuments: payload,
      };
    case MyBookingsActions.GET_VEHICLE_DOCUMENTS_FAILED:
      return {
        ...state,
      };

    case MyBookingsActions.DOWNLOAD_VEHICLE_DOCUMENT_REQUESTED:
      return {
        ...state,
      };
    case MyBookingsActions.DOWNLOAD_VEHICLE_DOCUMENT_SUCCESS:
      return {
        ...state,
        documentSignedUrl: payload,
      };
    case MyBookingsActions.DOWNLOAD_VEHICLE_DOCUMENT_FAILED:
      return {
        ...state,
      };

    case MyBookingsActions.CLEAR_BOOKING_DETAILS:
      return {
        ...state,
        bookingDetails: {},
        paymentSummary: {},
        planSummary: {},
      };

    case MyBookingsActions.GET_BOOKING_CANCELLATION_CHARGES_REQUESTED:
      return {
        ...state,
        cancellationChargeLoader: true,
        cancellationCharges: {},
      };

    case MyBookingsActions.GET_BOOKING_CANCELLATION_CHARGES_SUCCESS:
      return {
        ...state,
        cancellationChargeLoader: false,
        cancellationCharges: payload,
      };

    case MyBookingsActions.GET_BOOKING_CANCELLATION_CHARGES_FAILED:
      return {
        ...state,
        cancellationChargeLoader: false,
        cancellationCharges: {},
      };

    case MyBookingsActions.CANCEL_USER_BOOKING_REQUESTED:
      return {
        ...state,
        cancelBookingLoader: true,
      };

    case MyBookingsActions.CANCEL_USER_BOOKING_SUCCESS:
      return {
        ...state,
        cancelBookingLoader: false,
      };

    case MyBookingsActions.CANCEL_USER_BOOKING_FAILED:
      return {
        ...state,
        cancelBookingLoader: false,
      };

    default:
      return {
        ...state,
      };
  }
};

export default MyBookingReducer;
